import { combineReducers, configureStore } from '@reduxjs/toolkit';
import userReducer from './reducers/userSlice';
import appReducer from './reducers/appSlice';
import permissonsReducer from './reducers/permissionsSlice';
import { permissonsSlice } from './reducers/permissionsSlice';

const rootReducer = combineReducers({
  user: userReducer,
  app: appReducer,
  permissons: permissonsReducer,
});

export const store = configureStore({
  reducer: rootReducer,
});
