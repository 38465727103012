import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { injectIntl } from 'react-intl';
import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Popper,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import {
  deleteConfirmationTrackersRow,
  getConfirmationSettingsTrackers,
  getConfirmationTrackersNewRow,
  getConfirmationTrackersRow,
  saveConfirmationTrackersRow,
} from '../../../../../packages/common/api';
import clsx from 'clsx';
import SortIcon from '../../../../../packages/common/shared-ui/src/icons/SortIcon';
import {
  DataGridPro,
  GridActionsCellItem,
  GridCellEditStartReasons,
  GridRowEditStopReasons,
  GridRowModes,
} from '@mui/x-data-grid-pro';
import dataGridTableStyles from '../../../../../packages/common/shared-ui/src/styles/DataGridTable.styles';
import { getTranslatedText } from '../../../../../packages/common/utils/getTranslatedText';
import ChevronIcon from '../../../../../packages/common/shared-ui/src/icons/ChevronIcon';
import SubmitIcon from '../../../../../packages/common/shared-ui/src/icons/SubmitIcon';
import DeleteIconBig from '../../../../../packages/common/shared-ui/src/icons/DeleteIconBig';
import UserNewEditIcon from '../../../../../packages/common/shared-ui/src/icons/UserNewEditIcon';
import PlusIcon from '../../../../../packages/common/shared-ui/src/icons/PlusIcon';
import formFieldsStyles from '../../../../../packages/common/shared-ui/src/styles/FormFields.styles';
import CheckboxIconChecked from '../../../../../packages/common/shared-ui/src/icons/CheckboxIconChecked';
import DeleteIcon from '../../../../../packages/common/shared-ui/src/icons/DeleteIcon';
import CheckboxIcon from '../../../../../packages/common/shared-ui/src/icons/CheckboxIcon';
import { useNavigate } from 'react-router';

const ConfirmationSettingsTrackers = ({ intl }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [gridRows, setGridRows] = useState([]);
  const [gridColumns, setGridColumns] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});

  const fieldsClasses = formFieldsStyles();
  const dataGridTableStyle = dataGridTableStyles();
  const handleError = useCallback(
    error => {
      if (error) {
        navigate(`/errors/error-${error.status}`);
      }
    },
    [navigate],
  );

  const handleDelete = useCallback(
    async (id, row) => {
      if (row.new) {
        const newArray = gridRows.filter(item => item.id !== row.id);
        setGridRows(newArray);
        return;
      }
      try {
        setLoading(true);
        const response = await deleteConfirmationTrackersRow(id);
        if (response) {
          setGridRows(gridRows.filter(row => row.id !== id));
          setLoading(false);
        }
      } catch (error) {
        console.error('ERROR WITH DELETE', error);
      }
    },
    [gridRows],
  );

  const handleEditClick = useCallback(
    async id => {
      const index = gridRows.findIndex(item => item.id === id);
      try {
        const { data: response } = await getConfirmationTrackersRow(id);
        if (response) {
          const updatedObject = {
            ...gridRows[index],
            confirmationTracker:
              response.confirmationTrackers.find(item => item.isChecked) !== undefined
                ? response.confirmationTrackers.find(item => item.isChecked)
                : null,
            confirmationFieldForEdit: response.confirmationFieldsForEdit.filter(item => item.isChecked),
            confirmationFieldForView: response.confirmationFieldsForView.filter(item => item.isChecked),
            confirmationTrackers: response.confirmationTrackers,
            confirmationFieldForEditValues: response.confirmationFieldsForEdit,
            confirmationFieldForViewValues: response.confirmationFieldsForView,
          };
          setGridRows([...gridRows.slice(0, index), updatedObject, ...gridRows.slice(index + 1)]);
          setRowModesModel(oldModel => ({
            ...oldModel,
            [id]: {
              mode: GridRowModes.Edit,
            },
          }));
        }
      } catch (error) {
        console.error('ERROR WITH GET', error);
      }
    },
    [gridRows],
  );
  const handleTrackerField = useCallback(
    async (data, id, field) => {
      const pos = gridRows.findIndex(item => item.id === id);
      try {
        const { data: response } = await getConfirmationTrackersRow(data.valueId);
        if (response) {
          const updatedObject = {
            ...gridRows[pos],
            confirmationTracker:
              response.confirmationTrackers.find(item => item.isChecked) !== undefined
                ? response.confirmationTrackers.find(item => item.isChecked)
                : null,
            confirmationFieldForEdit: response.confirmationFieldsForEdit.filter(item => item.isChecked),
            confirmationFieldForView: response.confirmationFieldsForView.filter(item => item.isChecked),
            confirmationTrackers: response.confirmationTrackers,
            confirmationFieldForEditValues: response.confirmationFieldsForEdit,
            confirmationFieldForViewValues: response.confirmationFieldsForView,
          };
          setGridRows([...gridRows.slice(0, pos), updatedObject, ...gridRows.slice(pos + 1)]);
        }
      } catch (error) {
        console.error('ERROR WITH GET', error);
      }
    },
    [gridRows],
  );
  const handleField = useCallback(
    (data, id, field) => {
      let updatedObject;
      const pos = gridRows.findIndex(item => item.id === id);
      updatedObject = {
        ...gridRows[pos],
        [field]: data,
      };
      const newFields = [...gridRows.slice(0, pos), updatedObject, ...gridRows.slice(pos + 1)];
      setGridRows(newFields);
    },
    [gridRows],
  );

  const formattedColumns = useCallback(() => {
    const smallFields = ['tracker'];
    const columns = gridColumns.map(column => {
      return {
        field: column.field,
        headerName: intl.formatMessage({
          id: `confirmation_trackers_${column.field}`,
        }),
        renderHeader: props => (
          <Typography variant="h4" fontWeight={700}>
            {props.colDef.headerName}
          </Typography>
        ),
        disableExport: false,
        position: column.position,
        flex: smallFields.includes(column.field) ? 0 : 1,
        width: smallFields.includes(column.field) && 250,
        cellClassName: props => (props.cellMode === 'view' ? 'tableCell' : 'tableCell cellEdit'),
        editable: true,
        fieldType: column.fieldType,
        //renderCell: props => {
        //  if (props.field === 'confirmation_trackers.url') {
        //    return (
        //      <Box component={'span'} color={'#3448FF'}>
        //        {props.value}
        //      </Box>
        //    );
        //  } else {
        //    {
        //      return props.value;
        //    }
        //  }
        //},
        renderEditCell: props => {
          const currentRow = gridRows.find(row => row.id === props.row.id);
          if (currentRow !== undefined) {
            if (props.colDef.field === 'confirmationTracker') {
              return (
                <Autocomplete
                  //open={true}
                  noOptionsText={intl.formatMessage({ id: 'not_found' })}
                  onChange={(event, newValue) => handleTrackerField(newValue, props.row.id, 'confirmationTracker')}
                  className={clsx(fieldsClasses.fieldAutocomplete, fieldsClasses.fieldAutocompleteGrid)}
                  //disablePortal
                  options={currentRow.confirmationTrackers ? currentRow.confirmationTrackers : []}
                  renderOption={(props, option) => {
                    return (
                      <MenuItem
                        {...props}
                        key={option.valueId}
                        value={option.valueId}
                        //selected={option.valueId === field.valueId}
                      >
                        {option.valueName}
                      </MenuItem>
                    );
                  }}
                  value={currentRow.confirmationTracker ? currentRow.confirmationTracker : null}
                  isOptionEqualToValue={(option, value) => option.valueId === value.valueId}
                  getOptionLabel={option => option.valueName || ''}
                  PopperComponent={props => (
                    <Popper
                      {...props}
                      className={fieldsClasses.gridPopper}
                      sx={{ maxWidth: '100%!important' }}
                      placement="bottom-start"
                    ></Popper>
                  )}
                  renderInput={params => <TextField {...params} placeholder={intl.formatMessage({ id: 'enter' })} />}
                  popupIcon={<ChevronIcon direction="down"></ChevronIcon>}
                />
              );
            }
            if (
              props.colDef.field === 'confirmationFieldForEdit' ||
              props.colDef.field === 'confirmationFieldForView'
            ) {
              return (
                <Autocomplete
                  disabled={!currentRow.confirmationTracker}
                  forcePopupIcon={false}
                  className={clsx(
                    fieldsClasses.fieldAutocomplete,
                    fieldsClasses.fieldAutocompleteMultiple,
                    fieldsClasses.fieldAutocompleteGrid,
                  )}
                  multiple
                  disableClearable
                  //open={true}
                  onChange={(event, newValue) => handleField(newValue, props.row.id, props.colDef.field)}
                  options={currentRow[`${props.colDef.field}Values`] ? currentRow[`${props.colDef.field}Values`] : []}
                  disableCloseOnSelect
                  //disablePortal
                  value={currentRow[props.colDef.field] ? currentRow[props.colDef.field] : []}
                  isOptionEqualToValue={(option, value) => option.valueId === value.valueId}
                  getOptionLabel={option => option.valueName || ''}
                  renderOption={(props, option, { selected }) => {
                    return (
                      <li {...props} key={option.valueId}>
                        <Checkbox
                          icon=<CheckboxIcon width="12" height="12" viewBox="0 0 12 12" />
                          checkedIcon=<CheckboxIconChecked width="12" height="12" viewBox="0 0 12 12" />
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.valueName}
                      </li>
                    );
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                      const item = currentRow[props.colDef.field].find(item => Number(item.valueId) === option);
                      return (
                        <Chip
                          {...getTagProps({ index })}
                          key={index}
                          deleteIcon={<DeleteIcon width={10} height={10} viewBox="0 0 10 10" />}
                          className={fieldsClasses.chip}
                          variant="outlined"
                          label={item !== undefined ? item.valueName : option.valueName}
                        />
                      );
                    })
                  }
                  renderInput={params => <TextField {...params} placeholder={intl.formatMessage({ id: 'enter' })} />}
                  PopperComponent={props => (
                    <Popper
                      {...props}
                      className={fieldsClasses.gridPopper}
                      sx={{ maxWidth: '100%!important' }}
                      placement="bottom-start"
                    ></Popper>
                  )}
                />
              );
            }
          }
        },
      };
    });
    return [
      ...columns,
      {
        field: 'actions',
        type: 'actions',
        headerName: '',
        headerClassName: 'actions',
        width: 70,
        cellClassName: 'actions tableCell',
        getActions: ({ id, row }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
          if (isInEditMode) {
            return [
              <GridActionsCellItem onClick={() => handleSaveClick(id, row)} icon={<SubmitIcon />} label="Save" />,
              <GridActionsCellItem onClick={() => handleDelete(id, row)} icon={<DeleteIconBig />} label="Cancel" />,
            ];
          }
          return [
            <GridActionsCellItem
              onClick={() => handleEditClick(id)}
              icon={<UserNewEditIcon width="16" height="16" viewBox="0 0 16 16" />}
              label="Edit"
            />,
            <GridActionsCellItem
              onClick={() => handleDelete(id, row)}
              icon={<DeleteIconBig />}
              label="Delete"
              color="inherit"
            />,
          ];
        },
      },
    ];
  }, [
    fieldsClasses.chip,
    fieldsClasses.fieldAutocomplete,
    fieldsClasses.fieldAutocompleteGrid,
    fieldsClasses.fieldAutocompleteMultiple,
    fieldsClasses.gridPopper,
    gridColumns,
    gridRows,
    handleDelete,
    handleEditClick,
    handleField,
    handleSaveClick,
    handleTrackerField,
    intl,
    rowModesModel,
  ]);
  const columnsValue = formattedColumns();

  const formattedRows = useCallback(data => {
    return data.reduce((acc, row) => {
      const newCols = row.columns.reduce((columnAcc, _, i) => {
        return {
          ...columnAcc,
          [row.columns[i].fieldId.substring(row.columns[i].fieldId.indexOf('.') + 1)]: row.columns[i].value,
        };
      }, {});
      return [...acc, { ...newCols, id: row.rowId }];
    }, []);
  }, []);

  useEffect(() => {
    let ignore = false;
    async function getConfirmationTrackers() {
      const { data: trackers } = await getConfirmationSettingsTrackers();
      if (!ignore) {
        return trackers;
      }
    }
    getConfirmationTrackers()
      .then(res => {
        setGridColumns(res.fields);
        setGridRows(formattedRows(res.rows));
        setLoading(false);
      })
      .catch(error => {
        handleError(error.response);
      });
    return () => {
      ignore = true;
    };
  }, [handleError, formattedRows]);

  const handleSaveClick = useCallback(
    async (id, row) => {
      const index = gridRows.findIndex(item => item.id === id);
      //const validation = validateRow(row.id);
      if (row.confirmationFieldForEdit.length.length === 0) {
        return;
      }
      try {
        setLoading(true);
        const data = {
          confirmationTracker: Number(row.confirmationTracker.valueId),
          confirmationFieldForEdit: row.confirmationFieldForEdit.map(item => item.valueId),
          confirmationFieldForView: row.confirmationFieldForView.map(item => item.valueId),
        };
        const response = await saveConfirmationTrackersRow(data);
        if (response) {
          const { data: res } = response;
          const newCols = res.columns.reduce((columnAcc, item, i) => {
            return {
              ...columnAcc,
              [item.fieldId.substring(item.fieldId.indexOf('.') + 1)]: item.value,
            };
          }, {});
          const updatedObject = {
            id: res.rowId,
            ...newCols,
          };
          setGridRows([...gridRows.slice(0, index), updatedObject, ...gridRows.slice(index + 1)]);
          setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View, ignoreModifications: true } });
          setLoading(false);
        }
      } catch (error) {
        console.error('ERROR WITH GET', error);
      }
    },
    [gridRows, rowModesModel],
  );
  useEffect(() => {
    console.log(rowModesModel);
  }, [rowModesModel]);
  const addRowHandler = useCallback(async () => {
    try {
      const { data: response } = await getConfirmationTrackersNewRow();
      if (response) {
        //const rowColumns = gridColumns.map(item => item.field);
        setGridRows(oldRows => [
          ...oldRows,
          {
            new: true,
            id: `row-${gridRows.length + 1}`,
            confirmationTrackers: response,
            confirmationTracker:
              response.find(item => item.isChecked) !== undefined ? response.find(item => item.isChecked) : null,
            confirmationFieldForEditValues: [],
            confirmationFieldForViewValues: [],
          },
        ]);
        setRowModesModel(oldModel => ({
          ...oldModel,
          [`row-${gridRows.length + 1}`]: {
            mode: GridRowModes.Edit,
          },
        }));
      }
    } catch (error) {
      console.error('ERROR WITH GET', error);
    }
  }, [gridRows.length]);

  const handleRowModesModelChange = useCallback(newRowModesModel => {
    setRowModesModel(newRowModesModel);
  }, []);

  const handleRowEditStop = useCallback((params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  }, []);
  const processRowUpdate = useCallback(
    (newRow, oldRow) => {
      const updatedRow = { ...newRow, isNew: false };
      setGridRows(gridRows.map(row => (row.id === newRow.id ? updatedRow : row)));
      return updatedRow;
    },
    [gridRows],
  );
  return (
    <Box
      marginTop={'16px'}
      borderRadius={'16px'}
      backgroundColor={gridColumns.length > 0 ? '#fff' : 'tranparent'}
      //minHeight={loading ? '184px' : 'auto'}
      position={'relative'}
    >
      {loading && (
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          width="100%"
          height="100%"
          zIndex={99}
          top={0}
          position={'absolute'}
        >
          <CircularProgress color="secondary" />
        </Grid>
      )}
      {gridColumns.length > 0 && (
        <>
          <DataGridPro
            onRowEditStart={(params, event) => {
              if (params.reason === GridCellEditStartReasons.printableKeyDown) {
                event.defaultMuiPrevented = true;
              }
              if (params.reason === GridCellEditStartReasons.cellDoubleClick) {
                event.defaultMuiPrevented = true;
              }
            }}
            autoHeight
            hideFooter
            disableRowSelectionOnClick
            disableColumnMenu
            columns={columnsValue}
            rows={gridRows}
            getRowClassName={params => !params.row.active && 'disabled'}
            className={clsx(dataGridTableStyle.DataGridTableRoot, dataGridTableStyle.DataGridTableRootAddRows)}
            slots={{
              columnUnsortedIcon: () => <SortIcon width="20" height="20" viewBox="0 0 20 20" />,
              columnSortedAscendingIcon: () => <SortIcon direction="up" width="20" height="20" viewBox="0 0 20 20" />,
              columnSortedDescendingIcon: () => (
                <SortIcon direction="down" width="20" height="20" viewBox="0 0 20 20" />
              ),
            }}
            getRowHeight={() => 'auto'}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
          />
          <Grid
            item
            padding={'8px 16px'}
            sx={{
              lineHeight: 'normal',
              borderRadius: '0 0 16px 16px',
              borderTop: gridRows.length > 0 ? 'none' : '1px solid #E4E4EF',
              backgroundColor: '#fff',
            }}
          >
            <IconButton
              onClick={addRowHandler}
              sx={{ padding: '0' }}
              disabled={gridRows.filter(item => item.new).length > 0}
            >
              <PlusIcon width="24" height="24" viewBox="0 0 24 24" />
            </IconButton>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default injectIntl(ConfirmationSettingsTrackers);
