import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Page from 'Common/shared-ui/src/components/Page';
import { store } from '../app/redux/index';
import { injectIntl } from 'react-intl';
import {
  Box,
  Grid,
  Select,
  IconButton,
  MenuItem,
  Typography,
  ToggleButton,
  FormControl,
  ToggleButtonGroup,
  CircularProgress,
} from '@mui/material';
import PlusIcon from 'Common/shared-ui/src/icons/PlusIcon';
import ProjectSettingsIcons from 'Common/shared-ui/src/icons/ProjectSettingsIcons';
import ProjectsViewTypeTilesIcon from 'Common/shared-ui/src/icons/ProjectsViewTypeTilesIcon';
import ProjectsViewTypeListIcon from 'Common/shared-ui/src/icons/ProjectsViewTypeListIcon';
import ProjectsTilesView from './ProjectsTilesView';
import ProjectsFilters from './ProjectsFilters';
import ProjectsListView from './ProjectsListView';
import useStyles from './ProjectsContainer.styles';
import {
  createNewSavedQuery,
  deleteSavedQuery,
  getIssues,
  getProjects,
  getProjectsFilters,
  getProjectsList,
  getProjectsWithQuery,
  getSavedQuery,
  getUserCanCreate,
} from '../../../packages/common/api';
import { generateRedirectUrl } from 'Common/utils/getRedirectUrl';
import { useNavigate, useLocation } from 'react-router';
import { setProject, toggleDrawer } from '../app/redux/reducers/userSlice';
import CommonPage from '../CommonPage/CommonPage';
import {
  filterDateValues,
  formattedParamsData,
  paramsToQuery,
  queryToParams,
  selectFilters,
} from '../../../packages/common/utils/gridFiltersHelperNew';
import IssuesTable from '../issues/IssuesTable/IssuesTable';
import GridFiltersModalNew from '../GridFiltersModalNew/GridFiltersModalNew';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import { useParams } from 'react-router-dom';
import GridSaveQueryModal from '../GridSaveQueryModal/GridSaveQueryModal';
import GridFiltersPanelNew from '../GridFiltersPanelNew/GridFiltersPanelNew';
import { ButtonsTypes } from '../../../packages/common/shared-ui/src/enums/commonEnums';
import WarningModal from '../../../packages/common/shared-ui/src/components/WarningModal';

const ProjectsContainer = ({ intl }) => {
  const classes = useStyles();
  const redmineToken = useSelector(state => state.user.redmine_access_token);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const closeDrawer = useCallback(() => dispatch(toggleDrawer(false)), [dispatch]);
  const [userCanCreate, setUserCanCreate] = React.useState(false);
  const [projects, setProjects] = React.useState([]);
  const [projectscColumns, setProjectscColumns] = React.useState([]);
  const [filters, setFilters] = React.useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const pageUseParams = useParams();
  const [query, setQuery] = useQueryParams({
    filters: StringParam,
    sorts: StringParam,
    fields: StringParam,
  });
  const [openParamsModal, setOpenParamsModal] = useState(false);
  const [openSaveQueryModal, setOpenSaveQueryModal] = useState(false);
  const [sortModel, setSortModel] = useState([]);
  const [queryData, setQueryData] = useState(null);
  const [currentParams, setCurrentParams] = useState(null);
  const [initialParams, setInitialParams] = useState(null);
  const [view, setView] = React.useState(window.localStorage.getItem('projectsView') || 'tiles');
  const [loading, setLoading] = useState(true);
  const [paramsLoading, setParamsLoading] = useState(false);
  const [gridRows, setGridRows] = useState([]);
  const [gridColumns, setGridColumns] = useState([]);
  const [canCreate, setCanCreate] = useState(false);
  const [openWarningModal, setOpenWarningModal] = useState(false);

  const handleError = useCallback(
    error => {
      if (error) {
        navigate(`/errors/error-${error.status}`);
      }
    },
    [navigate],
  );

  useEffect(() => {
    let ignore = false;
    let params = {};
    pageUseParams.queryId && (params.query = Number(pageUseParams.queryId));
    async function getProjectsFiltersData() {
      const response = await getProjectsFilters(params);
      if (!ignore) {
        return response;
      }
    }

    getProjectsFiltersData()
      .then(res => {
        const { data: filtersData } = res;
        const data = {};
        filtersData.filters && (data.filters = { items: filtersData.filters, type: 'list', multiple: true });
        filtersData.sorts && (data.sorts = { items: filtersData.sorts, type: 'checkbox' });
        filtersData.fields && (data.fields = { items: filtersData.fields, type: 'columns' });
        const formattedData = formattedParamsData(data);
        !initialParams && setInitialParams(formattedData);
        if (Object.values(query).filter(item => item !== undefined).length === 0) {
          setCurrentParams(formattedData);
          setQuery(paramsToQuery(formattedData));
        }
      })
      .catch(error => handleError(error.response));
    return () => {
      ignore = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleError, pageUseParams.queryId]);

  useEffect(() => {
    let ignore = false;
    let params = {};
    setParamsLoading(true);
    let queryIsSet = false;
    queryIsSet = Object.values(query).filter(item => item !== undefined).length > 0;
    queryIsSet && (params = { ...params, ...query });
    async function getProjectsData() {
      const response = await getProjectsList(params);
      if (!ignore) {
        return response;
      }
    }
    if (queryIsSet) {
      getProjectsData()
        .then(res => {
          const { data: projectsData } = res;
          projectsData.fields && setGridColumns(projectsData.fields);
          projectsData.rows && setGridRows(projectsData.rows);
          //const totalRow = issuesData.rows.find(item => item.rowType === 'total');
          //totalRow !== undefined && totalRow.columns && setTotals(totalRow.columns);
          setLoading(false);
          setParamsLoading(false);
        })
        .catch(error => handleError(error.response));
    }
    return () => {
      ignore = true;
    };
  }, [handleError, query]);

  //Получение query по id
  useEffect(() => {
    setQueryData(null);
    let ignore = false;
    async function getSavedQueries() {
      const { data: response } = await getSavedQuery({ id: pageUseParams.queryId });
      if (!ignore) {
        return response;
      }
    }
    if (pageUseParams.queryId) {
      getSavedQueries()
        .then(res => {
          res && setQueryData(res);
        })
        .catch(error => handleError(error.response));
    }
  }, [handleError, pageUseParams.queryId]);

  useEffect(() => {
    let ignore = false;

    async function getCanCreate() {
      const { data: response } = await getUserCanCreate();
      if (!ignore) {
        return response;
      }
    }

    getCanCreate()
      .then(res => {
        setCanCreate(res);
      })
      .catch(error => handleError(error.response));

    return () => {
      ignore = true;
    };
  }, [handleError]);

  useEffect(() => {
    if (Object.values(query).filter(item => item !== undefined).length > 0 && initialParams && !currentParams) {
      setCurrentParams(queryToParams(query, initialParams));
    }
  }, [currentParams, initialParams, query]);
  useEffect(() => {
    if (currentParams && currentParams.sorts && currentParams.sorts.selectedItems) {
      setSortModel(currentParams.sorts.selectedItems.map(item => ({ field: item.fieldId, sort: item.direction })));
    }
  }, [currentParams]);

  const handleSelectFilters = useCallback(
    data => {
      const result = selectFilters(data, currentParams);
      setCurrentParams(result);
      setQuery(paramsToQuery(result));
    },
    [currentParams, setQuery],
  );

  const handleParamsModalSave = useCallback(
    data => {
      setCurrentParams(data);
      setQuery(paramsToQuery(data));
      setOpenParamsModal(false);
    },
    [setQuery],
  );

  const handleSaveQueryModalSave = useCallback(
    async modalData => {
      let query = {
        filters: [],
        sorts: [],
        totals: [],
      };
      if (currentParams.filters && currentParams.filters.selectedItems) {
        const validFilters = currentParams.filters.selectedItems.filter(item => item.operation).length > 0;
        if (validFilters) {
          query.filters = currentParams.filters.selectedItems.map(filter => {
            let value = filter.values;
            Array.isArray(filter.values) && (value = filter.values.map(item => (item.valueId ? item.valueId : item)));
            return {
              instance: filter.instance,
              operation: filterDateValues.has(filter.operation)
                ? filterDateValues.get(filter.operation).operator
                : filter.operation,
              field: filter.field,
              values: value,
            };
          });
        }
      }
      if (currentParams.sorts && currentParams.sorts.selectedItems) {
        query.sorts = currentParams.sorts.selectedItems.map(item => {
          return {
            instance: item.instance,
            field: item.field,
            direction: item.direction,
          };
        });
      }
      if (currentParams.fields && currentParams.fields.selectedItems.selectedFields) {
        query.fields = currentParams.fields.selectedItems.selectedFields.map((item, index) => {
          return {
            instance: item.instance,
            field: item.field,
            position: index,
          };
        });
      }
      const data = {
        type: 'project',
        name: modalData.name,
        query: query,
        accessLevel: modalData.accessLevel,
        roles: modalData.roles,
        projectLevel: modalData.projectLevel,
        projectId: modalData.projectId,
      };
      try {
        const queryId = pageUseParams.queryId ? pageUseParams.queryId : null;
        const { data: response } = await createNewSavedQuery(data, queryId);
        if (response) {
          window.open(`/projects/query/${response.id}`, '_self');
        }
      } catch (error) {
        handleError(error.response);
      }
      setOpenSaveQueryModal(false);
    },
    [currentParams, handleError, pageUseParams.queryId],
  );

  const handleChangeView = (event, nextView) => {
    setView(nextView);
    window.localStorage.setItem('projectsView', nextView);
  };
  const pageTitle = intl.formatMessage({ id: 'page_title_projects' });

  const ViewToggle = () => {
    return (
      <ToggleButtonGroup
        orientation="vertical"
        value={view}
        exclusive
        className={classes.ProjectsViewChangeButtonWrap}
        onChange={handleChangeView}
      >
        {view !== 'list' ? (
          <ToggleButton value="list" aria-label="list" className={classes.ProjectsViewChangeButton}>
            <ProjectsViewTypeListIcon width={32} height={32} viewBox="0 0 32 32" />
          </ToggleButton>
        ) : null}
        {view !== 'tiles' ? (
          <ToggleButton value="tiles" aria-label="tiles" className={classes.ProjectsViewChangeButton}>
            <ProjectsViewTypeTilesIcon width={32} height={32} viewBox="0 0 32 32" />
          </ToggleButton>
        ) : null}
      </ToggleButtonGroup>
    );
  };

  const handleParamsModalClose = useCallback(() => {
    setOpenParamsModal(false);
  }, []);
  const handleSaveQueryModalClose = useCallback(() => {
    setOpenSaveQueryModal(false);
  }, []);
  const handleButtonEvent = useCallback(
    type => {
      switch (type) {
        case ButtonsTypes.ADD:
          const projectNewUrl = '/projects/create';
          navigate(projectNewUrl);
          break;
        case ButtonsTypes.PARAMS:
          setOpenParamsModal(true);
          break;
        case ButtonsTypes.QUERY:
          setOpenSaveQueryModal(true);
          break;
        case ButtonsTypes.EDIT_QUERY:
          setOpenSaveQueryModal(true);
          break;
        case ButtonsTypes.DELETE_QUERY:
          setOpenWarningModal(true);
          break;
      }
    },
    [navigate],
  );
  const handleWarningModalClose = useCallback(() => {
    setOpenWarningModal(false);
  }, []);
  const handleToggleDelete = useCallback(
    async e => {
      switch (e.currentTarget.attributes.value.value) {
        case 'save':
          await deleteSavedQuery({ id: pageUseParams.queryId });
          navigate('/projects');
          setOpenWarningModal(false);
          break;
        case 'delete':
          setOpenWarningModal(false);
          break;
      }
    },
    [pageUseParams.queryId, navigate],
  );
  const handleChangeSorting = useCallback(
    newSortModel => {
      if (!newSortModel || newSortModel.length === 0) {
        setQuery(prev => ({ ...prev, sorts: undefined }));
        return;
      }

      const sortString = newSortModel.map(item => `${item.field};${item.sort}`).join('$$');

      setQuery(prev => ({ ...prev, sorts: sortString }));
    },
    [setQuery],
  );
  return (
    <>
      <WarningModal
        simple
        isWarningModalOpen={openWarningModal}
        handleToggle={handleToggleDelete}
        closeWarningModal={handleWarningModalClose}
        title={`${intl.formatMessage({ id: 'spent_time_delete' })}?`}
      />
      {currentParams && (
        <GridFiltersModalNew
          handleSave={data => handleParamsModalSave(data)}
          handleClose={handleParamsModalClose}
          open={openParamsModal}
          data={currentParams}
        />
      )}
      <GridSaveQueryModal
        permission={'canSaveProjectsGlobalQueries'}
        queryData={queryData}
        open={openSaveQueryModal}
        handleSave={data => handleSaveQueryModalSave(data)}
        handleClose={handleSaveQueryModalClose}
      />
      <CommonPage
        pageTitle={pageTitle}
        loading={loading}
        buttons={<ViewToggle />}
        canCreate={canCreate}
        buttonEvent={handleButtonEvent}
      >
        {currentParams && currentParams.filters?.selectedItems?.length > 0 && (
          <GridFiltersPanelNew
            data={currentParams.filters.selectedItems}
            handleCloseFilter={handleSelectFilters}
          ></GridFiltersPanelNew>
        )}
        <Grid
          overflow={'auto'}
          container
          flexDirection={'column'}
          wrap="nowrap"
          className={classes.fancyScroll}
          marginTop={currentParams?.filters?.selectedItems?.length > 0 ? 0 : '10px'}
        >
          {paramsLoading && (
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              width="100%"
              height="100%"
              zIndex={99}
              position={'fixed'}
              top={0}
              left={0}
            >
              <CircularProgress color="secondary" />
            </Grid>
          )}
          {view === 'tiles' && (
            <ProjectsTilesView projects={gridRows} projectscColumns={gridColumns}></ProjectsTilesView>
          )}
          {view === 'list' && (
            <ProjectsListView
              projects={gridRows}
              projectscColumns={gridColumns}
              changeSorting={handleChangeSorting}
            ></ProjectsListView>
          )}
        </Grid>
      </CommonPage>
    </>
  );
};
export default injectIntl(ProjectsContainer);
