import typography from '../typography';

export default {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: {
      height: 36,
      borderRadius: 16,
      backgroundColor: '#6270D0',
      padding: '8px 20px',
      fontWeight: 700,
      textTransform: 'unset',
      zIndex: 1,
      ...typography.h4,
      '&:hover': {
        backgroundColor: '#6C7AD9',
      },
      '&:focus': {
        backgroundColor: '#7E8BE6',
      },
      '&.defaultButton': {
        fontWeight: '700',
        fontSize: '14px',
        lineHeight: '20px',
        padding: '10px 20px',
        height: 'auto',
        '&.primary': {
          color: '#FFFFFF',
          backgroundColor: '#6270D0',
          '&:hover': {
            backgroundColor: '#6C7AD9',
          },
          '&:focus': {
            backgroundColor: '#7E8BE6',
          },
          '&.Mui-disabled': {
            backgroundColor: '#DCDFF4',
            color: '#656985',
            '&:hover': {
              backgroundColor: '#DCDFF4',
            },
            '&:focus': {
              backgroundColor: '#656985',
            },
          },
        },
        '&.secondary': {
          color: '#7070B8',
          backgroundColor: '#DCDFF4',
          '&:hover': {
            backgroundColor: '#E1E4FA',
          },
          '&:focus': {
            backgroundColor: '#E5E9FF',
          },
          '&.Mui-disabled': {
            backgroundColor: '#E4E4EF',
            color: '#CFD2E5',
            '&:hover': {
              backgroundColor: '#E4E4EF',
            },
            '&:focus': {
              backgroundColor: '#E4E4EF',
            },
          },
        },
        '&.withIcon': {
          padding: '8px 16px 8px 4px',
        },
      },
    },
    cutBluePrimary: {
      color: 'white',
      borderRadius: '16px 0px 0px 16px',
    },
    cutBlueSecondary: {
      color: 'white',
      borderRadius: '0px 16px 16px 0px',
      '&:disabled': {
        color: 'white',
      },
    },
    cutGreyPrimaryBig: {
      borderRadius: '16px 0 0 16px',
      padding: '8px 20px',
      backgroundColor: '#DCDFF4',
      color: '#656985',
      '&:hover': {
        backgroundColor: '#DCDFF4',
      },
      '&:focus': {
        backgroundColor: '#DCDFF4',
      },
    },
    cutGreyPrimary: {
      padding: '10px 8px',
      backgroundColor: '#DCDFF4',
      borderRadius: '16px 0px 0px 16px',
      color: '#656985',
      '&:hover': {
        backgroundColor: '#DCDFF4',
      },
      '&:focus': {
        backgroundColor: '#DCDFF4',
      },
    },
    cutGreySecondary: {
      padding: '10px 8px',
      backgroundColor: '#DCDFF4',
      borderRadius: '0px 16px 16px 0px',
      color: '#656985',
      '&:hover': {
        backgroundColor: '#DCDFF4',
      },
      '&:focus': {
        backgroundColor: '#DCDFF4',
      },
    },
    defaultBluePrimary: {
      color: 'white',
      borderRadius: 16,
    },
    defaultBlueSecondary: {
      padding: '8px 20px',
      backgroundColor: '#6270D0',
      borderRadius: '16px',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#6C7AD9',
        color: '#ffffff',
      },
      '&:focus': {
        backgroundColor: '#7E8BE5',
        color: '#ffffff',
      },
      '&:disabled': {
        backgroundColor: '#DCDFF4',
        color: '#656985',
      },
    },
    defaultGreySecondary: {
      padding: '8px 20px',
      backgroundColor: '#DCDFF4',
      borderRadius: '16px',
      color: '#656985',
      transition: '.4s ease',
      '&:hover': {
        backgroundColor: '#DCDFF4',
        color: '#656985',
      },
      '&:focus': {
        backgroundColor: '#DCDFF4',
        color: '#656985',
      },
    },
    defaultGreyPrimary: {
      padding: '8px 20px',
      backgroundColor: '#DCDFF4',
      borderRadius: '16px',
      color: '#656985',
      transition: '.4s ease',
      '&:hover': {
        backgroundColor: '#6C7AD9',
        color: '#FFFFFF',
      },
      '&:focus': {
        backgroundColor: '#7E8BE5',
        color: '#FFFFFF',
      },
    },
    defaultGreyPrimaryWithIcon: {
      padding: '8px 20px 8px 8px',
      backgroundColor: '#DCDFF4',
      borderRadius: '16px',
      color: '#656985',
      '& .MuiSvgIcon-root path': {
        transition:
          'fill 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      },
      '&:hover': {
        backgroundColor: '#6C7AD9',
        color: '#ffffff',
        '& .MuiSvgIcon-root path': {
          fill: '#ffffff',
        },
      },
      '&:focus': {
        backgroundColor: '#7E8BE6',
        color: '#ffffff',
        '& .MuiSvgIcon-root path': {
          fill: '#ffffff',
        },
      },
    },
    defaultBluePrimaryWithIcon: {
      padding: '8px 20px 8px 8px',
      backgroundColor: '#6C7AD9',
      borderRadius: '16px',
      color: '#ffffff',
      '& .MuiSvgIcon-root path': {
        transition:
          'fill 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        fill: '#ffffff',
      },
      '&:hover': {
        backgroundColor: '#6C7AD9',
        color: '#ffffff',
        '& .MuiSvgIcon-root path': {
          fill: '#ffffff',
        },
      },
      '&:focus': {
        backgroundColor: '#7E8BE5',
        color: '#ffffff',
        '& .MuiSvgIcon-root path': {
          fill: '#ffffff',
        },
      },
    },
    defaultPrimary: {
      backgroundColor: 'white',
      padding: '10px 8px',
      fontWeight: 700,
      color: '#212346',
      height: 40,
      '&:hover': {
        backgroundColor: 'white',
      },
      '&:focus': {
        backgroundColor: 'white',
      },
    },
    defaultSecondary: {
      backgroundColor: 'transparent',
      padding: '10px 8px',
      fontWeight: 700,
      color: '#7174AC',
      height: 40,
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
  },
};
