import React, { useEffect, useReducer, useRef, useState } from 'react';
import Page from 'Common/shared-ui/src/components/Page';
import dayjs from 'dayjs';
import { useParams, Link } from 'react-router-dom';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import clsx from 'clsx';
import useStyles from './ProjectEdit.styles';
import { generateRedirectUrl } from 'Common/utils/getRedirectUrl';
import DeleteIcon from 'Common/shared-ui/src/icons/DeleteIcon';
import ReportDeleteIcon from 'Common/shared-ui/src/icons/ReportDeleteIcon';
import ProjectEditIcon from 'Common/shared-ui/src/icons/ProjectEditIcon';
import CheckboxIcon from '../../../../packages/common/shared-ui/src/icons/CheckboxIcon';
import CheckboxIconChecked from '../../../../packages/common/shared-ui/src/icons/CheckboxIconChecked';
import {
  addProjectMember,
  deleteProjectMember,
  editProject,
  getProject,
  getProjectMembers,
  getProjectMembersRoles,
  getProjectPermissions,
  getProjectStatistic,
  getVerifyProjectIdentifier,
  updateProject,
  updateProjectMember,
} from '../../../../packages/common/api';
import { getTranslatedText } from 'Common/utils/getTranslatedText';
import {
  CircularProgress,
  Box,
  Grid,
  Typography,
  Autocomplete,
  ClickAwayListener,
  Button,
  Checkbox,
  Chip,
  TextField,
  MenuItem,
  Paper,
  Popper,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
} from '@mui/material';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import CustomField from 'Common/shared-ui/src/components/CustomField';
import ProjectOpenIcon from '../../../../packages/common/shared-ui/src/icons/ProjectOpenIcon';
import ProjectCloseIcon from '../../../../packages/common/shared-ui/src/icons/ProjectCloseIcon';
import ProjectUserGroupeIcon from '../../../../packages/common/shared-ui/src/icons/ProjectUserGroupeIcon';
import UserNewSaveIcon from '../../../../packages/common/shared-ui/src/icons/UserNewSaveIcon';
import UserNewDeleteIcon from '../../../../packages/common/shared-ui/src/icons/UserNewDeleteIcon';
import ChevronIcon from '../../../../packages/common/shared-ui/src/icons/ChevronIcon';
import UserNewEditIcon from '../../../../packages/common/shared-ui/src/icons/UserNewEditIcon';
const ProjectEdit = (text, reviver) => {
  const pageParams = useParams();
  const navigate = useNavigate();
  const intl = useIntl();
  const classes = useStyles();
  const redmineToken = useSelector(state => state.user.redmine_access_token);
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  const [isLoading, setIsLoading] = useState(true);
  const [projectData, setProjectData] = useState([]);
  const [openAutocomplete, setOpenAutocomplete] = useState();
  const [saveUser, setSaveUser] = useState();
  const [usersData, setUsersData] = useState([]);
  const [projectUserPermissions, setProjectUserPermissions] = useState({});
  const [projectDataStatistic, setProjectDataStatistic] = useState({});
  const [usersRolesData, setUsersRolesData] = useState([]);
  const [projectidentifier, setProjectIdentifier] = useState('');
  const [errors, setErrors] = useState([]);
  const [blockNewUser, setBlockNewUser] = useState(false);
  const [changes, setChanges] = useState(false);
  const handleChangeInput = (e, fieldName) => {
    setChanges(true);
    let tempProjectData = JSON.parse(JSON.stringify(projectData));
    if (fieldName == 'isPublic') {
      tempProjectData[fieldName] = event.target.checked;
    } else {
      tempProjectData[fieldName] = e.target.value;
    }
    setProjectData(tempProjectData);
  };
  const handleDeleteTracker = (e, value, index) => {
    setChanges(true);
    setProjectData(prevState => {
      prevState.trackers[index].isChecked = false;
      return prevState;
    }, forceUpdate());
  };
  const updateUserData = userData => {
    userData = userData.reduce(
      (r, o) => {
        r[o.isChecked ? 'notAvailableUsers' : 'availableUsers'].push(o);
        return r;
      },
      { notAvailableUsers: [], availableUsers: [], newUsers: [] },
    );
    setUsersData(userData);
    setBlockNewUser(false);
    setSaveUser('');
  };
  const editUser = user => {
    setOpenAutocomplete(user.userId);
    forceUpdate();
  };
  const closePoper = async (e, index) => {
    setOpenAutocomplete('');
    setSaveUser(usersData.notAvailableUsers[index].userId);
  };
  const deleteUser = async memberId => {
    try {
      const response = await deleteProjectMember(projectData.identifier, memberId);
      updateUserData(response);
    } catch (error) {
      return console.log(error);
    }
  };
  const updateUser = async index => {
    try {
      let rolesData = usersData.notAvailableUsers[index].roles.filter(item => item.isChecked).map(item => item.roleId);
      rolesData = {
        roles: rolesData,
      };
      const response = await updateProjectMember(
        projectData.identifier,
        usersData.notAvailableUsers[index].memberId,
        rolesData,
      );
      try {
        let responseProjects = await editProject(pageParams.projectId);
        setProjectIdentifier(responseProjects.identifier);
        setProjectData(responseProjects);
        setIsLoading(false);
        setChanges(true);
      } catch (e) {
        console.error(e);
      }
      updateUserData(response);
    } catch (error) {
      return console.log(error);
    }
    forceUpdate();
  };
  const saveNewUser = async user => {
    let newUser = {
      userId: user.userId,
      roles: user.roles.filter(item => item.isChecked).map(item => item.roleId),
    };
    try {
      const response = await addProjectMember(projectData.identifier, newUser);
      setBlockNewUser(false);
      updateUserData(response);
      try {
        let responseProjects = await editProject(pageParams.projectId);
        setProjectIdentifier(responseProjects.identifier);
        setProjectData(responseProjects);
        setIsLoading(false);
        setChanges(true);
      } catch (e) {
        console.error(e);
      }
    } catch (error) {
      return console.log(error);
    }
  };
  const deleteNewUser = (val, index) => {
    setUsersData(
      prevState => {
        delete prevState.newUsers[index];
        val.isChecked = false;
        val.roles = [];
        prevState.availableUsers.push(val);
        prevState.availableUsers = prevState.availableUsers.sort((a, b) => (a.userName > b.userName ? 1 : -1));
        return prevState;
      },
      setBlockNewUser(false),
      forceUpdate(),
    );
  };
  const handleChangeSelect = (event, type) => {
    setChanges(true);
    setProjectData(prevState => {
      if (type == 'parents') {
        prevState.parentId = event.target.value;
        prevState.parents.forEach(item => {
          if (item.projectId == event.target.value) {
            item.isChecked = item.isChecked === true ? false : true;
          } else {
            item.isChecked = false;
          }
        });
      } else if (type == 'calculation') {
        prevState.calculationTypeId = prevState.calculationTypeId === event.target.value ? '' : event.target.value;
        prevState.calculationTypes.forEach(item => {
          if (item.valueId == event.target.value) {
            item.isChecked = item.isChecked === true ? false : true;
          } else {
            item.isChecked = false;
          }
        });
      } else {
        prevState.trackers.forEach(item => {
          if (item.trackerId == event.target.value) {
            item.isChecked = item.isChecked === true ? false : true;
          }
        });
      }

      return prevState;
    }, forceUpdate());
  };
  const handleChangeSelectNewUser = (event, val) => {
    setUsersData(
      prevState => {
        val.isChecked = true;
        val.roles = JSON.parse(JSON.stringify(usersRolesData));
        prevState.newUsers.push(val);
        prevState.availableUsers = prevState.availableUsers.filter(object => object.userId !== val.userId);
        return prevState;
      },
      setBlockNewUser(true),
      forceUpdate(),
    );
  };
  const handleChangeSelectRoleNewUser = (event, index) => {
    setUsersData(prevState => {
      prevState.newUsers[index].roles.forEach(item => {
        if (item.roleId == event.target.value) {
          item.isChecked = item.isChecked === true ? false : true;
        }
      });
      return prevState;
    }, forceUpdate());
  };
  const handleChangeSelectRoleUser = (event, index) => {
    if (!event.target.value.isLocked) {
      setUsersData(prevState => {
        prevState.notAvailableUsers[index].roles.forEach(item => {
          if (item.roleId == event.target.value) {
            item.isChecked = item.isChecked === true ? false : true;
          }
        });
        return prevState;
      }, forceUpdate());
    }
  };

  const goProjectView = project => {
    const projectUrl = '/projects/' + projectData.identifier;
    navigate(projectUrl);
  };
  const handelGoUserLink = id => {
    const link = 'https://opt2.rbtechnologies.ru:9909/users/' + id;
    window.open(link, '_blank');
  };
  const handelGoProjectLink = project => {
    const projectUrl = '/projects/' + project;
    navigate(projectUrl);
  };
  const handelGoMemberLink = member => {
    const memberUrl = '/users/' + member;
    let hostUrl = generateRedirectUrl({ host: window.location.origin, path: memberUrl, redmineToken });
    window.open(hostUrl, '_blank');
  };
  const handleSaveProject = async () => {
    let tempErrors = [];
    const regexForIdentifier = /^[a-z0-9-_]*$/;
    const regexForUrl = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
    if (projectidentifier != projectData.identifier) {
      try {
        const response = await getVerifyProjectIdentifier(projectData.identifier);
        if (!response) {
          setChanges(false);
          setIsLoading(false);
          tempErrors = [...tempErrors, intl.formatMessage({ id: 'incorrect_id' })];
        }
      } catch (error) {
        return console.log(error);
      }
    }
    if (projectData.homepage.length > 0 && !regexForUrl.test(projectData.homepage)) {
      tempErrors = [...tempErrors, intl.formatMessage({ id: 'incorrect_homepage_url' })];
    }
    if (projectData.name.length < 1) {
      tempErrors = [...tempErrors, intl.formatMessage({ id: 'incorrect_name' })];
    }
    let tempProjectData = [...projectData.customs];
    tempProjectData = tempProjectData.filter(field => field.fieldDefinition.isRequired && field.valueId === null);
    tempProjectData.forEach(field => {
      let message = intl.formatMessage({ id: 'incorrect_field' });
      tempErrors = [...tempErrors, message + field.fieldName];
    });
    setErrors(tempErrors);
    if (tempErrors.length < 1) {
      let saveProjectObject = JSON.parse(JSON.stringify(projectData));
      delete saveProjectObject.parents;
      delete saveProjectObject.defaultAssignedTo;
      saveProjectObject.trackers = saveProjectObject.trackers
        .filter(filter => filter.isChecked)
        .map(item => item.trackerId);
      saveProjectObject.customs = saveProjectObject.customs.filter(item => item.valueId !== null);
      saveProjectObject.customs.map((field, index) => {
        if (field.fieldDefinition.fieldFormat == 'date') {
          field.valueId = field.valueId ? dayjs(field.valueId).format('YYYY-MM-DD') : '';
        }
        if (
          field.fieldDefinition.fieldFormat == 'enumeration' ||
          field.fieldDefinition.fieldFormat == 'user' ||
          field.fieldDefinition.fieldFormat == 'list'
        ) {
          field.valueId = field.fieldDefinition.values.filter(field => field.isChecked);
        }
        delete field.fieldDefinition;
        delete field.fieldName;
        delete field.valueName;

        if (Array.isArray(field.valueId)) {
          field.valueId = field.valueId.map(item => item.valueId);
        } else {
          if (typeof field.valueId === 'object') {
            field.valueId = field.valueId;
          }
        }

        return field;
      });
      //saveProjectObject.customs = saveProjectObject.customs.filter(item => item.valueId.length > 0);
      saveProjectObject.customs = saveProjectObject.customs.filter(item => item.changed);
      try {
        const response = await updateProject(saveProjectObject, projectData.identifier);
        const projectUrl = '/projects/' + saveProjectObject.identifier;
        navigate(projectUrl);
      } catch (error) {
        return console.log(error);
      }
    }
  };
  useEffect(() => {
    const updateUserData = userData => {
      userData = userData.reduce(
        (r, o) => {
          r[o.isChecked ? 'notAvailableUsers' : 'availableUsers'].push(o);
          return r;
        },
        { notAvailableUsers: [], availableUsers: [], newUsers: [] },
      );
      setUsersData(userData);
    };
    async function getProjectUsersRoles() {
      try {
        let response = await getProjectMembersRoles(pageParams.projectId);
        setUsersRolesData(response);
      } catch (e) {
        console.error(e);
      }
    }

    async function getProjectMembersList() {
      try {
        let response = await getProjectMembers(pageParams.projectId);
        console.log(response);
        updateUserData(response);
      } catch (e) {
        console.error(e);
      }
    }

    async function createProjectData() {
      try {
        let response = projectUserPermissions.canEdit
          ? await editProject(pageParams.projectId)
          : await getProject(pageParams.projectId);
        setProjectIdentifier(response.identifier);
        if (typeof response.parents === 'undefined') {
          response.parents = [];
        }
        let projectStatistic = await getProjectStatistic(response.identifier);
        projectStatistic.rows = projectStatistic.rows.map(item => {
          let trackerLink = `/issues?project=${response.redmineId}&filters=issue.tracker%3Beq%3B${item.rowId}`;
          item.columns[0].link = trackerLink;
          return item;
        });
        setProjectDataStatistic(projectStatistic);
        setProjectData(response);
        setIsLoading(false);
      } catch (e) {
        console.error(e);
      }
    }
    async function checkProjectPermision() {
      try {
        const data = await getProjectPermissions(pageParams.projectId);
        setProjectUserPermissions(data);
      } catch (e) {
        console.error(e);
      }
    }

    if (Object.entries(projectUserPermissions).length === 0) {
      checkProjectPermision();
    }
    if (projectUserPermissions.canEdit || projectUserPermissions.canEditMembers) {
      getProjectUsersRoles();
      getProjectMembersList();
      createProjectData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageParams.projectId, projectUserPermissions]);
  return (
    <>
      {!isLoading ? (
        // eslint-disable-next-line no-cyrillic-string/no-cyrillic-string
        <Page title={projectData.name} width="100%" className={classes.ProjectsContainerRoot}>
          <Grid container position="relative" className={classes.filtersWrap}>
            <Grid
              item
              container
              marginTop={1}
              spacing={5}
              alignItems="center"
              justifyContent="space-between"
              display="flex"
              position="relative"
              className={classes.projectsPageTtl}
            >
              {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
              <Typography variant="h1">{projectData.name}</Typography>
              <Box className={classes.actionsBlock}>
                <Button
                  disabled={!changes}
                  onClick={handleSaveProject}
                  variant="cutBluePrimary"
                  className={classes.saveReportButton}
                >
                  <FormattedMessage id="save_text" defaultMessage="Save" />
                </Button>
                <Button onClick={goProjectView} variant="cutBluePrimary" className={classes.cancelReportButton}>
                  <FormattedMessage id="cancel" defaultMessage="cancel" />
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Box className={classes.ProjectsTabsWrap}>
            <Grid container marginTop={5} gap={10} position="relative">
              <Grid item xs={5.5} className={classes.projectWrap}>
                <Box className={classes.projectErrorsWrap}>
                  {errors.map((error, index) => (
                    <Box className={classes.projectError} key={index}>
                      {error}
                    </Box>
                  ))}
                </Box>
                <Grid container spacing={5} position="relative" className={classes.projectFieldsWrap}>
                  {projectUserPermissions.canEdit ? (
                    <>
                      <Box className={classes.projectFieldWrap}>
                        <Box className={clsx(classes.projectFiledTtl, classes.projectFieldRow)}>
                          {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                          <Box className={classes.projectFieldLeft}>
                            Имя проекта
                            <Box component="span" className={classes.red}>
                              *
                            </Box>
                          </Box>
                          <Box className={classes.projectFieldRight}>
                            <TextField
                              hiddenLabel
                              onChange={e => handleChangeInput(e, 'name')}
                              placeholder={projectData.name.length > 0 ? '' : intl.formatMessage({ id: 'enter' })}
                              value={projectData.name}
                              className={classes.nameInput}
                              fullWidth
                              variant="filled"
                            />
                          </Box>
                        </Box>
                      </Box>
                      <Box className={classes.projectFieldWrap}>
                        <Box
                          className={clsx(
                            classes.projectFiledTtl,
                            classes.projectFieldRow,
                            classes.projectFieldDescRow,
                          )}
                        >
                          {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                          <Box className={classes.projectFieldLeft}>Описание</Box>
                          <Box className={classes.projectFieldRight}>
                            <TextField
                              hiddenLabel
                              rows={5}
                              multiline
                              onChange={e => handleChangeInput(e, 'description')}
                              value={projectData.description}
                              className={clsx(classes.nameInput, classes.textArea)}
                              fullWidth
                              variant="filled"
                            />
                          </Box>
                        </Box>
                      </Box>
                      <Box className={classes.projectFieldWrap}>
                        <Box className={clsx(classes.projectFiledTtl, classes.projectFieldRow)}>
                          {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                          <Box className={classes.projectFieldLeft}>
                            Уникальный идентификатор
                            <Box component="span" className={classes.red}>
                              *
                            </Box>
                          </Box>
                          <Box className={classes.projectFieldRight}>
                            <TextField
                              hiddenLabel
                              disabled={true}
                              onChange={e => handleChangeInput(e, 'identifier')}
                              value={projectData.identifier}
                              placeholder={projectData.identifier.length > 0 ? '' : intl.formatMessage({ id: 'enter' })}
                              className={classes.nameInput}
                              fullWidth
                              variant="filled"
                            />
                          </Box>
                        </Box>
                      </Box>
                      <Box className={classes.projectFieldWrap}>
                        <Box className={clsx(classes.projectFiledTtl, classes.projectFieldRow)}>
                          {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                          <Box className={classes.projectFieldLeft}>Стартовая страница</Box>
                          <Box className={classes.projectFieldRight}>
                            <TextField
                              hiddenLabel
                              onChange={e => handleChangeInput(e, 'homepage')}
                              value={projectData.homepage}
                              placeholder={projectData.homepage.length > 0 ? '' : intl.formatMessage({ id: 'enter' })}
                              className={classes.nameInput}
                              fullWidth
                              variant="filled"
                            />
                          </Box>
                        </Box>
                      </Box>
                      <Box className={classes.projectFieldWrap}>
                        <Box className={clsx(classes.projectFiledTtl, classes.projectFieldRow)}>
                          {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                          <Box className={classes.projectFieldLeft}>Общедоступный</Box>
                          <Box className={classes.projectFieldRight}>
                            <Checkbox
                              onChange={e => handleChangeInput(e, 'isPublic')}
                              icon={<CheckboxIcon width="12" height="12" viewBox="0 0 12 12" />}
                              checkedIcon={<CheckboxIconChecked width="12" height="12" viewBox="0 0 12 12" />}
                              checked={projectData.isPublic}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          </Box>
                        </Box>
                      </Box>
                      <Box className={classes.projectFieldWrap}>
                        <Box className={clsx(classes.projectFiledTtl, classes.projectFieldRow)}>
                          {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                          <Box className={classes.projectFieldLeft}>Родительский проект:</Box>
                          <Box className={classes.projectFieldRight}>
                            <Autocomplete
                              options={projectData.parents}
                              value={projectData.parents}
                              popupIcon={<ChevronIcon direction="down" viewBox="0 0 24 24" />}
                              disableClearable
                              isOptionEqualToValue={(option, value) => option.value === value.value}
                              className={classes.autocompleteRolesList}
                              onChange={(e, val) => handleChangeSelect(e, 'parents')}
                              getOptionLabel={option => option.projectName || ''}
                              clearIcon={null}
                              ChipProps={{
                                className: classes.subItemChip,
                                deleteIcon: null,
                              }}
                              renderOption={(props, option, { selected }) => (
                                <MenuItem
                                  {...props}
                                  key={option.projectId}
                                  value={option.projectId}
                                  className={clsx(classes.poperItem, option.isChecked ? classes.itemSelected : '')}
                                >
                                  {option.projectName}
                                </MenuItem>
                              )}
                              renderInput={params => {
                                let selectedFields = projectData.parents?.filter(filter => filter.isChecked);
                                let selectedFieldsNames = selectedFields?.map(project => project.projectName).join(',');
                                return (
                                  <TextField
                                    {...params}
                                    value={selectedFieldsNames}
                                    placeholder={
                                      selectedFieldsNames?.length > 0
                                        ? selectedFieldsNames
                                        : intl.formatMessage({ id: 'choose' })
                                    }
                                  />
                                );
                              }}
                            />
                          </Box>
                        </Box>
                      </Box>
                      {projectData.hasOwnProperty('calculationTypeId') && (
                        <Box className={classes.projectFieldWrap}>
                          <Box className={clsx(classes.projectFiledTtl, classes.projectFieldRow)}>
                            {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                            <Box className={classes.projectFieldLeft}>Тип расчета:</Box>
                            <Box className={classes.projectFieldRight}>
                              <Autocomplete
                                options={projectData.calculationTypes}
                                value={projectData.calculationTypeId ? projectData.calculationTypeId : ''}
                                popupIcon={<ChevronIcon direction="down" viewBox="0 0 24 24" />}
                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                className={classes.autocompleteRolesList}
                                onChange={(e, val) => handleChangeSelect(e, 'calculation')}
                                getOptionLabel={option => option.valueName || ''}
                                ChipProps={{
                                  className: classes.subItemChip,
                                  deleteIcon: null,
                                }}
                                renderOption={(props, option, { selected }) => (
                                  <MenuItem
                                    {...props}
                                    key={option.valueId}
                                    value={option.valueId}
                                    className={clsx(classes.poperItem, option.isChecked ? classes.itemSelected : '')}
                                  >
                                    {option.valueName}
                                  </MenuItem>
                                )}
                                renderInput={params => {
                                  let selectedFields = projectData.calculationTypes?.filter(filter => filter.isChecked);
                                  let selectedFieldsNames = selectedFields?.map(project => project.valueName).join(',');
                                  return (
                                    <TextField
                                      {...params}
                                      value={selectedFieldsNames}
                                      placeholder={
                                        selectedFieldsNames?.length > 0
                                          ? selectedFieldsNames
                                          : intl.formatMessage({ id: 'choose' })
                                      }
                                    />
                                  );
                                }}
                              />
                            </Box>
                          </Box>
                        </Box>
                      )}
                      {projectData.customs.map((field, index) => (
                        <Box className={classes.projectFieldWrap} key={index}>
                          <Box className={clsx(classes.projectFiledTtl, classes.projectFieldRow)}>
                            {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                            <Box className={classes.projectFieldLeft}>
                              {field.fieldName}
                              <Box component="span" className={classes.red}>
                                {field.fieldDefinition.isRequired ? '*' : ''}
                              </Box>
                            </Box>
                            <Box className={classes.projectFieldRight}>
                              <CustomField
                                intl={intl}
                                fieldData={field}
                                fieldIndex={index}
                                setProjectData={setProjectData}
                                setChanges={setChanges}
                              />
                            </Box>
                          </Box>
                        </Box>
                      ))}
                    </>
                  ) : (
                    <>
                      {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                      <Typography className={classes.projectFieldsTtl} variant="h3">
                        Свойства проекта
                      </Typography>
                      <Box className={classes.projectFieldWrapView}>
                        <Box className={clsx(classes.projectFiledTtl, classes.projectFieldRowView)}>
                          {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                          <Box className={classes.projectFieldLeft}>Описание:</Box>
                          <Box className={classes.projectFieldRight}>{projectData.description}</Box>
                        </Box>
                      </Box>
                      <Box className={classes.projectFieldWrapView}>
                        <Box className={clsx(classes.projectFiledTtl, classes.projectFieldRowView)}>
                          {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                          <Box className={classes.projectFieldLeft}>Уникальный индификатор:</Box>
                          <Box className={classes.projectFieldRight}>{projectData.identifier}</Box>
                        </Box>
                      </Box>
                      <Box className={classes.projectFieldWrapView}>
                        <Box className={clsx(classes.projectFiledTtl, classes.projectFieldRowView)}>
                          {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                          <Box className={classes.projectFieldLeft}>Стартовая страница:</Box>
                          <Box className={classes.projectFieldRight}>{projectData.homepage}</Box>
                        </Box>
                      </Box>
                      <Box className={classes.projectFieldWrapView}>
                        <Box className={clsx(classes.projectFiledTtl, classes.projectFieldRowView)}>
                          {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                          <Box className={classes.projectFieldLeft}>Общедоступный:</Box>
                          {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                          <Box className={classes.projectFieldRight}>{projectData.isPublic ? 'Да' : 'Нет'}</Box>
                        </Box>
                      </Box>
                      <Box className={classes.projectFieldWrapView}>
                        <Box className={clsx(classes.projectFiledTtl, classes.projectFieldRowView)}>
                          {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                          <Box className={classes.projectFieldLeft}>Родительский проект:</Box>
                          <Box
                            className={clsx(classes.projectFieldRight, classes.Url)}
                            onClick={() => handelGoProjectLink(projectData.parent.projectIdentifier)}
                          >
                            {projectData.parent?.projectName}
                          </Box>
                        </Box>
                      </Box>
                      {projectData.customs.map((field, index) => (
                        <Box className={classes.projectFieldWrapView} key={index}>
                          <Box className={clsx(classes.projectFiledTtl, classes.projectFieldRowView)}>
                            <Box className={classes.projectFieldLeft}>{field.fieldName}:</Box>
                            <Box
                              className={clsx(
                                classes.projectFieldRight,
                                field.fieldDefinition?.fieldFormat == 'user' ||
                                  field.fieldDefinition?.fieldFormat == 'link' ||
                                  field.fieldDefinition?.fieldFormat == 'attachment'
                                  ? classes.Url
                                  : '',
                              )}
                              onClick={() =>
                                field.fieldDefinition?.fieldFormat == 'user' ||
                                field.fieldDefinition?.fieldFormat == 'link' ||
                                field.fieldDefinition?.fieldFormat == 'attachment'
                                  ? handelGoFieldLink(field)
                                  : undefined
                              }
                            >
                              {field.valueName}
                            </Box>
                          </Box>
                        </Box>
                      ))}
                    </>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={6} className={classes.projectWrap}>
                <Grid container spacing={5} position="relative" className={classes.projectFieldsWrap}>
                  {projectUserPermissions.canEdit ? (
                    <>
                      {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                      <Typography className={classes.projectFieldsTtl} variant="h3">
                        Настройка задач
                      </Typography>
                      <Box className={classes.projectFieldWrap}>
                        <Box
                          className={clsx(
                            classes.projectFiledTtl,
                            classes.projectFieldRow,
                            classes.projectTrackersWrap,
                          )}
                        >
                          {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                          <Box className={classes.projectFieldLeft}>Тип задачи:</Box>
                          <Box className={classes.projectFieldRight}>
                            <Autocomplete
                              multiple
                              options={projectData.trackers}
                              value={projectData.trackers}
                              disableCloseOnSelect
                              disableClearable
                              popupIcon={''}
                              isOptionEqualToValue={(option, value) => option.value === value.value}
                              onChange={(e, val) => handleChangeSelect(e, 'trackers')}
                              getOptionLabel={option => option.trackerName || ''}
                              clearIcon={null}
                              ChipProps={{
                                className: classes.subItemChip,
                                deleteIcon: null,
                              }}
                              renderTags={(value, getTagProps) =>
                                value.map((option, index) => {
                                  if (option.isChecked) {
                                    return (
                                      <Box className={classes.chips} key={option.trackerId}>
                                        <Chip
                                          key={option.trackerId}
                                          label={option.trackerName}
                                          clickable
                                          deleteIcon={<DeleteIcon width={10} height={10} viewBox="0 0 10 10" />}
                                          className={classes.chip}
                                          onDelete={e => handleDeleteTracker(e, value, index)}
                                        />
                                      </Box>
                                    );
                                  }
                                })
                              }
                              renderOption={(props, option) => (
                                <MenuItem
                                  {...props}
                                  key={option.trackerId}
                                  value={option.trackerId}
                                  className={classes.poperItem}
                                >
                                  <Checkbox
                                    icon=<CheckboxIcon width="12" height="12" viewBox="0 0 12 12" />
                                    checkedIcon=<CheckboxIconChecked width="12" height="12" viewBox="0 0 12 12" />
                                    style={{ marginRight: 8 }}
                                    checked={option.isChecked}
                                    value={option.trackerId}
                                  />
                                  {option.trackerName}
                                </MenuItem>
                              )}
                              renderInput={params => (
                                <TextField {...params} variant="standard" margin="normal" fullWidth />
                              )}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </>
                  ) : (
                    <>
                      {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                      <Typography className={classes.projectFieldsTtl} variant="h3">
                        Задачи
                      </Typography>
                      <TableContainer className={classes.ProjectsListViewTableRootWrap}>
                        <Table className={classes.ProjectsListViewTableRoot}>
                          <TableHead align="left">
                            <TableRow className={classes.tableRow}>
                              {projectDataStatistic.fields.map((field, index) => (
                                <TableCell className={classes.tableCellHead} key={index}>
                                  <Typography variant="h4" fontWeight={700}>
                                    {getTranslatedText(intl, 'project', field.fieldId, field.fieldName)}
                                  </Typography>
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {projectDataStatistic.rows.map((project, index) => (
                              <TableRow key={index}>
                                {project.columns.map((field, index) => (
                                  <TableCell key={index} className={classes.tableCell}>
                                    <Typography
                                      className={field.link ? classes.Url : ''}
                                      onClick={() => (field.link ? handelGoLink(field.link) : undefined)}
                                      variant="h4"
                                    >
                                      {field.value}{' '}
                                    </Typography>
                                  </TableCell>
                                ))}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  )}
                </Grid>
                <Grid container spacing={5} position="relative" className={classes.projectFieldsWrap}>
                  {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                  <Typography className={classes.projectFieldsTtl} variant="h3">
                    Участники
                  </Typography>
                  {projectUserPermissions.canEditMembers ? (
                    <>
                      {usersData.notAvailableUsers?.map((field, index) => {
                        const merge = (a, b, p) => a.filter(aa => !b.find(bb => aa[p] === bb[p])).concat(b);
                        let uniqueData = merge(JSON.parse(JSON.stringify(usersRolesData)), field.roles, 'roleId');
                        uniqueData = uniqueData.sort((a, b) => (a.roleName > b.roleName ? 1 : -1));
                        field.roles = uniqueData;
                        return (
                          <Box className={classes.projectFieldMemberWrap} key={index}>
                            {field.isGroup ? (
                              <ProjectUserGroupeIcon
                                className={classes.ProjectUserGroupeIcon}
                                width={16}
                                height={10}
                                viewBox="0 0 16 10"
                              />
                            ) : null}
                            <Typography
                              variant="h5"
                              className={clsx(
                                field.isActive
                                  ? classes.projectFiledTtlActiveUsers
                                  : classes.projectFiledTtlBlockedUsers,
                                classes.projectFiledTtl,
                                classes.Url,
                              )}
                              onClick={() => handelGoMemberLink(field.userId)}
                            >
                              {field.userName}
                            </Typography>
                            <Box className={classes.projectFieldMemberRolesWrap}>
                              {field.roles.map((role, index) => {
                                if (role.isChecked) {
                                  return (
                                    <Typography key={index} variant="h5" className={classes.projectFiledValue}>
                                      {role.roleName}
                                    </Typography>
                                  );
                                }
                              })}
                              {field.userId == openAutocomplete || field.userId == saveUser ? (
                                <Autocomplete
                                  options={field.roles}
                                  value={field.roles}
                                  open={field.userId == openAutocomplete}
                                  onOpen={() => setOpenAutocomplete(field.userId)}
                                  onClose={() => setOpenAutocomplete('')}
                                  disableCloseOnSelect
                                  disableClearable
                                  multiple
                                  isOptionEqualToValue={(option, value) => option.roleId === value.roleId}
                                  className={classes.autocompleteUserAdd}
                                  onChange={(e, val) => handleChangeSelectRoleUser(e, index)}
                                  PopperComponent={props => (
                                    <ClickAwayListener onClickAway={e => closePoper(e, index)}>
                                      <Popper {...props}>{props.children}</Popper>
                                    </ClickAwayListener>
                                  )}
                                  getOptionLabel={option => option.roleName || ''}
                                  clearIcon={null}
                                  ChipProps={{
                                    className: classes.subItemChip,
                                    deleteIcon: null,
                                  }}
                                  renderOption={(props, option, { selected }) => (
                                    <MenuItem
                                      {...props}
                                      className={classes.poperItem}
                                      disabled={option.isLocked}
                                      key={option.roleId}
                                      value={option.roleId}
                                    >
                                      <Checkbox
                                        icon=<CheckboxIcon width="12" height="12" viewBox="0 0 12 12" />
                                        checkedIcon=<CheckboxIconChecked width="12" height="12" viewBox="0 0 12 12" />
                                        style={{ marginRight: 8 }}
                                        checked={option.isChecked}
                                        disabled={option.isLocked}
                                        value={option.roleId}
                                      />
                                      {option.roleName}
                                    </MenuItem>
                                  )}
                                  renderInput={params => {
                                    return (
                                      <TextField
                                        {...params}
                                        placeholder={intl.formatMessage({ id: 'add_user_role' })}
                                      />
                                    );
                                  }}
                                />
                              ) : null}
                            </Box>
                            <Box className={classes.projectFieldMemberRolesActionsWrap}>
                              {field.userId == saveUser ? (
                                <IconButton onClick={e => updateUser(index)} className={classes.editUser}>
                                  <UserNewSaveIcon width={12} height={12} viewBox="0 0 12 12" />
                                </IconButton>
                              ) : (
                                <IconButton onClick={e => editUser(field)} className={classes.editUser}>
                                  <UserNewEditIcon width={12} height={10.66} viewBox="0 0 12 10.66" />
                                </IconButton>
                              )}

                              <IconButton
                                disableRipple
                                className={classes.deleteUser}
                                disabled={field.isUnremovable}
                                onClick={e => deleteUser(field.memberId)}
                              >
                                <ReportDeleteIcon width={9.33} height={12} viewBox="0 0 9.33 12" />
                              </IconButton>
                            </Box>
                          </Box>
                        );
                      })}
                      {usersData.newUsers?.map((field, index) => (
                        <Box className={classes.projectFieldMemberWrap} key={index}>
                          {field.isGroup ? (
                            <ProjectUserGroupeIcon
                              className={classes.ProjectUserGroupeIcon}
                              width={16}
                              height={10}
                              viewBox="0 0 16 10"
                            />
                          ) : null}
                          <Typography
                            variant="h5"
                            className={clsx(
                              field.isActive ? classes.projectFiledTtlActiveUsers : classes.projectFiledTtlBlockedUsers,
                              classes.projectFiledTtl,
                              classes.Url,
                            )}
                            onClick={() => handelGoMemberLink(field.userId)}
                          >
                            {field.userName}
                          </Typography>
                          <Box className={classes.projectFieldMemberRolesWrap}>
                            <Autocomplete
                              options={field.roles}
                              value={field.roles}
                              disableCloseOnSelect
                              disableClearable
                              multiple
                              isOptionEqualToValue={(option, value) => option.roleId === value.roleId}
                              className={classes.autocompleteUserAdd}
                              onChange={(e, val) => handleChangeSelectRoleNewUser(e, index)}
                              getOptionLabel={option => option.roleName || ''}
                              clearIcon={null}
                              ChipProps={{
                                className: classes.subItemChip,
                                deleteIcon: null,
                              }}
                              renderOption={(props, option, { selected }) => (
                                <MenuItem
                                  {...props}
                                  key={option.roleId}
                                  value={option.roleId}
                                  className={clsx(classes.poperItem, option.isChecked ? classes.itemSelected : '')}
                                >
                                  <Checkbox
                                    icon=<CheckboxIcon width="12" height="12" viewBox="0 0 12 12" />
                                    checkedIcon=<CheckboxIconChecked width="12" height="12" viewBox="0 0 12 12" />
                                    style={{ marginRight: 8 }}
                                    checked={option.isChecked}
                                    value={option.roleId}
                                  />
                                  {option.roleName}
                                </MenuItem>
                              )}
                              renderInput={params => {
                                const { InputLabelProps, ...restParams } = params;
                                return (
                                  <TextField {...params} placeholder={intl.formatMessage({ id: 'add_user_role' })} />
                                );
                              }}
                            />
                            {field.roles.map((role, index) => {
                              if (role.isChecked) {
                                return (
                                  <Typography key={index} variant="h5" className={classes.projectFiledValue}>
                                    {role.roleName}
                                  </Typography>
                                );
                              }
                            })}
                          </Box>
                          <Box className={classes.projectFieldMemberRolesActionsWrap}>
                            <IconButton onClick={e => saveNewUser(field)} className={classes.editUser}>
                              <UserNewSaveIcon width={12} height={12} viewBox="0 0 12 12" />
                            </IconButton>
                            <IconButton
                              disableRipple
                              className={classes.deleteUser}
                              onClick={e => deleteNewUser(field, index)}
                            >
                              <ReportDeleteIcon width={9.33} height={12} viewBox="0 0 9.33 12" />
                            </IconButton>
                          </Box>
                        </Box>
                      ))}
                      {!blockNewUser ? (
                        <Box className={classes.projectFieldMemberWrap}>
                          <Autocomplete
                            options={usersData.availableUsers}
                            value={usersData.availableUsers}
                            disableClearable
                            className={classes.autocompleteUserAdd}
                            onChange={(e, val) => handleChangeSelectNewUser(e, val)}
                            getOptionLabel={option => option.userName || ''}
                            clearIcon={null}
                            ChipProps={{
                              className: classes.subItemChip,
                              deleteIcon: null,
                            }}
                            renderOption={(props, option, { selected }) => (
                              <MenuItem
                                {...props}
                                key={option.userId}
                                value={option.userId}
                                className={clsx(classes.poperItem, option.isChecked ? classes.itemSelected : '')}
                              >
                                {option.userName}
                              </MenuItem>
                            )}
                            renderInput={params => {
                              const { InputLabelProps, ...restParams } = params;
                              return <TextField {...params} placeholder={intl.formatMessage({ id: 'add_user' })} />;
                            }}
                          />
                        </Box>
                      ) : null}
                    </>
                  ) : (
                    <>
                      {usersData.notAvailableUsers.map((field, index) => (
                        <Box className={classes.projectFieldMemberWrap} key={index}>
                          {field.isGroup ? (
                            <ProjectUserGroupeIcon
                              className={classes.ProjectUserGroupeIcon}
                              width={16}
                              height={10}
                              viewBox="0 0 16 10"
                            />
                          ) : null}
                          <Typography
                            variant="h5"
                            className={clsx(
                              field.isActive ? classes.projectFiledTtlActiveUsers : classes.projectFiledTtlBlockedUsers,
                              classes.projectFiledTtl,
                              field.isGroup ? '' : classes.Url,
                            )}
                            onClick={() => (field.isGroup ? '' : handelGoMemberLink(field.userId))}
                          >
                            {field.userName}
                          </Typography>
                          <Box className={classes.projectFieldMemberRolesWrap} key={index}>
                            {field.roles.map((role, index) => (
                              <Typography key={index} variant="h5" className={classes.projectFiledValue}>
                                {role.roleName}
                              </Typography>
                            ))}
                          </Box>
                        </Box>
                      ))}
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Page>
      ) : (
        <Grid container alignItems="center" justifyContent="center" width="100%" height="100vh">
          <CircularProgress color="secondary" />
        </Grid>
      )}
    </>
  );
};
export default injectIntl(ProjectEdit);
