import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Popper,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import CheckboxIcon from '../../icons/CheckboxIcon';
import CheckboxIconChecked from '../../icons/CheckboxIconChecked';

import 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/models/dom/model';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/image';
import 'tinymce/plugins/table';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/content.min.css';
import 'tinymce/skins/content/default/content.min.css';
import { Editor } from '@tinymce/tinymce-react';
import issueFormFieldStyles from '../../../../../../src/components/issueCreateEdit/components/IssueFormField.styles';
import IssueFileUpload from '../../../../../../src/components/issueCreateEdit/components/IssueFileUpload';
import ClearFormFieldIcon from '../../icons/ClearFormFieldIcon';
import CalendarFormIcon from '../../icons/CalendarFormIcon';
import ChevronIcon from '../../icons/ChevronIcon';
import { useLocation } from 'react-router';
import { handleField } from '../../../../utils/fieldHelper';
import formFieldStyles from './FormField.styles';
import clsx from 'clsx';
import DeleteIcon from '../../icons/DeleteIcon';
import { getParentIssueQuery } from '../../../../api';
const FormField = ({ intl, field, fieldEvent, bulk, changeSearchInput, searchFieldOptions, modal, searchLoading }) => {
  const textField = useRef(['link', 'string']);
  const numberFields = useRef(['float', 'acl_percent', 'int']);
  const autocompleteFields = useRef(['list', 'enumeration', 'user', 'project', 'tracker', 'trackers']);
  const searchFields = useRef(['timesheet_issue', 'issue_parent']);
  const classes = formFieldStyles();
  const [parentIssue, setParentIssue] = useState('');
  const [singleField, setSingleField] = useState(false);
  const [fieldError, setFieldError] = useState(null);
  const [currentLocation, setCurrentLocation] = useState('');
  const location = useLocation();

  const validateUrl = useCallback((value, field) => {
    const urlPattern = new RegExp(
      '^(https?:\\/\\/)?' + // validate protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
        '(\\#[-a-z\\d_]*)?$',
      'i',
    );
    value !== '' && field === 'link' && !urlPattern.test(value) && setFieldError(true);
    value !== '' && field === 'link' && urlPattern.test(value) && setFieldError(false);
  }, []);

  useEffect(() => {
    if (location.pathname) {
      setCurrentLocation(location.pathname.split('/')[1]);
    }
  }, [location]);

  const handleFormField = useCallback(
    (data, field, deleted = false) => {
      const fieldValue = handleField(data, field, deleted);
      fieldEvent(fieldValue, field);
    },
    [fieldEvent],
  );

  const handleNumberField = useCallback(
    (value, field) => {
      const reg = new RegExp(/^[0-9,.-]*$/);
      if (reg.test(value)) {
        handleFormField(value, field);
      }
    },
    [handleFormField],
  );

  return (
    <Grid
      container
      sx={{ marginTop: singleField ? 0 : '16px' }}
      alignItems={'center'}
      flexWrap={'nowrap'}
      position={'relative'}
    >
      <FormControl
        required={field.fieldDefinition.isRequired}
        key={field.fieldId}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flex: 1,
          alignItems: singleField ? 'flex-start' : 'center',
        }}
      >
        <InputLabel className={classes.selectLabel} sx={{ marginTop: singleField ? '8px' : 0 }}>
          <Typography variant="h5" color={'#41424E'}>
            {field.custom
              ? `${field.fieldDefinition.fieldName}:`
              : `${intl.formatMessage({
                  id: `issue_page_${field.fieldDefinition.fieldName.replaceAll(' ', '_').toLowerCase()}`,
                })}:`}
          </Typography>
        </InputLabel>
        {numberFields.current.includes(field.fieldDefinition.fieldFormat) && (
          <TextField
            inputProps={{ maxLength: field.fieldDefinition.maxLength, minLength: field.fieldDefinition.minLength }}
            disabled={field.deleted || field.fieldDefinition.isReadonly}
            error={field.error}
            required={field.fieldDefinition.isRequired}
            variant="outlined"
            sx={{ width: 250 }}
            className={classes.textField}
            placeholder={intl.formatMessage({ id: 'enter' })}
            value={field.valueId ? field.valueId : ''}
            onChange={event => handleNumberField(event.target.value, field)}
          ></TextField>
        )}
        {(field.fieldDefinition.fieldId === 'description' || field.fieldDefinition.fieldFormat === 'text') && (
          <Grid container flexDirection={'column'}>
            <Editor
              init={{
                skin: false,
                content_css: false,
                height: 200,
                menubar: false,
                plugins: field.fieldDefinition.fieldId === 'description' ? 'image autolink' : '',
                toolbar: 'formatselect | bold italic backcolor',
                link_default_target: '_blank',
                branding: false,
              }}
              value={field.valueName ? field.valueName : ''}
              onEditorChange={content => handleFormField(content, field)}
            />
          </Grid>
        )}
        {field.fieldDefinition.fieldId !== 'description' &&
          textField.current.includes(field.fieldDefinition.fieldFormat) && (
            <TextField
              onBlur={event => validateUrl(event.target.value, field.fieldDefinition.fieldFormat)}
              disabled={field.deleted || field.fieldDefinition.isReadonly}
              error={field.error}
              multiline={singleField}
              rows={singleField ? 3 : ''}
              required={field.fieldDefinition.isRequired}
              sx={{ width: 353 }}
              variant="outlined"
              className={classes.textField}
              placeholder={intl.formatMessage({ id: 'enter' })}
              value={field.valueId ? field.valueId : ''}
              onChange={event => handleFormField(event.target.value, field)}
            ></TextField>
          )}
        {field.fieldDefinition.fieldFormat === 'bool' && (
          <RadioGroup
            disabled={field.deleted || field.fieldDefinition.isReadonly}
            required={field.fieldDefinition.isRequired}
            className={classes.booleanWrap}
            onChange={(event, value) => handleFormField(value, field)}
            value={field.valueId}
          >
            <FormControlLabel
              className={classes.booleanLabel}
              value={true}
              checked={field.valueId !== '' && field.valueId === 'true'}
              control={
                <Radio
                  disableRipple
                  icon={
                    field.error ? (
                      <CheckboxIcon color={'#E03737'} width="12" height="12" viewBox="0 0 12 12" />
                    ) : (
                      <CheckboxIcon width="12" height="12" viewBox="0 0 12 12" />
                    )
                  }
                  checkedIcon={<CheckboxIconChecked width="12" height="12" viewBox="0 0 12 12" />}
                />
              }
              label={
                <Typography variant="h5">
                  <FormattedMessage id="yes" />
                </Typography>
              }
            />
            <FormControlLabel
              className={classes.booleanLabel}
              value={false}
              checked={field.valueId !== '' && field.valueId === 'false'}
              control={
                <Radio
                  disableRipple
                  icon={
                    field.error ? (
                      <CheckboxIcon color={'#E03737'} width="12" height="12" viewBox="0 0 12 12" />
                    ) : (
                      <CheckboxIcon width="12" height="12" viewBox="0 0 12 12" />
                    )
                  }
                  checkedIcon={<CheckboxIconChecked width="12" height="12" viewBox="0 0 12 12" />}
                />
              }
              label={
                <Typography variant="h5">
                  <FormattedMessage id="no" />
                </Typography>
              }
            />
          </RadioGroup>
        )}
        {searchFields.current.includes(field.fieldDefinition.fieldFormat) && (
          <Autocomplete
            disabled={field.deleted || field.fieldDefinition.isReadonly}
            noOptionsText={intl.formatMessage({ id: 'not_found' })}
            filterOptions={x => x}
            required={field.fieldDefinition.isRequired}
            onChange={(event, newValue) => handleFormField(newValue, field)}
            className={classes.fieldAutocomplete}
            disablePortal
            options={searchFieldOptions}
            sx={{ width: 253 }}
            disableClearable={bulk}
            renderOption={(props, option) => {
              return (
                <MenuItem
                  {...props}
                  selected={option.valueId === field.valueId}
                  key={option.valueId}
                  value={option.valueId + option.valueName}
                >
                  {option.valueName}
                </MenuItem>
              );
            }}
            value={field.valueId}
            getOptionLabel={option => option.valueName || ''}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            PopperComponent={props => <Popper {...props} placement="bottom-start"></Popper>}
            loading={searchLoading}
            renderInput={params => (
              <TextField
                {...params}
                error={field.error}
                onChange={ev => changeSearchInput(ev.target.value)}
                placeholder={intl.formatMessage({ id: 'choose' })}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {searchLoading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
            popupIcon={<ChevronIcon direction="down"></ChevronIcon>}
          />
        )}
        {autocompleteFields.current.includes(field.fieldDefinition.fieldFormat) &&
          !field.fieldDefinition.isMultiple && (
            <Autocomplete
              noOptionsText={intl.formatMessage({ id: 'not_found' })}
              disabled={field.deleted || field.fieldDefinition.isReadonly}
              required={field.fieldDefinition.isRequired}
              onChange={(event, newValue) => handleFormField(newValue, field)}
              className={classes.fieldAutocomplete}
              //disablePortal
              options={field.fieldDefinition.values ? field.fieldDefinition.values : []}
              sx={{ width: 253 }}
              disableClearable={bulk}
              renderOption={(props, option) => {
                return (
                  <MenuItem
                    {...props}
                    key={option.valueId + option.valueName}
                    value={option.valueId}
                    selected={field.valueId && option.valueId.toString() === field.valueId.toString()}
                  >
                    {option.valueName}
                  </MenuItem>
                );
              }}
              value={field.valueId}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              getOptionLabel={option => option.valueName || ''}
              PopperComponent={props => (
                <Popper {...props} placement="bottom-start" className={classes.autocompletePopper}></Popper>
              )}
              renderInput={params => (
                <TextField {...params} error={field.error} placeholder={intl.formatMessage({ id: 'choose' })} />
              )}
              popupIcon={<ChevronIcon direction="down"></ChevronIcon>}
            />
          )}
        {autocompleteFields.current.includes(field.fieldDefinition.fieldFormat) && field.fieldDefinition.isMultiple && (
          <Autocomplete
            forcePopupIcon={false}
            className={clsx(classes.fieldAutocomplete, classes.fieldAutocompleteMultiple)}
            multiple
            disableClearable
            //open={true}
            onChange={(event, newValue) => handleFormField(newValue, field)}
            options={field.fieldDefinition.values}
            sx={{ width: '100%' }}
            disableCloseOnSelect
            disablePortal
            value={field.valueId ? field.valueId : []}
            //isOptionEqualToValue={(option, value) => option.fieldId === value.fieldId}
            getOptionLabel={option => option.valueName || ''}
            renderOption={(props, option, { selected }) => (
              <li {...props} key={option.valueId}>
                <Checkbox
                  icon=<CheckboxIcon width="12" height="12" viewBox="0 0 12 12" />
                  checkedIcon=<CheckboxIconChecked width="12" height="12" viewBox="0 0 12 12" />
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.valueName}
              </li>
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  {...getTagProps({ index })}
                  key={index}
                  deleteIcon={<DeleteIcon width={10} height={10} viewBox="0 0 10 10" />}
                  className={classes.chip}
                  variant="outlined"
                  label={option.valueName}
                />
              ))
            }
            renderInput={params => <TextField {...params} placeholder={intl.formatMessage({ id: 'enter' })} />}
          />
        )}
        {field.fieldDefinition.fieldFormat === 'date' && (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              required={field.fieldDefinition.isRequired}
              disabled={field.deleted || field.fieldDefinition.isReadonly}
              format={'DD.MM.YYYY'}
              components={{
                OpenPickerIcon: ({ ...props }) => <CalendarFormIcon {...props} />,
                LeftArrowIcon: () => <ChevronIcon direction="left" viewBox="0 0 24 24" width={24} height={24} />,
                RightArrowIcon: () => <ChevronIcon direction="right" viewBox="0 0 24 24" width={24} height={24} />,
                RightArrowButton: componentProps => (
                  <IconButton disableRipple {...componentProps}>
                    {componentProps.children}
                  </IconButton>
                ),
                LeftArrowButton: componentProps => (
                  <IconButton disableRipple {...componentProps}>
                    {componentProps.children}
                  </IconButton>
                ),
              }}
              renderInput={params => (
                <TextField {...params} error={field.error} placeholder={intl.formatMessage({ id: 'enter' })} />
              )}
              className={classes.datePicker}
              value={field.valueName}
              onChange={value => handleFormField(value, field)}
              autoFocus={true}
            />
          </LocalizationProvider>
        )}
        {field.fieldDefinition.fieldFormat === 'attachment' && (
          <IssueFileUpload handleUpload={value => handleFormField(value, field)} single={true}></IssueFileUpload>
        )}
      </FormControl>
      {bulk && !field.fieldDefinition.isRequired && (
        <Tooltip
          PopperProps={{ className: classes.tooltipForm }}
          placement="top-start"
          title={intl.formatMessage({ id: 'issue_clear_tooltip' })}
        >
          <IconButton
            onClick={value => handleFormField(value, field, true)}
            disableRipple
            sx={{ padding: 0, marginLeft: '2px' }}
          >
            <ClearFormFieldIcon color={field.deleted ? '#E4E4EF' : '#7174AC'} />
          </IconButton>
        </Tooltip>
      )}
      {fieldError && (
        <Box sx={{ position: 'absolute', bottom: '-17px', left: '205px' }}>
          <Typography variant="h5" color={'#E03737'}>
            {intl.formatMessage({ id: 'issue_page_error_url_error' })}
          </Typography>
        </Box>
      )}
    </Grid>
  );
};

export default injectIntl(FormField);
