import xlanceAPI from 'Common/utils/axios';

// REDMINE
export const redmineGet = query => {
  return xlanceAPI.get(`/redmine/${query}`);
};

// AUTHORIZATION
export const authUser = ({ username, password }) => {
  return xlanceAPI.post('/api/auth/login', { username, password });
};

export const getXlanceAuthToken = ({ redmine_token }) => {
  return xlanceAPI.post('/api/auth/passthrough', { redmine_token });
};

export const getSupersetToken = () => {
  return xlanceAPI.get('/api/auth/superset');
};
// USER
export const getUserData = () => {
  return xlanceAPI.get('/api/auth/current');
};

//RESOURCE PLAN
export const getResourcePlanFilters = async () => {
  const { data } = await xlanceAPI.get(`/api/resource-plans/filters`);
  return data;
};
export const getResourcePlanFiltersQuery = async query => {
  const response = await xlanceAPI.get(`/api/resource-plans/filters?filters=${query}`);
  return response;
};

export const getResplanGlobalSettings = async () => {
  const { data } = await xlanceAPI.get(`/api/resource-plans/global/settings`);
  return data;
};

export const updateResplanGlobalSettings = data => {
  return xlanceAPI.post(`/api/resource-plans/global/settings`, data);
};

export const getResplanSettings = async () => {
  const { data } = await xlanceAPI.get(`/api/resource-plans/settings`);
  return data;
};
export const updateResplanSettings = data => {
  return xlanceAPI.post(`/api/resource-plans/settings`, data);
};

export const getResplanQuery = async () => {
  const { data } = await xlanceAPI.get(
    `/api/resource-plans/query?filters=resourcePlan.date_from;gte;2023-01-01$$resourcePlan.date_to;lte;2023-07-31$$resourcePlan.group_by;eq;0`,
  );
  return data;
};

export const getResplanUsers = async query => {
  const { data } = await xlanceAPI.get(`/api/resource-plans/get-users?project=${query}`);
  return data;
};
export const getResplanProjects = async query => {
  const { data } = await xlanceAPI.get(`/api/resource-plans/get-projects?user=${query}`);
  return data;
};
export const getResplanContracts = async query => {
  const { data } = await xlanceAPI.get(`/api/resource-plans/get-contracts?project=${query}`);
  return data;
};
export const getResplanTrackers = async (projectId, userId) => {
  const { data } = await xlanceAPI.get(`/api/resource-plans/get-trackers?project=${projectId}&user=${userId}`);
  return data;
};
export const getResplanCsv = async () => {
  const { data } = await xlanceAPI.get(`/api/resource-plans/export-csv`);
  return data;
};

//PROJECTS
export const getProjects = async () => {
  const { data } = await xlanceAPI.get(`/api/projects`);
  return data;
};

export const getProjectsWithQuery = async query => {
  const response = await xlanceAPI.get(`/api/projects?${query}`);
  return response.data;
};

export const getProjectsWithFilters = async query => {
  const { data } = await xlanceAPI.get(`/api/projects?${query}`);
  return data;
};

export const openProjectById = async id => {
  const { data } = await xlanceAPI.patch(`/api/projects/${id}/reopen`);
  return data;
};

export const closeProjectById = async id => {
  const { data } = await xlanceAPI.patch(`/api/projects/${id}/close`);
  return data;
};

export const getProjectReport = async query => {
  const { data } = await xlanceAPI.get(`/api/projects/${query}/reports`);
  return data;
};

export const getProject = async slug => {
  const { data } = await xlanceAPI.get(`/api/projects/${slug}`);
  return data;
};
export const updateProject = (id, data) => {
  return xlanceAPI.patch(`/api/projects/${id}`, data);
};
export const getProjectStatistic = async id => {
  const { data } = await xlanceAPI.get(`/api/projects/${id}/statistic`);
  return data;
};
export const getProjectMembers = async id => {
  return xlanceAPI.get(`/api/projects/${id}/members`);
};
export const deleteProjectMember = async (id, memberId) => {
  return xlanceAPI.delete(`/api/projects/${id}/members/${memberId}`);
};
export const updateProjectMember = async (id, memberId, memberRoles) => {
  return xlanceAPI.patch(`/api/projects/${id}/members/${memberId}`, memberRoles);
};
export const addProjectMember = async (id, user) => {
  return xlanceAPI.post(`/api/projects/${id}/members`, user);
};
export const getProjectMembersRoles = async id => {
  return xlanceAPI.get(`/api/projects/${id}/roles`);
};
export const getVerifyProjectIdentifier = async id => {
  const { data } = await xlanceAPI.get(`/api/projects/verify?identifier=${id}`);
  return data;
};
export const getProjectPermissions = async id => {
  const { data } = await xlanceAPI.get(`/api/projects/${id}/permissions`);
  return data;
};
export const getProjectFiles = id => {
  return xlanceAPI.get(`/api/projects/${id}/files`);
};
export const saveProjectFile = (id, value) => {
  return xlanceAPI.post(`/api/projects/${id}/file`, value);
};
export const deleteProjectFile = (id, fileId) => {
  return xlanceAPI.delete(`/api/projects/${id}/files/${fileId}`);
};
export const getUserCanCreate = async () => {
  return xlanceAPI.get(`/api/projects/can_create`);
};

export const getProjectsFilters = async params => {
  return xlanceAPI.get(`/api/projects/filters`, {
    params: params,
  });
};

export const getProjectReports = async () => {
  const response = await xlanceAPI.get(`/api/project-reports`);
  return response.data;
};

export const updateProjectReports = data => {
  return xlanceAPI.post(`/api/project-reports`, data);
};

export const getProjectsSettings = async () => {
  const response = await xlanceAPI.get(`/api/projects/settings`);
  return response.data;
};
export const createProjectsSettings = data => {
  return xlanceAPI.post(`/api/projects/settings`, data);
};
export const createProject = async () => {
  const response = await xlanceAPI.get(`/api/projects/create`);
  return response.data;
};
export const getProjectCreate = () => {
  return xlanceAPI.get(`/api/projects/create`);
};
export const getProjectEdit = params => {
  return xlanceAPI.get(`/api/projects/${params.id}/edit`);
};
export const editProject = async id => {
  const { data } = await xlanceAPI.get(`/api/projects/${id}/edit`);
  return data;
};
export const deleteUploadedFile = fileId => {
  return xlanceAPI.delete(`/redmine/attachments/${fileId}.json`);
};
export const uploadFile = (data, fileName) => {
  return xlanceAPI.post(`/redmine/uploads.json?filename=${fileName}`, data, {
    headers: {
      'Content-Type': 'application/octet-stream',
    },
  });
};
export const createProjectFromData = (id, data) => {
  return xlanceAPI.post(`/api/projects/create`, data);
};
// EXCLUSIONS
export const getCalendarExclusions = year => {
  return xlanceAPI.get(`/api/calendar?year=${year}`);
};

export const getTableExclusions = year => {
  return xlanceAPI.get(`/api/calendar-exclusions?year=${year}`);
};

export const createCustomExclusions = (year, exclusions) => {
  return xlanceAPI.post(`/api/calendar-exclusions/${year}`, exclusions);
};

export const updateCustomExclusion = (exclusionId, data) => {
  return xlanceAPI.patch(`/api/calendar-exclusions/${exclusionId}`, data);
};

export const deleteCustomExclusion = exclusionId => {
  return xlanceAPI.delete(`/api/calendar-exclusions/${exclusionId}`);
};

// ADMINISTRATION SETTINGS
export const getAdminSettings = () => {
  return xlanceAPI.get(`/api/settings`);
};

export const updateCalendarSettings = settings => {
  return xlanceAPI.patch('/api/settings', settings);
};

// SPREADSHEET PAGE
export const getTimesheets = ({ start, end }, signal) => {
  return xlanceAPI.get(`/api/timesheets?start=${start}&end=${end}`, { signal });
};

export const getAnotherTimesheets = ({ start, end, userId = null }) => {
  return xlanceAPI.get(
    `/api/timesheets/issues${start || userId ? (userId ? `?userId=${userId}` : `?start=${start}&end=${end}`) : ''}`,
  );
};

export const createOrUpdateTimesheets = value => {
  return xlanceAPI.post(`/api/timesheets`, value);
};
export const createTimesheets = value => {
  return xlanceAPI.post(`/api/timesheets/table`, value);
};
// SPENTTIME PAGE
export const deleteTimesheets = value => {
  return xlanceAPI.delete(`/api/timesheets`, { data: value });
};

export const checkIsButtonDisplayed = () => {
  return xlanceAPI.get('/api/timesheets/can_log');
};

export const getSpentTimes = params => {
  return xlanceAPI.get(`/api/timesheets/query`, {
    params: params,
  });
};
export const getIssues = params => {
  return xlanceAPI.get(`/api/issues`, {
    params: params,
  });
};
export const getSpentTimeFilters = params => {
  return xlanceAPI.get('/api/timesheets/filters', {
    params: params,
  });
};
export const getIssuesParams = params => {
  return xlanceAPI.get('/api/issues/filters', {
    params: params,
  });
};
export const getIssuesFiltersByProjectId = id => {
  return id ? xlanceAPI.get(`/api/issues/filters?project=${id}`) : xlanceAPI.get('/api/issues/filters');
};
// export const getQueryProjects = ({ checked = '' }) => {
export const getQueryProjects = () => {
  // return xlanceAPI.get(`/api/query/projects?checked=${checked}`);
  return xlanceAPI.get(`/api/query/projects`);
};

export const getQueryTrackers = projectId => {
  return xlanceAPI.get(`/api/query/trackers`, {
    params: {
      [projectId && 'project']: projectId ? projectId : null,
    },
  });
};
export const getQueryIssueTrackers = params => {
  return xlanceAPI.get(`/api/query/trackers`, {
    params: params,
  });
};
export const getQueryResplanIssues = (searchTxt, projectIds, businessUnitIds, contractIds) => {
  return xlanceAPI.get(`/api/query/res-plan-issues`, {
    params: {
      search: searchTxt,
      [projectIds && 'projectIds']: projectIds ? projectIds : null,
      [businessUnitIds && 'businessUnitIds']: businessUnitIds ? businessUnitIds : null,
      [contractIds && 'contractIds']: contractIds ? contractIds : null,
    },
  });
};

export const getQueryCategory = () => {
  return xlanceAPI.get(`/api/query/category`);
};

export const getQueryUser = (fieldId, projectId) => {
  return xlanceAPI.get(`/api/query/users`, {
    params: {
      [projectId && 'project']: projectId ? projectId : null,
      [fieldId && 'field']: fieldId ? fieldId : null,
    },
  });
};
export const getQueryUsers = () => {
  return xlanceAPI.get(`/api/query/users`);
};
export const getQueryActivity = () => {
  return xlanceAPI.get(`/api/query/activities`);
};

export const getQueryRoles = () => {
  return xlanceAPI.get(`/api/query/roles`);
};

export const getFilterSubItems = filter => {
  return xlanceAPI.get(`/api/query/list?field=${filter}`);
};
export const getFilterIssueWatcher = projectId => {
  return xlanceAPI.get(`/api/query/issue_watchers`, {
    params: {
      [projectId && 'project']: projectId ? projectId : null,
    },
  });
};
export const getFilterIssuePriorities = filter => {
  return xlanceAPI.get(`/api/query/issue_priorities`);
};
export const getFilterIssueRoles = filter => {
  return xlanceAPI.get(`/api/query/roles`);
};
export const getFilterCalculationTypes = filter => {
  return xlanceAPI.get(`/api/query/calculation_types`);
};
export const getFilterIssueUserGroups = filter => {
  return xlanceAPI.get(`/api/query/user_groups`);
};
export const getFilterProjectParents = filter => {
  return xlanceAPI.get(`/api/query/parents`);
};
export const getFilterIssueStatuses = () => {
  return xlanceAPI.get(`/api/query/issue_statuses`);
};

export const getFilterProjectStatuses = () => {
  return xlanceAPI.get(`/api/query/project_statuses`);
};

export const getTimesheetProjectInfo = ({ projectId, timesheetId = 0 }) => {
  return xlanceAPI.get(`/api/timesheets/${timesheetId}`, {
    params: {
      project: projectId,
    },
  });
};
export const getTimesheetProjectInfoSpentTime = ({ params, timesheetId = 0 }) => {
  return xlanceAPI.get(`/api/timesheets/${timesheetId}`, {
    params: params,
  });
};
export const getTimesheetInfo = timesheetId => {
  return xlanceAPI.get(`/api/timesheets/${timesheetId ? timesheetId : 0}`);
};
export const queryForApproveTimesheet = ({ values }) => {
  return xlanceAPI.post(`/api/timesheets/approve`, values);
};

export const createNewSavedQuery = (
  { type, name, categoryId, query, roles, accessLevel, projectLevel, projectId },
  savedQueryId,
) => {
  const data = {
    type,
    name,
    categoryId,
    query,
    roles,
    accessLevel,
    projectLevel,
    projectId,
  };
  return savedQueryId ? xlanceAPI.patch(`/api/query/${savedQueryId}`, data) : xlanceAPI.post(`/api/query`, data);
};

export const deleteSavedQuery = ({ id }) => {
  return xlanceAPI.delete(`/api/query/${id}`);
};

export const getSavedQuery = ({ id }) => {
  return xlanceAPI.get(`/api/query/${id}`);
};

export const getQueryIssues = ({ searchText }) => {
  return xlanceAPI.get(`/api/query/issues?search=${searchText}`);
};
export const getIssueQuery = params => {
  return xlanceAPI.get(`/api/query/issues`, {
    params: params,
  });
};
export const getQueryIssuesParams = params => {
  return xlanceAPI.get(`/api/query/issues`, { params });
};
export const getTimesheetCsv = query => {
  return xlanceAPI.get(`/api/timesheets/query/export-csv?${query}`);
};
export const getIssuesCsv = query => {
  return xlanceAPI.get(`/api/issues/export-csv?${query}`);
};
export const getTimesheetProjects = () => {
  return xlanceAPI.get(`/api/timesheets/projects`);
};

export const getIssuesActionMenu = query => {
  return xlanceAPI.get(`/api/issues/lists?issues=${query}`);
};
export const patchIssues = data => {
  return xlanceAPI.patch(`/api/issues/bulk`, data);
};
export const getTimesheetSavedQueries = params => {
  return xlanceAPI.get(`/api/timesheets/queries`, {
    params: params,
  });
};
export const getTimesheetEditBulk = params => {
  return xlanceAPI.get(`/api/timesheets/bulk`, {
    params: params,
  });
};
export const saveTimesheetEditBulk = data => {
  return xlanceAPI.patch(`/api/timesheets/bulk`, data);
};
export const getIssuesSavedQueries = params => {
  return xlanceAPI.get(`/api/issues/queries`, {
    params: params,
  });
};
export const getIssuesSavedQueriesByProject = query => {
  return xlanceAPI.get(`/api/issues/queries?project=${query}`);
};
export const getCalculationTypes = () => {
  return xlanceAPI.get(`api/rate/get-calculation-type`);
};
export const saveCalculationType = value => {
  return xlanceAPI.post(`/api/rate/save-calculation-type`, value);
};
export const editCalculationType = (id, name) => {
  return xlanceAPI.put(`/api/rate/calculation-type/${id}`, name);
};
export const deleteCalculationType = id => {
  return xlanceAPI.delete(`/api/rate/delete-calculation-type/${id}`);
};
export const blockCalculationType = value => {
  return xlanceAPI.patch(`/api/rate/block-calculation-type`, value);
};
export const unblockCalculationType = value => {
  return xlanceAPI.patch(`/api/rate/unblock-calculation-type`, value);
};
export const getTrackers = () => {
  return xlanceAPI.get(`api/rate/get-trackers`);
};
export const saveTracker = value => {
  return xlanceAPI.post(`api/rate/save-tracker`, value);
};
export const deleteTracker = id => {
  return xlanceAPI.delete(`/api/rate/trackers/${id}`);
};
export const getUsersData = query => {
  return xlanceAPI.get(`/api/users`, {
    params: query,
  });
};
export const getUsersFilters = () => {
  return xlanceAPI.get('/api/users/filters');
};
export const getUsersInfo = id => {
  return xlanceAPI.get(`/api/users/info/${id}`);
};
export const getUserRates = id => {
  return xlanceAPI.get(`/api/users/user-rates/${id}`);
};
export const saveUserRates = (id, value) => {
  return xlanceAPI.post(`/api/users/user-rates/${id}`, value);
};
export const deleteUserRate = (id, rateId) => {
  return xlanceAPI.delete(`/api/users/user-rates/${id}/${rateId}`);
};
export const editUserRates = (id, rateId, value) => {
  return xlanceAPI.put(`/api/users/user-rates/${id}/${rateId}`, value);
};
export const getIssueData = id => {
  return xlanceAPI.get(`/api/issues/${id}`);
};
export const getIssuePermissions = id => {
  return xlanceAPI.get(`/api/issues/${id}/permissions`);
};
export const getIssueWatchers = id => {
  return xlanceAPI.get(`/api/issues/${id}/watchers`);
};
export const saveIssueWatchers = (id, value) => {
  return xlanceAPI.post(`/api/issues/${id}/watchers`, value);
};
export const deleteIssueWatcher = (id, watcherId) => {
  return xlanceAPI.delete(`/api/issues/${id}/watchers/${watcherId}`);
};
export const getIssueChildren = id => {
  return xlanceAPI.get(`/api/issues/${id}/children`);
};
export const getIssueRelated = id => {
  return xlanceAPI.get(`/api/issues/${id}/related`);
};
export const saveIssueRelated = (id, value) => {
  return xlanceAPI.post(`/api/issues/${id}/related`, value);
};
export const deleteIssueRelated = (id, relationId) => {
  return xlanceAPI.delete(`/api/issues/${id}/related/${relationId}`);
};
export const getIssueFiles = id => {
  return xlanceAPI.get(`/api/issues/${id}/files`);
};
export const saveIssueFile = (id, value) => {
  return xlanceAPI.post(`/api/issues/${id}/files`, value);
};
export const deleteIssueFile = (id, fileId) => {
  return xlanceAPI.delete(`/api/issues/${id}/files/${fileId}`);
};
export const getIssueHistory = id => {
  return xlanceAPI.get(`/api/issues/${id}/history`);
};
export const getIssueProjects = params => {
  return xlanceAPI.get(`/api/issues/projects`, {
    params: params,
  });
};
export const getaGanttIssueTrackers = projectId => {
  return xlanceAPI.get(`/api/gantt/trackers/${projectId}`);
};
export const getaGanttIssueTrackersAssignees = params => {
  return xlanceAPI.get(`/api/query/issue_assignees`, {
    params: params,
  });
};
export const getIssuePredecessors = params => {
  return xlanceAPI.get(`/api/gantt/get-predecessor-issues`, {
    params: params,
  });
};
export const getIssueTrackers = params => {
  return xlanceAPI.get(`/api/issues/trackers`, {
    params: params,
  });
};
export const getIssueTrackersBulk = params => {
  return xlanceAPI.get(`/api/issues/trackers/bulk`, {
    params: params,
  });
};
export const getIssueCreate = (id, params) => {
  return xlanceAPI.get(`/api/issues/create`, {
    params: params,
  });
};
export const getIssueEdit = (id, params) => {
  return xlanceAPI.get(`/api/issues/${id}/edit`, {
    params: params,
  });
};
export const getIssuesBulk = (id, params) => {
  return xlanceAPI.get(`/api/issues/bulk`, {
    params: params,
  });
};
export const saveIssueEdit = (id, data) => {
  return xlanceAPI.patch(`/api/issues/${id}`, data);
};
export const saveIssue = (id, data) => {
  return xlanceAPI.post(`/api/issues`, data);
};
export const saveIssueBulk = (id, data) => {
  return xlanceAPI.patch(`/api/issues/bulk`, data);
};
export const getParentIssueQuery = params => {
  return xlanceAPI.get(`/api/query/parent-issues`, {
    params: params,
  });
};
export const deleteIssue = (id, params) => {
  return xlanceAPI.delete(`/api/issues/${id}`, {
    params: params,
  });
};
export const deleteIssueBulk = (issuesIds, params) => {
  return xlanceAPI.delete(`/api/issues/bulk`, { data: issuesIds, params: params });
};
// CONTRACT STAGES

export const getContractStagesSettings = () => {
  return xlanceAPI.get('/api/contract-stages/settings');
};

export const getContractStagesCommonSettings = () => {
  return xlanceAPI.get('/api/contract-stages/settings/common');
};
export const saveContractStagesCommonSettings = data => {
  return xlanceAPI.post('/api/contract-stages/settings/common', data);
};
export const deleteContractStagesSettings = id => {
  return xlanceAPI.delete(`/api/contract-stages/settings/${id}`);
};
export const saveContractStageSettings = data => {
  return xlanceAPI.post(`/api/contract-stages/settings`, data);
};
export const getIssueContractStages = id => {
  return xlanceAPI.get(`/api/contract-stages/issue/${id}`);
};
export const getIssueCreateContractStages = id => {
  return xlanceAPI.get(`/api/contract-stages/issue/${id}/create`);
};
export const saveIssueContractStage = value => {
  return xlanceAPI.post(`/api/contract-stages`, value);
};
export const saveEditIssueContractStage = (id, value) => {
  return xlanceAPI.patch(`/api/contract-stages/${id}`, value);
};
export const getIssueContractStage = id => {
  return xlanceAPI.get(`/api/contract-stages/${id}`);
};
export const deleteIssueContractStage = id => {
  return xlanceAPI.delete(`/api/contract-stages/${id}`);
};

// AGILE
export const getAgile = params => {
  return xlanceAPI.get(`/api/agile`, {
    params: params,
  });
};
export const getAgileRules = params => {
  return xlanceAPI.get(`/api/agile/rules`, {
    params: params,
  });
};
export const getAgileFilters = params => {
  return xlanceAPI.get(`/api/agile/filters`, {
    params: params,
  });
};
export const getAgileSavedQueriesByProject = params => {
  return xlanceAPI.get(`/api/agile/queries`, {
    params: params,
  });
};

// CONFIRMATION SETTINGS
export const getConfirmationSettingsCommon = () => {
  return xlanceAPI.get(`/api/confirmation/settings/common`);
};
export const saveConfirmationSettingsCommon = value => {
  return xlanceAPI.post(`/api/confirmation/settings/common`, value);
};
export const getConfirmationSettings = () => {
  return xlanceAPI.get(`/api/confirmation/settings`);
};
export const getConfirmationSettingsRow = id => {
  return xlanceAPI.get(`/api/confirmation/settings/${id}`);
};
export const deleteConfirmationSettingsRow = id => {
  return xlanceAPI.delete(`/api/confirmation/settings/${id}`);
};
export const saveConfirmationSettings = value => {
  return xlanceAPI.post(`/api/confirmation/settings`, value);
};
export const saveEditConfirmationSettings = (id, value) => {
  return xlanceAPI.put(`/api/confirmation/settings/${id}`, value);
};
export const confirmationInitial = issues => {
  return xlanceAPI.post(`/api/confirmation/initial`, {
    issues: issues,
  });
};
export const getConfirmationById = id => {
  return xlanceAPI.get(`/api/confirmation/${id}`);
};
export const saveConfirmationResult = (id, resultId, value) => {
  return xlanceAPI.patch(`/api/confirmation/${id}/result/${resultId}`, value);
};
export const getConfirmationReinit = params => {
  return xlanceAPI.get(`/api/confirmation/reinitial`, {
    params: params,
  });
};
export const saveConfirmationReinit = value => {
  return xlanceAPI.post(`/api/confirmation/reinitial`, {
    issues: value,
  });
};
export const getConfirmations = params => {
  return xlanceAPI.get(`/api/confirmation`, {
    params: params,
  });
};
export const getConfirmationsFilters = params => {
  return xlanceAPI.get(`/api/confirmation/filters`, {
    params: params,
  });
};
export const cancelConfirmation = id => {
  return xlanceAPI.post(`/api/confirmation/${id}/cancel`);
};
export const getIssueConfirmationById = id => {
  return xlanceAPI.get(`/api/confirmation/issue/${id}`);
};
export const getQueryConfirmationStatuses = () => {
  return xlanceAPI.get(`/api/query/confirmation_status`);
};
export const getQueryConfirmationTrackers = () => {
  return xlanceAPI.get(`/api/query/confirmation_trackers`);
};
export const getQueryConfirmationIssues = params => {
  return xlanceAPI.get(`/api/query/confirmation_issues`, {
    params: params,
  });
};
export const getQueryConfirmationConfirmations = params => {
  return xlanceAPI.get(`/api/query/confirmations`, {
    params: params,
  });
};
export const getQueryConfirmationApprovers = () => {
  return xlanceAPI.get(`/api/query/confirmation_approvers`);
};
export const getQueryConfirmationProjects = () => {
  return xlanceAPI.get(`/api/query/confirmation_projects`);
};
export const getQueryConfirmationAuthors = () => {
  return xlanceAPI.get(`/api/query/confirmation_authors`);
};
export const getQueryConfirmationEditors = () => {
  return xlanceAPI.get(`/api/query/confirmation_editors`);
};
export const fixConfirmation = id => {
  return xlanceAPI.post(`/api/confirmation/${id}/fix`);
};
export const getConfirmationsSavedQueries = () => {
  return xlanceAPI.get(`/api/confirmation/queries`);
};
// GANT
export const getGantSettings = projectId => {
  return xlanceAPI.get(`/api/gantt/${projectId}/settings`);
};
export const getGantFilters = projectId => {
  return xlanceAPI.get(`/api/gantt/${projectId}/filters`);
};
export const getGantIssueCreate = (id, params) => {
  return xlanceAPI.get(`/api/gantt/issue/create`, {
    params: params,
  });
};
export const getGantIssueEdit = (id, params) => {
  return xlanceAPI.get(`/api/gantt/issue/${id}/edit`, {
    params: params,
  });
};
export const getGantIssueCreateTrackers = params => {
  return xlanceAPI.get(`/api/gantt/create/trackers`, {
    params: params,
  });
};
export const getGantParentIssue = params => {
  return xlanceAPI.get(`/api/gantt/get-parent-issues`, {
    params: params,
  });
};
export const saveGanttSettings = (id, value) => {
  return xlanceAPI.post(`/api/gantt/${id}/settings`, {
    settings: value,
  });
};
export const getGanttAccessRoles = () => {
  return xlanceAPI.get(`/api/gantt/global/settings`);
};
export const getGanttIssueUsers = params => {
  return xlanceAPI.get(`/api/gantt/get-issue-users`, {
    params: params,
  });
};
export const saveGanttAccessRoles = value => {
  return xlanceAPI.post(`/api/gantt/global/settings`, {
    accessRoles: value,
  });
};
export const getGanttMovingIssue = params => {
  return xlanceAPI.get(`/api/gantt/get-moving-issue`, {
    params: params,
  });
};
export const clearGanttSave = id => {
  return xlanceAPI.post(`/api/gantt/clear-cash`, {
    project: id,
  });
};
export const getGantPredecessorIssues = params => {
  return xlanceAPI.get(`/api/gantt/get-predecessor-issues`, {
    params: params,
  });
};
export const getGantCsv = params => {
  return xlanceAPI.get(`/api/gantt/export-csv`, {
    params: params,
  });
};
export const postImportData = (dataTypeOption, data) => {
  const form = new FormData();
  form.append('file', data);
  return xlanceAPI.post(`/api/import/excel?subject=${dataTypeOption}`, form);
};
export const getFilterListItems = params => {
  return xlanceAPI.get(`/api/query/list`, {
    params: params,
  });
};

//Art
export const getArtSettingsCommon = () => {
  return xlanceAPI.get(`/api/art/settings/common`);
};
export const saveArtSettingsCommon = value => {
  return xlanceAPI.post(`/api/art/settings/common`, value);
};
export const getArtSettingsTrackers = () => {
  return xlanceAPI.get(`/api/art/settings/trackers`);
};
export const getArtTrackersNewRow = () => {
  return xlanceAPI.get(`/api/art/settings/trackers/create`);
};
export const deleteArtTrackersRow = id => {
  return xlanceAPI.delete(`/api/art/settings/trackers/${id}`);
};
export const saveArtTrackersRow = value => {
  return xlanceAPI.post(`/api/art/settings/trackers`, value);
};
export const getArtSettingsCustomers = () => {
  return xlanceAPI.get(`/api/art/settings/customers`);
};
export const getArtFilters = () => {
  return xlanceAPI.get(`/api/art/filters`);
};
export const getArtTrackersRow = id => {
  return xlanceAPI.get(`/api/art/settings/trackers/${id}`);
};
export const getArtCustomersNewRow = () => {
  return xlanceAPI.get(`/api/art/settings/customers/create`);
};
export const getArtCustomersRow = id => {
  return xlanceAPI.get(`/api/art/settings/customers/${id}`);
};
export const saveArtCustomersRow = value => {
  return xlanceAPI.post(`/api/art/settings/customers`, value);
};
export const deleteArtCustomersRow = id => {
  return xlanceAPI.delete(`/api/art/settings/customers/${id}`);
};
export const saveArtCustomersRowId = (id, value) => {
  return xlanceAPI.patch(`/api/art/settings/customers/${id}`, value);
};

//Menu
export const getMenuNavigation = () => {
  return xlanceAPI.get(`/api/projects/navigation`);
};
export const getMenuNavigationId = id => {
  return xlanceAPI.get(`/api/projects/${id}/navigation`);
};

//Confirmation trackers
export const getConfirmationSettingsTrackers = () => {
  return xlanceAPI.get(`/api/confirmation/trackers`);
};
export const getConfirmationTrackersNewRow = () => {
  return xlanceAPI.get(`/api/confirmation/trackers/create`);
};
export const deleteConfirmationTrackersRow = id => {
  return xlanceAPI.delete(`/api/confirmation/trackers/${id}`);
};
export const saveConfirmationTrackersRow = value => {
  return xlanceAPI.post(`/api/confirmation/trackers`, value);
};
export const getConfirmationTrackersRow = id => {
  return xlanceAPI.get(`/api/confirmation/trackers/${id}`);
};
export const getProjectsList = params => {
  return xlanceAPI.get(`/api/projects`, {
    params: params,
  });
};
export const getProjectsSavedQueries = params => {
  return xlanceAPI.get(`/api/projects/queries`);
};
export const getTimesheetCreate = (id, params) => {
  return xlanceAPI.get(`/api/timesheets/create`, {
    params: params,
  });
};
export const createTimesheet = (id, data) => {
  return xlanceAPI.post(`/api/timesheets`, data);
};
export const getTimesheet = (id, params) => {
  return xlanceAPI.get(`/api/timesheets/${id}`, {
    params: params,
  });
};
export const getTimesheetIssueQuery = params => {
  return xlanceAPI.get(`/api/query/timesheet-issues`, {
    params: params,
  });
};
export const getTimesheetBulk = (id, params) => {
  return xlanceAPI.get(`/api/timesheets/bulk`, {
    params: params,
  });
};
export const saveTimesheetBulk = (id, data) => {
  return xlanceAPI.patch(`/api/timesheets/bulk`, data);
};
export const saveEditTimesheet = (id, data) => {
  return xlanceAPI.patch(`/api/timesheets/${id}`, data);
};
export const checkLicense = params => {
  return xlanceAPI.get(`/api/auth/license`);
};
export const issueCanCreate = async () => {
  return xlanceAPI.get(`/api/issues/can_create`);
};
export const saveQuery = data => {
  return xlanceAPI.post(`/api/query`, data);
};
export const editQuery = (id, data) => {
  return xlanceAPI.patch(`/api/query/${id}`, data);
};

// Objects hierarchy
export const getHierarchyMasterObjects = () => {
  return xlanceAPI.get(`/api/trackers`);
};
export const getHierarchyObjectsById = id => {
  return xlanceAPI.get(`/api/trackers/${id}`);
};
export const saveHierarchyObject = (id, value) => {
  return xlanceAPI.put(`/api/trackers/${id}`, value);
};
export const deleteHierarchyObject = (id, value) => {
  return xlanceAPI.delete(`/api/trackers/${id}`, { data: value });
};

// Timesheet settings
export const getTimesheetsSettings = () => {
  return xlanceAPI.get(`/api/trackers/timesheets`);
};
export const saveTimesheetsSettings = data => {
  return xlanceAPI.patch(`/api/trackers/timesheets`, data);
};
