import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  createProjectFromData,
  getProjectCreate,
  getProjectEdit,
  getVerifyProjectIdentifier,
  updateProject,
} from '../../../packages/common/api';
import { useLocation, useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import CommonPage from '../CommonPage/CommonPage';
import { Box, Grid, Typography } from '@mui/material';
import { injectIntl } from 'react-intl';
import userPageStyles from '../administration/userPage/UserPage.styles';
import IssueFormField from '../issueCreateEdit/components/IssueFormField';
import FormField from '../../../packages/common/shared-ui/src/components/FormField/FormField';
import { ButtonsTypes } from '../../../packages/common/shared-ui/src/enums/commonEnums';
import ProjectMembers from './components/ProjectMembers';
import { formValidation } from '../../../packages/common/utils/formValidation';
import { formatFields, saveFormat } from '../../../packages/common/utils/fieldHelper';

const ProjectCreateEdit = ({ intl }) => {
  const commonClasses = userPageStyles();
  const location = useLocation();
  const pageParams = useParams();
  const navigate = useNavigate();

  const PageState = { CREATE: 'create', EDIT: 'edit' };
  const [pageState, setPageState] = useState(null);
  const [loading, setLoading] = useState(true);
  const [formAllFields, setFormAllFields] = useState([]);
  const [projectTrackers, setProjectTrackers] = useState(null);
  const [trackers, setTrackers] = useState([]);
  const [errors, setErrors] = useState([]);
  const [currentIdentifier, setCurrentIdentifier] = useState(null);

  const projectFormState = useRef(
    new Map([
      [
        PageState.CREATE,
        { getResponse: getProjectCreate, saveResponse: createProjectFromData, pageTitle: 'project_new_title' },
      ],
      [PageState.EDIT, { getResponse: getProjectEdit, saveResponse: updateProject, pageTitle: 'project_edit_title' }],
    ]),
  );
  const handleError = useCallback(
    error => {
      if (error) {
        navigate(`/errors/error-${error.status}`);
      }
    },
    [navigate],
  );
  useEffect(() => {
    location.pathname.includes(PageState.CREATE) && setPageState(PageState.CREATE);
    location.pathname.includes(PageState.EDIT) && setPageState(PageState.EDIT);
  }, [PageState.BULK_EDIT, PageState.CREATE, PageState.EDIT, location.pathname]);

  useEffect(() => {
    let ignore = false;
    async function getPageData() {
      let params = {};
      pageState === PageState.EDIT && (params.id = pageParams.projectId);
      const response = await projectFormState.current.get(pageState).getResponse(params);
      if (!ignore) {
        return response;
      }
    }
    getPageData()
      .then(res => {
        const { data: projectData } = res;
        let fields = {};
        projectData.standards && (fields.standards = projectData.standards);
        projectData.customs && (fields.customs = projectData.customs);
        const identifierField = projectData.standards.find(field => field.fieldId === 'identifier');
        identifierField !== undefined && identifierField.valueId && setCurrentIdentifier(identifierField.valueId);
        projectData.trackers && setTrackers(projectData.trackers);
        setFormAllFields(formatFields(fields));
        setLoading(false);
      })
      .catch(error => handleError(error.response));
    return () => {
      ignore = true;
    };
  }, [PageState.EDIT, handleError, pageParams.projectId, pageState]);

  useEffect(() => {
    if (trackers.length > 0) {
      const field = {
        fieldId: 'trackers',
        fieldName: 'Trackers',
        valueId: trackers.filter(item => item.isChecked),
        valueName: trackers.filter(item => item.isChecked),
        fieldDefinition: {
          fieldId: 'trackers',
          fieldName: 'Trackers',
          fieldFormat: 'multiple',
          isMultiple: true,
          isRequired: false,
          values: trackers,
        },
      };
      setProjectTrackers(field);
    }
  }, [trackers, trackers.length]);

  const handleFormField = useCallback(
    (data, field) => {
      const pos = formAllFields.map(e => e.fieldId).indexOf(field.fieldId);
      const fieldValue = {
        ...field,
        ...data,
      };
      const newFields = [...formAllFields.slice(0, pos), fieldValue, ...formAllFields.slice(pos + 1)];
      setFormAllFields(newFields);
    },
    [formAllFields],
  );

  const handleValidation = useCallback(
    identifier => {
      const validationResult = formValidation(formAllFields, identifier);
      setFormAllFields(validationResult.fields);
      setErrors(validationResult.errors);
      return validationResult.error;
    },
    [formAllFields],
  );

  const handleIssueError = useCallback(
    error => {
      if (error) {
        navigate(`/errors/error-${error.status}`);
      }
    },
    [navigate],
  );

  const saveForm = useCallback(
    async action => {
      let identifierError;
      const identifierField = formAllFields.find(field => field.fieldId === 'identifier');
      const regexForIdentifier = /^[a-z0-9-_]*$/;
      let checkIdentifier =
        pageState === PageState.CREATE
          ? identifierField !== undefined && identifierField.valueId !== null
          : identifierField.valueId !== currentIdentifier;
      if (checkIdentifier) {
        if (regexForIdentifier.test(identifierField.valueId)) {
          try {
            identifierError = await getVerifyProjectIdentifier(identifierField.valueId);
          } catch (error) {
            return console.log(error);
          }
        } else {
          identifierError = false;
        }
      }
      const validation = handleValidation(identifierError);
      if (validation) {
        return;
      }
      let changedField;
      let filledStandardFields;
      let filledCustomsFields;
      let requiredFieldsStandard;
      let requiredFieldsCustom;
      let changedFieldsStandard;
      let changedFieldsCustom;
      let resultStandardArray;
      let resultCustomsArray;
      changedField = formAllFields.filter(field => field.changed);
      //filledStandardFields = formAllFields.filter(
      //  field => field.valueId !== null && field.valueName !== '' && !field.custom,
      //);
      //filledCustomsFields = formAllFields.filter(
      //  field => field.valueId !== null && field.valueName !== '' && field.custom,
      //);
      pageState === PageState.CREATE &&
        (changedField = formAllFields.filter(field => field.changed && !field.fieldDefinition.isRequired));
      changedFieldsStandard = changedField.filter(field => !field.custom);
      changedFieldsCustom = changedField.filter(field => field.custom);
      pageState === PageState.CREATE &&
        (requiredFieldsStandard = formAllFields.filter(field => field.fieldDefinition.isRequired && !field.custom));
      pageState === PageState.CREATE &&
        (requiredFieldsCustom = formAllFields.filter(field => field.fieldDefinition.isRequired && field.custom));
      resultStandardArray = [...changedFieldsStandard];
      resultCustomsArray = [...changedFieldsCustom];
      pageState === PageState.CREATE && (resultStandardArray = [...requiredFieldsStandard, ...changedFieldsStandard]);
      pageState === PageState.CREATE && (resultCustomsArray = [...requiredFieldsCustom, ...changedFieldsCustom]);
      const fields = saveFormat({ standards: resultStandardArray, customs: resultCustomsArray });
      let data = {
        ...fields,
        trackers: projectTrackers.valueId.map(item => item.trackerId),
      };
      setLoading(true);
      try {
        return await projectFormState.current.get(pageState).saveResponse(pageParams.projectId, data);
      } catch (error) {
        setLoading(false);
        handleIssueError(error.response);
      }
    },
    [
      formAllFields,
      pageState,
      PageState.CREATE,
      currentIdentifier,
      handleValidation,
      projectTrackers,
      pageParams.projectId,
      handleIssueError,
    ],
  );

  const handleButtonEvent = useCallback(
    type => {
      switch (type) {
        case ButtonsTypes.SAVE:
          saveForm(type).then(res => {
            if (res) {
              const { data: response } = res;
              const projectUrl = '/projects/' + response.redmineId;
              navigate(projectUrl);
              setLoading(false);
            }
          });
          break;
        case ButtonsTypes.CANCEL:
          navigate('/projects/');
      }
    },
    [navigate, saveForm],
  );

  const handleTrackersField = useCallback((data, field) => {
    const fieldValue = {
      ...field,
      ...data,
    };
    setProjectTrackers(fieldValue);
  }, []);

  return (
    <>
      {pageState && (
        <CommonPage
          pageTitle={intl.formatMessage({ id: projectFormState.current.get(pageState).pageTitle })}
          loading={loading}
          buttonEvent={handleButtonEvent}
        >
          <>
            {errors.length > 0 && (
              <Box>
                {errors.map((error, index) => (
                  <Typography key={index} variant="h3" color={'#E03737'}>
                    {intl.formatMessage({ id: error.message })}
                  </Typography>
                ))}
              </Box>
            )}
            <Grid container spacing={5} paddingBottom={'20px'}>
              <Grid item lg={6}>
                <Grid
                  container
                  direction={'column'}
                  className={commonClasses.blockWrapper}
                  padding={'0 32px 16px 32px'}
                >
                  {formAllFields.length > 0 &&
                    formAllFields.map((field, index) => (
                      <FormField key={index} field={field} fieldEvent={handleFormField}></FormField>
                    ))}
                </Grid>
              </Grid>
              <Grid item lg={6}>
                {projectTrackers && (
                  <Grid
                    container
                    direction={'column'}
                    className={commonClasses.blockWrapper}
                    padding={'24px 32px 16px 32px'}
                    marginBottom={'20px'}
                  >
                    {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                    <Typography variant="h3" color={'#082253'} fontWeight={600}>
                      Настройка задач
                    </Typography>
                    <FormField field={projectTrackers} fieldEvent={handleTrackersField}></FormField>
                  </Grid>
                )}
                {pageState === PageState.EDIT && <ProjectMembers></ProjectMembers>}
              </Grid>
            </Grid>
          </>
        </CommonPage>
      )}
    </>
  );
};

export default injectIntl(ProjectCreateEdit);
