import React from 'react';
import { injectIntl, useIntl } from 'react-intl';
import { Grid, Box, Typography } from '@mui/material';
import useStyles from './ProjectsTilesView.styles';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { generateRedirectUrl } from 'Common/utils/getRedirectUrl';
import { getTranslatedText } from 'Common/utils/getTranslatedText';
import { useNavigate } from 'react-router';

const ProjectsTilesView = ({ projects }) => {
  const redmineToken = useSelector(state => state.user.redmine_access_token);
  const classes = useStyles();
  const navigate = useNavigate();
  const intl = useIntl();
  const handleDoubleClick = project => {
    const projectUrl = '/projects/' + project.services.redmineId;
    window.open(projectUrl, '_blank');
  };
  return (
    <Grid spacing={5} container>
      {projects.map((project, index) => (
        <Grid container item xs={3} key={index}>
          <Box direction="column" className={classes.ProjectWrap}>
            <Typography variant="h3" className={classes.projectTtl} onClick={() => handleDoubleClick(project)}>
              {project.rowName}{' '}
              <span
                className={clsx(classes.ProjectStatus, {
                  [classes.ProjectOpened]: project.services.status === '1',
                  [classes.ProjectClosed]: project.services.status != '1',
                })}
              >
                •
              </span>
            </Typography>
            {project.columns.map((field, index) =>
              field.fieldId != 'project.name' ? (
                <Box key={index} marginTop={'8px'}>
                  <Typography variant="h5" className={classes.projectFiledTtl}>
                    {getTranslatedText(intl, 'filter', field.fieldId, field.fieldName)}:
                  </Typography>
                  <Typography variant="h5" className={classes.projectFiledValue}>
                    {field.value}
                  </Typography>
                </Box>
              ) : null,
            )}
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};
export default injectIntl(ProjectsTilesView);
