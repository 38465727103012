import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  createFilterOptions,
  Dialog,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import ClearIcon from 'Common/shared-ui/src/icons/ClearIcon';
import gridFilterModal from './GridFilterModalNew.styles';
import CheckboxIconChecked from '../../../packages/common/shared-ui/src/icons/CheckboxIconChecked';
import DeleteIcon from '../../../packages/common/shared-ui/src/icons/DeleteIcon';
import CheckboxIcon from '../../../packages/common/shared-ui/src/icons/CheckboxIcon';
import DragDropLists from '../../../packages/common/shared-ui/src/components/DragDropLists';
import { getTranslatedText } from '../../../packages/common/utils/getTranslatedText';
import { useLocation } from 'react-router';
import ChevronIcon from '../../../packages/common/shared-ui/src/icons/ChevronIcon';
import clsx from 'clsx';
import DragDropListsNew from '../../../packages/common/shared-ui/src/components/DragDropListsNew/DragDropListsNew';
import { sortOptions } from '../../../packages/common/utils/fieldHelper';

const GridFiltersModalNew = ({ intl, open, data, handleSave, handleClose }) => {
  const classes = gridFilterModal();
  const [savedParams, setSavedParams] = useState(null);
  const [currentParams, setCurrentParams] = useState(null);
  const removeCheckbox = useRef(['/finances', '/projects', '/agile', '/timesheets']);

  useEffect(() => {
    if (data) {
      if (removeCheckbox.current.includes(location.pathname)) {
        delete data.sorts;
      }
      setSavedParams(data);
      setCurrentParams(data);
    }
  }, [data]);

  const handleSaveButton = useCallback(() => {
    handleSave(currentParams);
  }, [currentParams, handleSave]);

  const handleClearButton = useCallback(() => {
    setCurrentParams(savedParams);
  }, [savedParams]);

  const handleCloseButton = useCallback(() => {
    setCurrentParams(savedParams);
    handleClose();
  }, [handleClose, savedParams]);

  const handleField = useCallback(
    (value, field) => {
      let currentValue = value;
      if (field.key === 'sorts') {
        if (value) {
          const sortItem = field.value.items.find(item => item.fieldId === 'issue.parent_issue');
          currentValue = [...field.value.selectedItems, { ...sortItem, direction: 'asc' }];
        } else {
          currentValue = field.value.selectedItems.filter(item => item.fieldId !== 'issue.parent_issue');
        }
      }
      setCurrentParams(prev => ({ ...currentParams, [field.key]: { ...field.value, selectedItems: currentValue } }));
    },
    [currentParams],
  );

  return (
    <Dialog open={open} className={classes.modalRoot} disableRestoreFocus fullWidth={true}>
      <Grid container justifyContent="space-between" alignItems="center" wrap="nowrap" marginBottom={'20px'}>
        <Typography variant="h1">
          <FormattedMessage id="parameters_text" />
        </Typography>
        <IconButton disableRipple value="close" onClick={handleCloseButton}>
          <Box width={24} height={24}>
            <ClearIcon width={24} height={24} viewBox="0 0 24 24" />
          </Box>
        </IconButton>
      </Grid>
      <Grid container direction="column" overflow={'hidden'} flexWrap={'nowrap'}>
        {currentParams &&
          Object.keys(currentParams)
            .map(key => ({ key, value: currentParams[key] }))
            .filter(item => item.key !== 'fields')
            .map((item, index) => {
              return (
                <Grid
                  container
                  key={index}
                  //paddingLeft={'16px'}
                  marginTop={index > 0 ? '20px' : 0}
                  alignItems={'center'}
                >
                  {item.value.type !== 'columns' && (
                    <Grid item width={'200px'}>
                      <Typography variant="h5">
                        <FormattedMessage id={`params_${item.key}`} />
                      </Typography>
                    </Grid>
                  )}
                  <Grid item flex={1}>
                    {item.value.type === 'list' && item.value.multiple && (
                      <Autocomplete
                        forcePopupIcon={false}
                        sx={{ width: item.key === 'groups' ? '253px' : '352px' }}
                        className={clsx(classes.filterAutocomplete, classes.filterAutocompleteMultiple)}
                        multiple
                        disableClearable
                        //open={true}
                        onChange={(event, newValue) => handleField(newValue, item)}
                        options={sortOptions(item.value.items, item)}
                        disableCloseOnSelect
                        disablePortal
                        value={item.value.selectedItems ? item.value.selectedItems : []}
                        //isOptionEqualToValue={(option, value) => option.fieldId === value.fieldId}
                        getOptionLabel={option => {
                          if (option) {
                            return option.isCustom
                              ? option.fieldName
                              : item.key === 'statuses'
                              ? option.fieldName
                              : option.fieldId === 'project.status'
                              ? intl.formatMessage({
                                  id: `filters_select_${option.fieldId.replace('.', '_')}`,
                                })
                              : intl.formatMessage({
                                  id: `filters_select_${option.field}`,
                                });
                          } else {
                            return '';
                          }
                        }}
                        renderOption={(props, option, { selected }) => (
                          <li {...props} key={option.fieldId}>
                            <Checkbox
                              icon=<CheckboxIcon width="12" height="12" viewBox="0 0 12 12" />
                              checkedIcon=<CheckboxIconChecked width="12" height="12" viewBox="0 0 12 12" />
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.isCustom
                              ? option.fieldName
                              : item.key === 'statuses'
                              ? option.fieldName
                              : option.fieldId === 'project.status'
                              ? intl.formatMessage({
                                  id: `filters_select_${option.fieldId.replace('.', '_')}`,
                                })
                              : intl.formatMessage({
                                  id: `filters_select_${option.field}`,
                                })}
                          </li>
                        )}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              {...getTagProps({ index })}
                              key={index}
                              deleteIcon={<DeleteIcon width={10} height={10} viewBox="0 0 10 10" />}
                              className={classes.chip}
                              variant="outlined"
                              label={
                                option.isCustom
                                  ? option.fieldName
                                  : item.key === 'statuses'
                                  ? option.fieldName
                                  : option.fieldId === 'project.status'
                                  ? intl.formatMessage({
                                      id: `filters_select_${option.fieldId.replace('.', '_')}`,
                                    })
                                  : intl.formatMessage({
                                      id: `filters_select_${option.field}`,
                                    })
                              }
                            />
                          ))
                        }
                        renderInput={params => (
                          <TextField {...params} placeholder={intl.formatMessage({ id: 'enter' })} />
                        )}
                      />
                    )}
                    {item.value.type === 'list' && !item.value.multiple && (
                      <Autocomplete
                        popupIcon={<ChevronIcon direction="down"></ChevronIcon>}
                        sx={{ width: item.key === 'groups' ? '253px' : '352px' }}
                        className={classes.filterAutocomplete}
                        //disableClearable
                        //open={true}
                        onChange={(event, newValue) => handleField(newValue, item)}
                        options={item.value.items}
                        disablePortal
                        value={item.value.selectedItems ? item.value.selectedItems : ''}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        getOptionLabel={option => {
                          if (option) {
                            const optionValue = Array.isArray(option) ? option[0] : option;
                            return optionValue.isCustom
                              ? optionValue.fieldName
                              : intl.formatMessage({
                                  id: `filters_select_${optionValue.field}`,
                                });
                          } else {
                            return '';
                          }
                        }}
                        renderInput={params => (
                          <TextField {...params} placeholder={intl.formatMessage({ id: 'enter' })} />
                        )}
                        renderOption={(props, option) => {
                          return (
                            <MenuItem
                              {...props}
                              selected={option.fieldId === item.value?.selectedItems?.fieldId}
                              key={option.fieldId + option.fieldName}
                              value={option.fieldId}
                            >
                              {option.isCustom
                                ? option.fieldName
                                : intl.formatMessage({
                                    id: `filters_select_${option.field
                                      .replace(/([a-z])([A-Z])/g, '$1_$2')
                                      .toLowerCase()}`,
                                  })}
                            </MenuItem>
                          );
                        }}
                      />
                    )}
                    {item.value.type === 'checkbox' && (
                      <Checkbox
                        disableRipple
                        //checked={
                        //  item.value.selectedItems.find(item => item.fieldId === 'issue.issue.parent') !== undefined
                        //}
                        onChange={(event, newValue) => handleField(event.target.checked, item)}
                        icon={<CheckboxIcon width="12" height="12" viewBox="0 0 12 12" />}
                        checkedIcon={<CheckboxIconChecked width="12" height="12" viewBox="0 0 12 12" />}
                      />
                    )}
                  </Grid>
                </Grid>
              );
            })}
        {currentParams &&
          Object.keys(currentParams)
            .map(key => ({ key, value: data[key] }))
            .filter(item => item.key === 'fields')
            .map((item, index) => {
              return (
                <DragDropListsNew
                  key={index}
                  intl={intl}
                  fields={item.value.selectedItems}
                  handleChange={data => handleField(data, item)}
                  getOptionLabel={option =>
                    `${option.fieldId};${getTranslatedText(intl, 'filter', option.fieldId, option.fieldName)}`
                  }
                />
              );
            })}
        <Grid container justifyContent="center" marginTop={5}>
          <Button disableRipple variant="cutGreyPrimaryBig" onClick={handleClearButton}>
            <Typography variant="h5" fontWeight={700}>
              <FormattedMessage id="spreadsheet_table_clear" defaultMessage="Clear" />
            </Typography>
          </Button>
          <Box marginLeft={1}>
            <Button disableRipple variant="cutBlueSecondary" onClick={handleSaveButton}>
              <Typography variant="h5" fontWeight={700}>
                <FormattedMessage id="save" defaultMessage="Clear" />
              </Typography>
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default injectIntl(GridFiltersModalNew);
