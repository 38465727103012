import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Popover,
  Popper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import ClearIcon from '../../../packages/common/shared-ui/src/icons/ClearIcon';
import ChevronIcon from '../../../packages/common/shared-ui/src/icons/ChevronIcon';
import gridFilterPanel from './GridFiltersPanelNew.styles';
import {
  getFilterCalculationTypes,
  getFilterIssuePriorities,
  getFilterIssueRoles,
  getFilterIssueStatuses,
  getFilterIssueUserGroups,
  getFilterIssueWatcher,
  getFilterListItems,
  getFilterProjectParents,
  getFilterProjectStatuses,
  getFilterSubItems,
  getGantParentIssue,
  getIssueQuery,
  getParentIssueQuery,
  getQueryActivity,
  getQueryConfirmationApprovers,
  getQueryConfirmationAuthors,
  getQueryConfirmationConfirmations,
  getQueryConfirmationEditors,
  getQueryConfirmationIssues,
  getQueryConfirmationProjects,
  getQueryConfirmationStatuses,
  getQueryConfirmationTrackers,
  getQueryIssues,
  getQueryIssuesParams,
  getQueryIssueTrackers,
  getQueryProjects,
  getQueryTrackers,
  getQueryUser,
  getQueryUsers,
} from '../../../packages/common/api';
import { FormattedMessage, injectIntl } from 'react-intl';
import CheckboxIcon from '../../../packages/common/shared-ui/src/icons/CheckboxIcon';
import CheckboxIconChecked from '../../../packages/common/shared-ui/src/icons/CheckboxIconChecked';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CalendarFormIcon from '../../../packages/common/shared-ui/src/icons/CalendarFormIcon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField/SingleInputDateRangeField';
import weekday from 'dayjs/plugin/weekday';
import { filterDateValues, filtersDateRange, renderOptions } from '../../../packages/common/utils/gridFiltersHelperNew';
import clsx from 'clsx';
import { store } from '../app/redux';
import { useParams } from 'react-router-dom';

const GridFiltersPanelNew = ({ intl, data, handleCloseFilter }) => {
  const pageParams = useParams();
  const [filters, setFilters] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openedFilter, setOpenedFilter] = useState(null);
  const classes = gridFilterPanel();
  const filtersTypeBoolean = useRef(['boolean', 'bool', 'activity']);
  const filtersNumbers = useRef(['confirmation']);
  const filtersTypeText = useRef(['text', 'string']);
  const filtersTypeNumber = useRef(['float', 'int']);
  const disableFilterOperations = useRef(['all', 'null']);
  const filtersTypeAutocomplete = useRef([
    'project',
    'activity',
    'calculation_types',
    'user_groups',
    'issue_priority',
    'user_roles',
    'status',
    'list',
    'user',
    'tracker',
    'confirmation_status',
    'confirmation_projects',
    'confirmation_trackers',
    'confirmation_unapprovers',
    'confirmation_approvers',
    'confirmation_authors',
    'confirmation_editors',
    'parent',
    'issue_status',
  ]);
  const filtersTypeParamsAutocomplete = useRef([
    'issue',
    'issue.issue_id',
    'issue_watchers',
    'confirmation',
    'confirmation_issues',
    'issue.parent_issue',
  ]);
  const filtersTypeParamsSingle = useRef(['confirmation']);
  const filtersTypeParamsMultiple = useRef(['confirmation_issues']);
  const FilterQueries = {
    PROJECT: 'project',
    ISSUE: 'issue',
    TRACKER: 'tracker',
    USER: 'user',
    ACTIVITY: 'activity',
    LIST: 'list',
    ISSUE_STATUS: 'issue_status',
    CALCULATION_TYPES: 'calculation_types',
    ISSUE_WATCHERS: 'issue_watchers',
    USER_GROUPS: 'user_groups',
    ISSUE_PRIORITY: 'issue_priority',
    USER_ROLES: 'user_roles',
    STATUS: 'status',
    CONFIRMATION: 'confirmation',
    CONFIRMATION_STATUS: 'confirmation_status',
    CONFIRMATION_TRACKERS: 'confirmation_trackers',
    CONFIRMATION_ISSUES: 'confirmation_issues',
    CONFIRMATION_APPROVERS: 'confirmation_approvers',
    CONFIRMATION_UNAPPROVERS: 'confirmation_approvers',
    CONFIRMATION_PROJECTS: 'confirmation_projects',
    CONFIRMATION_AUTHORS: 'confirmation_authors',
    CONFIRMATION_EDITORS: 'confirmation_editors',
    ISSUE_PARENT_ISSUE: 'issue.parent_issue',
    PARENT: 'parent',
    ISSUE_ISSUE_ID: 'issue.issue_id',
  };
  const filterQuery = useRef(
    new Map([
      [FilterQueries.PROJECT, { getResponse: getQueryProjects }],
      [FilterQueries.ISSUE, { getResponse: getIssueQuery }],
      [FilterQueries.TRACKER, { getResponse: getQueryIssueTrackers }],
      [FilterQueries.USER, { getResponse: getQueryUsers }],
      [FilterQueries.ACTIVITY, { getResponse: getQueryActivity }],
      [FilterQueries.LIST, { getResponse: getFilterListItems }],
      [FilterQueries.CALCULATION_TYPES, { getResponse: getFilterCalculationTypes }],
      [FilterQueries.ISSUE_STATUS, { getResponse: getFilterIssueStatuses }],
      [FilterQueries.ISSUE_WATCHERS, { getResponse: getFilterIssueWatcher }],
      [FilterQueries.USER_GROUPS, { getResponse: getFilterIssueUserGroups }],
      [FilterQueries.ISSUE_PRIORITY, { getResponse: getFilterIssuePriorities }],
      [FilterQueries.USER_ROLES, { getResponse: getFilterIssueRoles }],
      [FilterQueries.STATUS, { getResponse: getFilterProjectStatuses }],
      [FilterQueries.CONFIRMATION_STATUS, { getResponse: getQueryConfirmationStatuses }],
      [FilterQueries.CONFIRMATION_TRACKERS, { getResponse: getQueryConfirmationTrackers }],
      [FilterQueries.CONFIRMATION_ISSUES, { getResponse: getQueryConfirmationIssues }],
      [FilterQueries.CONFIRMATION, { getResponse: getQueryConfirmationConfirmations }],
      [FilterQueries.CONFIRMATION_APPROVERS, { getResponse: getQueryConfirmationApprovers }],
      [FilterQueries.CONFIRMATION_UNAPPROVERS, { getResponse: getQueryConfirmationApprovers }],
      [FilterQueries.CONFIRMATION_PROJECTS, { getResponse: getQueryConfirmationProjects }],
      [FilterQueries.CONFIRMATION_AUTHORS, { getResponse: getQueryConfirmationAuthors }],
      [FilterQueries.CONFIRMATION_EDITORS, { getResponse: getQueryConfirmationEditors }],
      [FilterQueries.ISSUE_PARENT_ISSUE, { getResponse: getQueryIssuesParams }],
      [FilterQueries.PARENT, { getResponse: getFilterProjectParents }],
      [FilterQueries.ISSUE_ISSUE_ID, { getResponse: getQueryIssues }],
    ]),
  );
  const urlParams = useRef(new URLSearchParams(location.search));

  useEffect(() => {
    let filtersAddOptions = data.map(item => {
      let filter = { ...item };
      filterQuery.current.has(item.fieldType) && !filter.options && (filter.options = []);
      !filter.operation &&
        renderOptions(item.fieldType).length > 0 &&
        (filter.operation = renderOptions(item.fieldType)[0]);
      return filter;
    });
    if (filtersAddOptions) {
      setFilters(filtersAddOptions);
    }
  }, [data]);

  const handleClose = filter => {
    let currentFilter = filter;
    if (filtersTypeNumber.current.includes(filter.fieldType) && filter.operation === 'btw') {
      if (Object.values(currentFilter.values).length < 2) {
        delete currentFilter.values;
      }
    }
    handleCloseFilter(currentFilter);
    setOpenedFilter('');
    setAnchorEl(null);
  };

  const handleOpen = useCallback(
    async (event, index, filter) => {
      setOpenedFilter(index);
      setAnchorEl(event.currentTarget);
      let updatedObject = {};
      const pos = filters.map(e => e.fieldId).indexOf(filter.fieldId);
      //if (!filterQuery.current.has(filter.fieldId)) {
      //  updatedObject = {
      //    ...filters[pos],
      //    ...updatedObject,
      //    options: filter.values,
      //  };
      //  delete updatedObject.values;
      //  const newFields = [...filters.slice(0, pos), updatedObject, ...filters.slice(pos + 1)];
      //  setFilters(newFields);
      //}
      if (filterQuery.current.has(filter.fieldType) && filter.options) {
        let params = {};
        if (filtersTypeParamsAutocomplete.current.includes(filter.fieldType)) {
          if (!filter.values || filter.values.length === 0) {
            return;
          } else {
            const value = Array.isArray(filter.values) ? filter.values[0] : filter.values;
            params.search = value;
            !updatedObject.searchValue && (updatedObject.searchValue = value);
          }
        }
        filter.fieldType === 'list' && (params.field = filter.fieldId);
        pageParams.projectId && (params.project = pageParams.projectId);
        try {
          const { data: response } = await filterQuery.current.get(filter.fieldType).getResponse(params);
          if (response) {
            updatedObject = {
              ...filters[pos],
              ...updatedObject,
              options: response,
            };
            const newFields = [...filters.slice(0, pos), updatedObject, ...filters.slice(pos + 1)];
            setFilters(newFields);
          }
        } catch (error) {
          console.error('ERROR WITH GET', error);
        }
      }
    },
    [filters, pageParams.projectId],
  );

  const handleOperationTypeSelect = useCallback(
    (event, filter) => {
      let updatedObject;
      const pos = filters.map(e => e.fieldId).indexOf(filter.fieldId);
      updatedObject = {
        ...filters[pos],
        operation: event.target.value,
      };

      if (filter.fieldType === 'date' && filterDateValues.has(event.target.value)) {
        updatedObject = {
          ...filters[pos],
          operation: event.target.value,
          values: filterDateValues.get(event.target.value).value,
        };
      }
      if (event.target.value === 'null') {
        updatedObject.values = 'null';
      }
      if (event.target.value === 'all') {
        delete updatedObject.values;
      }
      if (event.target.value === 'btw') {
        delete updatedObject.values;
      }
      const newFields = [...filters.slice(0, pos), updatedObject, ...filters.slice(pos + 1)];
      setFilters(newFields);
    },
    [filters],
  );

  const handleFilter = useCallback(
    (data, filter) => {
      let updatedObject;
      const pos = filters.map(e => e.fieldId).indexOf(filter.fieldId);
      updatedObject = {
        ...filters[pos],
        values: data,
      };
      data === '' && delete updatedObject.values;
      if (data && data.constructor === Object && data.valueId) {
        updatedObject = {
          ...filters[pos],
          values: data.valueId,
        };
      }
      if (Array.isArray(data) && filter.fieldType !== 'date') {
        let dataArray = filtersTypeParamsSingle.current.includes(filter.fieldType)
          ? data.filter(item => item.valueId)
          : data;
        updatedObject = {
          ...filters[pos],
          values: dataArray.map(item => (item.valueId ? item.valueId : item)),
        };
        data.length === 0 && delete updatedObject.values;
      }
      if (filter.fieldType === 'date') {
        if (data) {
          updatedObject = {
            ...filters[pos],
            values: Array.isArray(data)
              ? data.map(item => dayjs(item.$d).format('YYYY-MM-DD'))
              : dayjs(data.$d).format('YYYY-MM-DD'),
          };
        } else {
          updatedObject = {
            ...filters[pos],
            values: '',
          };
        }

        data === '' && delete updatedObject.values;
      }
      if (filtersTypeBoolean.current.includes(filter.fieldType)) {
        updatedObject = {
          ...filters[pos],
          values: data.target.value,
        };
        data === '' && delete updatedObject.values;
      }
      const newFilters = [...filters.slice(0, pos), updatedObject, ...filters.slice(pos + 1)];
      setFilters(newFilters);
    },
    [filters, filtersTypeBoolean],
  );

  const changeAutocomleteTextfield = useCallback(
    async (value, filter, number = false) => {
      if (number) {
        const reg = new RegExp(/^[0-9,.]*$/);
        if (!reg.test(value)) {
          return;
        }
      }
      let params = {
        search: value,
      };
      filter.fieldId === FilterQueries.ISSUE_PARENT_ISSUE &&
        urlParams.current.get('project') &&
        (params.project = urlParams.current.get('project'));
      const pos = filters.map(e => e.fieldId).indexOf(filter.fieldId);
      let updatedObject;
      if (value !== '') {
        try {
          setSearchLoading(true);
          const { data: response } = await filterQuery.current.get(filter.fieldType).getResponse(params);
          if (response) {
            updatedObject = {
              ...filters[pos],
              searchValue: value,
              options: response,
            };
            setSearchLoading(false);
          }
        } catch (error) {
          console.error('ERROR WITH EDIT', error);
        }
      } else {
        updatedObject = {
          ...filters[pos],
          searchValue: value,
          options: [],
        };
      }
      const newFilters = [...filters.slice(0, pos), updatedObject, ...filters.slice(pos + 1)];
      setFilters(newFilters);
    },
    [FilterQueries.ISSUE_PARENT_ISSUE, filters],
  );

  const handleNumberField = useCallback(
    (value, field, type) => {
      const reg = new RegExp(/^[0-9,.]*$/);
      if (reg.test(value)) {
        if (field.operation === 'btw') {
          let formattedValue = {
            ...field.values,
            [type]: value,
          };
          handleFilter(formattedValue, field);
        } else {
          handleFilter(value, field);
        }
      }
    },
    [handleFilter],
  );

  return (
    <Grid container marginBottom={'20px'}>
      {filters &&
        filters.map((filter, index) => {
          return (
            <Grid key={index} item marginRight={'10px'} marginTop={'10px'}>
              <Button
                aria-describedby={'filter' + index}
                variant="contained"
                onClick={event => handleOpen(event, index, filter)}
                className={clsx(classes.filterPopoverButton, { active: filter.values })}
              >
                <Typography variant="h4" color={'#7174AC'}>
                  {filter.isCustom
                    ? filter.fieldName
                    : filter.fieldId === 'project.status'
                    ? intl.formatMessage({
                        id: `filters_select_${filter.fieldId.replace('.', '_')}`,
                      })
                    : intl.formatMessage({
                        id: `filters_select_${filter.field}`,
                      })}
                </Typography>
              </Button>
              <Popover
                id={'filter' + index}
                className={classes.filterPopover}
                open={openedFilter === index}
                anchorEl={anchorEl}
                onClose={() => handleClose(filter)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <Grid container flexDirection={'column'}>
                  <Grid item>
                    <Grid
                      container
                      padding={'6px 8px 6px 16px'}
                      borderBottom={'1px solid #E4E4EF'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      flexWrap={'nowrap'}
                    >
                      <Grid item paddingRight={'10px'}>
                        <Typography variant="h5" fontWeight={700}>
                          {filter.isCustom
                            ? filter.fieldName
                            : filter.fieldId === 'project.status'
                            ? intl.formatMessage({
                                id: `filters_select_${filter.fieldId.replace('.', '_')}`,
                              })
                            : intl.formatMessage({
                                id: `filters_select_${filter.field}`,
                              })}
                        </Typography>
                      </Grid>
                      <FormControl sx={{ flexShrink: 0 }}>
                        <InputLabel className={classes.selectLabel} id="select-label">
                          {intl.formatMessage({ id: 'choose' })}
                        </InputLabel>
                        <Select
                          IconComponent={props => <ChevronIcon direction={'down'} {...props} />}
                          className={classes.filterPopoverSelect}
                          value={filter.operation ? filter.operation : ''}
                          onChange={event => handleOperationTypeSelect(event, filter)}
                          MenuProps={{ className: classes.filterPopoverSelectMenu }}
                        >
                          {renderOptions(filter.fieldType).map((item, index) => (
                            <MenuItem value={item} key={index}>
                              <Typography variant="h5" color="#212346">
                                {intl.formatMessage({
                                  id: `operator_${item}`,
                                })}
                              </Typography>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item>
                    {filtersTypeText.current.includes(filter.fieldType) && (
                      <TextField
                        disabled={disableFilterOperations.current.includes(filter.operation)}
                        variant="outlined"
                        className={classes.filterPopoverTextfield}
                        placeholder={intl.formatMessage({ id: 'enter' })}
                        onChange={event => handleFilter(event.target.value, filter)}
                        value={filter.values && filter.values !== 'null' ? filter.values : ''}
                      ></TextField>
                    )}
                    {filtersTypeNumber.current.includes(filter.fieldType) &&
                      (filter.operation === 'btw' ? (
                        <Grid container alignItems={'center'}>
                          <TextField
                            sx={{ flex: 1 }}
                            disabled={disableFilterOperations.current.includes(filter.operation)}
                            variant="outlined"
                            className={classes.filterPopoverTextfield}
                            placeholder={intl.formatMessage({ id: 'enter' })}
                            onChange={event => handleNumberField(event.target.value, filter, 'from')}
                            value={
                              filter.values && filter.values.from && filter.values !== 'null' ? filter.values.from : ''
                            }
                          ></TextField>
                          <Box component={'span'}>-</Box>
                          <TextField
                            sx={{ flex: 1 }}
                            disabled={disableFilterOperations.current.includes(filter.operation)}
                            variant="outlined"
                            className={classes.filterPopoverTextfield}
                            placeholder={intl.formatMessage({ id: 'enter' })}
                            onChange={event => handleNumberField(event.target.value, filter, 'to')}
                            value={
                              filter.values && filter.values.to && filter.values !== 'null' ? filter.values.to : ''
                            }
                          ></TextField>
                        </Grid>
                      ) : (
                        <TextField
                          disabled={disableFilterOperations.current.includes(filter.operation)}
                          variant="outlined"
                          className={classes.filterPopoverTextfield}
                          placeholder={intl.formatMessage({ id: 'enter' })}
                          onChange={event => handleNumberField(event.target.value, filter)}
                          value={filter.values && filter.values !== 'null' ? filter.values : ''}
                        ></TextField>
                      ))}
                    {filtersTypeAutocomplete.current.includes(filter.fieldType) && (
                      <Autocomplete
                        disabled={disableFilterOperations.current.includes(filter.operation)}
                        onChange={(event, value) => handleFilter(value, filter)}
                        open={true}
                        multiple
                        loading={filter.options?.length === 0}
                        className={classes.filterPopoverAutocomplete}
                        disablePortal
                        options={filter.options}
                        value={filter.values && filter.values !== 'null' ? filter.values : []}
                        isOptionEqualToValue={(option, value) => option.valueId === value}
                        clearIcon={<ClearIcon></ClearIcon>}
                        disableClearable
                        renderOption={(props, option, { selected }) => {
                          return (
                            <li
                              {...props}
                              key={option.valueId}
                              aria-disabled={disableFilterOperations.current.includes(filter.operation)}
                            >
                              <Checkbox
                                icon=<CheckboxIcon width="12" height="12" viewBox="0 0 12 12" />
                                checkedIcon=<CheckboxIconChecked width="12" height="12" viewBox="0 0 12 12" />
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.valueName}
                            </li>
                          );
                        }}
                        getOptionLabel={option => option.valueName || ''}
                        PopperComponent={props => <Popper {...props} placement="bottom-start"></Popper>}
                        renderInput={params => (
                          <TextField
                            {...params}
                            placeholder={intl.formatMessage({ id: 'enter' })}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment:
                                filter.options.length === 0 ? <CircularProgress color="inherit" size={20} /> : null,
                            }}
                          />
                        )}
                      />
                    )}
                    {filtersTypeParamsAutocomplete.current.includes(filter.fieldType) && (
                      <Autocomplete
                        disabled={disableFilterOperations.current.includes(filter.operation)}
                        onChange={(event, value) => handleFilter(value, filter)}
                        open={true}
                        multiple
                        loading={searchLoading}
                        className={classes.filterPopoverAutocomplete}
                        disablePortal
                        disableClearable
                        options={filter.options}
                        filterOptions={x => x}
                        value={filter.values && filter.values !== 'null' ? filter.values : []}
                        isOptionEqualToValue={(option, value) => option.valueId === value}
                        clearIcon={<ClearIcon></ClearIcon>}
                        renderOption={(props, option) => {
                          let optionChecked = false;
                          if (filter.values) {
                            option.valueId === filter.values && (optionChecked = true);
                            Array.isArray(filter.values) &&
                              filter.values.includes(option.valueId) &&
                              (optionChecked = true);
                          }
                          return (
                            <li
                              {...props}
                              key={option.valueId}
                              aria-disabled={disableFilterOperations.current.includes(filter.operation)}
                            >
                              <Checkbox
                                icon=<CheckboxIcon width="12" height="12" viewBox="0 0 12 12" />
                                checkedIcon=<CheckboxIconChecked width="12" height="12" viewBox="0 0 12 12" />
                                style={{ marginRight: 8 }}
                                checked={optionChecked}
                              />
                              {option.valueName}
                            </li>
                          );
                        }}
                        getOptionLabel={option => option.valueName || ''}
                        PopperComponent={props => <Popper {...props} placement="bottom-start"></Popper>}
                        onInputChange={(event, newInputValue, reason) =>
                          reason === 'input' &&
                          changeAutocomleteTextfield(
                            newInputValue,
                            filter,
                            filtersNumbers.current.includes(filter.fieldType),
                          )
                        }
                        inputValue={filter.searchValue ? filter.searchValue : ''}
                        renderInput={params => (
                          <TextField
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {searchLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                            placeholder={intl.formatMessage({ id: 'enter' })}
                          />
                        )}
                      />
                    )}

                    {filter.fieldType === 'date' && (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        {filtersDateRange.includes(filter.operation) ? (
                          <DateRangePicker
                            disabled={disableFilterOperations.current.includes(filter.operation)}
                            label={''}
                            calendars={1}
                            value={filter.values ? filter.values : []}
                            onChange={value => handleFilter(value, filter)}
                            renderInput={(startProps, endProps) => (
                              <>
                                <TextField {...startProps} />
                                <TextField {...endProps} />
                              </>
                            )}
                            format={'DD.MM.YYYY'}
                            components={{
                              OpenPickerIcon: ({ ...props }) => <CalendarFormIcon {...props} />,
                              LeftArrowIcon: () => (
                                <ChevronIcon direction="left" viewBox="0 0 24 24" width={24} height={24} />
                              ),
                              RightArrowIcon: () => (
                                <ChevronIcon direction="right" viewBox="0 0 24 24" width={24} height={24} />
                              ),
                              RightArrowButton: componentProps => (
                                <IconButton disableRipple {...componentProps}>
                                  {componentProps.children}
                                </IconButton>
                              ),
                              LeftArrowButton: componentProps => (
                                <IconButton disableRipple {...componentProps}>
                                  {componentProps.children}
                                </IconButton>
                              ),
                            }}
                            className={classes.rangeDatePicker}
                          />
                        ) : (
                          <DatePicker
                            disabled={disableFilterOperations.current.includes(filter.operation)}
                            format={'DD.MM.YYYY'}
                            components={{
                              OpenPickerIcon: ({ ...props }) => <CalendarFormIcon {...props} />,
                              LeftArrowIcon: () => (
                                <ChevronIcon direction="left" viewBox="0 0 24 24" width={24} height={24} />
                              ),
                              RightArrowIcon: () => (
                                <ChevronIcon direction="right" viewBox="0 0 24 24" width={24} height={24} />
                              ),
                              RightArrowButton: componentProps => (
                                <IconButton disableRipple {...componentProps}>
                                  {componentProps.children}
                                </IconButton>
                              ),
                              LeftArrowButton: componentProps => (
                                <IconButton disableRipple {...componentProps}>
                                  {componentProps.children}
                                </IconButton>
                              ),
                            }}
                            renderInput={params => (
                              <TextField {...params} placeholder={intl.formatMessage({ id: 'enter' })} />
                            )}
                            className={classes.datePicker}
                            value={filter.values ? filter.values : ''}
                            onChange={value => handleFilter(value, filter)}
                          />
                        )}
                      </LocalizationProvider>
                    )}
                    {filtersTypeBoolean.current.includes(filter.fieldType) && (
                      <RadioGroup
                        disabled={disableFilterOperations.current.includes(filter.operation)}
                        onChange={value => handleFilter(value, filter)}
                        value={filter.values ? filter.values : ''}
                      >
                        <FormControlLabel
                          className={classes.booleanLabel}
                          value={true}
                          checked={filter.values !== '' && filter.values === 'true'}
                          control={
                            <Radio
                              disableRipple
                              icon={<CheckboxIcon width="12" height="12" viewBox="0 0 12 12" />}
                              checkedIcon={<CheckboxIconChecked width="12" height="12" viewBox="0 0 12 12" />}
                            />
                          }
                          label={intl.formatMessage({ id: 'yes' })}
                        />
                        <FormControlLabel
                          className={classes.booleanLabel}
                          value={false}
                          checked={filter.values !== '' && filter.values === 'false'}
                          control={
                            <Radio
                              disableRipple
                              icon={<CheckboxIcon width="12" height="12" viewBox="0 0 12 12" />}
                              checkedIcon={<CheckboxIconChecked width="12" height="12" viewBox="0 0 12 12" />}
                            />
                          }
                          label={intl.formatMessage({ id: 'no' })}
                        />
                      </RadioGroup>
                    )}
                  </Grid>
                </Grid>
              </Popover>
            </Grid>
          );
        })}
    </Grid>
  );
};

export default injectIntl(GridFiltersPanelNew);
