import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Box, CircularProgress, Grid, IconButton, Popover, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { Link, useParams } from 'react-router-dom';

import useStyles from './MenuItem.styles';
import clsx from 'clsx';
import ChevronIcon from '../../../../packages/common/shared-ui/src/icons/ChevronIcon';
import {
  getIssuesSavedQueries,
  getIssuesSavedQueriesByProject,
  getSupersetToken,
  getTimesheetSavedQueries,
  getAgileSavedQueriesByProject,
  getConfirmationsSavedQueries,
  getProjectsSavedQueries,
} from '../../../../packages/common/api';
import { getTranslatedText } from '../../../../packages/common/utils/getTranslatedText';
import { useQueryParams } from 'use-query-params';
import { store } from '../../app/redux';
import { generateRedirectUrl } from '../../../../packages/common/utils/getRedirectUrl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { toggleDrawer } from '../../app/redux/reducers/userSlice';
import dayjs from 'dayjs';
import DeleteFileIcon from '../../../../packages/common/shared-ui/src/icons/DeleteFileIcon';

const MenuItem = ({ item, context }) => {
  //const [query] = useQueryParams({
  //  savedQueryId: '',
  //});
  //const [dropdownListStatus, setDropdownListStatus] = useState(item?.isDropdown || false);
  //const [isChecked, setIsChecked] = useState(item?.isDropdown || false);
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.maxWidth('sm'));

  const classes = useStyles();
  const navigate = useNavigate();
  const [currentItem, setCurrentItem] = useState(null);
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const [itemsLoading, setItemsLoading] = useState(false);
  const [disableSubitems, setDisableSubitems] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const redmineToken = useSelector(state => state.user.redmine_access_token);
  const state = store.getState();
  const dispatch = useDispatch();
  const pageParams = useParams();

  ///const open = Boolean(anchorEl);
  //useEffect(() => {
  //  if (window.location.pathname === path) {
  //    setIsChecked(true);
  //  } else {
  //    setIsChecked(false);
  //  }
  //  setDropdownListStatus(false);
  //  setAnchorEl(null);
  //}, [isDrawerOpen, path]);

  /*  const handleChangeStatus = useCallback(
    async e => {
      e.stopPropagation();
      if (!isSmallScreen) {
        setAnchorEl(e.currentTarget);
        if (item.lang_key === 'menu_item_work_costs') {
          setIsLoading(true);
          try {
            const { data: queries } = await getTimesheetSavedQueries();
            const projectQuery =
              typeof item.projectId != 'undefined' ? `?projectId=${item.projectId}&locked=timesheet.project` : '';
            item.dropdown = queries.map(currentQuery => {
              return {
                name: currentQuery.name,
                path: `/spentTime/query/${currentQuery.id}${projectQuery}`,
                isChecked: Number(query.savedQueryId) === Number(currentQuery.id),
                isItemVisible: true,
              };
            });
          } catch (error) {
            console.error('ERROR WITH FETCH SAVED QUERIES', error);
          } finally {
            setIsLoading(false);
          }
        } else if (item.lang_key === 'menu_item_tasks') {
          setIsLoading(true);
          try {
            let queries = '';
            if (item.projectId) {
              queries = await getIssuesSavedQueriesByProject(item.projectId);
            } else {
              queries = await getIssuesSavedQueries(item.projectId);
            }

            const projectQuery =
              typeof item.projectId != 'undefined' ? `?projectId=${item.projectId}&locked=issue.project` : '';
            item.dropdown = queries.data.map(currentQuery => {
              return {
                name: currentQuery.name,
                path: `/issues/query/${currentQuery.id}${projectQuery}`,
                isChecked: Number(query.savedQueryId) === Number(currentQuery.id),
                isItemVisible: true,
              };
            });
          } catch (error) {
            console.error('ERROR WITH FETCH SAVED QUERIES', error);
          } finally {
            setIsLoading(false);
          }
        } else if (item.lang_key === 'menu_item_kanban') {
          setIsLoading(true);
          try {
            const queries = await getAgileSavedQueriesByProject(item.projectId);
            const projectQuery = `?projectId=${item.projectId}&locked=issue.project`;
            item.dropdown = queries.data.map(currentQuery => {
              return {
                name: currentQuery.name,
                path: `/agile/query/${currentQuery.id}`,
                isChecked: Number(query.savedQueryId) === Number(currentQuery.id),
                isItemVisible: true,
              };
            });
          } catch (error) {
            console.error('ERROR WITH FETCH SAVED QUERIES', error);
          } finally {
            setIsLoading(false);
          }
        } else if (item.lang_key === 'menu_item_confirmations') {
          setIsLoading(true);
          try {
            const queries = await getConfirmationsSavedQueries();
            item.dropdown = queries.data.map(currentQuery => {
              return {
                name: currentQuery.name,
                path: `/confirmations/query/${currentQuery.id}`,
                isChecked: Number(query.savedQueryId) === Number(currentQuery.id),
                isItemVisible: true,
              };
            });
          } catch (error) {
            console.error('ERROR WITH FETCH SAVED QUERIES', error);
          } finally {
            setIsLoading(false);
          }
        } else if (item.lang_key === 'menu_item_project_reports') {
          const supersetToken = await getSupersetToken();
          item.dropdown = state.user.project?.reports.map(query => {
            const reportUrl = new URL(query.url);
            reportUrl.searchParams.append('token', supersetToken.data);
            return { isChecked: false, isItemVisible: true, name: query.name, path: reportUrl, externalLink: true };
          });
        }
      }
      setDropdownListStatus(prevState => !prevState);
    },
    [isSmallScreen, item, query.savedQueryId, state],
  );*/

  const closeSubItemsMenu = useCallback(() => {
    setAnchorEl(null);
    setOpenSubMenu(false);
    //setDropdownListStatus(false);
  }, []);

  const MenuItemsEnum = {
    PROJECTS: 'projectsNavItem',
    ISSUES: 'issuesNavItem',
    PROJECT_ISSUES: 'projectIssuesNavItem',
    CONFIRMATIONS: 'confirmationsNavItem',
    PROJECT_CONFIRMATIONS: 'projectConfirmationsNavItem',
    TIMESHEETS: 'timesheetsNavItem',
    FINANCE: 'financeNavItem',
    TIMESHEET: 'timesheetTableNavItem',
    RESOURCE_PLAN: 'resourcePlanNavItem',
    ISSUES_BOARD: 'issuesBoardNavItem',
    ADMIN: 'adminMainNavItem',
    ADMIN_USERS: 'adminUsersNavItem',
    ADMIN_REPORTS: 'adminReportsNavItem',
    ADMIN_CALENDAR: 'adminCalendarNavItem',
    ADMIN_RESOURCE_PLAN: 'adminResourcePlanNavItem',
    ADMIN_CONFIRMATIONS: 'adminConfirmationsNavItem',
    ADMIN_CALC_TYPES: 'adminCalcTypesNaveItem',
    ADMIN_CONTRACT_STAGES: 'adminContractStagesNavItem',
    ADMIN_GANTT: 'adminGanttNavItem',
    PROJECT_GANTT: 'projectGanttNavItem',
    ADMIN_FINANCE: 'adminFinanceNavItem',
    ADMIN_DATA_IMPORT: 'adminDataImportNavItem',
    PROJECT_ISSUES_KANBAN: 'projectIssuesKanbanNavItem',
    PROJECT_TIMESHEETS: 'projectTimesheetsNavItem',
    PROJECT_REPORTS: 'projectReportsNavItem',
    PROJECT_SPRINT_MANAGEMENT: 'projectSprintManagementNavItem',
  };

  const menuItemsData = useRef(
    new Map([
      [MenuItemsEnum.PROJECTS, { link: '/projects', getResponse: getProjectsSavedQueries }],
      [MenuItemsEnum.ISSUES, { link: '/issues', getResponse: getIssuesSavedQueries }],
      [MenuItemsEnum.PROJECT_ISSUES, { link: '/issues', getResponse: getIssuesSavedQueries }],
      [MenuItemsEnum.CONFIRMATIONS, { link: '/confirmations', getResponse: getConfirmationsSavedQueries }],
      [MenuItemsEnum.PROJECT_CONFIRMATIONS, { link: '/confirmations', getResponse: getConfirmationsSavedQueries }],
      [MenuItemsEnum.TIMESHEETS, { link: '/timesheets', getResponse: getTimesheetSavedQueries }],
      [MenuItemsEnum.FINANCE, { link: '/finances', externalLink: '/art_table' }],
      [MenuItemsEnum.TIMESHEET, { link: '/spreadsheet' }],
      [MenuItemsEnum.PROJECT_TIMESHEETS, { link: '/timesheets', getResponse: getTimesheetSavedQueries }],
      [MenuItemsEnum.RESOURCE_PLAN, { link: '/resourcePlan' }],
      [
        MenuItemsEnum.ISSUES_BOARD,
        { link: '', externalLink: '/agile/board', getResponse: getAgileSavedQueriesByProject },
      ],
      [MenuItemsEnum.ADMIN, { link: '', externalLink: '/admin' }],
      [MenuItemsEnum.PROJECT_GANTT, { link: '/gantt' }],
      [MenuItemsEnum.PROJECT_ISSUES_KANBAN, { link: '/agile', getResponse: getAgileSavedQueriesByProject }],
      [
        MenuItemsEnum.PROJECT_SPRINT_MANAGEMENT,
        { link: '', externalLink: `/projects/${state.user.project?.identifier}/settings?tab=agile_sprints` },
      ],
      [MenuItemsEnum.ADMIN_USERS, { link: '/administration/users' }],
      [MenuItemsEnum.ADMIN_REPORTS, { link: '/administration/project-reports' }],
      [MenuItemsEnum.ADMIN_CALENDAR, { link: `/administration/production-calendar?year=${dayjs().format('YYYY')}` }],
      [MenuItemsEnum.ADMIN_RESOURCE_PLAN, { link: '/administration/resourcePlan-settings' }],
      [MenuItemsEnum.ADMIN_CONFIRMATIONS, { link: '/administration/confirmation-settings' }],
      [MenuItemsEnum.ADMIN_CALC_TYPES, { link: '/administration/calculation-types' }],
      [MenuItemsEnum.ADMIN_CONTRACT_STAGES, { link: '/administration/contract-stages' }],
      [MenuItemsEnum.ADMIN_GANTT, { link: '/administration/gantt-settings' }],
      [MenuItemsEnum.ADMIN_FINANCE, { link: '/administration/art-settings' }],
      [MenuItemsEnum.ADMIN_DATA_IMPORT, { link: '/administration/import' }],
    ]),
  );

  useEffect(() => {
    if (item) {
      setCurrentItem(item);
    }
  }, [item]);

  const handleOpenSubMenu = useCallback(
    async e => {
      setAnchorEl(e.currentTarget);
      let updatedObject = {};
      if (currentItem.itemId === MenuItemsEnum.PROJECT_REPORTS) {
        setItemsLoading(true);
        const supersetToken = await getSupersetToken();
        if (state.user.project?.reports.length > 0) {
          updatedObject = {
            ...currentItem,
            subitems: state.user.project?.reports.map(item => {
              const reportUrl = new URL(item.url);
              reportUrl.searchParams.append('token', supersetToken.data);
              return {
                itemId: item.id,
                itemName: item.name,
                query: true,
                path: reportUrl,
              };
            }),
          };
          setCurrentItem(updatedObject);
          setItemsLoading(false);
          setOpenSubMenu(true);
        }
      } else {
        if (!!menuItemsData.current.get(currentItem.itemId).getResponse) {
          setItemsLoading(true);
          try {
            const projectQuery = [
              MenuItemsEnum.PROJECT_ISSUES,
              MenuItemsEnum.PROJECT_TIMESHEETS,
              MenuItemsEnum.PROJECT_ISSUES_KANBAN,
            ];
            let params = {};
            projectQuery.includes(currentItem.itemId) && (params.project = pageParams.projectId);
            const { data: response } = await menuItemsData.current.get(currentItem.itemId).getResponse(params);
            if (response) {
              if (response.length > 0) {
                updatedObject = {
                  ...currentItem,
                  subitems: response.map(item => {
                    return {
                      itemId: item.id,
                      itemName: item.name,
                      query: true,
                    };
                  }),
                };
                setCurrentItem(updatedObject);
                setItemsLoading(false);
                setOpenSubMenu(true);
                setDisableSubitems(false);
              } else {
                setDisableSubitems(true);
                setItemsLoading(false);
              }
            }
          } catch (error) {
            console.error('ERROR WITH GET', error);
          }
        } else {
          setAnchorEl(e.currentTarget);
          setOpenSubMenu(true);
        }
      }
    },
    [
      MenuItemsEnum.PROJECT_ISSUES,
      MenuItemsEnum.PROJECT_ISSUES_KANBAN,
      MenuItemsEnum.PROJECT_REPORTS,
      MenuItemsEnum.PROJECT_TIMESHEETS,
      currentItem,
      pageParams.projectId,
      state.user.project?.reports,
    ],
  );

  const redirectToAnotherUrl = useCallback(
    async menuItem => {
      const isDev = process.env.NODE_ENV === 'development';
      const hasExternalLink = !!menuItemsData.current.get(currentItem.itemId).externalLink;
      if (menuItem.query) {
        if (menuItem.path) {
          window.open(menuItem.path, '_blank');
        } else {
          const link = pageParams.projectId
            ? `/projects/${pageParams.projectId}${menuItemsData.current.get(currentItem.itemId).link}/query/${
                menuItem.itemId
              }`
            : `${menuItemsData.current.get(currentItem.itemId).link}/query/${menuItem.itemId}`;
          window.open(link, '_self');
        }
        dispatch(toggleDrawer(false));
      } else {
        if (!isDev && hasExternalLink) {
          const link = generateRedirectUrl({
            host: window.location.origin,
            path: menuItemsData.current.get(currentItem.itemId).externalLink,
            redmineToken,
          });
          dispatch(toggleDrawer(false));
          window.open(link, '_blank');
        } else {
          let link = menuItemsData.current.get(menuItem.itemId).link;
          if (menuItem.context) {
            link = `/projects/${state.user.project.redmineId}${link}`;
          }
          dispatch(toggleDrawer(false));
          window.open(link, '_self');
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentItem, navigate, redmineToken, pageParams.projectId],
  );

  return (
    <>
      {currentItem && (
        <Box className={classes.menuItem} paddingLeft={context ? '80px' : '60px'}>
          <Box component={'span'} onClick={() => redirectToAnotherUrl(currentItem)} sx={{ cursor: 'pointer' }}>
            <Typography variant="h3" fontWeight={600} color={'#212346'}>
              {currentItem.itemName}
            </Typography>
          </Box>

          {currentItem.subitems && (
            <Box display={'flex'} alignItems={'center'}>
              {itemsLoading && (
                <Grid container alignItems="center" justifyContent="center" width="20px" height="20px">
                  <CircularProgress size={20} color="secondary" />
                </Grid>
              )}
              <IconButton
                disabled={disableSubitems}
                sx={{ padding: '0', margin: '0 4px 0 0 !important' }}
                onClick={event => handleOpenSubMenu(event)}
              >
                <ChevronIcon className={classes.menuItemIcon} direction={'right'} viewBox="0 0 24 24" />
              </IconButton>
            </Box>
          )}
          {currentItem.subitems && (
            <Popover
              open={openSubMenu}
              anchorEl={anchorEl}
              onClose={closeSubItemsMenu}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              className={classes.menuSubItems}
              PaperProps={{
                className: classes.menuSubItemsPaper,
              }}
            >
              {currentItem.subitems.map((item, index) => (
                <Box className={classes.menuItem} key={index} paddingLeft={'36px'}>
                  <Box component={'span'} onClick={() => redirectToAnotherUrl(item)} sx={{ cursor: 'pointer' }}>
                    <Typography variant="h4" color={'#212346'}>
                      {item.itemName}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Popover>
            /*<Box className={classes.menuItem} position={'absolute'}>
              {item.subitems.map((item, index) => (
                <Box className={classes.menuItem} key={index}>
                  <Box
                    component={'span'}
                    onClick={() => redirectToAnotherUrl(item.itemId)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <Typography variant="h3" fontWeight={600} color={'#212346'}>
                      {item.itemName}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>*/
          )}
        </Box>
      )}
    </>
    /*    <>
      {item.path ? (
        <Box width="100%" overflow="hidden">
          <Grid
            item
            container
            wrap="nowrap"
            alignItems="center"
            xs={12}
            className={clsx(classes.MenuItemRoot, {
              [classes.dropdownItem]: dropdownListStatus || isChecked,
              [classes.projectItem]: item.projectItem,
            })}
          >
            {itemIcon && <Box className={classes.menuItemIcon}>{itemIcon}</Box>}
            {process.env.NODE_ENV === 'development' ? (
              <Link to={path} onClick={closeDrawer}>
                <Typography variant="h3" fontWeight={600}>
                  {getTranslatedText(intl, '', itemName, itemName)}
                </Typography>
              </Link>
            ) : (
              <Box className={classes.anotherUrlLink} onClick={_ => redirectToAnotherUrl(path)}>
                <Typography variant="h3" fontWeight={600}>
                  {getTranslatedText(intl, '', itemName, itemName)}
                </Typography>
              </Box>
            )}
            {item.dropdown && item.isDropdownVisible && (
              <Box
                onClick={item.hasOwnProperty('isDropdown') ? handleChangeStatus : null}
                className={clsx(classes.menuItemIcon, classes.dropdownIcon, { [classes.rotate]: dropdownListStatus })}
              >
                <ChevronIcon direction={isSmallScreen ? 'down' : 'right'} viewBox="0 0 24 24" />
              </Box>
            )}
          </Grid>
        </Box>
      ) : (
        <Box onClick={handleChangeStatus} className={classes.dropdownMenu}>
          <Grid
            item
            container
            wrap="nowrap"
            alignItems="center"
            xs={12}
            className={clsx(classes.MenuItemRoot, { [classes.dropdownItem]: dropdownListStatus })}
          >
            {item.isDropdownVisible && (
              <Box className={clsx(classes.menuItemIcon, { [classes.rotate]: dropdownListStatus })}>{itemIcon}</Box>
            )}
            <Typography variant="h3" fontWeight={600}>
              {getTranslatedText(intl, '', itemName, itemName)}
            </Typography>
            {item.dropdown && item.isDropdownVisible && (
              <Box
                onClick={item.hasOwnProperty('isDropdown') ? handleChangeStatus : null}
                className={clsx(classes.menuItemIcon, classes.dropdownIcon, { [classes.rotate]: dropdownListStatus })}
              >
                <ChevronIcon direction={isSmallScreen ? 'down' : 'right'} viewBox="0 0 24 24" />
              </Box>
            )}
          </Grid>
        </Box>
      )}
      {isSmallScreen ? (
        dropdownListStatus &&
        item.isDropdownVisible &&
        item?.dropdown &&
        item.dropdown.map(item => {
          return (
            <Tooltip
              title={getTranslatedText(intl, '', item.name, item.name)}
              PopperProps={{ className: classes.commentTooltip }}
            >
              <Box minHeight={32} key={item.path} className={classes.dropdownItem}>
                <Link onClick={closeDrawer} to={item.path} className={clsx({ [classes.itemLink]: currentItem })}>
                  <Grid
                    item
                    container
                    wrap="nowrap"
                    alignItems="center"
                    xs={12}
                    className={clsx(classes.MenuItemRoot, classes.menuSubItem)}
                  >
                    <Typography variant="h4">{getTranslatedText(intl, '', item.name, item.name)}</Typography>
                  </Grid>
                </Link>
              </Box>
            </Tooltip>
          );
        })
      ) : (
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={closeSubItemsMenu}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          PaperProps={{
            className: classes.menuSubItemsPaper,
          }}
          className={classes.menuSubItems}
        >
          {dropdownListStatus &&
            item.isDropdownVisible &&
            item?.dropdown &&
            !isLoading &&
            item.dropdown.map(item => {
              return (
                item.isItemVisible && (
                  <Box
                    height={32}
                    bgcolor={item.isChecked && '#DCDFF4'}
                    key={item.path}
                    onMouseOver={() => setOpenTooltip({ [item.path]: true })}
                    onMouseLeave={() => setOpenTooltip('')}
                  >
                    <Tooltip
                      title={getTranslatedText(intl, '', item.name, item.name)}
                      open={openTooltip[item.path] ? openTooltip[item.path] : false}
                      PopperProps={{ className: classes.commentTooltip }}
                      style={{ zIndex: 10000 }}
                    >
                      {process.env.NODE_ENV === 'development' ? (
                        <Box>
                          {item.externalLink ? (
                            <Box className={classes.anotherUrlLink}>
                              <Grid
                                item
                                container
                                wrap="nowrap"
                                alignItems="center"
                                xs={12}
                                className={clsx(classes.MenuItemRoot, classes.menuSubItem)}
                              >
                                <Typography variant="h4">
                                  <a href={item.path} target={'_blank'}>
                                    {getTranslatedText(intl, '', item.name, item.name)}
                                  </a>
                                </Typography>
                              </Grid>
                            </Box>
                          ) : (
                            <Link onClick={closeDrawer} to={item.path} className={{ [classes.itemLink]: currentItem }}>
                              <Grid
                                item
                                container
                                wrap="nowrap"
                                alignItems="center"
                                xs={12}
                                className={clsx(classes.MenuItemRoot, classes.menuSubItem)}
                              >
                                <Typography variant="h4">
                                  {getTranslatedText(intl, '', item.name, item.name)}
                                </Typography>
                              </Grid>
                            </Link>
                          )}
                        </Box>
                      ) : (
                        <Box className={classes.anotherUrlLink} onClick={_ => redirectToAnotherUrl(item.path)}>
                          <Grid
                            item
                            container
                            wrap="nowrap"
                            alignItems="center"
                            xs={12}
                            className={clsx(classes.MenuItemRoot, classes.menuSubItem)}
                          >
                            <Typography variant="h4">{getTranslatedText(intl, '', item.name, item.name)}</Typography>
                          </Grid>
                        </Box>
                      )}
                    </Tooltip>
                  </Box>
                )
              );
            })}
        </Popover>
      )}
    </>*/
  );
};

export default MenuItem;
