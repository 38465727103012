import React, { memo, useCallback, useEffect, useState } from 'react';
import { Box, Grid, PaginationItem, Typography } from '@mui/material';
import CheckboxIcon from '../../../../packages/common/shared-ui/src/icons/CheckboxIcon';
import CheckboxIconChecked from '../../../../packages/common/shared-ui/src/icons/CheckboxIconChecked';
import SortIcon from '../../../../packages/common/shared-ui/src/icons/SortIcon';
import dataGridTableStyles from '../../../../packages/common/shared-ui/src/styles/DataGridTable.styles';
import {
  DataGridPro,
  gridPageCountSelector,
  GridPagination,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro';
import { generateRedirectUrl } from '../../../../packages/common/utils/getRedirectUrl';
import { Link, useParams } from 'react-router-dom';
import MuiPagination from '@mui/material/Pagination';
import PaginationNextButtonIcon from '../../../../packages/common/shared-ui/src/icons/PaginationNextButtonIcon';
import PaginationPreviousButtonIcon from '../../../../packages/common/shared-ui/src/icons/PaginationPreviousButtonIcon';

const ConfirmationsTable = ({ intl, paginationData, columnsData, rowsData, changePagination, changeSorting }) => {
  const pageUseParams = useParams();
  const dataGridTableStyle = dataGridTableStyles();
  const [gridRows, setGridRows] = useState([]);
  const [gridColumns, setGridColumns] = useState([]);
  const [pagination, setPagination] = useState({ count: 0, page: 0 });
  const [sortModel, setSortModel] = useState([]);
  const handleGetRedirectUrl = useCallback(
    ({ id, columnType }) => {
      switch (columnType) {
        case 'unapprover':
        case 'createdBy':
        case 'updatedBy':
        case 'approver':
          return generateRedirectUrl({ host: window.location.origin, path: `/users/${id}` });
        case 'document':
          return `${window.location.origin}/issues/${id}`;
        case 'project':
          return `${window.location.origin}/projects/${id}`;
        case 'confirmation':
          return pageUseParams.projectId
            ? `/projects/${pageUseParams.projectId}/confirmations/${id}`
            : `${window.location.origin}/confirmations/${id}`;
        default:
          return '';
      }
    },
    [pageUseParams.projectId],
  );

  const cellRenderer = useCallback(
    ({ props, column }) => {
      const linkFields = ['project', 'confirmation', 'createdBy', 'updatedBy'];
      const multiLinkFields = ['unapprover', 'document', 'approver'];
      if (!props.value && props.row.groupName) {
        if (props.colDef.position === 0) {
          return (
            <Typography variant="h4" fontWeight={700}>
              {props.row.groupName}
            </Typography>
          );
        }
      } else {
        if (linkFields.includes(column.field)) {
          return (
            <Link
              to={handleGetRedirectUrl({ id: props.row[column.fieldId + 'Id'], columnType: column.field })}
              target={'_blank'}
            >
              {props.value}
            </Link>
          );
        }
        if (multiLinkFields.includes(column.field)) {
          return (
            <Grid container flexDirection={'column'}>
              {props.value?.map((item, index) => {
                return (
                  <Link
                    key={index}
                    to={handleGetRedirectUrl({
                      id: props.row[column.fieldId + 'Id'].find((item, itemIndex) => itemIndex === index),
                      columnType: column.field,
                    })}
                    target={'_blank'}
                  >
                    {item}
                  </Link>
                );
              })}
            </Grid>
          );
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleGetRedirectUrl],
  );

  useEffect(() => {
    if (paginationData) {
      setPagination(() => ({ page: paginationData.offset / 100, count: Number(paginationData.total) }));
    }
  }, [paginationData]);

  useEffect(() => {
    if (rowsData) {
      const rows = rowsData.filter(item => item.rowType !== 'total');
      const data = rows.reduce((acc, row, index) => {
        const newCols = row.columns.reduce((columnAcc, item, i) => {
          return {
            ...columnAcc,
            [row.columns[i].fieldId]: row.columns[i].value,
            [row.columns[i].fieldId + 'Id']: row.columns[i].valueId,
          };
        }, {});
        delete row.columns;
        return [...acc, { ...newCols, ...row, id: row.rowId }];
      }, []);
      setGridRows(data);
    }
  }, [rowsData]);

  useEffect(() => {
    if (columnsData) {
      const smallFields = ['date', 'bool'];
      const mediumFields = ['project', 'unapprover', 'created_by', 'updated_by'];
      const bigFields = ['confirmation'];
      const data = columnsData.map(column => {
        let columnWidth = 200;
        let columnFlex = 0;
        smallFields.includes(column.field) && (columnWidth = 100);
        mediumFields.includes(column.field) && (columnWidth = 220);
        bigFields.includes(column.field) && (columnWidth = 240);
        return {
          field: column.fieldId,
          headerName: intl.formatMessage({
            id: `confirmations_page_${column.field.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase()}`,
          }),
          renderHeader: props => (
            <Typography variant="h4" fontWeight={700}>
              {props.colDef.headerName}
            </Typography>
          ),
          disableExport: false,
          flex: columnFlex,
          width: columnWidth,
          cellClassName: props => (props.row.rowType === 'group' ? 'tableCell tableGroupCell' : 'tableCell'),
          renderCell: props => cellRenderer({ props, column }),
        };
      });
      setGridColumns(data);
    }
  }, [cellRenderer, columnsData, intl]);

  function Pagination({ page, onPageChange, className }) {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    return (
      <MuiPagination
        color="primary"
        className={className}
        count={pagination.count}
        siblingCount={1}
        boundaryCount={0}
        page={pagination.page + 1}
        renderItem={item => {
          if (item.type === 'next') {
            return (
              !item.disabled && (
                <Box>
                  {intl.formatMessage({ id: 'pagination_next' })}
                  <PaginationItem
                    {...item}
                    slots={{
                      next: PaginationNextButtonIcon,
                    }}
                  ></PaginationItem>
                </Box>
              )
            );
          } else if (item.type === 'previous') {
            return (
              !item.disabled && (
                <Box>
                  <PaginationItem
                    {...item}
                    slots={{
                      previous: PaginationPreviousButtonIcon,
                    }}
                  ></PaginationItem>
                  {intl.formatMessage({ id: 'pagination_previous' })}
                </Box>
              )
            );
          } else {
            return <PaginationItem {...item}></PaginationItem>;
          }
        }}
        onChange={(event, newPage) => {
          changePagination(newPage - 1);
        }}
      />
    );
  }
  function CustomPagination(props) {
    return (
      <GridPagination {...props} labelDisplayedRows={() => ''} rowsPerPageOptions={[]} ActionsComponent={Pagination} />
    );
  }
  const handleSorting = useCallback(
    newSortModel => {
      const validSortModel = newSortModel.filter(
        item => item && typeof item.field === 'string' && ['asc', 'desc'].includes(item.sort),
      );

      setSortModel(validSortModel);
      changeSorting(validSortModel);
    },
    [changeSorting],
  );
  return (
    <Grid container flexDirection={'column'} flex={1} height={'100%'} overflow={'hidden'}>
      <DataGridPro
        pagination
        disableColumnFilter
        disableRowSelectionOnClick
        disableColumnMenu
        columns={gridColumns}
        rows={gridRows}
        className={dataGridTableStyle.DataGridTableRoot}
        getRowId={item => item.id}
        sortModel={Array.isArray(sortModel) ? sortModel : []}
        onSortModelChange={handleSorting}
        sortingMode="server"
        slotProps={{
          baseCheckbox: {
            icon: <CheckboxIcon width="12" height="12" viewBox="0 0 12 12" />,
            checkedIcon: <CheckboxIconChecked width="12" height="12" viewBox="0 0 12 12" />,
          },
        }}
        slots={{
          pagination: CustomPagination,
          columnUnsortedIcon: () => <SortIcon width="20" height="20" viewBox="0 0 20 20" />,
          columnSortedAscendingIcon: () => <SortIcon direction="up" width="20" height="20" viewBox="0 0 20 20" />,
          columnSortedDescendingIcon: () => <SortIcon direction="down" width="20" height="20" viewBox="0 0 20 20" />,
        }}
        getRowClassName={params => params.row.groupName && 'groupRow'}
        isRowSelectable={params => !params.row.groupName}
        getRowHeight={() => 'auto'}
      />
    </Grid>
  );
};

export default memo(ConfirmationsTable);
