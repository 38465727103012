import { FormattedMessage, injectIntl } from 'react-intl';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { DataGridPro, GridRowModes } from '@mui/x-data-grid-pro';
import issuePageStyles from '../../../../../issuePage/IssuePage.styles';
import SortIcon from '../../../../../../../packages/common/shared-ui/src/icons/SortIcon';
import ChevronIcon from '../../../../../../../packages/common/shared-ui/src/icons/ChevronIcon';
import dataGridTableStyles from '../../../../../../../packages/common/shared-ui/src/styles/DataGridTable.styles';
import { saveConfirmationResult } from '../../../../../../../packages/common/api';
import { useParams } from 'react-router-dom';
import ArrowDownIcon from '../../../../../../../packages/common/shared-ui/src/icons/ArrowDownIcon';
import clsx from 'clsx';

const ConfirmationResultTable = ({ intl, confirmationResult }) => {
  const pageParams = useParams();
  const issueCommonStyles = issuePageStyles();
  const dataGridTableStyle = dataGridTableStyles();
  const [confirmationResultColumns, setConfirmationResultColumns] = useState([]);
  const [confirmationResultRows, setConfirmationResultRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [error, setError] = useState(null);
  const [showSaveButton, setShowSaveButton] = useState(null);

  useEffect(() => {
    if (confirmationResult) {
      confirmationResult.columns && setConfirmationResultColumns(confirmationResult.columns);
      if (confirmationResult.rows) {
        setConfirmationResultRows(formattedGridRows(confirmationResult.rows));
        const editableRow = confirmationResult.rows.find(item => item.services.canEdit);
        if (editableRow !== undefined) {
          setShowSaveButton(true);
          setRowModesModel(oldModel => ({
            ...oldModel,
            [editableRow.rowId]: {
              mode: GridRowModes.Edit,
            },
          }));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmationResult]);

  const handleSelect = useCallback(
    (event, id) => {
      const index = confirmationResultRows.findIndex(item => Number(item.id) === Number(id));
      const updatedObject = { ...confirmationResultRows[index], ['confirmation.resultId']: event.target.value };
      setConfirmationResultRows([
        ...confirmationResultRows.slice(0, index),
        updatedObject,
        ...confirmationResultRows.slice(index + 1),
      ]);
    },
    [confirmationResultRows],
  );
  const handleTextField = useCallback(
    (event, id) => {
      const index = confirmationResultRows.findIndex(item => item.id === id);

      const updatedObject = { ...confirmationResultRows[index], ['confirmation.comment']: event.target.value };
      setConfirmationResultRows([
        ...confirmationResultRows.slice(0, index),
        updatedObject,
        ...confirmationResultRows.slice(index + 1),
      ]);
    },
    [confirmationResultRows],
  );
  const formattedGridColumns = useCallback(() => {
    const smallFields = ['date', 'bool'];
    const editableColumns = ['confirmation.result', 'confirmation.comment'];
    const columns = confirmationResultColumns.map(column => {
      return {
        field: column.fieldId,
        headerName: intl.formatMessage({
          id: `confirmation_page_${column.field.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase()}`,
        }),
        //headerName: getTranslatedText(intl, '', column.fieldId.split('-').join('_'), column.fieldName),
        renderHeader: props => (
          <Typography variant="h4" fontWeight={700}>
            {props.colDef.headerName}
          </Typography>
        ),
        disableExport: false,
        flex: smallFields.includes(column.fieldType) ? 0 : 1,
        width: smallFields.includes(column.fieldType) && 150,
        cellClassName: props => (props.field === 'confirmation.result' ? 'tableCell selectCell' : 'tableCell'),
        editable: editableColumns.includes(column.fieldId) && true,
        renderEditCell: props => {
          if (props.field === 'confirmation.result') {
            return (
              <FormControl sx={{ width: '100%', height: '100%' }}>
                <InputLabel
                  className={clsx(dataGridTableStyle.selectLabel, dataGridTableStyle.rowSelectPaddingPadding)}
                  id="select-label"
                >
                  {intl.formatMessage({ id: 'choose' })}
                </InputLabel>
                <Select
                  native={false}
                  labelId="select-label"
                  label={intl.formatMessage({ id: 'choose' })}
                  variant="standard"
                  sx={{ width: '100%', border: 'none', padding: '0 10px' }}
                  className={clsx(dataGridTableStyle.rowSelect, dataGridTableStyle.rowSelectPadding)}
                  MenuProps={{ classes: { paper: dataGridTableStyle.paperDropdown } }}
                  IconComponent={props => <ChevronIcon direction={'down'} {...props} />}
                  value={props.row['confirmation.resultId'] !== '0' ? props.row['confirmation.resultId'] : ''}
                  /*                  renderValue={value => {
                    return props.row.values.find(item => item.valueId === value.toString()).valueName;
                  }}*/
                  onChange={event => handleSelect(event, props.id)}
                >
                  {props.row.values
                    .filter(item => item.valueId !== '0')
                    .map((item, index) => (
                      <MenuItem key={index} value={item.valueId}>
                        {item.valueName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            );
          } else {
            return (
              <TextField
                autoFocus
                className={dataGridTableStyle.rowInput}
                placeholder={intl.formatMessage({ id: 'enter' })}
                variant="standard"
                //defaultValue={props.row.comment ? props.row.comment : ''}
                onChange={event => handleTextField(event, props.row.id)}
              ></TextField>
            );
          }
        },
        renderCell: props => {
          const stringExists = props.value
            ? !!intl.messages[`confirmation_result_${props.value.toLowerCase()}`]
            : false;
          const stringValue = stringExists
            ? intl.formatMessage({
                id: `confirmation_result_${props.value.toLowerCase()}`,
              })
            : props.value;
          return stringValue ? stringValue : '';
        },
      };
    });
    return [...columns];
  }, [
    confirmationResultColumns,
    intl,
    dataGridTableStyle.selectLabel,
    dataGridTableStyle.rowSelectPaddingPadding,
    dataGridTableStyle.rowSelect,
    dataGridTableStyle.rowSelectPadding,
    dataGridTableStyle.paperDropdown,
    dataGridTableStyle.rowInput,
    handleSelect,
    handleTextField,
  ]);
  const contractStagesColumnsValue = formattedGridColumns();

  const formattedGridRows = useCallback(
    data =>
      data.reduce((acc, row, index) => {
        const newCols = row.columns.reduce((columnAcc, item, i) => {
          let newItem = {
            ...columnAcc,
            [row.columns[i].fieldId]: row.columns[i].valueName,
            [row.columns[i].fieldId + 'Id']: row.columns[i].valueId,
          };
          if (item.fieldDefinition && item.fieldDefinition.values) {
            newItem.values = item.fieldDefinition.values;
          }
          return newItem;
        }, {});
        delete row.columns;
        return [...acc, { ...newCols, ...row, id: row.rowId }];
      }, []),
    [],
  );

  const handleCellDoubleClick = useCallback(event => {
    event.stopPropagation();
  }, []);

  const validateRow = useCallback(
    (index, rowToSave) => {
      let isError;
      let updatedObject;
      if (rowToSave['confirmation.resultId'] === '2' && !rowToSave['confirmation.comment']) {
        isError = true;
        isError && setError(['confirmation_result_save_error']);
      }
      if (rowToSave['confirmation.resultId'] === '0') {
        isError = true;
        isError && setError(['confirmation_result_save_result_error']);
      }
      if (isError) {
        updatedObject = {
          ...confirmationResultRows[index],
          error: true,
        };
        setConfirmationResultRows([
          ...confirmationResultRows.slice(0, index),
          updatedObject,
          ...confirmationResultRows.slice(index + 1),
        ]);
        return false;
      } else {
        updatedObject = { ...confirmationResultRows[index] };
        delete updatedObject.error;
        setConfirmationResultRows([
          ...confirmationResultRows.slice(0, index),
          updatedObject,
          ...confirmationResultRows.slice(index + 1),
        ]);
        setError('');
        return true;
      }
    },
    [confirmationResultRows],
  );

  const handleSaveClick = useCallback(async () => {
    const rowToSave = confirmationResultRows.find(item => item.services.canEdit);
    const index = confirmationResultRows.findIndex(item => Number(item.id) === Number(rowToSave.id));
    const validation = validateRow(index, rowToSave);
    if (!validation) {
      return;
    }
    try {
      const data = {
        result: Number(rowToSave['confirmation.resultId']),
        comment: rowToSave['confirmation.comment'],
      };
      const response = await saveConfirmationResult(pageParams.id, rowToSave.id, data);
      if (response) {
        const { data: res } = response;
        //const formattedRes = formattedGridRows([res]);
        const newCols = res.columns.reduce((columnAcc, item, i) => {
          let newItem = {
            ...columnAcc,
            [res.columns[i].fieldId]: res.columns[i].valueName,
            [res.columns[i].fieldId + 'Id']: res.columns[i].valueId,
          };
          if (item.fieldDefinition && item.fieldDefinition.values) {
            newItem.values = item.fieldDefinition.values;
          }
          return newItem;
        }, {});
        delete res.columns;
        const updatedObject = {
          ...res,
          ...newCols,
          id: res.rowId,
        };
        setConfirmationResultRows([
          ...confirmationResultRows.slice(0, index),
          updatedObject,
          ...confirmationResultRows.slice(index + 1),
        ]);
        setRowModesModel({ ...rowModesModel, [res.rowId]: { mode: GridRowModes.View, ignoreModifications: true } });
        setShowSaveButton(false);
      }
    } catch (error) {
      console.error('ERROR WITH GET', error);
    }
  }, [confirmationResultRows, pageParams.id, rowModesModel, validateRow]);

  return (
    <>
      {confirmationResultColumns && confirmationResultRows && (
        <Grid container flexDirection={'column'} sx={{ position: 'relative' }}>
          {error && (
            <Box sx={{ position: 'absolute', top: '-20px', left: '200px' }}>
              <Typography variant="h3" color={'#E03737'}>
                {intl.formatMessage({ id: error })}
              </Typography>
            </Box>
          )}
          <Grid
            item
            sx={{ position: 'relative' }}
            borderRadius={'16px'}
            marginTop={'8px'}
            overflow={'hidden'}
            width={'100%'}
          >
            <DataGridPro
              onRowDoubleClick={(params, event) => handleCellDoubleClick(event)}
              onCellDoubleClick={(params, event) => handleCellDoubleClick(event)}
              autoHeight
              hideFooter
              disableRowSelectionOnClick
              disableColumnMenu
              columns={contractStagesColumnsValue}
              rows={confirmationResultRows}
              getRowClassName={params => params.row.groupName && 'groupRow'}
              className={dataGridTableStyle.DataGridTableRoot}
              pageSize={10}
              slots={{
                columnUnsortedIcon: () => <SortIcon width="20" height="20" viewBox="0 0 20 20" />,
                columnSortedAscendingIcon: () => <SortIcon direction="up" width="20" height="20" viewBox="0 0 20 20" />,
                columnSortedDescendingIcon: () => (
                  <SortIcon direction="down" width="20" height="20" viewBox="0 0 20 20" />
                ),
              }}
              rowHeight={40}
              editMode="row"
              rowModesModel={rowModesModel}
            />
          </Grid>
          {showSaveButton && (
            <Grid item>
              <Button onClick={handleSaveClick} variant="defaultBlueSecondary" disableRipple sx={{ marginTop: '8px' }}>
                <Typography variant="h4" fontWeight={700}>
                  <FormattedMessage id="save" />
                </Typography>
              </Button>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

export default injectIntl(ConfirmationResultTable);
