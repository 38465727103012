import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { injectIntl } from 'react-intl';

const GridTotals = ({ data, intl }) => {
  return (
    <Grid container padding={'0 16px'}>
      {data.map((item, index) => (
        <Grid key={index} item marginLeft={index > 0 ? '10px' : ''}>
          <Typography variant="h5" color={'#41424E'}>
            <Box component={'span'} marginRight={'8px'}>
              {item.isCustom
                ? item.fieldName
                : intl.formatMessage({
                    id: `filters_select_${item.fieldName
                      .replace(/([a-z])([A-Z])/g, '$1_$2')
                      .replace(' ', '_')
                      .toLowerCase()}`,
                  })}
            </Box>
            <Box component={'span'} marginRight={'8px'} fontWeight={700}>
              {item.value}
            </Box>
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};

export default injectIntl(GridTotals);
