import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Popper,
  TextField,
  Typography,
} from '@mui/material';
import ClearIcon from 'Common/shared-ui/src/icons/ClearIcon';
import ganttIssueModalStyles from './GanttIssueModal.styles';
import ChevronIcon from '../../../../../packages/common/shared-ui/src/icons/ChevronIcon';
import issueFormFieldStyles from '../../../issueCreateEdit/components/IssueFormField.styles';
import { useLocation, useNavigate } from 'react-router';
import {
  getGantIssueCreate,
  getGantIssueCreateTrackers,
  getGantIssueEdit,
  getGantParentIssue,
} from '../../../../../packages/common/api';
import IssueFormField from '../../../issueCreateEdit/components/IssueFormField';
import dayjs from 'dayjs';
import { store } from '../../../app/redux';
import GanttIssuePredecessor from '../GanttIssuePredecessor/GanttIssuePredecessor';

const GanttIssueModal = ({ intl, open, data, handleSave, handleClose, handleClear, socket, token }) => {
  const ModalState = { CREATE: 'create', EDIT: 'edit' };
  const state = store.getState();
  const classes = ganttIssueModalStyles();
  const issueFormClasses = issueFormFieldStyles();
  const location = useLocation();
  const urlParams = useRef(new URLSearchParams(location.search));
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedTracker, setSelectedTracker] = useState(null);
  const [defaultTracker, setDefaultTracker] = useState(null);
  const [trackers, setTrackers] = useState([]);
  const [modalData, setModalData] = useState(null);
  const [ganttIssueFields, setGanttIssueFields] = useState([]);
  const [formError, setFormError] = useState({ state: false, message: '', date: false });
  const [issueRowId, setIssueRowId] = useState(null);
  const [parentIssueOptions, setParentIssueOptions] = useState([]);
  const [getFieldsLoading, setGetFieldsLoading] = useState(false);
  const [modalState, setModalState] = useState(ModalState.CREATE);
  const [getParams, setGetParams] = useState({ paramsChecked: false, params: {}, id: null });
  const [defaultPredecessors, setDefaultPredecessors] = useState([]);
  const modalFormState = useRef(
    new Map([
      [ModalState.CREATE, { getResponse: getGantIssueCreate }],
      [ModalState.EDIT, { getResponse: getGantIssueEdit }],
    ]),
  );
  const formFieldsSockets = useRef(
    new Map([
      ['startDate', { socket: 'updateGanttModalStartDate' }],
      ['endDate', { socket: 'updateGanttModalEndDate' }],
      ['loadingPercent', { socket: 'updateGanttModalLoadingPercent' }],
      ['estimatedHours', { socket: 'updateGanttModalEstimatedHours' }],
      ['duration', { socket: 'updateGanttModalDuration' }],
    ]),
  );
  const handleClearModal = useCallback(() => {
    setModalData(null);
    setModalState(ModalState.CREATE);
    setTrackers([]);
    //setSelectedProject(null);
    setGetParams({ paramsChecked: false, params: {}, id: null });
    setParentIssueOptions([]);
    setGanttIssueFields([]);
    setSelectedTracker(null);
    setDefaultTracker(null);
    setDefaultPredecessors([]);
  }, [ModalState.CREATE]);
  const handleCloseButton = useCallback(() => {
    if (socket) {
      socket.emit('cancelGanttModal', {
        token: token,
        rowId: issueRowId,
        projectId: Number(selectedProject.valueId),
      });
    }
    handleClose();
    setFormError({ state: false, message: '', date: false });
  }, [handleClose, issueRowId, selectedProject, socket, token]);

  const navigate = useNavigate();
  const handleError = useCallback(
    error => {
      if (error) {
        navigate(`/errors/error-${error.status}`);
      }
    },
    [navigate],
  );
  useEffect(() => {
    if (open) {
      setSelectedProject({
        valueId: state.user.project.redmineId,
        valueName: state.user.project.name,
      });
    }
    if (!open) {
      handleClearModal();
    }
  }, [handleClearModal, open, state.user.project]);
  useEffect(() => {
    if (data) {
      setModalData(data);
      data.id && setIssueRowId(data.id);
      setModalState(ModalState.EDIT);
    }
  }, [ModalState.EDIT, data, open, trackers]);

  useEffect(() => {
    if (modalData) {
      modalData.tracker &&
        modalState === ModalState.EDIT &&
        trackers.length > 0 &&
        setDefaultTracker(trackers.find(item => Number(item.valueId) === modalData.tracker));
    }
  }, [ModalState.EDIT, modalData, modalState, trackers]);

  useEffect(() => {
    let params = {};
    selectedProject && (params.project = selectedProject.valueId);
    selectedTracker && (params.tracker = selectedTracker.valueId);
    if (modalState === ModalState.CREATE) {
      selectedProject && selectedTracker && setGetParams({ paramsChecked: true, params: params, id: null });
    } else {
      setGetParams({ paramsChecked: true, params: params, id: modalData.id });
    }
  }, [ModalState.CREATE, ModalState.EDIT, modalData, modalState, selectedProject, selectedTracker]);

  useEffect(() => {
    let ignore = false;
    let params = {};
    selectedProject && (params.project = selectedProject.valueId);
    async function getTrackers() {
      const { data: trackers } = await getGantIssueCreateTrackers(params);
      if (!ignore) {
        return trackers;
      }
    }
    if (selectedProject) {
      getTrackers()
        .then(res => {
          setTrackers(res);
        })
        .catch(error => handleError(error.response));
      return () => {
        ignore = true;
      };
    }
  }, [handleError, selectedProject]);

  useEffect(() => {
    let ignore = false;
    async function getGanttIssueData() {
      setGetFieldsLoading(true);
      const { data: response } = await modalFormState.current
        .get(modalState)
        .getResponse(getParams.id, getParams.params);
      if (!ignore) {
        return response;
      }
    }
    if (getParams.paramsChecked) {
      getGanttIssueData()
        .then(res => {
          const formattedCustoms = res.issueFields.issue.customs?.map(field => ({ ...field, custom: true }));
          setGanttIssueFields(res.issueFields.issue.standards.concat(formattedCustoms));
          modalState === ModalState.CREATE && setIssueRowId(res.newRowId);
          res.issueFields.issue.predecessors && setDefaultPredecessors(res.issueFields.issue.predecessors);
          setGetFieldsLoading(false);
        })
        .catch(error => handleError(error.response));
    }
    return () => {
      ignore = true;
    };
  }, [ModalState.CREATE, getParams.id, getParams.params, getParams.paramsChecked, handleError, modalState]);

  useEffect(() => {
    socket.on('ganttModalUpdate', data => {
      setFormError({ state: false, message: '', date: false });
      if (data) {
        const formattedCustoms = data.customs?.map(field => ({ ...field, custom: true }));
        setGanttIssueFields(data.standards.concat(formattedCustoms));
        data.predecessors && setDefaultPredecessors(data.predecessors);
        setGetFieldsLoading(false);
      }
    });
  }, [socket]);

  const socketEmitter = useCallback(
    (field, value) => {
      setGetFieldsLoading(true);
      if (formFieldsSockets.current.has(field.fieldId)) {
        socket.emit(formFieldsSockets.current.get(field.fieldId).socket, {
          token: token,
          rowId: issueRowId,
          groupId: selectedProject.valueId,
          projectId: Number(selectedProject.valueId),
          updatedValue: value,
        });
      } else {
        let updatedObject = value;
        field.custom && (updatedObject = { customs: [{ fieldId: field.fieldId, valueId: value[field.fieldId] }] });
        socket.emit('saveModalOtherFields', {
          token: token,
          rowId: issueRowId,
          groupId: selectedProject.valueId,
          projectId: Number(selectedProject.valueId),
          updatedValue: updatedObject,
        });
      }
    },
    [issueRowId, selectedProject, socket, token],
  );
  const handleFocusField = useCallback(
    (event, field) => {
      const pos = ganttIssueFields.map(e => e.fieldId).indexOf(field.fieldId);
      if (event.target.value === '0') {
        const updatedObject = {
          ...ganttIssueFields[pos],
          valueId: '',
          valueName: '',
        };
        const newFields = [...ganttIssueFields.slice(0, pos), updatedObject, ...ganttIssueFields.slice(pos + 1)];
        setGanttIssueFields(newFields);
      }
    },
    [ganttIssueFields],
  );
  const handleFormField = useCallback(
    (data, field, deleted = false) => {
      let updatedObject;
      const pos = ganttIssueFields.map(e => e.fieldId).indexOf(field.fieldId);
      let fieldError = false;
      switch (field.fieldDefinition.fieldFormat) {
        case 'issue_status':
        case 'issue_priority':
        case 'user':
        case 'issue_parent':
        case 'list':
        case 'enumeration':
          updatedObject = {
            ...ganttIssueFields[pos],
            valueId: data ? data.valueId : null,
            valueName: data ? data.valueName : null,
            changed: true,
          };
          break;
        case 'float':
        case 'acl_percent':
        case 'int':
          updatedObject = {
            ...ganttIssueFields[pos],
            valueId: data,
            valueName: data,
            changed: true,
          };
          break;
        case 'date':
          updatedObject = updatedObject = {
            ...ganttIssueFields[pos],
            valueName: data ? dayjs(data.$d).format('YYYY-MM-DD') : '',
            valueId: data ? dayjs(data.$d).format('YYYY-MM-DD') : '',
            changed: true,
          };
          break;
        case 'bool':
          updatedObject = {
            ...ganttIssueFields[pos],
            valueId: data,
            valueName: data,
            changed: true,
          };
          break;
        case 'attachment':
          updatedObject = { ...ganttIssueFields[pos], valueName: data.token ? data.token : null, changed: true };
          break;
        default:
          updatedObject = {
            ...ganttIssueFields[pos],
            valueId: data.target ? data.target.value : data.toString(),
            valueName: data.target ? data.target.value : data.toString(),
            changed: true,
          };
          break;
      }
      if (field.fieldId === 'endDate' && data !== '' && data) {
        const startDateField = ganttIssueFields.find(item => item.fieldId === 'startDate');
        if (startDateField !== undefined) {
          const isBefore = dayjs(dayjs(data.$d).format('YYYY-MM-DD')).isBefore(startDateField.valueId, 'date');
          if (isBefore) {
            fieldError = true;
            updatedObject.error = true;
            setFormError({
              state: true,
              message: intl.formatMessage({ id: 'gantt_issue_modal_date_error' }),
              date: true,
            });
          } else {
            updatedObject.error && delete updatedObject.error;
            setFormError({ state: false, message: '', date: false });
          }
        }
      }
      deleted &&
        (updatedObject = { ...ganttIssueFields[pos], valueId: '', valueName: '', changed: true, deleted: true });
      const newFields = [...ganttIssueFields.slice(0, pos), updatedObject, ...ganttIssueFields.slice(pos + 1)];
      setGanttIssueFields(newFields);
      const value =
        field.fieldDefinition.fieldFormat !== 'date'
          ? { [field.fieldId]: updatedObject.valueId }
          : { updateDate: updatedObject.valueId };
      !fieldError && socketEmitter(field, value);
    },
    [ganttIssueFields, intl, socketEmitter],
  );

  const validateForm = useCallback(() => {
    const emptyFields = ganttIssueFields.filter(
      field => field.fieldDefinition.isRequired && (field.valueId === null || field.valueId === ''),
    );
    if (emptyFields.length > 0) {
      const requiredField = ganttIssueFields.map(field => {
        if (field.fieldDefinition.isRequired && (field.valueId === null || field.valueId === '')) {
          return { ...field, error: true };
        } else {
          field.error && delete field.error;
          return field;
        }
      });
      setGanttIssueFields(requiredField);
      setFormError({ state: true, message: intl.formatMessage({ id: 'issue_page_error' }), date: false });
      return false;
    } else {
      setFormError({ state: false, message: '', date: false });
      return true;
    }
  }, [ganttIssueFields, intl]);

  const handleSaveButton = useCallback(() => {
    const validation = validateForm();
    if (!validation) {
      return;
    }
    let filledStandardFields;
    let filledCustomsFields;
    filledStandardFields = ganttIssueFields.filter(field => !field.custom);
    filledCustomsFields = ganttIssueFields.filter(field => field.custom);
    const list = ['issue_status', 'issue_priority', 'user', 'issue_parent', 'list', 'enumeration'];
    const numbers = ['float', 'acl_percent', 'int'];
    const result = filledStandardFields.reduce((acc, field) => {
      let value = field.valueId;
      field.valueId && list.includes(field.fieldDefinition.fieldFormat) && (value = Number(field.valueId));
      !field.valueId && field.fieldDefinition.fieldFormat === 'date' && (value = '');
      //field.fieldDefinition.values && (value = field.valueId !== '' ? Number(field.valueId) : null);
      if (numbers.includes(field.fieldDefinition.fieldFormat)) {
        if (field.valueId === '' || !field.valueId) {
          value = 0;
        } else {
          value = Number(field.valueId.toString().replace(',', '.'));
        }
      }
      field.fieldDefinition.fieldFormat === 'bool' && (value = field.valueId === 'true');
      acc[field.fieldId] = value;

      return acc;
    }, {});
    const resultCustoms = filledCustomsFields.map(field => {
      let value = field.valueId;
      field.valueId && field.fieldDefinition.values && (value = Number(field.valueId));
      field.fieldDefinition.fieldFormat === 'bool' && (value = (field.valueId === 'true').toString());
      numbers.includes(field.fieldDefinition.fieldFormat) && (value = Number(field.valueName.replace(',', '.')));
      return {
        fieldId: field.fieldId,
        valueId: value.toString(),
      };
    });
    const data = {
      ...result,
      customs: resultCustoms,
    };
    handleSave(data, issueRowId);
    setFormError({ state: false, message: '', date: false });
  }, [ganttIssueFields, handleSave, issueRowId, validateForm]);

  const handleParentIssueChange = useCallback(
    async value => {
      let params = {
        search: value,
      };
      issueRowId && (params.row = issueRowId);
      selectedProject && (params.project = selectedProject.valueId);
      try {
        const response = await getGantParentIssue(params);
        if (response) {
          setParentIssueOptions(response.data);
        }
      } catch (error) {
        console.error('ERROR WITH EDIT', error);
      }
    },
    [issueRowId, selectedProject],
  );

  return (
    <Dialog open={open} className={classes.modalRoot} disableRestoreFocus fullWidth>
      <Grid container position={'relative'} marginBottom={'34px'}>
        <Typography variant="h1" color={'#082253'}>
          {modalData && modalData.name ? modalData.name : <FormattedMessage id="issue_new_title" />}
        </Typography>
      </Grid>
      {formError.state && (
        <Box>
          <Typography variant="h3" color={'#E03737'}>
            {formError.message}
          </Typography>
        </Box>
      )}
      <Grid
        className={classes.modalFieldHolder}
        container
        flexDirection={'column'}
        flexWrap={'nowrap'}
        sx={{
          backgroundColor: '#fff',
          padding: '16px',
          borderRadius: '16px',
          overflowY: ganttIssueFields.length > 0 ? 'auto' : 'visible',
        }}
      >
        {getFieldsLoading && (
          <Grid
            container
            flexDirection={'column'}
            alignItems="center"
            justifyContent="center"
            width="100%"
            height="100%"
            zIndex={99}
            position={'absolute'}
            top={0}
            left={0}
          >
            <CircularProgress color="secondary" />
          </Grid>
        )}
        <FormControl required fullWidth sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
          <InputLabel className={issueFormClasses.selectLabel}>
            <Typography variant="h5" color={'#41424E'}>
              {intl.formatMessage({ id: 'issue_project_label' })}
            </Typography>
          </InputLabel>
          <Autocomplete
            noOptionsText={intl.formatMessage({ id: 'not_found' })}
            disabled
            //onChange={(event, newValue) => handleSelectProject(newValue)}
            className={issueFormClasses.filterSelect}
            disablePortal
            options={[]}
            sx={{ width: 253 }}
            disableClearable
            renderOption={(props, option) => {
              return (
                <MenuItem
                  key={option.valueId + option.valueName}
                  {...props}
                  //selected={
                  //  selectedProject
                  //    ? option.valueId === selectedProject.valueId
                  //    : defaultProject
                  //      ? option.valueId === defaultProject.valueId
                  //      : false
                  //}
                  value={option.valueId}
                >
                  {option.valueName}
                </MenuItem>
              );
            }}
            value={selectedProject ? selectedProject : ''}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            getOptionLabel={option => option.valueName || ''}
            PopperComponent={props => <Popper {...props} placement="bottom-start"></Popper>}
            renderInput={params => <TextField {...params} placeholder={intl.formatMessage({ id: 'enter' })} />}
            popupIcon={<ChevronIcon direction="down"></ChevronIcon>}
          />
        </FormControl>
        <FormControl
          required
          fullWidth
          sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', marginTop: '10px' }}
        >
          <InputLabel className={issueFormClasses.selectLabel}>
            <Typography variant="h5" color={'#41424E'}>
              {intl.formatMessage({ id: 'issue_tracker_label' })}
            </Typography>
          </InputLabel>
          <Autocomplete
            disabled={modalState === ModalState.EDIT}
            noOptionsText={intl.formatMessage({ id: 'not_found' })}
            onChange={(event, newValue) => setSelectedTracker(newValue)}
            className={issueFormClasses.filterSelect}
            disablePortal
            options={trackers}
            sx={{ width: 253 }}
            disableClearable
            renderOption={(props, option) => {
              return (
                <MenuItem
                  key={option.valueId + option.valueName}
                  {...props}
                  selected={selectedTracker ? option.valueId === selectedTracker.valueId : false}
                  value={option.valueId}
                >
                  {option.valueName}
                </MenuItem>
              );
            }}
            value={selectedTracker ? selectedTracker : defaultTracker ? defaultTracker : ''}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            getOptionLabel={option => option.valueName || ''}
            PopperComponent={props => <Popper {...props} placement="bottom-start"></Popper>}
            renderInput={params => <TextField {...params} placeholder={intl.formatMessage({ id: 'enter' })} />}
            popupIcon={<ChevronIcon direction="down"></ChevronIcon>}
          />
        </FormControl>
        {ganttIssueFields.map((field, index) => (
          <IssueFormField
            modal={true}
            key={index}
            field={field}
            handleField={handleFormField}
            handleFocusField={handleFocusField}
            changeParentIssue={handleParentIssueChange}
            parentIssueOptions={parentIssueOptions}
          ></IssueFormField>
        ))}
      </Grid>
      {ganttIssueFields.length > 0 && (
        <Grid
          className={classes.modalFieldHolder}
          container
          flexDirection={'column'}
          marginTop={'10px'}
          flexWrap={'nowrap'}
          sx={{
            position: 'relative',
            backgroundColor: '#fff',
            padding: '16px',
            borderRadius: '16px',
          }}
        >
          <GanttIssuePredecessor
            defaultPredecessors={defaultPredecessors}
            token={token}
            issueId={issueRowId}
            projectId={selectedProject.valueId}
            socket={socket}
          ></GanttIssuePredecessor>
        </Grid>
      )}
      <Grid container justifyContent="center" marginTop={5}>
        <Button disableRipple variant="cutGreyPrimaryBig" onClick={handleCloseButton}>
          <Typography variant="h5" fontWeight={700}>
            <FormattedMessage id="cancel" defaultMessage="cancel" />
          </Typography>
        </Button>
        <Box marginLeft={1}>
          <Button
            disableRipple
            variant="cutBlueSecondary"
            onClick={handleSaveButton}
            disabled={ganttIssueFields.length === 0 || getFieldsLoading || formError.date}
          >
            <Typography variant="h5" fontWeight={700}>
              <FormattedMessage id="save" defaultMessage="save" />
            </Typography>
          </Button>
        </Box>
      </Grid>
    </Dialog>
  );
};

export default injectIntl(GanttIssueModal);
