import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import dayjs from 'dayjs';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import SpentTimeTable from '../SpentTimeTable';
import useStyles from './SpentTimeContainer.styles';
import { useLocation, useNavigate } from 'react-router';
import WarningModal from '../../../../packages/common/shared-ui/src/components/WarningModal';
import {
  checkIsButtonDisplayed,
  createNewSavedQuery,
  deleteSavedQuery,
  editQuery,
  getSavedQuery,
  getSpentTimeFilters,
  getSpentTimes,
  getTimesheetCsv,
  saveQuery,
} from '../../../../packages/common/api';
import { useSelector } from 'react-redux';
import { generateRedirectUrl } from '../../../../packages/common/utils/getRedirectUrl';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import {
  filterDateValues,
  formatQueryToSave,
  formattedParamsData,
  paramsToQuery,
  queryToParams,
} from '../../../../packages/common/utils/gridFiltersHelperNew';
import GridSaveQueryModal from '../../GridSaveQueryModal/GridSaveQueryModal';
import GridFiltersModalNew from '../../GridFiltersModalNew/GridFiltersModalNew';
import GridFiltersPanelNew from '../../GridFiltersPanelNew/GridFiltersPanelNew';
import CommonPage from '../../CommonPage/CommonPage';
import { ButtonsTypes } from '../../../../packages/common/shared-ui/src/enums/commonEnums';
import TimesheetsCreateEditModal from '../TimesheetsCreateEditModal/TimesheetsCreateEditModal';

const SpentTimeContainer = ({ intl }) => {
  const pageTitle = intl.formatMessage({ id: 'page_title_spentTime' });
  //const { state: locationState = null, search } = useLocation();
  const navigate = useNavigate();
  //const classes = useStyles();
  //const currentUser = useSelector(state => state.user.info);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const pageUseParams = useParams();
  const [isAddButtonDisplayed, setIsAddButtonDisplayed] = useState(false);
  const [issuesGridRows, setIssuesGridRows] = useState([]);
  const [issuesGridColumns, setIssuesGridColumns] = useState([]);
  const [paramsLoading, setParamsLoading] = useState(false);
  const [openParamsModal, setOpenParamsModal] = useState(false);
  const [openSaveQueryModal, setOpenSaveQueryModal] = useState(false);
  const [openCreateEditModal, setCreateEditModal] = useState(false);
  const [createModalState, setCreateModalState] = useState(null);
  const [queryData, setQueryData] = useState(null);
  const [totals, setTotals] = useState([]);
  const [query, setQuery] = useQueryParams({
    groups: StringParam,
    filters: StringParam,
    totals: StringParam,
    sorts: StringParam,
    fields: StringParam,
    limit: NumberParam,
    offset: NumberParam,
  });
  const [currentParams, setCurrentParams] = useState(null);
  const [initialParams, setInitialParams] = useState(null);
  const [pagination, setPagination] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);

  const handleOpenWarningModal = () => setIsDeleteConfirmOpen(true);
  const closeWarningModal = () => setIsDeleteConfirmOpen(false);
  const handleError = useCallback(
    error => {
      if (error) {
        navigate(`/errors/error-${error.status}`);
      }
    },
    [navigate],
  );

  const handleToggleDelete = useCallback(
    async e => {
      switch (e.currentTarget.attributes.value.value) {
        case 'save':
          await deleteSavedQuery({ id: pageUseParams.queryId });
          navigate('/timesheets');
          setOpenWarningModal(false);
          break;
        case 'delete':
          setOpenWarningModal(false);
          break;
      }
    },
    [pageUseParams.queryId, navigate],
  );
  useEffect(() => {
    let ignore = false;
    async function canAdd() {
      const response = await checkIsButtonDisplayed();
      if (!ignore) {
        return response;
      }
    }
    canAdd()
      .then(res => {
        const { data: canAdd } = res;
        setIsAddButtonDisplayed(canAdd);
      })
      .catch(error => handleError(error.response));
    return () => {
      ignore = true;
    };
  }, [handleError]);
  //Получение фильтров
  useEffect(() => {
    let ignore = false;
    let params = {};
    pageUseParams.queryId && (params.query = Number(pageUseParams.queryId));
    pageUseParams.projectId && (params.project = pageUseParams.projectId);
    async function getFiltersData() {
      const response = await getSpentTimeFilters(params);
      if (!ignore) {
        return response;
      }
    }

    getFiltersData()
      .then(res => {
        const { data: issuesFiltersData } = res;
        const data = {};
        issuesFiltersData.groups && (data.groups = { items: issuesFiltersData.groups, type: 'list', multiple: false });
        issuesFiltersData.filters &&
          (data.filters = { items: issuesFiltersData.filters, type: 'list', multiple: true });
        issuesFiltersData.totals && (data.totals = { items: issuesFiltersData.totals, type: 'list', multiple: true });
        issuesFiltersData.sorts && (data.sorts = { items: issuesFiltersData.sorts, type: 'checkbox' });
        issuesFiltersData.fields && (data.fields = { items: issuesFiltersData.fields, type: 'columns' });
        const formattedData = formattedParamsData(data);
        !initialParams && setInitialParams(formattedData);
        if (Object.values(query).filter(item => item !== undefined).length === 0) {
          setCurrentParams(formattedData);
          setQuery(paramsToQuery(formattedData));
        }
      })
      .catch(error => handleError(error.response));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageUseParams.queryId]);

  //Получение query по id
  useEffect(() => {
    setQueryData(null);
    let ignore = false;
    async function getSavedQueries() {
      const { data: response } = await getSavedQuery({ id: pageUseParams.queryId });
      if (!ignore) {
        return response;
      }
    }
    if (pageUseParams.queryId) {
      getSavedQueries()
        .then(res => {
          res && setQueryData(res);
        })
        .catch(error => handleError(error.response));
    }
  }, [handleError, pageUseParams.queryId]);

  const getTimesheets = useCallback(() => {
    setParamsLoading(true);
    let params = query;
    pageUseParams.projectId && (params.project = Number(pageUseParams.projectId));
    async function getData() {
      return await getSpentTimes(params);
    }
    getData()
      .then(res => {
        const { data: issuesData } = res;
        issuesData.fields && setIssuesGridColumns(issuesData.fields);
        issuesData.rows && setIssuesGridRows(issuesData.rows);
        issuesData.paging && setPagination(issuesData.paging);
        const totalRow = issuesData.rows.find(item => item.rowType === 'total');
        totalRow !== undefined && totalRow.columns && setTotals(totalRow.columns);
        setLoading(false);
        setParamsLoading(false);
      })
      .catch(error => handleError(error.response));
  }, [handleError, pageUseParams.projectId, query]);
  useEffect(() => {
    const queryIsSet = Object.values(query).filter(item => item !== undefined).length > 0;
    queryIsSet && getTimesheets();
  }, [getTimesheets, query]);
  /*  useEffect(() => {
    let ignore = false;

    //query.fields && (params.fields = query.fields.join('$$'));

    //query.project && (params.project = query.project);
    //pageUseParams.queryId && (params.query = pageUseParams.queryId);


    return () => {
      ignore = true;
    };
  }, [handleError, query, queryIsSet]);*/

  useEffect(() => {
    if (Object.values(query).filter(item => item !== undefined).length > 0 && initialParams && !currentParams) {
      setCurrentParams(queryToParams(query, initialParams));
    }
  }, [currentParams, initialParams, query]);

  const handleSelectFilters = useCallback(
    data => {
      if (!data.hasOwnProperty('operation')) {
        return;
      }
      const items = currentParams.filters.selectedItems.map(item => {
        if (item.fieldId === data.fieldId) {
          return data;
        } else {
          return item;
        }
      });
      const updatedParams = { ...currentParams, filters: { ...currentParams.filters, selectedItems: items } };
      setCurrentParams(updatedParams);
      setQuery(paramsToQuery(updatedParams));
    },
    [currentParams, setQuery],
  );

  const handleParamsModalSave = useCallback(
    data => {
      setCurrentParams(data);
      setQuery(paramsToQuery(data));
      setOpenParamsModal(false);
    },
    [setQuery],
  );
  const handleParamsModalClose = useCallback(() => {
    setOpenParamsModal(false);
  }, []);
  const handleSaveQueryModalClose = useCallback(() => {
    setOpenSaveQueryModal(false);
  }, []);
  const handleChangePagination = useCallback(
    data => {
      setQuery(prev => ({ ...prev, offset: data * 100 }));
    },
    [setQuery],
  );
  const handleCreateEditModalClose = useCallback(() => {
    setCreateEditModal(false);
  }, []);
  const handleCreateEditModalSave = useCallback(() => {
    setCreateEditModal(false);
    getTimesheets();
  }, [getTimesheets]);

  const handleChangeSorting = useCallback(
    newSortModel => {
      if (!newSortModel || newSortModel.length === 0) {
        setQuery(prev => ({ ...prev, sorts: undefined }));
        return;
      }

      const sortString = newSortModel.map(item => `${item.field};${item.sort}`).join('$$');

      setQuery(prev => ({ ...prev, sorts: sortString }));
    },
    [setQuery],
  );

  const handleGetRedirectUrl = useCallback(({ id, columnType }) => {
    switch (columnType) {
      case 'user':
        return generateRedirectUrl({ host: window.location.origin, path: `/users/${id}` });
      case 'project':
        return `${window.location.origin}/projects/${id}`;
      case 'issue':
        return generateRedirectUrl({ host: window.location.origin, path: `/issues/${id}` });
      default:
        return '';
    }
  }, []);

  const [contextMenuData, setContextMenuData] = useState({
    isSecondStepVisible: false,
    secondPopoverStepValue: null,
    currentRow: {
      services: {
        isApproved: '',
        isOvertime: false,
        canApprove: false,
        canEdit: false,
        isProjectClosed: false,
      },
    },
  });
  const downloadCsv = useCallback(
    e => {
      async function downloadTimesheetCsv() {
        try {
          setDownloadLoading(true);
          let query = searchParams.toString();
          query = query.replaceAll(/ThisWeek|LastWeek|LastTwoWeeks|ThisMonth|LastMonth|ThisYear/gi, 'btw');
          query = query.replaceAll('Today', 'eq');
          if (query?.length > 0) {
            const { data } = await getTimesheetCsv(query);
            const url = window.URL.createObjectURL(new Blob(['\ufeff', data], { type: 'text/csv;charset=utf-8;' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `spentTime-${dayjs().format('YYYY-MM-DD')}`);
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            link.remove();
          }
        } catch (e) {
          console.error(e);
        } finally {
          setDownloadLoading(false);
        }
      }
      downloadTimesheetCsv();
    },
    [searchParams, setDownloadLoading],
  );

  const handleSaveQueryModalSave = useCallback(
    async modalData => {
      const value = formatQueryToSave(modalData, currentParams, 'timesheet');
      try {
        setSaveLoader(true);
        let response;
        if (pageUseParams.queryId) {
          response = await editQuery(pageUseParams.queryId, value);
        } else {
          response = await saveQuery(value);
        }
        if (response) {
          setSaveLoader(false);
          const { data: res } = response;
          if (pageUseParams.projectId) {
            window.open(`/projects/${pageUseParams.projectId}/timesheets/query/${res.id}`, '_self');
          } else {
            window.open(`/timesheets/query/${res.id}`, '_self');
          }
        }
      } catch (error) {
        handleError(error.response);
      }
      setOpenSaveQueryModal(false);
    },
    [currentParams, handleError, pageUseParams.queryId, pageUseParams.projectId],
  );

  const handleButtonEvent = useCallback(type => {
    switch (type) {
      case ButtonsTypes.ADD:
        //const url = pageUseParams.projectId
        //  ? `/projects/${pageUseParams.projectId}/timesheets/create`
        //  : `/timesheets/create`;
        //window.open(url, '_blank');
        setCreateModalState('create');
        setCreateEditModal(true);
        break;
      case ButtonsTypes.PARAMS:
        setOpenParamsModal(true);
        break;
      case ButtonsTypes.QUERY:
        setOpenSaveQueryModal(true);
        break;
      case ButtonsTypes.EDIT_QUERY:
        setOpenSaveQueryModal(true);
        break;
      case ButtonsTypes.DELETE_QUERY:
        setOpenWarningModal(true);
        break;
    }
  }, []);
  const handleEditEvent = useCallback(data => {
    if (Array.isArray(data)) {
      setCreateModalState('bulk_edit');
    } else {
      setCreateModalState('edit');
    }
    setModalData(data);
    setCreateEditModal(true);
  }, []);
  const handleWarningModalClose = useCallback(() => {
    setOpenWarningModal(false);
  }, []);
  return (
    <>
      <WarningModal
        simple
        isWarningModalOpen={openWarningModal}
        handleToggle={handleToggleDelete}
        closeWarningModal={handleWarningModalClose}
        title={`${intl.formatMessage({ id: 'spent_time_delete' })}?`}
      />
      {currentParams && (
        <GridFiltersModalNew
          handleSave={data => handleParamsModalSave(data)}
          handleClose={handleParamsModalClose}
          open={openParamsModal}
          data={currentParams}
        />
      )}
      <GridSaveQueryModal
        saveLoader={saveLoader}
        permission={'canSaveTimesheetsGlobalQueries'}
        queryData={queryData}
        open={openSaveQueryModal}
        handleSave={data => handleSaveQueryModalSave(data)}
        handleClose={handleSaveQueryModalClose}
      />
      <TimesheetsCreateEditModal
        open={openCreateEditModal}
        state={createModalState}
        handleClose={handleCreateEditModalClose}
        handleSave={handleCreateEditModalSave}
        data={modalData}
        project={pageUseParams.projectId ? pageUseParams.projectId : null}
      ></TimesheetsCreateEditModal>
      <CommonPage
        pageTitle={pageTitle}
        loading={loading}
        canCreate={isAddButtonDisplayed}
        buttonEvent={handleButtonEvent}
        queryName={queryData?.name}
      >
        <>
          {currentParams && currentParams.filters?.selectedItems?.length > 0 && (
            <GridFiltersPanelNew
              data={currentParams.filters.selectedItems}
              handleCloseFilter={handleSelectFilters}
            ></GridFiltersPanelNew>
          )}
          {totals.length > 0 && (
            <Grid container padding={'0 16px'}>
              {totals.map((item, index) => (
                <Grid key={index} item marginLeft={index > 0 ? '10px' : ''}>
                  <Typography variant="h5" color={'#41424E'}>
                    <Box component={'span'} marginRight={'8px'}>
                      {item.isCustom
                        ? item.fieldName
                        : intl.formatMessage({
                            id: `filters_select_${item.fieldName
                              .replace(/([a-z])([A-Z])/g, '$1_$2')
                              .replace(' ', '_')
                              .toLowerCase()}`,
                          })}
                    </Box>
                    <Box component={'span'} marginRight={'8px'} fontWeight={700}>
                      {item.value}
                    </Box>
                  </Typography>
                </Grid>
              ))}
            </Grid>
          )}
          {issuesGridColumns && issuesGridRows && (
            <Grid overflow={'hidden'} container flexDirection={'column'} wrap="nowrap" position={'relative'}>
              {paramsLoading && (
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  width="100%"
                  height="100%"
                  zIndex={99}
                  position={'absolute'}
                >
                  <CircularProgress color="secondary" />
                </Grid>
              )}
              <SpentTimeTable
                intl={intl}
                contextMenuData={contextMenuData}
                setContextMenuData={setContextMenuData}
                columnsData={issuesGridColumns}
                rowsData={issuesGridRows}
                changePagination={handleChangePagination}
                changeSorting={handleChangeSorting}
                paginationData={pagination}
                editEvent={handleEditEvent}
              />
            </Grid>
          )}
        </>
      </CommonPage>
    </>
  );

  /*  return (
    <>
      {loading ? (
        <Grid container alignItems="center" justifyContent="center" width="100%" height="70px">
          <CircularProgress color="secondary" />
        </Grid>
      ) : (
        <>


          <Page
            title={pageTitle}
            width="100%"
            sx={{ height: 'calc(100vh - 80px)', display: 'flex', flexDirection: 'column', paddingBottom: '20px' }}
          >
            <Grid container justifyContent={'space-between'} alignItems={'center'} marginBottom={'20px'}>
              <Grid item>
                <Grid container alignItems={'center'}>
                  <Grid item>
                    <Typography variant="h1" marginRight={locationState?.name ? 2.5 : 0}>
                      {pageTitle}
                    </Typography>
                  </Grid>
                  {queryData && queryData.name && (
                    <Grid item>
                      <Typography variant="h2" color={'#082253'} marginLeft={'12px'} fontWeight={'400'}>
                        {queryData.name}
                      </Typography>
                    </Grid>
                  )}
                  {/!*                  <ButtonSwitcher
                    //selectedButtonIndex={currentType}
                    //handleChangeFunc={handleChangeSpentTimeType}
                    values={{
                      firstButton: '0',
                      firstButtonText: () => <FormattedMessage id="spent_time_type_details" />,
                      secondButton: '1',
                      secondButtonText: null,
                    }}
                  />*!/}
                </Grid>
              </Grid>
              <Grid item>
                <Grid container alignItems={'center'}>
                  <Box marginRight={2.5}>
                    <Button variant="defaultGreyPrimary" disableRipple onClick={handleSaveQueryModalOpen}>
                      <Typography variant="h4" fontWeight={600}>
                        <FormattedMessage id="spent_time_save" />
                      </Typography>
                    </Button>
                  </Box>
                  {isAddButtonDisplayed && (
                    <Box marginRight={2.5}>
                      <Button variant="defaultBlueSecondary" disableRipple onClick={handleAddTimeSheet}>
                        <Typography variant="h4" fontWeight={600}>
                          <FormattedMessage id="spent_time_add" />
                        </Typography>
                      </Button>
                    </Box>
                  )}
                  {((queryData && currentUser.isAdmin) || locationState?.userId === currentUser.id) && (
                    <>
                      <Box marginRight={2.5}>
                        <Button variant="defaultGreyPrimary" disableRipple onClick={handleSaveQueryModalOpen}>
                          <Typography variant="h4" fontWeight={600}>
                            <FormattedMessage id="spent_time_edit" />
                          </Typography>
                        </Button>
                      </Box>
                      <Box marginRight={2.5}>
                        <Button variant="defaultGreyPrimary" disableRipple onClick={handleOpenWarningModal}>
                          <Typography variant="h4" fontWeight={600}>
                            <FormattedMessage id="spent_time_delete" />
                          </Typography>
                        </Button>
                      </Box>
                    </>
                  )}
                  <Box marginRight={2.5}>
                    <Button variant="defaultGreyPrimaryWithIcon" disableRipple onClick={handleParamsModalOpen}>
                      <Box display="flex" alignItems="center" marginRight={'4px'}>
                        <FilterIcon width="20" height="20" viewBox="0 0 20 20" />
                      </Box>
                      <Typography variant="h4" fontWeight={600}>
                        <FormattedMessage id="parameters_text" />
                      </Typography>
                    </Button>
                  </Box>
                  {!downloadLoading ? (
                    <>
                      <IconButton onClick={e => downloadCsv(e)} disableRipple>
                        <DownloadButton width="24" height="24" viewBox="0 0 24 24" />
                      </IconButton>
                    </>
                  ) : (
                    <>
                      <CircularProgress size={18} color="secondary" />
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
            {currentParams && currentParams.filters?.selectedItems?.length > 0 && (
              <GridFiltersPanelNew
                data={currentParams.filters.selectedItems}
                handleCloseFilter={handleSelectFilters}
              ></GridFiltersPanelNew>
            )}
            {totals.length > 0 && (
              <Grid container padding={'0 16px'}>
                {totals.map((item, index) => (
                  <Grid key={index} item marginLeft={index > 0 ? '10px' : ''}>
                    <Typography variant="h5" color={'#41424E'}>
                      <Box component={'span'} marginRight={'8px'}>
                        {item.isCustom
                          ? item.fieldName
                          : intl.formatMessage({
                              id: `filters_select_${item.fieldName
                                .replace(/([a-z])([A-Z])/g, '$1_$2')
                                .replace(' ', '_')
                                .toLowerCase()}`,
                            })}
                      </Box>
                      <Box component={'span'} marginRight={'8px'} fontWeight={700}>
                        {item.value}
                      </Box>
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            )}
            {issuesGridColumns && issuesGridRows && (
              <Grid overflow={'hidden'} container flexDirection={'column'} wrap="nowrap" position={'relative'}>
                {paramsLoading && (
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    width="100%"
                    height="100%"
                    zIndex={99}
                    position={'absolute'}
                  >
                    <CircularProgress color="secondary" />
                  </Grid>
                )}
                <SpentTimeTable
                  intl={intl}
                  sorting={currentParams?.sorts}
                  contextMenuData={contextMenuData}
                  setContextMenuData={setContextMenuData}
                  columnsData={issuesGridColumns}
                  rowsData={issuesGridRows}
                  changePagination={handleChangePagination}
                  changeSorting={handleChangeSorting}
                />
              </Grid>
            )}
          </Page>
        </>
      )}
    </>
  );*/
};

export default injectIntl(SpentTimeContainer);
