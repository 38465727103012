import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  fancyScroll: {
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#E4E4EF',
    },
    '&::-webkit-scrollbar': {
      width: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#7174AC',
      borderRadius: '5px',
    },
  },
  ProjectsContainerRoot: {
    width: '100%',
  },
  filtersWrap: {
    display: 'block !important',
    marginBottom: '10px',
  },
  projectsPageTtl: {
    float: 'left',
    paddingTop: '5px',
    marginRight: '15px !important',
    width: 'auto !important',
  },
  ProjectsViewChangeButtonWrap: {
    float: 'right',
    position: 'relative',
  },
  ProjectsViewProjectSettingsIconsWrap: {
    float: 'right',
    position: 'relative',
    '&.MuiButtonBase-root.MuiIconButton-root': {
      '&:hover': {
        backgroundColor: 'initial',
      },
      '&:focus': {
        backgroundColor: 'initial',
      },
    },
  },
  ProjectsViewChangeButton: {
    border: '0px !important',
    padding: '0px !important',
  },
}));

export default useStyles;
