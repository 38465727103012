import { useCallback } from 'react';
import { getVerifyProjectIdentifier } from '../api';

const checkIdentifier = async identifier => {
  try {
    return await getVerifyProjectIdentifier(identifier);
  } catch (error) {
    return console.log(error);
  }
};

export const formValidation = (fields, identifier = null) => {
  let isError = false;
  let errors = [];
  let errorFields = [];
  const regexForIdentifier = /^[a-z0-9-_]*$/;
  const regexForUrl = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
  const identifierField = fields.find(field => field.fieldId === 'identifier');
  const homePageField = fields.find(field => field.fieldId === 'homepage');
  const requiredFields = fields.filter(field => field.fieldDefinition.isRequired);
  if (requiredFields.length > 0) {
    const emptyFields = requiredFields.filter(field => field.valueId === null || field.valueId === '');
    errorFields = fields.map(field => {
      if (field.fieldDefinition.isRequired && (field.valueId === null || field.valueId === '')) {
        return { ...field, error: true };
      } else {
        field.error && delete field.error;
        return field;
      }
    });
    if (emptyFields.length > 0) {
      const findError = errors.find(error => error.type === 'required');
      findError === undefined && errors.push({ type: 'required', message: 'issue_page_error' });
      isError = true;
    } else {
      errors = errors.filter(error => error.type !== 'required');
      isError = false;
    }
    //setFormAllFields(errorFields);
  }
  if (identifier !== null) {
    const findError = errors.find(error => error.type === 'required');
    if (identifier) {
      errors = errors.filter(error => error.type !== 'identifier');
      isError = false;
    } else {
      findError === undefined && errors.push({ type: 'identifier', message: 'incorrect_id' });
      isError = true;
    }
  }
  if (homePageField !== undefined && homePageField.valueId !== null && homePageField.valueId !== '') {
    const findError = errors.find(error => error.type === 'homepage');
    if (regexForUrl.test(homePageField.valueId)) {
      errors = errors.filter(error => error.type !== 'homepage');
      isError = false;
    } else {
      findError === undefined && errors.push({ type: 'identifier', message: 'incorrect_homepage_url' });
      isError = true;
    }
  }
  //setErrors(errors);
  //setFormError(false);
  return {
    error: isError,
    errors: errors,
    fields: errorFields,
  };
};
