export const ButtonsTypes = {
  ADD: 'add',
  PARAMS: 'params',
  QUERY: 'query',
  EDIT_QUERY: 'edit_query',
  DELETE_QUERY: 'delete_query',
  STATUS: 'status',
  SAVE: 'save',
  CANCEL: 'cancel',
  DOWNLOAD: 'download',
  SAVE_AND_CONTINUE: 'save_and_continue',
};
