export default {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: {
      '&.defaultIconButton': {
        padding: '4px',
      },
    },
  },
};
