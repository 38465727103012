import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { injectIntl } from 'react-intl';
import formFieldsStyles from '../../../../../packages/common/shared-ui/src/styles/FormFields.styles';
import dataGridTableStyles from '../../../../../packages/common/shared-ui/src/styles/DataGridTable.styles';
import {
  deleteArtCustomersRow,
  getArtCustomersNewRow,
  getArtCustomersRow,
  getArtSettingsCustomers,
  saveArtCustomersRow,
  saveArtCustomersRowId,
} from '../../../../../packages/common/api';
import { getTranslatedText } from '../../../../../packages/common/utils/getTranslatedText';
import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Popper,
  TextField,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import ChevronIcon from '../../../../../packages/common/shared-ui/src/icons/ChevronIcon';
import CheckboxIconChecked from '../../../../../packages/common/shared-ui/src/icons/CheckboxIconChecked';
import DeleteIcon from '../../../../../packages/common/shared-ui/src/icons/DeleteIcon';
import {
  DataGridPro,
  GridActionsCellItem,
  GridCellEditStartReasons,
  GridRowEditStopReasons,
  GridRowModes,
} from '@mui/x-data-grid-pro';
import SubmitIcon from '../../../../../packages/common/shared-ui/src/icons/SubmitIcon';
import DeleteIconBig from '../../../../../packages/common/shared-ui/src/icons/DeleteIconBig';
import UserNewEditIcon from '../../../../../packages/common/shared-ui/src/icons/UserNewEditIcon';
import SortIcon from '../../../../../packages/common/shared-ui/src/icons/SortIcon';
import PlusIcon from '../../../../../packages/common/shared-ui/src/icons/PlusIcon';
import { useNavigate } from 'react-router';

const ArtCustomers = ({ intl }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [gridRows, setGridRows] = useState([]);
  const [gridColumns, setGridColumns] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});

  const fieldsClasses = formFieldsStyles();
  const dataGridTableStyle = dataGridTableStyles();
  const handleError = useCallback(
    error => {
      if (error) {
        navigate(`/errors/error-${error.status}`);
      }
    },
    [navigate],
  );

  const handleDelete = useCallback(
    async (id, row) => {
      if (row.new) {
        const newArray = gridRows.filter(item => item.id !== row.id);
        setGridRows(newArray);
        return;
      }
      try {
        setLoading(true);
        const response = await deleteArtCustomersRow(id);
        if (response) {
          setGridRows(gridRows.filter(row => row.id !== id));
          setLoading(false);
        }
      } catch (error) {
        console.error('ERROR WITH DELETE', error);
      }
    },
    [gridRows],
  );
  const handleEditClick = useCallback(
    async id => {
      const index = gridRows.findIndex(item => item.id === id);
      try {
        const { data: response } = await getArtCustomersRow(id);
        if (response) {
          const updatedObject = {
            ...response,
            id: id,
            customerValue:
              response.customers.find(item => item.isChecked) !== undefined
                ? response.customers.find(item => item.isChecked)
                : null,
            useWorkDaysActValue:
              response.useWorkDaysActs.find(item => item.isChecked) !== undefined
                ? response.useWorkDaysActs.find(item => item.isChecked)
                : null,
            useWorkDaysBillValue:
              response.useWorkDaysBills.find(item => item.isChecked) !== undefined
                ? response.useWorkDaysBills.find(item => item.isChecked)
                : null,
            billHandlingTimeValue: response.billHandlingTime,
            actHandlingTimeValue: response.actHandlingTime,
          };
          setGridRows([...gridRows.slice(0, index), updatedObject, ...gridRows.slice(index + 1)]);
          setRowModesModel(oldModel => ({
            ...oldModel,
            [id]: {
              mode: GridRowModes.Edit,
            },
          }));
        }
      } catch (error) {
        console.error('ERROR WITH GET', error);
      }
    },
    [gridRows],
  );
  const handleField = useCallback(
    (data, id, field) => {
      let updatedObject;
      const pos = gridRows.findIndex(item => item.id === id);
      updatedObject = {
        ...gridRows[pos],
        [field]: typeof data === 'string' ? data : data.valueId,
        [`${field}Value`]: data,
      };
      const newFields = [...gridRows.slice(0, pos), updatedObject, ...gridRows.slice(pos + 1)];
      setGridRows(newFields);
    },
    [gridRows],
  );

  const formattedColumns = useCallback(() => {
    const smallFields = ['tracker'];
    const columns = gridColumns.map(column => {
      return {
        field: column.fieldId,
        headerName: getTranslatedText(intl, '', column.fieldId.split('-').join('_'), column.fieldName),
        renderHeader: props => (
          <Typography variant="h4" fontWeight={700}>
            {props.colDef.headerName}
          </Typography>
        ),
        disableExport: false,
        position: column.position,
        flex: smallFields.includes(column.field) ? 0 : 1,
        width: smallFields.includes(column.field) && 250,
        cellClassName: props => (props.cellMode === 'view' ? 'tableCell' : 'tableCell cellEdit'),
        editable: true,
        fieldType: column.fieldType,
        //renderCell: props => {
        //  if (props.field === 'confirmation_trackers.url') {
        //    return (
        //      <Box component={'span'} color={'#3448FF'}>
        //        {props.value}
        //      </Box>
        //    );
        //  } else {
        //    {
        //      return props.value;
        //    }
        //  }
        //},
        renderEditCell: props => {
          if (props.colDef.fieldType === 'list') {
            return (
              <Autocomplete
                //open={true}
                noOptionsText={intl.formatMessage({ id: 'not_found' })}
                onChange={(event, newValue) =>
                  handleField(newValue, props.row.id, props.field.substring(props.field.indexOf('.') + 1))
                }
                className={clsx(fieldsClasses.fieldAutocomplete, fieldsClasses.fieldAutocompleteGrid)}
                //disablePortal
                options={props.row[`${props.field.substring(props.field.indexOf('.') + 1)}s`]}
                renderOption={(props, option) => {
                  return (
                    <MenuItem
                      {...props}
                      key={option.valueId}
                      value={option.valueId}
                      //selected={option.valueId === field.valueId}
                    >
                      {option.valueName}
                    </MenuItem>
                  );
                }}
                value={
                  props.row[`${props.field.substring(props.field.indexOf('.') + 1)}Value`]
                    ? props.row[`${props.field.substring(props.field.indexOf('.') + 1)}Value`]
                    : null
                }
                isOptionEqualToValue={(option, value) => option.valueId === value.valueId}
                getOptionLabel={option => option.valueName || ''}
                PopperComponent={props => (
                  <Popper
                    {...props}
                    className={fieldsClasses.gridPopper}
                    sx={{ maxWidth: '100%!important' }}
                    placement="bottom-start"
                  ></Popper>
                )}
                renderInput={params => <TextField {...params} placeholder={intl.formatMessage({ id: 'enter' })} />}
                popupIcon={<ChevronIcon direction="down"></ChevronIcon>}
              />
            );
          }
          if (props.colDef.fieldType === 'int') {
            return (
              <TextField
                autoFocus
                className={fieldsClasses.fieldTextGrid}
                placeholder={intl.formatMessage({ id: 'enter' })}
                variant="standard"
                value={
                  props.row[`${props.field.substring(props.field.indexOf('.') + 1)}Value`]
                    ? props.row[`${props.field.substring(props.field.indexOf('.') + 1)}Value`]
                    : ''
                }
                onChange={event =>
                  handleField(event.target.value, props.row.id, props.field.substring(props.field.indexOf('.') + 1))
                }
              ></TextField>
            );
          }
        },
      };
    });
    return [
      ...columns,
      {
        field: 'actions',
        type: 'actions',
        headerName: '',
        headerClassName: 'actions',
        width: 70,
        cellClassName: 'actions tableCell',
        getActions: ({ id, row }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
          if (isInEditMode) {
            if (id !== '0') {
              return [
                <GridActionsCellItem onClick={() => handleSaveClick(id, row)} icon={<SubmitIcon />} label="Save" />,
                <GridActionsCellItem onClick={() => handleDelete(id, row)} icon={<DeleteIconBig />} label="Cancel" />,
              ];
            } else {
              return [
                <GridActionsCellItem onClick={() => handleSaveClick(id, row)} icon={<SubmitIcon />} label="Save" />,
              ];
            }
          } else {
            if (id !== '0') {
              return [
                <GridActionsCellItem
                  onClick={() => handleEditClick(id)}
                  icon={<UserNewEditIcon width="16" height="16" viewBox="0 0 16 16" />}
                  label="Edit"
                />,
                <GridActionsCellItem
                  onClick={() => handleDelete(id, row)}
                  icon={<DeleteIconBig />}
                  label="Delete"
                  color="inherit"
                />,
              ];
            } else {
              return [
                <GridActionsCellItem
                  onClick={() => handleEditClick(id)}
                  icon={<UserNewEditIcon width="16" height="16" viewBox="0 0 16 16" />}
                  label="Edit"
                />,
              ];
            }
          }
        },
      },
    ];
  }, [
    fieldsClasses.fieldAutocomplete,
    fieldsClasses.fieldAutocompleteGrid,
    fieldsClasses.fieldTextGrid,
    fieldsClasses.gridPopper,
    gridColumns,
    handleDelete,
    handleEditClick,
    handleField,
    handleSaveClick,
    intl,
    rowModesModel,
  ]);
  const columnsValue = formattedColumns();
  const formattedRows = useCallback(data => {
    return data.reduce((acc, row) => {
      const newCols = row.columns.reduce((columnAcc, _, i) => {
        return {
          ...columnAcc,
          [row.columns[i].fieldId]: row.columns[i].value,
          [row.columns[i].fieldId + 'Id']: row.columns[i].valueId,
        };
      }, {});
      return [...acc, { ...newCols, id: row.rowId }];
    }, []);
  }, []);

  useEffect(() => {
    let ignore = false;
    async function getArtCustomers() {
      const { data: trackers } = await getArtSettingsCustomers();
      if (!ignore) {
        return trackers;
      }
    }
    getArtCustomers()
      .then(res => {
        setGridColumns(res.fields);
        setGridRows(formattedRows(res.rows));
        setLoading(false);
      })
      .catch(error => {
        handleError(error.response);
      });
    return () => {
      ignore = true;
    };
  }, [handleError, formattedRows]);

  const handleSaveClick = useCallback(
    async (id, row) => {
      const index = gridRows.findIndex(item => item.id === id);
      //const validation = validateRow(row.id);
      //if (!validation) {
      //  return;
      //}
      let response;
      try {
        const data = {
          customer: row.customerValue.valueId,
          billHandlingTime: row.billHandlingTimeValue,
          useWorkDaysBill: row.useWorkDaysBillValue.valueId,
          actHandlingTime: row.actHandlingTimeValue,
          useWorkDaysAct: row.useWorkDaysActValue.valueId,
        };
        if (rowModesModel[id].mode === 'edit' && !row.new) {
          response = await saveArtCustomersRowId(id, data);
        }
        if (row.new) {
          response = response = await saveArtCustomersRow(data);
        }
        if (response) {
          const { data: res } = response;
          const newCols = res.columns.reduce((columnAcc, item, i) => {
            return {
              ...columnAcc,
              [item.fieldId]: item.value,
            };
          }, {});
          const updatedObject = {
            id: res.rowId,
            ...newCols,
          };
          setGridRows([...gridRows.slice(0, index), updatedObject, ...gridRows.slice(index + 1)]);
          setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View, ignoreModifications: true } });
        }
      } catch (error) {
        console.error('ERROR WITH GET', error);
      }
    },
    [gridRows, rowModesModel],
  );

  const addRowHandler = useCallback(async () => {
    try {
      const { data: response } = await getArtCustomersNewRow();
      if (response) {
        //const rowColumns = gridColumns.map(item => item.field);
        setGridRows(oldRows => [
          ...oldRows,
          {
            new: true,
            id: `row-${gridRows.length + 1}`,
            ...response,
            customerValue:
              response.customers.find(item => item.isChecked) !== undefined
                ? response.customers.find(item => item.isChecked)
                : null,
            useWorkDaysActValue:
              response.useWorkDaysActs.find(item => item.isChecked) !== undefined
                ? response.useWorkDaysActs.find(item => item.isChecked)
                : null,
            useWorkDaysBillValue:
              response.useWorkDaysBills.find(item => item.isChecked) !== undefined
                ? response.useWorkDaysBills.find(item => item.isChecked)
                : null,
            billHandlingTimeValue: response.billHandlingTime,
            actHandlingTimeValue: response.actHandlingTime,
          },
        ]);
        setRowModesModel(oldModel => ({
          ...oldModel,
          [`row-${gridRows.length + 1}`]: {
            mode: GridRowModes.Edit,
          },
        }));
      }
    } catch (error) {
      console.error('ERROR WITH GET', error);
    }
  }, [gridRows.length]);

  const handleRowModesModelChange = useCallback(newRowModesModel => {
    setRowModesModel(newRowModesModel);
  }, []);

  const handleRowEditStop = useCallback((params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  }, []);
  const processRowUpdate = useCallback(
    (newRow, oldRow) => {
      const updatedRow = { ...newRow, isNew: false };
      setGridRows(gridRows.map(row => (row.id === newRow.id ? updatedRow : row)));
      return updatedRow;
    },
    [gridRows],
  );
  return (
    <Box
      marginTop={'16px'}
      borderRadius={'16px'}
      backgroundColor={gridColumns.length > 0 ? '#fff' : 'tranparent'}
      minHeight={loading ? '184px' : 'auto'}
      position={'relative'}
    >
      {loading && (
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          width="100%"
          height="100%"
          zIndex={99}
          top={0}
          position={'absolute'}
        >
          <CircularProgress color="secondary" />
        </Grid>
      )}
      {gridColumns.length > 0 && (
        <>
          <DataGridPro
            onRowEditStart={(params, event) => {
              if (params.reason === GridCellEditStartReasons.printableKeyDown) {
                event.defaultMuiPrevented = true;
              }
              if (params.reason === GridCellEditStartReasons.cellDoubleClick) {
                event.defaultMuiPrevented = true;
              }
            }}
            autoHeight
            hideFooter
            disableRowSelectionOnClick
            disableColumnMenu
            columns={columnsValue}
            rows={gridRows}
            getRowClassName={params => !params.row.active && 'disabled'}
            className={clsx(dataGridTableStyle.DataGridTableRoot, dataGridTableStyle.DataGridTableRootAddRows)}
            slots={{
              columnUnsortedIcon: () => <SortIcon width="20" height="20" viewBox="0 0 20 20" />,
              columnSortedAscendingIcon: () => <SortIcon direction="up" width="20" height="20" viewBox="0 0 20 20" />,
              columnSortedDescendingIcon: () => (
                <SortIcon direction="down" width="20" height="20" viewBox="0 0 20 20" />
              ),
            }}
            getRowHeight={() => 'auto'}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
          />
          <Grid
            item
            padding={'8px 16px'}
            sx={{
              lineHeight: 'normal',
              borderRadius: '0 0 16px 16px',
              borderTop: gridRows.length > 0 ? 'none' : '1px solid #E4E4EF',
              backgroundColor: '#fff',
            }}
          >
            <IconButton
              onClick={addRowHandler}
              sx={{ padding: '0' }}
              disabled={gridRows.filter(item => item.new).length > 0}
            >
              <PlusIcon width="24" height="24" viewBox="0 0 24 24" />
            </IconButton>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default injectIntl(ArtCustomers);
