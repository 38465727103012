import React, { useCallback, useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { useQueryParams } from 'use-query-params';
import {
  createOrUpdateTimesheets,
  createTimesheets,
  getAnotherTimesheets,
  getTimesheets,
} from '../../../packages/common/api';
import { formatWeek } from './formatWeek';
import { useDebouncedEffect } from '../../../packages/common/utils/debounce';

export const withTimesheetsRequests =
  Component =>
  ({ ...props }) => {
    const { current: timeTypes } = useRef({
      MAIN_HOURS: 'MAIN_HOURS',
      OVER_HOURS: 'OVER_HOURS',
    });

    const [query, setQuery] = useQueryParams({
      start: '',
      end: '',
      create: '',
      issue: '',
      editIssue: '',
    });

    const [readOnlyProjectsData, setReadOnlyProjectsData] = useState({});
    const [tableFooterDaysSum, setTableFooterDaysSum] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [timeType, setTimeType] = useState(timeTypes.MAIN_HOURS);
    const [issueData, setIssueData] = useState({});
    const [editedIssueData, setEditedIssueData] = useState({});
    const controller = useRef();

    const formatIssues = useCallback(
      projects => {
        const allIssues = Object.keys(projects).reduce((acc, projectKey) => {
          return [
            ...acc,
            ...projects[projectKey].issues.map(issue => ({
              ...issue,
              projectName: projects[projectKey].projectName,
              projectId: projects[projectKey].projectId,
            })),
          ];
        }, []);
        return allIssues.reduce((acc, issue) => {
          const formattedWeek = formatWeek({ startOfWeek: query.start, issue });
          const formattedOvertimeWeek = formatWeek({ startOfWeek: query.start, issue, type: 'OVER_HOURS' });
          const newIssue = {
            [issue.issueUniqueId]: {
              ...issue,
              weekDays: formattedWeek,
              overTimeWeekDays: formattedOvertimeWeek,
            },
          };
          return { ...acc, ...newIssue };
        }, {});
      },
      [query.start],
    );

    const getReadOnlyProjectsData = useCallback(async () => {
      try {
        setIsLoading(true);
        const { data: projectsData } = await getTimesheets(query);
        setReadOnlyProjectsData(projectsData);
      } catch (error) {
        setIsLoading(false);
        return console.log(error);
      }
    }, [query]);

    const getProjectsData = useCallback(async () => {
      try {
        if (query.issue || query.editIssue) {
          return;
        }
        setIsLoading(true);
        if (controller.current) {
          controller.current.abort();
        }
        controller.current = new AbortController();
        const { data: projectsData } = await getTimesheets(query, controller.current.signal);
        setTableFooterDaysSum(projectsData.dates);
        const formattedIssues = formatIssues(projectsData.projects);
        setIssueData(formattedIssues);
        setReadOnlyProjectsData(projectsData);
        controller.current = null;
        return setIsLoading(false);
      } catch (error) {
        if (error.message !== 'canceled') {
          setIsLoading(false);
        }
        return console.error(error);
      }
    }, [formatIssues, query]);

    const handleSaveSpreadsheet = useCallback(async () => {
      try {
        setIsError(false);
        setIsLoading(true);
        let formattedIssues = [];
        Object.keys(editedIssueData).map(issueKey => {
          const issue = editedIssueData[issueKey];
          const editedDays = issue.weekDays.filter(day => day.isEdited);
          const editedOvertimeDays = issue.overTimeWeekDays.filter(day => day.isEdited);
          const weekEditedDays = editedDays.map(day => {
            day.issueUniqueId = issue.issueUniqueId;
            formattedIssues = [...formattedIssues, day];
            return formattedIssues;
          });
          const overTimeWeekEdited = editedOvertimeDays.map(day => {
            day.issueUniqueId = issue.issueUniqueId;
            formattedIssues = [...formattedIssues, day];
            return formattedIssues;
          });
          return [...weekEditedDays, ...overTimeWeekEdited];
        });
        if (timeType === 'OVER_HOURS') {
          formattedIssues.map(issue => {
            issue.isOvertime = true;
            return issue;
          });
        }
        await createTimesheets(
          formattedIssues.map(item => {
            return {
              ...item,
              hours: Number(item.hours),
            };
          }),
        );
        setEditedIssueData({});
        await getProjectsData();
        return true;
      } catch (error) {
        await getReadOnlyProjectsData();
        error.response.data.errors.map(errTimeEntry => {
          const currentIssue = issueData[errTimeEntry.issueUniqueId];
          const fieldError = currentIssue[timeType === timeTypes.MAIN_HOURS ? 'weekDays' : 'overTimeWeekDays'].find(
            day => day.date === errTimeEntry.date,
          );
          fieldError.isError = true;
          return setEditedIssueData({});
        });
        setIsError(true);
        setIsLoading(false);
        return false;
      }
    }, [editedIssueData, getProjectsData, getReadOnlyProjectsData, issueData, timeType, timeTypes.MAIN_HOURS]);

    const handleUpdateSpreadsheet = useCallback(async () => {
      await handleSaveSpreadsheet();
      setIsLoading(false);
    }, [handleSaveSpreadsheet]);

    const getAnotherProjects = useCallback(async query => {
      try {
        const { data: anotherTimesheets } = await getAnotherTimesheets(query);
        return anotherTimesheets;
      } catch (error) {
        console.log(error);
      }
    }, []);

    useDebouncedEffect(getProjectsData, 500);

    useEffect(() => {
      if (query.start && query.end) {
        return;
      }
      return setQuery({
        start: dayjs().startOf('week').format('YYYY-MM-DD'),
        end: dayjs().endOf('week').format('YYYY-MM-DD'),
      });
    }, [query, setQuery]);

    const setWeekAgo = useCallback(() => {
      setIsLoading(true);
      const startOfAgoWeek = dayjs(query.start).subtract(1, 'week').format('YYYY-MM-DD');
      const endOfAgoWeek = dayjs(query.end).subtract(1, 'week').format('YYYY-MM-DD');
      return setQuery({
        start: startOfAgoWeek,
        end: endOfAgoWeek,
      });
    }, [query, setQuery]);

    const setWeekForward = useCallback(() => {
      setIsLoading(true);
      const startOfForwardWeek = dayjs(query.start).add(1, 'week').format('YYYY-MM-DD');
      const endOfForwardWeek = dayjs(query.end).add(1, 'week').format('YYYY-MM-DD');
      return setQuery({
        start: startOfForwardWeek,
        end: endOfForwardWeek,
      });
    }, [query, setQuery]);

    return (
      <>
        <Component
          query={query}
          setQuery={setQuery}
          setWeekAgo={setWeekAgo}
          setWeekForward={setWeekForward}
          timeTypes={timeTypes}
          tableFooterDaysSum={tableFooterDaysSum}
          isLoading={isLoading}
          handleUpdateSpreadsheet={handleUpdateSpreadsheet}
          handleSaveSpreadsheet={handleSaveSpreadsheet}
          getProjectsData={getProjectsData}
          getAnotherProjects={getAnotherProjects}
          formattedIssues={issueData}
          setFormattedIssues={setIssueData}
          editedFormattedIssues={editedIssueData}
          setEditedFormattedIssues={setEditedIssueData}
          formatIssues={formatIssues}
          timeType={timeType}
          setTimeType={setTimeType}
          readOnlyProjectsData={readOnlyProjectsData}
          setIsLoading={setIsLoading}
          isError={isError}
          setIsError={setIsError}
          {...props}
        />
      </>
    );
  };
