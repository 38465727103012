import React, { useState, useEffect, useCallback, useReducer, useRef, useMemo } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Page from '../../../../packages/common/shared-ui/src/components/Page';
import ProjectSettingsIcons from '../../../../packages/common/shared-ui/src/icons/ProjectSettingsIcons';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import { useLocation, useNavigate } from 'react-router';
import { Box, Button, Grid, Typography, CircularProgress, Pagination } from '@mui/material';
import useStyles from './AgileContainer.styles';
import {
  createNewSavedQuery,
  getAgile,
  getAgileFilters,
  getAgileRules,
  getProjectsList,
  getSavedQuery,
  getSpentTimeFilters,
} from '../../../../packages/common/api';
import AgileSettingsModal from '../AgileSettingsModal/AgileSettingsModal';
import AgileTable from '../AgileTable';
import AgileFilters from '../AgileFilters/AgileFilters';
import AgileQueryModal from '../AgileQueryModal/AgileQueryModal';
import { useSelector } from 'react-redux';
import { deleteSavedQuery } from '../../../../packages/common/api';
import WarningModal from '../../../../packages/common/shared-ui/src/components/WarningModal';
import clsx from 'clsx';
import ChevronIcon from 'Common/shared-ui/src/icons/ChevronIcon';
import { useParams } from 'react-router-dom';
import GridFiltersModalNew from '../../GridFiltersModalNew/GridFiltersModalNew';
import GridSaveQueryModal from '../../GridSaveQueryModal/GridSaveQueryModal';
import GridFiltersPanelNew from '../../GridFiltersPanelNew/GridFiltersPanelNew';
import CommonPage from '../../CommonPage/CommonPage';
import {
  filterDateValues,
  formattedParamsData,
  paramsToQuery,
  queryToParams,
} from '../../../../packages/common/utils/gridFiltersHelperNew';
import { ButtonsTypes } from '../../../../packages/common/shared-ui/src/enums/commonEnums';

const AgileContainer = ({ intl }) => {
  /*  const [queryParams, setQueryParams] = useQueryParams({
    project: '',
    fields: 'issue.assigned$$issue.tracker',
    savedQueryId: '',
    filters: '',
    statuses: '',
  });
  const [agileData, setAgileData] = useState([]);
  const [agileRules, setAgileRules] = useState([]);
  const [agileFilters, setAgileFilters] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [queryModalTitle, setQueryModalTitle] = useState('');
  const [isQueryOpen, setIsQueryOpen] = useState(false);
  const [isNewQuery, setIsNewQuery] = useState(true);
  const { state: locationState = null } = useLocation();
  const location = useLocation();
  const prevLocationKey = useRef(location.key);

  const [query, setQuery] = useState({
    filters: queryParams.filters ? queryParams.filters : '',
    fields: queryParams.fields ? queryParams.fields : 'issue.assigned$$issue.tracker',
    projectId: queryParams.project,
    statuses: queryParams.statuses ? queryParams.statuses : '',
    limit: '500',
    page: '1',
    offset: '0',
    savedQueryId: queryParams.savedQueryId ? queryParams.savedQueryId : '',
  });
  const pagesCount = useMemo(
    () => (agileData?.rows && Math.ceil(agileData?.rows[0]?.services?.count / Number(query.limit))) || 1,
    [agileData?.rows, query.limit],
  );
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const handleOpenWarningModal = () => setIsDeleteConfirmOpen(true);
  const closeWarningModal = () => setIsDeleteConfirmOpen(false);

  const [, forceUpdate] = useReducer(x => x + 1, 0);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const pageParams = useParams();


  const classes = useStyles();*/
  const permission = useSelector(state => state.permissons.canSaveAgileGlobalQueries);
  const navigate = useNavigate();
  const pageTitle = intl.formatMessage({ id: 'agile_title' });
  const [loading, setLoading] = useState(true);
  const pageUseParams = useParams();
  const [paramsLoading, setParamsLoading] = useState(false);
  const [openParamsModal, setOpenParamsModal] = useState(false);
  const [openSaveQueryModal, setOpenSaveQueryModal] = useState(false);
  const [queryData, setQueryData] = useState(null);
  const [query, setQuery] = useQueryParams({
    filters: StringParam,
    statuses: StringParam,
    fields: StringParam,
  });
  const [currentParams, setCurrentParams] = useState(null);
  const [initialParams, setInitialParams] = useState(null);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [agileData, setAgileData] = useState(null);
  const [agileRules, setAgileRules] = useState([]);
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);

  const handleError = useCallback(
    error => {
      if (error) {
        navigate(`/errors/error-${error.status}`);
      }
    },
    [navigate],
  );

  //Получение фильтров
  useEffect(() => {
    let ignore = false;
    let params = {};
    pageUseParams.queryId && (params.query = Number(pageUseParams.queryId));
    pageUseParams.projectId && (params.project = pageUseParams.projectId);
    async function getFiltersData() {
      const response = await getAgileFilters(params);
      if (!ignore) {
        return response;
      }
    }

    getFiltersData()
      .then(res => {
        const { data: agileFiltersData } = res;
        const data = {};
        agileFiltersData.filters && (data.filters = { items: agileFiltersData.filters, type: 'list', multiple: true });
        if (agileFiltersData.statuses) {
          const values = agileFiltersData.statuses.values.map(item => {
            return { ...item, fieldId: item.valueId, fieldName: item.valueName };
          });
          data.statuses = { items: values, type: 'list', multiple: true };
        }
        agileFiltersData.fields && (data.fields = { items: agileFiltersData.fields, type: 'columns' });
        const formattedData = formattedParamsData(data);
        !initialParams && setInitialParams(formattedData);
        if (Object.values(query).filter(item => item !== undefined).length === 0) {
          setCurrentParams(formattedData);
          setQuery(paramsToQuery(formattedData));
        }
      })
      .catch(error => handleError(error.response));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageUseParams.queryId, pageUseParams.projectId]);

  //Получение query по id
  useEffect(() => {
    setQueryData(null);
    let ignore = false;
    async function getSavedQueries() {
      const { data: response } = await getSavedQuery({ id: pageUseParams.queryId });
      if (!ignore) {
        return response;
      }
    }
    if (pageUseParams.queryId) {
      getSavedQueries()
        .then(res => {
          res && setQueryData(res);
        })
        .catch(error => handleError(error.response));
    }
  }, [handleError, pageUseParams.queryId]);

  useEffect(() => {
    let ignore = false;
    let params = {};
    pageUseParams.projectId && (params.project = pageUseParams.projectId);
    query.statuses !== undefined && (params.statuses = query.statuses);
    async function getRules() {
      const { data: response } = await getAgileRules(params);
      if (!ignore) {
        return response;
      }
    }
    if (query.statuses !== undefined) {
      getRules()
        .then(res => {
          //res && setQueryData(res);
        })
        .catch(error => handleError(error.response));
    }
  }, [handleError, pageUseParams.projectId, pageUseParams.queryId, query.statuses]);

  useEffect(() => {
    let ignore = false;
    let params = {};
    setParamsLoading(true);
    let queryIsSet = false;
    queryIsSet = Object.values(query).filter(item => item !== undefined).length > 0;
    pageUseParams.projectId && (params.project = Number(pageUseParams.projectId));
    queryIsSet && (params = { ...params, ...query });
    async function getAgileData() {
      const response = await getAgile(params);
      if (!ignore) {
        return response;
      }
    }
    if (queryIsSet) {
      getAgileData()
        .then(res => {
          const { data: agileData } = res;
          setAgileData(agileData);
          setLoading(false);
          setParamsLoading(false);
        })
        .catch(error => handleError(error.response));
    }
    return () => {
      ignore = true;
    };
  }, [handleError, pageUseParams.projectId, query]);

  useEffect(() => {
    if (Object.values(query).filter(item => item !== undefined).length > 0 && initialParams && !currentParams) {
      setCurrentParams(queryToParams(query, initialParams));
    }
  }, [currentParams, initialParams, query]);

  const handleSelectFilters = useCallback(
    data => {
      if (!data.hasOwnProperty('operation')) {
        return;
      }
      const items = currentParams.filters.selectedItems.map(item => {
        if (item.fieldId === data.fieldId) {
          return data;
        } else {
          return item;
        }
      });
      const updatedParams = { ...currentParams, filters: { ...currentParams.filters, selectedItems: items } };
      setCurrentParams(updatedParams);
      setQuery(paramsToQuery(updatedParams));
    },
    [currentParams, setQuery],
  );

  const handleParamsModalSave = useCallback(
    data => {
      setCurrentParams(data);
      setQuery(paramsToQuery(data));
      setOpenParamsModal(false);
    },
    [setQuery],
  );

  const handleSaveQueryModalSave = useCallback(
    async modalData => {
      let query = {
        filters: [],
        statuses: [],
        sorts: [],
        totals: [],
      };
      if (currentParams.filters && currentParams.filters.selectedItems) {
        query.filters = currentParams.filters.selectedItems.map(filter => {
          let value = filter.values;
          Array.isArray(filter.values) && (value = filter.values.map(item => (item.valueId ? item.valueId : item)));
          return {
            instance: filter.instance,
            operation: filterDateValues.has(filter.operation)
              ? filterDateValues.get(filter.operation).operator
              : filter.operation,
            field: filter.field,
            values: value,
          };
        });
      }
      if (currentParams.fields && currentParams.fields.selectedItems.selectedFields) {
        query.fields = currentParams.fields.selectedItems.selectedFields.map((item, index) => {
          return {
            instance: item.instance,
            field: item.field,
            position: index,
          };
        });
      }
      if (currentParams.statuses && currentParams.statuses.selectedItems) {
        query.statuses = {
          instance: 'issue',
          field: 'issue_status',
          values: currentParams.statuses.selectedItems.map(item => item.valueId),
          operation: 'eq',
        };
      }
      const data = {
        type: 'agile',
        name: modalData.name,
        query: query,
        accessLevel: modalData.accessLevel,
        roles: modalData.roles,
        projectLevel: modalData.projectLevel,
        projectId: modalData.projectId,
      };
      try {
        setSaveLoader(true);
        const queryId = pageUseParams.queryId ? pageUseParams.queryId : null;
        const { data: response } = await createNewSavedQuery(data, queryId);
        if (response) {
          setSaveLoader(false);
          window.open(`/projects/${pageUseParams.projectId}/agile/query/${response.id}`, '_self');
        }
      } catch (error) {
        handleError(error.response);
      }
      setOpenSaveQueryModal(false);
    },
    [currentParams, handleError, pageUseParams.projectId, pageUseParams.queryId],
  );
  const handleParamsModalClose = useCallback(() => {
    setOpenParamsModal(false);
  }, []);
  const handleSaveQueryModalClose = useCallback(() => {
    setOpenSaveQueryModal(false);
  }, []);

  const handleWarningModalClose = useCallback(() => {
    setOpenWarningModal(false);
  }, []);

  const handleButtonEvent = useCallback(type => {
    switch (type) {
      case ButtonsTypes.PARAMS:
        setOpenParamsModal(true);
        break;
      case ButtonsTypes.QUERY:
        setOpenSaveQueryModal(true);
        break;
      case ButtonsTypes.EDIT_QUERY:
        setOpenSaveQueryModal(true);
        break;
      case ButtonsTypes.DELETE_QUERY:
        setOpenWarningModal(true);
        break;
    }
  }, []);
  const handleToggleDelete = useCallback(
    async e => {
      switch (e.currentTarget.attributes.value.value) {
        case 'save':
          await deleteSavedQuery({ id: pageUseParams.queryId });
          navigate(`/agile`);
          setOpenWarningModal(false);
          break;
        case 'delete':
          setOpenWarningModal(false);
          break;
      }
    },
    [pageUseParams.queryId, navigate],
  );

  return (
    <>
      <WarningModal
        simple
        isWarningModalOpen={openWarningModal}
        handleToggle={handleToggleDelete}
        closeWarningModal={handleWarningModalClose}
        title={`${intl.formatMessage({ id: 'spent_time_delete' })}?`}
      />
      {currentParams && (
        <GridFiltersModalNew
          handleSave={data => handleParamsModalSave(data)}
          handleClose={handleParamsModalClose}
          open={openParamsModal}
          data={currentParams}
        />
      )}
      <GridSaveQueryModal
        saveLoader={saveLoader}
        permission={'canSaveAgileGlobalQueries'}
        queryData={queryData}
        open={openSaveQueryModal}
        handleSave={data => handleSaveQueryModalSave(data)}
        handleClose={handleSaveQueryModalClose}
      />
      <CommonPage pageTitle={pageTitle} loading={loading} buttonEvent={handleButtonEvent}>
        {currentParams && currentParams.filters?.selectedItems?.length > 0 && (
          <GridFiltersPanelNew
            data={currentParams.filters.selectedItems}
            handleCloseFilter={handleSelectFilters}
          ></GridFiltersPanelNew>
        )}
        {agileData && (
          <AgileTable
            key={query}
            agileData={agileData}
            agileRulesData={agileRules}
            projectId={pageUseParams.projectId}
            intl={intl}
          />
        )}
      </CommonPage>
    </>
  );
  /*

  const handleOpenQuery = useCallback(({ title, isClear }) => {
    setQueryModalTitle(title);
    setIsNewQuery(isClear);
    setIsQueryOpen(true);
  }, []);
  const handleCloseQuery = useCallback(() => setIsQueryOpen(false), []);
  const pageTitle = intl.formatMessage({ id: 'agile_title' });
  const createQueryString = queryObj => {
    let queryString = '';
    if (pageParams.projectId) {
      queryString = `project=${pageParams.projectId}`;
    }
    if (queryObj.filters && queryObj.filters !== 'filters=') {
      queryString += `&filters=${queryObj.filters}`;
    }
    if (queryObj.fields) {
      queryString += `&fields=${queryObj.fields}`;
    }
    if (queryObj.statuses && queryObj.statuses !== 'statuses=') {
      queryString += `&statuses=${queryObj.statuses}`;
    }
    if (queryObj.savedQueryId) {
      queryString += `&savedQueryId=${queryObj.savedQueryId}`;
    }
    if (queryObj.limit) {
      queryString += `&limit=${queryObj.limit}`;
    }
    if (queryObj.offset) {
      queryString += `&offset=${queryObj.offset}`;
    }
    return queryString;
  };*/

  /*  useEffect(() => {
    setQuery(prevQuery => ({
      ...prevQuery,
      filters: queryParams.filters ? queryParams.filters : '',
      fields: queryParams.fields ? queryParams.fields : 'issue.assigned$$issue.tracker',
      projectId: queryParams.project,
      statuses: queryParams.statuses ? queryParams.statuses : '',
      savedQueryId: queryParams.savedQueryId ? queryParams.savedQueryId : '',
      limit: queryParams.limit ? queryParams.limit : '500',
      offset: queryParams.offset ? queryParams.offset : '0',
      page: queryParams.page ? queryParams.page : '1',
    }));
    setAgileFilters([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams.project, queryParams.savedQueryId]);*/

  /*  useEffect(() => {
    let ignore = false;
    const handleResults = results => {
      const errors = results.filter(result => result.status === 'rejected').map(result => result.reason);
      if (errors.length) {
        console.error(errors, 'ERROR IN GET');
      }
      if (!ignore) {
        return results.map(result => (result.value ? result.value.data : null));
      }
    };
    const fetchAgileData = async () => {
      try {
        setIsLoading(true);
        const queryString = createQueryString(query);
        try {
          const agileData = await getAgile(queryString);
          setAgileData(agileData.data);
        } catch (e) {
          console.error(e);
        } finally {
          setIsLoading(false);
        }
      } catch (e) {
        console.error(e);
      }
    };
    const fetchInitialData = async () => {
      try {
        setIsPageLoading(true);
        const queryString = createQueryString(query);
        let rulesQuery = '';
        pageParams.projectId && (rulesQuery = `project=${pageParams.projectId}&statuses=${query.statuses}`);
        query.statuses && (rulesQuery = rulesQuery + `&statuses=${query.statuses}`);
        Promise.allSettled([getAgile(queryString), getAgileFilters(queryString), getAgileRules(rulesQuery)]).then(
          data => {
            if (!ignore) {
              const [agileDataResult, agileFiltersResult, agileRulesResult] = handleResults(data);
              if (agileDataResult) {
                setAgileData(agileDataResult);
                console.log('agileData', agileDataResult);
              }
              if (agileFiltersResult) {
                const queryFilters = query?.filters?.split('$$').map(filter => filter.split(';')[0]);
                const updatedFilters = agileFiltersResult.filters.map(filter => {
                  if (queryFilters?.includes(filter.fieldId)) {
                    return { ...filter, isChecked: true };
                  }
                  return filter;
                });
                const updatedFiltersResult = {
                  ...agileFiltersResult,
                  filters: updatedFilters,
                };
                setAgileFilters(updatedFiltersResult);
              }
              if (agileRulesResult) {
                setAgileRules(agileRulesResult);
              }
              setIsPageLoading(false);
            }
          },
        );
      } catch (e) {
        console.error(e);
      }
    };
    if (agileFilters.length === 0) {
      fetchInitialData();
    } else {
      const searchString = createQueryString(query);
      navigate(`?${searchString}`, { replace: true, state: locationState });
      fetchAgileData();
    }

    return () => {
      ignore = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);*/
  /*  const handlePaginate = useCallback(
    (e, page) => {
      if (page) {
        setIsLoading(true);
        setQuery(prevQuery => ({
          ...prevQuery,
          page: page,
          offset: (page - 1) * query.limit,
        }));
      }
    },
    [query.limit],
  );*/

  /*return (
    <>
      {!isPageLoading ? (
        <Page
          title={pageTitle}
          width="100%"
          sx={{ height: 'calc(100vh - 80px)', display: 'flex', flexDirection: 'column' }}
        >
          <WarningModal
            simple
            isWarningModalOpen={isDeleteConfirmOpen}
            handleToggle={handleToggleDelete}
            closeWarningModal={closeWarningModal}
            title={`${intl.formatMessage({ id: 'agile_delete_board' })}?`}
          />
          <AgileSettingsModal
            intl={intl}
            open={open}
            handleClose={handleClose}
            agileFilters={agileFilters}
            setQuery={setQuery}
            setAgileFilters={setAgileFilters}
            query={query}
          />
          {queryModalTitle && isQueryOpen && (
            <AgileQueryModal
              locationState={locationState}
              isNewQuery={isNewQuery}
              query={query}
              isModalOpen={isQueryOpen}
              closeModal={handleCloseQuery}
              title={queryModalTitle}
            />
          )}
          <Grid container wrap="nowrap" direction="row" justifyContent="space-between" alignItems="center">
            <Box display="flex" flexWrap="nowrap" alignItems="baseline" marginRight={6}>
              <Typography variant="h1" marginRight={locationState?.name ? 2.5 : 0}>
                <FormattedMessage id="agile_title" defaultMessage="Agile" />
              </Typography>
              {query.savedQueryId && (
                <Typography variant="h2" fontWeight={600}>
                  {locationState?.name}
                </Typography>
              )}
            </Box>
            <Box display="flex">
              <Box>
                <Button
                  type="submit"
                  disableRipple
                  variant="defaultGreyPrimary"
                  onClick={() =>
                    handleOpenQuery({ title: intl.formatMessage({ id: 'agile_create_board' }), isClear: true })
                  }
                >
                  <Typography variant="h4" fontWeight={400}>
                    <FormattedMessage id="agile_create_board" defaultMessage="Create" />
                  </Typography>
                </Button>
              </Box>
              {query.savedQueryId && (
                <>
                  <Box marginLeft={2.5}>
                    <Button
                      variant="defaultGreyPrimary"
                      disableRipple
                      onClick={() =>
                        handleOpenQuery({ title: intl.formatMessage({ id: 'agile_edit_board' }), isClear: false })
                      }
                    >
                      <Typography variant="h4" fontWeight={600}>
                        <FormattedMessage id="agile_edit_board" />
                      </Typography>
                    </Button>
                  </Box>
                  <Box marginLeft={2.5}>
                    <Button variant="defaultGreyPrimary" disableRipple onClick={handleOpenWarningModal}>
                      <Typography variant="h4" fontWeight={600}>
                        <FormattedMessage id="agile_delete_board" />
                      </Typography>
                    </Button>
                  </Box>
                </>
              )}
              <Box marginLeft={2.5}>
                <Button variant="defaultGreyPrimaryWithIcon" disableRipple onClick={handleOpen}>
                  <Box display="flex" alignItems="center" marginRight={1.5}>
                    <ProjectSettingsIcons color="#7174AC" width={24} height={24} viewBox="0 0 20 20" />
                  </Box>
                  <Typography variant="h4" fontWeight={600}>
                    <FormattedMessage id="parameters_text" />
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid container wrap="wrap">
            <AgileFilters
              filters={agileFilters.filters}
              projectId={queryParams.project}
              query={query}
              setQuery={setQuery}
            />
          </Grid>
          <Grid
            container
            wrap="nowrap"
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            overflow={'hidden'}
            flexDirection={'column'}
            sx={{ flex: 1 }}
          >
            <AgileTable
              key={query}
              agileData={agileData}
              agileRulesData={agileRules}
              projectId={queryParams.project}
              intl={intl}
            />
          </Grid>
          {agileData.rows && pagesCount > 1 && (
            <Grid container marginY={5}>
              <Pagination
                onChange={handlePaginate}
                count={pagesCount}
                page={Number(query.page ?? 0)}
                shape="rounded"
                renderItem={item => {
                  if ((item.page === pagesCount || item.page === 1) && item.type === 'page') {
                    return (
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        className={clsx(classes.paginateButton, { [classes.paginateButtonSelected]: item.selected })}
                        minWidth={28}
                        height={28}
                        marginX={0.5}
                        onClick={item.onClick}
                      >
                        <Typography variant="h3" paddingX={1}>
                          {item.page}
                        </Typography>
                      </Box>
                    );
                  }
                  if (item.type === 'start-ellipsis') {
                    return (
                      <Typography variant="h3" className={classes.paginateGoToPage} marginX={1.5}>
                        ...
                      </Typography>
                    );
                  }
                  if (item.type === 'end-ellipsis') {
                    return (
                      <Typography variant="h3" className={classes.paginateGoToPage} marginX={1.5}>
                        ...
                      </Typography>
                    );
                  }
                  if (item.type === 'previous') {
                    return Number(query.page) !== 1 ? (
                      <Box
                        display="flex"
                        flexWrap="nowrap"
                        alignItems="center"
                        onClick={item.onClick}
                        className={classes.paginateButton}
                        marginRight={2}
                        height={28}
                        paddingRight={2}
                        key={item.page}
                      >
                        <ChevronIcon width={20} height={20} direction="left" viewBox="0 0 24 24" color="#41424E" />
                        {/!* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string *!/}
                        <Typography variant="h3" align="center" color="#41424E">
                          Предыдущая
                        </Typography>
                      </Box>
                    ) : null;
                  }
                  if (item.type === 'next') {
                    return Number(query.page) !== pagesCount ? (
                      <Box
                        display="flex"
                        flexWrap="nowrap"
                        alignItems="center"
                        onClick={item.onClick}
                        className={classes.paginateButton}
                        height={28}
                        paddingLeft={2}
                        key={item.page}
                      >
                        {/!* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string *!/}
                        <Typography variant="h3" align="center" color="#41424E">
                          Следующая
                        </Typography>
                        <ChevronIcon width={20} height={20} direction="right" viewBox="0 0 24 24" color="#41424E" />
                      </Box>
                    ) : null;
                  }
                  if (item.type === 'page' && item.page === pagesCount) {
                    return Number(query.page) !== item.page ? (
                      <Typography variant="h3" className={classes.paginateGoToPage}>
                        ...
                      </Typography>
                    ) : (
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        className={clsx(classes.paginateButton, { [classes.paginateButtonSelected]: item.selected })}
                        minWidth={28}
                        height={28}
                        marginX={0.5}
                        onClick={item.onClick}
                      >
                        <Typography variant="h3" paddingX={1}>
                          {item.page}
                        </Typography>
                      </Box>
                    );
                  }
                  return (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      className={clsx(classes.paginateButton, { [classes.paginateButtonSelected]: item.selected })}
                      minWidth={28}
                      height={28}
                      marginX={0.5}
                      onClick={item.onClick}
                      key={item.page}
                    >
                      <Typography variant="h3" paddingX={1}>
                        {item.page}
                      </Typography>
                    </Box>
                  );
                }}
              />
            </Grid>
          )}
        </Page>
      ) : (
        <Grid container alignItems="center" justifyContent="center" width="100%" height="100vh">
          <CircularProgress color="secondary" />
        </Grid>
      )}
    </>
  );*/
};

export default injectIntl(AgileContainer);
