import React, { useCallback, useEffect, useRef, useState } from 'react';
import commonModal from '../../../../packages/common/shared-ui/src/styles/Modal.style';
import { Box, Button, CircularProgress, Dialog, Grid, IconButton, Typography } from '@mui/material';
import { FormattedMessage, injectIntl } from 'react-intl';
import ClearIcon from '../../../../packages/common/shared-ui/src/icons/ClearIcon';
import clsx from 'clsx';
import { ButtonsTypes } from '../../../../packages/common/shared-ui/src/enums/commonEnums';
import FilterIcon from '../../../../packages/common/shared-ui/src/icons/FilterIcon';
import SpentTimeCreate from '../SpentTimeCreate';
import {
  createTimesheet,
  getTimesheet,
  getTimesheetBulk,
  getTimesheetCreate,
  getTimesheetIssueQuery,
  getTimesheetProjects,
  saveEditTimesheet,
  saveTimesheetBulk,
} from '../../../../packages/common/api';
import { Link, useParams } from 'react-router-dom';
import FormField from '../../../../packages/common/shared-ui/src/components/FormField/FormField';
import { formValidation } from '../../../../packages/common/utils/formValidation';
import userPageStyles from '../../administration/userPage/UserPage.styles';
import { useLocation, useNavigate } from 'react-router';
import { formatFields } from '../../../../packages/common/utils/fieldHelper';

const TimesheetsCreateEditModal = ({ intl, open, handleClose, handleSave, state, data, project }) => {
  const classes = commonModal();
  const ModalState = { CREATE: 'create', EDIT: 'edit', BULK_EDIT: 'bulk_edit' };
  const [modalState, setModalState] = useState(ModalState.CREATE);
  const commonClasses = userPageStyles();
  const location = useLocation();
  const pageParams = useParams();
  const navigate = useNavigate();
  const urlParams = useRef(new URLSearchParams(location.search));

  const PageState = { CREATE: 'create', EDIT: 'edit', BULK_EDIT: 'bulk_edit' };
  //const [pageState, setPageState] = useState(PageState.CREATE);
  const [loading, setLoading] = useState(true);
  const [formAllFields, setFormAllFields] = useState([]);
  const [errors, setErrors] = useState([]);
  const [projectField, setProjectField] = useState(null);
  const [fieldsLoading, setFieldsLoading] = useState(false);
  const [searchFieldOptions, setSearchFieldOptions] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [getParams, setGetParams] = useState({ paramsChecked: false, params: {} });
  const [bulkTimesheets, setBulkTimesheets] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [modalData, setModalData] = useState(null);

  const formState = useRef(
    new Map([
      [
        ModalState.CREATE,
        { getResponse: getTimesheetCreate, saveResponse: createTimesheet, pageTitle: 'page_title_spentTime' },
      ],
      [
        ModalState.EDIT,
        { getResponse: getTimesheet, saveResponse: saveEditTimesheet, pageTitle: 'page_title_spentTime' },
      ],
      [
        ModalState.BULK_EDIT,
        { getResponse: getTimesheetBulk, saveResponse: saveTimesheetBulk, pageTitle: 'page_title_spentTime_bulk' },
      ],
    ]),
  );

  useEffect(() => {
    if (state) {
      setModalState(state);
    }
  }, [state]);
  useEffect(() => {
    if (data) {
      setModalData(data);
    }
  }, [data]);
  const handleCloseButton = useCallback(() => {
    handleClose();
    setModalData(null);
    setFormAllFields([]);
    setSelectedProject(null);
    setProjectField(null);
    setGetParams({ paramsChecked: false, params: {} });
  }, [handleClose]);

  const handleError = useCallback(
    error => {
      if (error) {
        navigate(`/errors/error-${error.status}`);
      }
    },
    [navigate],
  );

  useEffect(() => {
    let ignore = false;
    async function getProjects() {
      const response = await getTimesheetProjects();
      if (!ignore) {
        return response;
      }
    }
    if (open) {
      getProjects()
        .then(res => {
          const { data: projects } = res;
          let projectValue = null;
          let projectDisabled = false;
          if (project) {
            projectDisabled = true;
            const currentProject = projects.find(item => item.projectId === Number(project));
            currentProject !== undefined &&
              (projectValue = {
                projectIdentifier: currentProject.projectIdentifier,
                valueId: currentProject.projectId,
                valueName: currentProject.projectName,
              });
            setSelectedProject({
              projectIdentifier: currentProject.projectIdentifier,
              valueId: currentProject.projectId,
              valueName: currentProject.projectName,
            });
          }
          setProjectField({
            fieldId: 'project',
            fieldName: 'Project',
            valueId: projectValue,
            valueName: projectValue,
            fieldDefinition: {
              fieldId: 'project',
              fieldName: 'Project',
              fieldFormat: 'list',
              isMultiple: false,
              isRequired: true,
              isReadonly: projectDisabled,
              values: projects.map(item => {
                return {
                  projectIdentifier: item.projectIdentifier,
                  valueId: item.projectId,
                  valueName: item.projectName,
                };
              }),
            },
          });
        })
        .catch(error => handleError(error.response));
      return () => {
        ignore = true;
      };
    }
  }, [handleError, project, open]);

  useEffect(() => {
    let params = {};
    if (selectedProject) {
      params.project = selectedProject.valueId;
    } else {
      project && (params.project = project);
      urlParams.current.get('projectId') && (params.project = urlParams.current.get('projectId'));
    }
    modalState === PageState.BULK_EDIT && Array.isArray(modalData) && (params.timesheets = modalData.join('||'));
    urlParams.current.get('issueId') && (params.issue = urlParams.current.get('issueId'));
    pageParams.id && (params.issue = pageParams.id);
    if (modalState === PageState.CREATE || modalState === PageState.BULK_EDIT) {
      Object.values(params).length > 0 && setGetParams({ paramsChecked: true, params: params });
    } else {
      setGetParams({ paramsChecked: true, params: params });
    }
  }, [PageState.BULK_EDIT, PageState.CREATE, project, modalState, selectedProject, modalData, pageParams.id]);

  useEffect(() => {
    let ignore = false;
    async function getPageData() {
      let id = null;
      typeof modalData === 'string' && (id = modalData);
      setFieldsLoading(true);
      const response = await formState.current.get(state).getResponse(id, getParams.params);
      if (!ignore) {
        return response;
      }
    }
    if (getParams.paramsChecked && open) {
      getPageData()
        .then(res => {
          let fields = {};
          const { data: timesheetData } = res;
          timesheetData.standards && (fields.standards = timesheetData.standards);
          timesheetData.customs && (fields.customs = timesheetData.customs);
          timesheetData.project &&
            setProjectField(prev => ({
              ...prev,
              valueName: { valueId: timesheetData.project.projectId, valueName: timesheetData.project.projectName },
              valueId: { valueId: timesheetData.project.projectId, valueName: timesheetData.project.projectName },
            }));
          timesheetData.timesheets && setBulkTimesheets(timesheetData.timesheets);
          setFormAllFields(formatFields(fields));
          setFieldsLoading(false);
        })
        .catch(error => handleError(error.response));
      return () => {
        ignore = true;
      };
    }
  }, [getParams, handleError, modalData, open, state]);

  const handleFormField = useCallback(
    (data, field) => {
      const fieldValue = {
        ...field,
        ...data,
      };
      if (field.fieldId === 'project') {
        setSelectedProject(data.valueId);
        setProjectField(prev => ({
          ...prev,
          valueName: data.valueId,
          valueId: data.valueName,
        }));
      } else {
        const pos = formAllFields.map(e => e.fieldId).indexOf(field.fieldId);
        const newFields = [...formAllFields.slice(0, pos), fieldValue, ...formAllFields.slice(pos + 1)];
        setFormAllFields(newFields);
      }
    },
    [formAllFields],
  );

  const handleValidation = useCallback(() => {
    const validationResult = formValidation(formAllFields);
    setFormAllFields(validationResult.fields);
    setErrors(validationResult.errors);
    return validationResult.error;
  }, [formAllFields]);

  const saveForm = useCallback(
    async action => {
      const validation = handleValidation();
      if (state !== PageState.BULK_EDIT && !projectField.valueId.valueId) {
        return;
      }
      if (validation) {
        return;
      }
      let changedField;
      let filledStandardFields;
      let filledCustomsFields;
      let requiredFieldsStandard;
      let requiredFieldsCustom;
      let changedFieldsStandard;
      let changedFieldsCustom;
      let resultStandardArray;
      let resultCustomsArray;
      changedField = formAllFields.filter(field => field.changed);
      //filledStandardFields = formAllFields.filter(
      //  field => field.valueId !== null && field.valueName !== '' && !field.custom,
      //);
      //filledCustomsFields = formAllFields.filter(
      //  field => field.valueId !== null && field.valueName !== '' && field.custom,
      //);
      state === PageState.CREATE &&
        (changedField = formAllFields.filter(field => field.changed && !field.fieldDefinition.isRequired));
      changedFieldsStandard = changedField.filter(field => !field.custom);
      changedFieldsCustom = changedField.filter(field => field.custom);
      state === PageState.CREATE &&
        (requiredFieldsStandard = formAllFields.filter(field => field.fieldDefinition.isRequired && !field.custom));
      state === PageState.CREATE &&
        (requiredFieldsCustom = formAllFields.filter(field => field.fieldDefinition.isRequired && field.custom));
      resultStandardArray = [...changedFieldsStandard];
      resultCustomsArray = [...changedFieldsCustom];
      state === PageState.CREATE && (resultStandardArray = [...requiredFieldsStandard, ...changedFieldsStandard]);
      state === PageState.CREATE && (resultCustomsArray = [...requiredFieldsCustom, ...changedFieldsCustom]);
      const numbers = ['float', 'acl_percent', 'int'];
      const result = resultStandardArray.reduce((acc, field) => {
        let value = field.valueId;
        field.fieldDefinition.fieldFormat === 'date' && field.valueId === '' && (value = null);
        typeof field.valueId === 'object' &&
          (value = field.valueId.valueId !== '' ? Number(field.valueId.valueId) : null);
        numbers.includes(field.fieldDefinition.fieldFormat) && (value = Number(field.valueId.replace(',', '.')));
        field.fieldDefinition.fieldFormat === 'bool' && (value = field.valueId === 'true');
        field.deleted && field.fieldDefinition.values && (value = field.valueId);
        acc[field.fieldId] = value;

        return acc;
      }, {});
      const resultCustoms = resultCustomsArray.map(field => {
        let value = field.valueId.toString();
        typeof field.valueId === 'object' &&
          (value = field.valueId.valueId !== '' ? Number(field.valueId.valueId) : '');
        field.fieldDefinition.fieldFormat === 'bool' && (value = (field.valueId === 'true').toString());
        numbers.includes(field.fieldDefinition.fieldFormat) && (value = Number(field.valueId.replace(',', '.')));
        return {
          fieldId: field.fieldId,
          valueId: value.toString(),
        };
      });
      let data = {
        ...result,
        customs: resultCustoms,
      };
      if (state === PageState.BULK_EDIT) {
        data = {
          ids: modalData.map(item => Number(item)),
          timesheet: {
            ...result,
            customs: resultCustoms,
          },
        };
      }
      setLoading(true);
      try {
        let id = null;
        typeof modalData === 'string' && (id = modalData);
        const response = await formState.current.get(state).saveResponse(id, data);
        if (response) {
          setLoading(false);
          handleSave();
        }
      } catch (error) {
        setLoading(false);
        handleError(error.response);
      }
    },
    [
      handleSave,
      handleValidation,
      state,
      PageState.BULK_EDIT,
      PageState.CREATE,
      projectField,
      formAllFields,
      modalData,
      handleError,
    ],
  );

  const handleSearchFieldInput = useCallback(
    async value => {
      setSearchLoading(true);
      const { valueId: projectValue } = projectField;
      let params = {
        search: value,
        project: projectValue.valueId,
      };
      try {
        const response = await getTimesheetIssueQuery(params);
        if (response) {
          setSearchFieldOptions(response.data);
          setSearchLoading(false);
        }
      } catch (error) {
        console.error('ERROR WITH EDIT', error);
      }
    },
    [projectField],
  );
  return (
    <Dialog open={open} className={classes.modalRoot} disableRestoreFocus fullWidth={true}>
      <Grid container justifyContent="space-between" alignItems="center" wrap="nowrap" marginBottom={'20px'}>
        <Typography variant="h1">{intl.formatMessage({ id: formState.current.get(modalState).pageTitle })}</Typography>
        <IconButton disableRipple value="close" onClick={handleCloseButton}>
          <Box width={24} height={24}>
            <ClearIcon width={24} height={24} viewBox="0 0 24 24" />
          </Box>
        </IconButton>
      </Grid>
      <Grid container direction="column" overflow={'hidden'} flexWrap={'nowrap'}>
        {errors.length > 0 && (
          <Box>
            {errors.map((error, index) => (
              <Typography key={index} variant="h3" color={'#E03737'}>
                {intl.formatMessage({ id: error.message })}
              </Typography>
            ))}
          </Box>
        )}
        {fieldsLoading && (
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            width="100%"
            height="100%"
            zIndex={99}
            position={'fixed'}
            top={0}
            left={0}
          >
            <CircularProgress color="secondary" />
          </Grid>
        )}
        <Grid container direction={'column'} sx={{ mt: '4px' }}>
          {bulkTimesheets.length > 0 && (
            <Box marginTop={'4px'} marginBottom={'20px'}>
              {bulkTimesheets.map((item, index) => {
                return (
                  <Grid item key={index}>
                    <Typography variant="h5">
                      <Link to={`/timesheets/${item.timeEntryId}/edit`} target={'_blank'} style={{ color: '#212346' }}>
                        {item?.timeEntryName}
                      </Link>
                    </Typography>
                  </Grid>
                );
              })}
            </Box>
          )}
        </Grid>
        <Grid container direction={'column'} className={classes.blockWrapper} padding={'0 32px 16px 32px'}>
          {projectField && <FormField field={projectField} fieldEvent={handleFormField}></FormField>}
          {formAllFields.map((field, index) => (
            <FormField
              key={index}
              field={field}
              fieldEvent={handleFormField}
              changeSearchInput={handleSearchFieldInput}
              searchLoading={searchLoading}
              searchFieldOptions={searchFieldOptions}
              bulk={state === PageState.BULK_EDIT}
            ></FormField>
          ))}
        </Grid>
        <Grid container justifyContent="center" marginTop={5}>
          <Box>
            <Button disableRipple className={clsx('defaultButton', 'primary')} onClick={saveForm}>
              <Typography variant="h4" fontWeight={600}>
                <FormattedMessage id="save" />
              </Typography>
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default injectIntl(TimesheetsCreateEditModal);
