import React, { useCallback, useEffect, useRef, useState } from 'react';
import Page from '../../../packages/common/shared-ui/src/components/Page';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { FormattedMessage, injectIntl } from 'react-intl';
import DownloadButton from '../../../packages/common/shared-ui/src/icons/DownloadButton';
import FilterIcon from '../../../packages/common/shared-ui/src/icons/FilterIcon';
import { useLocation } from 'react-router';
import clsx from 'clsx';
import formFieldsStyles from '../../../packages/common/shared-ui/src/styles/FormFields.styles';
import { ButtonsTypes } from '../../../packages/common/shared-ui/src/enums/commonEnums';
import { useParams } from 'react-router-dom';

const CommonPage = ({
  children,
  buttons,
  pageTitle,
  loading,
  canCreate,
  buttonEvent,
  queryName,
  disableSave,
  intl,
}) => {
  const pageUseParams = useParams();
  const iconButtons = useRef([ButtonsTypes.DOWNLOAD]);
  const listPages = useRef(['issues', 'projects', 'timesheets', 'confirmations', 'agile']);
  const formPages = useRef(['create', 'edit', 'bulk_edit', 'copy']);
  const saveClearParamsPages = useRef(['finances']);
  const downloadPages = useRef(['spentTime']);
  const [currentLocation, setCurrentLocation] = useState('');
  const location = useLocation();

  const [currentButtons, setCurrentButtons] = useState([]);

  useEffect(() => {
    let buttons = [];
    const primaryButtons = [ButtonsTypes.SAVE, ButtonsTypes.SAVE_AND_CONTINUE, ButtonsTypes.ADD];
    const secondaryButtons = [
      ButtonsTypes.QUERY,
      ButtonsTypes.CANCEL,
      ButtonsTypes.EDIT_QUERY,
      ButtonsTypes.DELETE_QUERY,
      ButtonsTypes.PARAMS,
    ];
    const withIconButtons = [ButtonsTypes.PARAMS];
    listPages.current.some(item => location.pathname.includes(item)) &&
      (buttons = [ButtonsTypes.QUERY, ButtonsTypes.PARAMS]);
    formPages.current.some(item => location.pathname.includes(item)) &&
      (buttons = [ButtonsTypes.SAVE, ButtonsTypes.CANCEL]);
    saveClearParamsPages.current.some(item => location.pathname.includes(item)) &&
      (buttons = [ButtonsTypes.SAVE, ButtonsTypes.CANCEL, ButtonsTypes.PARAMS]);
    downloadPages.current.some(item => location.pathname.includes(item)) && buttons.push(ButtonsTypes.DOWNLOAD);
    pageUseParams.queryId && (buttons = [ButtonsTypes.EDIT_QUERY, ButtonsTypes.DELETE_QUERY, ButtonsTypes.PARAMS]);
    canCreate && buttons.unshift(ButtonsTypes.ADD);
    const formatButtons = buttons.reduce((acc, item) => {
      let className = ['defaultButton'];
      primaryButtons.includes(item) && className.push('primary');
      secondaryButtons.includes(item) && className.push('secondary');
      withIconButtons.includes(item) && className.push('withIcon');
      acc.push({ type: item, class: className.join(' ') });

      return acc;
    }, []);
    setCurrentButtons(formatButtons);
  }, [canCreate, location.pathname, pageUseParams.queryId]);

  useEffect(() => {
    if (location) {
      setCurrentLocation(pageUseParams.projectId ? location.pathname.split('/')[3] : location.pathname.split('/')[1]);
    }
  }, [location, pageUseParams.projectId]);

  return (
    <>
      {loading ? (
        <Grid container alignItems="center" justifyContent="center" width="100%" height="100%">
          <CircularProgress color="secondary" />
        </Grid>
      ) : (
        <Page
          title={pageTitle}
          width="100%"
          sx={{ height: 'calc(100vh - 80px)', display: 'flex', flexDirection: 'column', paddingBottom: '20px' }}
        >
          <Grid container justifyContent={'space-between'} alignItems={'center'} marginBottom={'20px'}>
            <Grid item>
              <Grid container alignItems={'center'}>
                <Typography variant="h1" color={'#082253'} fontWeight={700}>
                  {pageTitle}
                </Typography>
                {queryName && (
                  <Typography variant="h2" color={'#082253'} marginLeft={'12px'} fontWeight={'400'}>
                    {queryName}
                  </Typography>
                )}
              </Grid>
            </Grid>

            <Grid item>
              <Grid container alignItems={'center'}>
                {currentButtons.map((item, index) => {
                  return iconButtons.current.includes(item) ? (
                    <IconButton
                      onClick={() => buttonEvent(item)}
                      disableRipple
                      className={'defaultIconButton'}
                      sx={{ marginLeft: '10px' }}
                    >
                      <DownloadButton width="24" height="24" viewBox="0 0 24 24" />
                    </IconButton>
                  ) : (
                    <Button
                      disabled={
                        disableSave && (item.type === ButtonsTypes.SAVE || item.type === ButtonsTypes.SAVE_AND_CONTINUE)
                      }
                      key={index}
                      disableRipple
                      sx={{ marginLeft: index > 0 ? '10px' : 0 }}
                      className={item.class}
                      onClick={() => buttonEvent(item.type)}
                    >
                      {item.type === ButtonsTypes.PARAMS && (
                        <Box display="flex" alignItems="center" marginRight={'4px'}>
                          <FilterIcon />
                        </Box>
                      )}
                      <Typography variant="h4" fontWeight={600}>
                        <FormattedMessage id={`${currentLocation}_${item.type}`} />
                      </Typography>
                    </Button>
                  );
                })}
                {buttons}
              </Grid>
            </Grid>
          </Grid>
          {children}
        </Page>
      )}
    </>
  );
};

export default injectIntl(CommonPage);
