import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';

export const filtersDateRange = ['btw', 'thisWeek', 'lastWeek', 'thisMonth', 'lastMonth', 'thisYear'];
const FiltersDateConst = {
  BTW: 'btw',
  TODAY: 'today',
  YESTERDAY: 'yesterday',
  THIS_WEEK: 'thisWeek',
  LAST_WEEK: 'lastWeek',
  THIS_MONTH: 'thisMonth',
  LAST_MONTH: 'lastMonth',
  THIS_YEAR: 'thisYear',
};
dayjs.extend(weekday);
export const filterDateValues = new Map([
  [FiltersDateConst.TODAY, { operator: 'eq', value: dayjs().format('YYYY-MM-DD') }],
  [FiltersDateConst.BTW, { operator: 'btw', value: [dayjs().format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')] }],
  [FiltersDateConst.YESTERDAY, { operator: 'eq', value: dayjs().subtract(1, 'd').format('YYYY-MM-DD') }],
  [
    FiltersDateConst.THIS_WEEK,
    { operator: 'btw', value: [dayjs().weekday(0).format('YYYY-MM-DD'), dayjs().weekday(6).format('YYYY-MM-DD')] },
  ],
  [
    FiltersDateConst.THIS_MONTH,
    {
      operator: 'btw',
      value: [dayjs().startOf('month').format('YYYY-MM-DD'), dayjs().endOf('month').format('YYYY-MM-DD')],
    },
  ],
  [
    FiltersDateConst.LAST_WEEK,
    {
      operator: 'btw',
      value: [
        dayjs().subtract(1, 'w').weekday(0).format('YYYY-MM-DD'),
        dayjs().subtract(1, 'w').weekday(6).format('YYYY-MM-DD'),
      ],
    },
  ],
  [
    FiltersDateConst.LAST_MONTH,
    {
      operator: 'btw',
      value: [
        dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
        dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
      ],
    },
  ],
  [
    FiltersDateConst.THIS_YEAR,
    {
      operator: 'btw',
      value: [dayjs().startOf('year').format('YYYY-MM-DD'), dayjs().endOf('year').format('YYYY-MM-DD')],
    },
  ],
]);

export const renderOptions = type => {
  let data = [];
  const operationTypeText = ['text', 'string'];
  const operationTypeNumber = ['float', 'int'];
  const operationTypeSelect = [
    'list',
    'activity',
    'calculation_types',
    'user_groups',
    'issue_priority',
    'user_roles',
    'status',
    'issue_status',
    'issue_watchers',
    'user',
    'issue',
    'project',
    'tracker',
    'confirmation',
    'confirmation_projects',
    'confirmation_status',
    'confirmation_trackers',
    'confirmation_issues',
    'confirmation_approvers',
    'confirmation_unapprovers',
    'confirmation_authors',
    'confirmation_editors',
    'parent',
  ];
  const operationTypeDate = ['spent_on', 'date'];

  const operationTypeBoolean = ['boolean', 'bool', 'activity'];

  operationTypeText.includes(type) && (data = ['in', 'notin', 'startswith', 'endswith', 'null', 'all']);
  operationTypeSelect.includes(type) && (data = ['eq', 'noteq', 'null', 'all']);
  operationTypeDate.includes(type) &&
    (data = [
      'eq',
      'gte',
      'lte',
      'btw',
      'today',
      'yesterday',
      'thisWeek',
      'lastWeek',
      'thisMonth',
      'lastMonth',
      'thisYear',
      'null',
      'all',
    ]);

  operationTypeBoolean.includes(type) && (data = ['eq', 'noteq']);
  operationTypeNumber.includes(type) && (data = ['eq', 'gte', 'lte', 'btw', 'null', 'all']);
  return data;
};

export const selectFilters = (data, params) => {
  if (!data.hasOwnProperty('operation')) {
    return;
  }
  const items = params.filters.selectedItems.map(item => {
    if (item.fieldId === data.fieldId) {
      return data;
    } else {
      return item;
    }
  });
  return { ...params, filters: { ...params.filters, selectedItems: items } };
};

export const formatFilters = params => {
  const data = {};
  params.groups && (data.groups = { items: params.groups, type: 'list', multiple: false });
  params.filters && (data.filters = { items: params.filters, type: 'list', multiple: true });
  params.totals && (data.totals = { items: params.totals, type: 'list', multiple: true });
  params.sorts && (data.sorts = { items: params.sorts, type: 'checkbox' });
  params.fields && (data.fields = { items: params.fields, type: 'columns' });

  return formattedParamsData(data);
};
export const formattedParamsData = data => {
  return Object.entries(data).reduce((acc, [key, value]) => {
    if (value.type === 'list') {
      const object = value;
      if (value.multiple && value.items.filter(item => item.isChecked).length > 0) {
        object.selectedItems = value.items
          .filter(item => item.isChecked)
          .map(item => {
            let selectedItem = item;
            if (item.values) {
              if (typeof item.values === 'string') {
                return { ...selectedItem, values: item.values };
              } else {
                return {
                  ...selectedItem,
                  values: item.values.filter(item => item.isChecked).map(item => item.valueId),
                };
              }
            } else {
              delete selectedItem.values;
              return selectedItem;
            }
          });
        if (key === 'filters' && object.selectedItems.filter(item => !item.values).length > 0) {
          delete object.selectedItems;
        }
      }
      if (!value.multiple) {
        const selectedItem = value.items.find(item => item.isChecked);
        selectedItem !== undefined && (object.selectedItems = value.items.find(item => item.isChecked));
      }
      acc[key] = object;
      //return {...acc, {}};
    }
    if (value.type === 'columns') {
      acc[key] = {
        ...value,
        selectedItems: {
          availableFields: value.items.filter(item => !item.isChecked),
          selectedFields: value.items.filter(item => item.isChecked).sort((a, b) => a.position - b.position),
        },
      };
    }
    if (value.type === 'checkbox') {
      const object = { ...value };
      value.items.filter(item => item.isChecked).length > 0 &&
        (object.selectedItems = value.items.filter(item => item.isChecked));
      acc[key] = object;
    }
    return acc;
  }, {});
};

export const paramsToQuery = params => {
  const formattedParams = Array.isArray(params) ? params : Object.entries(params);
  return formattedParams.reduce((acc, [key, value]) => {
    const specialParams = ['sorts', 'fields', 'filters', 'statuses'];
    if (!value.selectedItems || value.selectedItems.length === 0) {
      acc[key] = undefined;
    } else {
      if (specialParams.includes(key)) {
        if (key === 'statuses') {
          acc[key] = value.selectedItems.map(item => item.fieldId).join('||');
        }
        if (key === 'sorts') {
          acc[key] = value.selectedItems.map(item => `${item.fieldId};${item.direction}`).join('$$');
        }
        if (key === 'fields') {
          acc[key] = value.selectedItems.selectedFields.map(item => item.fieldId).join('$$');
        }
        if (key === 'filters') {
          if (value.selectedItems.filter(item => item.values).length > 0) {
            acc[key] = value.selectedItems
              .filter(item => item.values)
              .map(item => {
                let operation = item.operation;
                let value = item.values;
                if (filterDateValues.has(item.operation)) {
                  operation = filterDateValues.get(item.operation).operator;
                }
                Array.isArray(item.values) &&
                  (value = item.values
                    .map(item => (item.valueId ? item.valueId : item))
                    .join(item.fieldType === 'date' ? ';' : '||'));
                let queryString = `${item.fieldId};${operation};${value}`;
                item.operation === 'null' && (queryString = `${item.fieldId};${item.operation}`);
                item.operation === 'btw' &&
                  ['float', 'int'].includes(item.fieldType) &&
                  (queryString = `${item.fieldId};${item.operation};${value.from};${value.to}`);
                return queryString;
              })
              .join('$$');
          } else {
            acc[key] = undefined;
          }
        }
      } else {
        acc[key] = Array.isArray(value.selectedItems)
          ? value.selectedItems.map(item => item.fieldId).join('$$')
          : value.selectedItems.fieldId;
      }
    }
    return acc;
  }, {});
};

export const queryToParams = (query, params) => {
  return Object.entries(query)
    .filter(([key, value]) => params.hasOwnProperty(key))
    .reduce((acc, [key, value]) => {
      if (value !== undefined) {
        const splitString = value.split(key === 'statuses' ? '||' : '$$');
        const items = splitString.reduce((acc, item) => {
          const currentItem = params[key].items.find(el => el.fieldId === item);
          return [...acc, currentItem];
        }, []);
        acc[key] = { ...params[key], selectedItems: items };
        if (key === 'fields') {
          const fields = params[key].items.reduce(
            (acc, item) => {
              const currentItem = splitString.find(el => el === item.fieldId);
              if (currentItem !== undefined) {
                acc.selectedFields = [...acc.selectedFields, { ...item, position: splitString.indexOf(currentItem) }];
              } else {
                acc.availableFields = [...acc.availableFields, item];
              }
              acc.selectedFields.length > 0 && acc.selectedFields.sort((a, b) => a.position - b.position);
              return acc;
            },
            { selectedFields: [], availableFields: [] },
          );
          acc[key] = { ...params[key], selectedItems: fields };
        }
        if (key === 'filters') {
          const items = splitString.reduce((acc, item) => {
            const splitItem = item.split(';');
            const currentItem = params[key].items.find(el => el.fieldId === splitItem[0]);
            if (splitItem.length === 2) {
              currentItem.operation = 'null';
              currentItem.values = 'null';
            }
            if (splitItem.length === 3) {
              const [filterId, filterOperation, filterValue] = item.split(';');
              currentItem.operation = filterOperation;
              currentItem.values = filterValue.split('||');
            }
            if (splitItem.length === 4) {
              const [filterId, filterOperation, filterValueFrom, filterValueTo] = item.split(';');
              currentItem.operation = filterOperation;
              currentItem.values = { from: filterValueFrom, to: filterValueTo };
            }
            //let value = filterValue;
            //Array.isArray(filterValue) && (value = filterValue.split('||'));
            return [...acc, currentItem];
          }, []);
          acc[key] = { ...params[key], selectedItems: items };
        }
        if (key === 'sorts' && splitString[0].length > 0) {
          const items = splitString.reduce((acc, item) => {
            const [sortField, sortDirection] = item.split(';');
            const currentItem = params[key].items.find(el => el.fieldId === sortField);
            //let value = filterValue;
            //Array.isArray(filterValue) && (value = filterValue.split('||'));
            currentItem.direction = sortDirection;
            return [...acc, currentItem];
          }, []);
          acc[key] = { ...params[key], selectedItems: items };
        }
        if (key === 'statuses') {
          acc[key] = { ...params[key], selectedItems: items };
        }
      } else {
        acc[key] = params[key];
      }
      return acc;
    }, {});
};

export const formatQueryToSave = (queryData, params, type) => {
  let query;
  const selectedParams = Object.entries(params).filter(([key, value]) => value.selectedItems);
  query = selectedParams.reduce((acc, [key, value]) => {
    const selectedItems = key === 'fields' ? value.selectedItems.selectedFields : value.selectedItems;
    acc[key] = selectedItems.map((item, index) => {
      let value = {
        instance: item.instance,
        field: item.field,
      };
      if (key === 'filters') {
        let filterValue = item.values;
        Array.isArray(item.values) && (filterValue = item.values.map(item => (item.valueId ? item.valueId : item)));
        value.operation = filterDateValues.has(item.operation)
          ? filterDateValues.get(item.operation).operator
          : item.operation;
        value.values = filterValue;
      }
      key === 'sorts' && (value.direction = item.direction);
      key === 'fields' && (value.position = index);

      return value;
    });

    return acc;
  }, {});
  !query.filters && (query.filters = []);
  !query.totals && (query.totals = []);
  !query.sorts && (query.sorts = []);
  return {
    type: type,
    name: queryData.name,
    query: query,
    accessLevel: queryData.accessLevel,
    roles: queryData.roles,
    projectLevel: queryData.projectLevel,
    projectId: queryData.projectId,
  };
};
