import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import dayjs from 'dayjs';
import { Box, Button, CircularProgress, Grid, Typography, IconButton } from '@mui/material';
import Page from 'Common/shared-ui/src/components/Page';
import IssuesTable from '../IssuesTable';
import FilterIcon from 'Common/shared-ui/src/icons/FilterIcon';
import { useLocation, useNavigate } from 'react-router';
import WarningModal from '../../../../packages/common/shared-ui/src/components/WarningModal';
import {
  createNewSavedQuery,
  deleteSavedQuery,
  editQuery,
  getIssues,
  getIssuesCsv,
  getIssuesParams,
  getSavedQuery,
  issueCanCreate,
  saveQuery,
} from '../../../../packages/common/api';
import { useSelector } from 'react-redux';
import { store } from '../../app/redux';
import DownloadButton from '../../../../packages/common/shared-ui/src/icons/DownloadButton';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import {
  filterDateValues,
  formatFilters,
  formatQueryToSave,
  formattedParamsData,
  paramsToQuery,
  queryToParams,
  selectFilters,
} from '../../../../packages/common/utils/gridFiltersHelperNew';
import GridSaveQueryModal from '../../GridSaveQueryModal/GridSaveQueryModal';
import GridFiltersModalNew from '../../GridFiltersModalNew/GridFiltersModalNew';
import GridFiltersPanelNew from '../../GridFiltersPanelNew/GridFiltersPanelNew';
import CommonPage from '../../CommonPage/CommonPage';
import GridTotals from '../../GridTotals/GridTotals';
import { ButtonsTypes } from '../../../../packages/common/shared-ui/src/enums/commonEnums';

const IssuesContainer = ({ intl }) => {
  //const { state: locationState = null } = useLocation();
  //const state = store.getState();
  //const handleOpenWarningModal = () => setIsDeleteConfirmOpen(true);
  //const closeWarningModal = () => setIsDeleteConfirmOpen(false);
  //const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  //const location = useLocation();

  const pageUseParams = useParams();
  const pageTitle = intl.formatMessage({ id: 'page_title_issues' });
  const navigate = useNavigate();
  const currentUser = useSelector(state => state.user.info);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [gridRows, setGridRows] = useState([]);
  const [gridColumns, setGridColumns] = useState([]);
  const [paramsLoading, setParamsLoading] = useState(false);
  const [openParamsModal, setOpenParamsModal] = useState(false);
  const [openSaveQueryModal, setOpenSaveQueryModal] = useState(false);
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [queryData, setQueryData] = useState(null);
  const [totals, setTotals] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [currentParams, setCurrentParams] = useState(null);
  const [initialParams, setInitialParams] = useState(null);
  const [sortModel, setSortModel] = useState([]);
  const [canCreate, setCanCreate] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [query, setQuery] = useQueryParams({
    groups: StringParam,
    filters: StringParam,
    totals: StringParam,
    sorts: StringParam,
    fields: StringParam,
    limit: NumberParam,
    offset: NumberParam,
  });

  const handleError = useCallback(
    error => {
      if (error) {
        navigate(`/errors/error-${error.status}`);
      }
    },
    [navigate],
  );

  //Получение параметров
  useEffect(() => {
    let ignore = false;
    let params = {};
    pageUseParams.queryId && (params.query = Number(pageUseParams.queryId));
    pageUseParams.projectId && (params.project = Number(pageUseParams.projectId));
    async function getParamsData() {
      const response = await getIssuesParams(params);
      if (!ignore) {
        return response;
      }
    }

    getParamsData()
      .then(res => {
        const { data: paramsData } = res;
        const params = formatFilters(paramsData);
        !initialParams && setInitialParams(params);
        if (Object.values(query).filter(item => item !== undefined).length === 0) {
          setCurrentParams(params);
          setQuery(paramsToQuery(params));
        }
      })
      .catch(error => handleError(error.response));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleError, pageUseParams.queryId, pageUseParams.projectId]);

  //Получение query по id
  useEffect(() => {
    setQueryData(null);
    let ignore = false;
    async function getSavedQueries() {
      const { data: response } = await getSavedQuery({ id: pageUseParams.queryId });
      if (!ignore) {
        return response;
      }
    }
    if (pageUseParams.queryId) {
      getSavedQueries()
        .then(res => {
          res && setQueryData(res);
        })
        .catch(error => handleError(error.response));
    }
  }, [handleError, pageUseParams.queryId]);

  useEffect(() => {
    let ignore = false;
    setParamsLoading(true);
    let params = {};
    let queryIsSet = false;
    queryIsSet = Object.values(query).filter(item => item !== undefined).length > 0;
    pageUseParams.projectId && (params.project = Number(pageUseParams.projectId));
    queryIsSet && (params = { ...params, ...query });
    async function getIssuesData() {
      const response = await getIssues(params);
      if (!ignore) {
        return response;
      }
    }
    if (queryIsSet) {
      getIssuesData()
        .then(res => {
          const { data: issuesData } = res;
          issuesData.fields && setGridColumns(issuesData.fields);
          issuesData.rows && setGridRows(issuesData.rows);
          issuesData.paging && setPagination(issuesData.paging);
          const totalRow = issuesData.rows.find(item => item.rowType === 'total');
          totalRow !== undefined && totalRow.columns && setTotals(totalRow.columns);
          setLoading(false);
          setParamsLoading(false);
        })
        .catch(error => handleError(error.response));
    }
    return () => {
      ignore = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleError, query, pageUseParams.projectId]);

  useEffect(() => {
    let ignore = false;
    async function getCanCreate() {
      let params = {};
      pageUseParams.projectId && (params.project = pageUseParams.projectId);
      const { data: response } = await issueCanCreate(params);
      if (!ignore) {
        return response;
      }
    }

    getCanCreate()
      .then(res => {
        setCanCreate(res);
      })
      .catch(error => handleError(error.response));

    return () => {
      ignore = true;
    };
  }, [pageUseParams.projectId, handleError]);

  useEffect(() => {
    if (Object.values(query).filter(item => item !== undefined).length > 0 && initialParams && !currentParams) {
      setCurrentParams(queryToParams(query, initialParams));
    }
  }, [currentParams, initialParams, query]);
  useEffect(() => {
    if (currentParams && currentParams.sorts && currentParams.sorts.selectedItems) {
      setSortModel(currentParams.sorts.selectedItems.map(item => ({ field: item.fieldId, sort: item.direction })));
    }
  }, [currentParams]);

  const handleSelectFilters = useCallback(
    data => {
      const result = selectFilters(data, currentParams);
      setCurrentParams(result);
      setQuery(paramsToQuery(result));
    },
    [currentParams, setQuery],
  );

  const handleParamsModalSave = useCallback(
    data => {
      setCurrentParams(data);
      setQuery(paramsToQuery(data));
      setOpenParamsModal(false);
    },
    [setQuery],
  );

  const handleToggleDelete = useCallback(
    async e => {
      switch (e.currentTarget.attributes.value.value) {
        case 'save':
          await deleteSavedQuery({ id: pageUseParams.queryId });
          navigate('/issues');
          setOpenWarningModal(false);
          break;
        case 'delete':
          setOpenWarningModal(false);
          break;
      }
    },
    [pageUseParams.queryId, navigate],
  );

  const goCreateTask = useCallback(() => {
    let url = '/issues/create';
    let project = pageUseParams.projectId;
    if (project) {
      url = `/projects/${pageUseParams.projectId}/issues/create?projectId=${pageUseParams.projectId}`;
    }
    window.open(url);
  }, [pageUseParams.projectId]);

  const [contextMenuData, setContextMenuData] = useState({
    isSecondStepVisible: false,
    secondPopoverStepValue: null,
    data: [],
    currentRow: {
      services: {
        isApproved: '',
        isOvertime: false,
        canApprove: false,
        canEdit: false,
        isProjectClosed: false,
      },
    },
  });
  const downloadCsv = useCallback(
    e => {
      async function downloadIssuesCsv() {
        try {
          setDownloadLoading(true);
          let query = searchParams.toString();
          query = query.replaceAll(/ThisWeek|LastWeek|LastTwoWeeks|ThisMonth|LastMonth|ThisYear/gi, 'btw');
          query = query.replaceAll('Today', 'eq');
          if (query?.length > 0) {
            const { data } = await getIssuesCsv(query);
            const url = window.URL.createObjectURL(new Blob(['\ufeff', data], { type: 'text/csv;charset=utf-8;' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `issues-${dayjs().format('YYYY-MM-DD')}`);
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            link.remove();
          }
        } catch (e) {
          console.error(e);
        } finally {
          setDownloadLoading(false);
        }
      }
      downloadIssuesCsv();
    },
    [searchParams, setDownloadLoading],
  );

  const handleSaveQueryModalSave = useCallback(
    async modalData => {
      const value = formatQueryToSave(modalData, currentParams, 'issue');
      try {
        setSaveLoader(true);
        let response;
        if (pageUseParams.queryId) {
          response = await editQuery(pageUseParams.queryId, value);
        } else {
          response = await saveQuery(value);
        }
        if (response) {
          setSaveLoader(false);
          const { data: res } = response;
          if (pageUseParams.projectId) {
            window.open(`/projects/${pageUseParams.projectId}/issues/query/${res.id}`, '_self');
          } else {
            window.open(`/issues/query/${res.id}`, '_self');
          }
        }
      } catch (error) {
        handleError(error.response);
      }
      setOpenSaveQueryModal(false);
    },
    [currentParams, handleError, pageUseParams.queryId, pageUseParams.projectId],
  );

  const handleParamsModalClose = useCallback(() => {
    setOpenParamsModal(false);
  }, []);

  const handleSaveQueryModalClose = useCallback(() => {
    setOpenSaveQueryModal(false);
  }, []);

  const handleWarningModalClose = useCallback(() => {
    setOpenWarningModal(false);
  }, []);

  const handleChangePagination = useCallback(
    data => {
      setQuery(prev => ({ ...prev, offset: data * 100 }));
    },
    [setQuery],
  );

  const handleChangeSorting = useCallback(
    newSortModel => {
      if (!newSortModel || newSortModel.length === 0) {
        setQuery(prev => ({ ...prev, sorts: undefined }));
        return;
      }

      const sortString = newSortModel.map(item => `${item.field};${item.sort}`).join('$$');

      setQuery(prev => ({ ...prev, sorts: sortString }));
    },
    [setQuery],
  );

  const handleButtonEvent = useCallback(
    type => {
      switch (type) {
        case ButtonsTypes.ADD:
          goCreateTask();
          break;
        case ButtonsTypes.PARAMS:
          setOpenParamsModal(true);
          break;
        case ButtonsTypes.QUERY:
          setOpenSaveQueryModal(true);
          break;
        case ButtonsTypes.EDIT_QUERY:
          setOpenSaveQueryModal(true);
          break;
        case ButtonsTypes.DELETE_QUERY:
          setOpenWarningModal(true);
          break;
      }
    },
    [goCreateTask],
  );

  return (
    <>
      <WarningModal
        simple
        isWarningModalOpen={openWarningModal}
        handleToggle={handleToggleDelete}
        closeWarningModal={handleWarningModalClose}
        title={`${intl.formatMessage({ id: 'spent_time_delete' })}?`}
      />
      {currentParams && (
        <GridFiltersModalNew
          handleSave={data => handleParamsModalSave(data)}
          handleClose={handleParamsModalClose}
          open={openParamsModal}
          data={currentParams}
        />
      )}
      <GridSaveQueryModal
        saveLoader={saveLoader}
        permission={'canSaveIssuesGlobalQueries'}
        queryData={queryData}
        open={openSaveQueryModal}
        handleSave={data => handleSaveQueryModalSave(data)}
        handleClose={handleSaveQueryModalClose}
      />
      <CommonPage
        pageTitle={pageTitle}
        loading={loading}
        canCreate={canCreate}
        buttonEvent={handleButtonEvent}
        queryName={queryData?.name}
      >
        {currentParams && currentParams.filters?.selectedItems?.length > 0 && (
          <GridFiltersPanelNew
            data={currentParams.filters.selectedItems}
            handleCloseFilter={handleSelectFilters}
          ></GridFiltersPanelNew>
        )}
        {totals.length > 0 && <GridTotals data={totals}></GridTotals>}
        {gridColumns && gridRows && (
          <Grid overflow={'hidden'} container flexDirection={'column'} wrap="nowrap" position={'relative'}>
            {paramsLoading && (
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                width="100%"
                height="100%"
                zIndex={99}
                position={'absolute'}
              >
                <CircularProgress color="secondary" />
              </Grid>
            )}
            <IssuesTable
              intl={intl}
              contextMenuData={contextMenuData}
              setContextMenuData={setContextMenuData}
              issuesColumnsData={gridColumns}
              issuesRowsData={gridRows}
              changePagination={handleChangePagination}
              paginationData={pagination}
              changeSorting={handleChangeSorting}
            />
          </Grid>
        )}
      </CommonPage>
    </>
  );

  /*  return (
    <>
      {loading ? (
        <Grid container alignItems="center" justifyContent="center" width="100%" height="70px">
          <CircularProgress color="secondary" />
        </Grid>
      ) : (
        <>
          {/!*          <WarningModal
            simple
            isWarningModalOpen={isDeleteConfirmOpen}
            handleToggle={handleToggleDelete}
            closeWarningModal={closeWarningModal}
            title={`${intl.formatMessage({ id: 'spent_time_delete' })}?`}
          />*!/}
          {currentParams && (
            <GridFiltersModalNew
              issues={true}
              handleSave={data => handleParamsModalSave(data)}
              //handleClear={data => handleParamsModalClear(data)}
              handleClose={handleParamsModalClose}
              open={openParamsModal}
              data={currentParams}
              initialParams={initialParams}
            />
          )}
          <GridSaveQueryModal
            queryData={queryData}
            open={openSaveQueryModal}
            handleSave={data => handleSaveQueryModalSave(data)}
            handleClose={handleSaveQueryModalClose}
          />
          <Page
            title={pageTitle}
            width="100%"
            sx={{ height: 'calc(100vh - 80px)', display: 'flex', flexDirection: 'column', paddingBottom: '20px' }}
          >
            <Grid container justifyContent={'space-between'} alignItems={'center'} marginBottom={'20px'}>
              <Grid item>
                <Grid container alignItems={'center'}>
                  <Grid item>
                    {/!*                    <Typography variant="h1" marginRight={locationState?.name ? 2.5 : 0}>
                      {pageTitle}
                    </Typography>*!/}
                  </Grid>
                  {queryData && queryData.name && (
                    <Grid item>
                      <Typography variant="h2" color={'#082253'} marginLeft={'12px'} fontWeight={'400'}>
                        {queryData.name}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item>
                <Grid container alignItems={'center'}>
                  <Box marginRight={2.5}>
                    <Button variant="defaultBlueSecondary" disableRipple onClick={() => goCreateTask()}>
                      <Typography variant="h4" fontWeight={600}>
                        <FormattedMessage id="issues_add" />
                      </Typography>
                    </Button>
                  </Box>
                  {!queryData && (
                    <Box marginRight={2.5}>
                      <Button variant="defaultGreyPrimary" disableRipple onClick={handleSaveQueryModalOpen}>
                        <Typography variant="h4" fontWeight={600}>
                          <FormattedMessage id="spent_time_save" />
                        </Typography>
                      </Button>
                    </Box>
                  )}
                  {/!*                  {((queryData && currentUser.isAdmin) || locationState?.userId === currentUser.id) && (
                    <>
                      <Box marginRight={2.5}>
                        <Button variant="defaultGreyPrimary" disableRipple onClick={handleSaveQueryModalOpen}>
                          <Typography variant="h4" fontWeight={600}>
                            <FormattedMessage id="spent_time_edit" />
                          </Typography>
                        </Button>
                      </Box>
                      <Box marginRight={2.5}>
                        <Button variant="defaultGreyPrimary" disableRipple onClick={handleOpenWarningModal}>
                          <Typography variant="h4" fontWeight={600}>
                            <FormattedMessage id="spent_time_delete" />
                          </Typography>
                        </Button>
                      </Box>
                    </>
                  )}*!/}
                  <Box marginRight={2.5}>
                    <Button variant="defaultGreyPrimaryWithIcon" disableRipple onClick={handleParamsModalOpen}>
                      <Box display="flex" alignItems="center" marginRight={'4px'}>
                        <FilterIcon width="20" height="20" viewBox="0 0 20 20" />
                      </Box>
                      <Typography variant="h4" fontWeight={600}>
                        <FormattedMessage id="parameters_text" />
                      </Typography>
                    </Button>
                  </Box>
                  <Box marginLeft={'auto'}>
                    {!downloadLoading ? (
                      <>
                        <IconButton onClick={e => downloadCsv(e)} disableRipple>
                          <DownloadButton width="24" height="24" viewBox="0 0 24 24" />
                        </IconButton>
                      </>
                    ) : (
                      <>
                        <CircularProgress size={18} color="secondary" />
                      </>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            {currentParams && currentParams.filters?.selectedItems?.length > 0 && (
              <GridFiltersPanelNew
                data={currentParams.filters.selectedItems}
                handleCloseFilter={handleSelectFilters}
              ></GridFiltersPanelNew>
            )}
            {totals.length > 0 && (
              <Grid container padding={'0 16px'}>
                {totals.map((item, index) => {
                  const stringExists =
                    !!intl.messages[
                      `filters_select_${item.fieldName
                        .replace(/([a-z])([A-Z])/g, '$1_$2')
                        .replace(' ', '_')
                        .toLowerCase()}`
                    ];
                  return (
                    <Grid key={index} item marginLeft={index > 0 ? '10px' : ''}>
                      <Typography variant="h5" color={'#41424E'}>
                        <Box component={'span'} marginRight={'8px'}>
                          {stringExists
                            ? intl.formatMessage({
                                id: `filters_select_${item.fieldName
                                  .replace(/([a-z])([A-Z])/g, '$1_$2')
                                  .replace(' ', '_')
                                  .toLowerCase()}`,
                              })
                            : item.fieldName}
                        </Box>
                        <Box component={'span'} marginRight={'8px'} fontWeight={700}>
                          {item.value}
                        </Box>
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            )}
            {gridColumns && gridRows && (
              <Grid overflow={'hidden'} container flexDirection={'column'} wrap="nowrap" position={'relative'}>
                {paramsLoading && (
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    width="100%"
                    height="100%"
                    zIndex={99}
                    position={'absolute'}
                  >
                    <CircularProgress color="secondary" />
                  </Grid>
                )}
                <IssuesTable
                  intl={intl}
                  sorting={sortModel}
                  //query={query}
                  //setQuery={setQuery}
                  contextMenuData={contextMenuData}
                  setContextMenuData={setContextMenuData}
                  issuesColumnsData={gridColumns}
                  issuesRowsData={gridRows}
                  changePagination={handleChangePagination}
                  paginationData={pagination}
                  //changeSorting={handleChangeSorting}
                />
              </Grid>
            )}
          </Page>
        </>
      )}
    </>
  );*/
};

export default injectIntl(IssuesContainer);
