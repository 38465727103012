import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  PaginationItem,
  Popper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

import issuePageStyles from '../../issuePage/IssuePage.styles';
import useStyles from '../../resourcePlan/ResourcePlanHeader/ResourcePlanHeader.styles';
import { FormattedMessage, useIntl } from 'react-intl';
import { ArrayParam, NumberParam, StringParam, useQueryParams } from 'use-query-params';
import { getCookie } from 'Common/utils/cookies';
import { getTranslatedText } from '../../../../packages/common/utils/getTranslatedText';
import dayjs from 'dayjs';
import {
  getGantCsv,
  getArtFilters,
  createNewSavedQuery,
  getSavedQuery,
  getProjectsFilters,
} from '../../../../packages/common/api';
import { useNavigate } from 'react-router';
import {
  DataGridPro,
  GRID_TREE_DATA_GROUPING_FIELD,
  gridFilteredDescendantCountLookupSelector,
  useGridApiRef,
  useGridSelector,
  gridFilteredSortedRowIdsSelector,
  GridCellEditStopReasons,
  GridRowId,
  GridRowTreeNodeConfig,
  GridPagination,
} from '@mui/x-data-grid-pro';
import dataGridTableStyles from '../../../../packages/common/shared-ui/src/styles/DataGridTable.styles';
import GridFiltersPanel from '../../GridFiltersPanel/GridFiltersPanel';
import { filtersToQuery } from '../../../../packages/common/utils/gridFiltersHelper';
import clsx from 'clsx';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ChevronIcon from '../../../../packages/common/shared-ui/src/icons/ChevronIcon';
import issueFormFieldStyles from '../../issueCreateEdit/components/IssueFormField.styles';
import artContainerStyles from './ArtContainer.styles';
import UncheckedRadioIcon from '../../../../packages/common/shared-ui/src/icons/UncheckedRadioIcon';
import CheckedRadioIcon from '../../../../packages/common/shared-ui/src/icons/CheckedRadioIcon';
import { useWebSockets } from '../../resourcePlan/useWebsockets';
import DownloadButton from '../../../../packages/common/shared-ui/src/icons/DownloadButton';
import { store } from '../../app/redux';
import CheckboxIcon from '../../../../packages/common/shared-ui/src/icons/CheckboxIcon';
import CheckboxIconChecked from '../../../../packages/common/shared-ui/src/icons/CheckboxIconChecked';
import SortIcon from '../../../../packages/common/shared-ui/src/icons/SortIcon';
import MuiPagination from '@mui/material/Pagination';
import PaginationNextButtonIcon from '../../../../packages/common/shared-ui/src/icons/PaginationNextButtonIcon';
import PaginationPreviousButtonIcon from '../../../../packages/common/shared-ui/src/icons/PaginationPreviousButtonIcon';
import { generateRedirectUrl } from '../../../../packages/common/utils/getRedirectUrl';
import FilterIcon from '../../../../packages/common/shared-ui/src/icons/FilterIcon';
import CommonPage from '../../CommonPage/CommonPage';
import { ButtonsTypes } from '../../../../packages/common/shared-ui/src/enums/commonEnums';
import {
  filterDateValues,
  formattedParamsData,
  paramsToQuery,
  queryToParams,
} from '../../../../packages/common/utils/gridFiltersHelperNew';
import { useParams } from 'react-router-dom';
import GridFiltersModalNew from '../../GridFiltersModalNew/GridFiltersModalNew';
import GridSaveQueryModal from '../../GridSaveQueryModal/GridSaveQueryModal';
import GridFiltersPanelNew from '../../GridFiltersPanelNew/GridFiltersPanelNew';

function sleep(delay = 0) {
  return new Promise(resolve => {
    setTimeout(resolve, delay);
  });
}
const ArtContainer = () => {
  const issueCreateEditClasses = issueFormFieldStyles();
  const issueCommonStyles = issuePageStyles();
  const expansionState = React.useRef({});
  const [pagination, setPagination] = useState({ count: 0, page: 0 });
  const classes = useStyles();
  const artStyle = artContainerStyles();
  const dataGridTableStyle = dataGridTableStyles();
  const [rowsToUpdate, setRowsToUpdate] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [firstLoadGroup, setFirstLoadGroup] = useState(true);
  const [open, setOpen] = useState(false);
  const [paramsLoading, setParamsLoading] = useState(true);
  const [openGantIssueModal, setOpenGantIssueModal] = useState(false);
  const [openGantIssueRelatedModal, setOpenGantIssueRelatedModal] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [queryData, setQueryData] = useState(null);
  const pageUseParams = useParams();
  const [currentParams, setCurrentParams] = useState(null);
  const [initialParams, setInitialParams] = useState(null);
  const [query, setQuery] = useQueryParams({
    filters: StringParam,
    project: StringParam,
    totals: StringParam,
    sorts: StringParam,
    fields: StringParam,
    limit: 100,
    offset: NumberParam,
    page: NumberParam,
  });
  const formatDate = useCallback(dateStr => {
    if (dateStr) {
      return dayjs(dateStr).format('DD.MM.YYYY');
    }
    return dateStr;
  }, []);
  const intl = useIntl();
  const apiRef = useGridApiRef();
  const dataGridProRef = useRef(null);
  const accessToken = getCookie('access_token');
  const pageTitle = intl.formatMessage({ id: 'finance_view_page_title' });
  const [showSavingError, setShowSavingError] = useState({ state: false, errors: [] });
  const [gantErrorValue, setGanttErrorValue] = useState('save_all');
  const [ganttSavingLoader, setGanttSavingLoader] = useState(false);
  const [disableSaveButton, setDisableSaveButton] = useState(true);
  const [artRowsErrors, setArtRowsErrors] = useState([]);
  const [ganttStartDateErrors, setGanttStartDateErrors] = useState(false);
  const [ganttDateErrors, setGanttDateErrors] = useState(null);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [tempCellValue, setTempCellValue] = useState([]);
  const socket = useWebSockets();
  const state = store.getState();
  const [artTableData, setArtTableData] = useState([]);
  const [artTableRowsData, setArtTableRowsData] = useState([]);
  const [artTableColumnsData, setArtTableColumnsData] = useState([]);
  const [openParamsModal, setOpenParamsModal] = useState(false);
  const [openSaveQueryModal, setOpenSaveQueryModal] = useState(false);
  const isCellEditable = params => {
    return !!params.row.columnsEditable?.[params.field]?.isEditable;
  };
  const handleSort = useCallback(
    sortModel => {
      const newSorts = sortModel.map(item => `${item.field};${item.sort}`).join(',');
      setQuery(prevQuery => ({ ...prevQuery, sorts: newSorts }));
      setIsLoading(true);
    },
    [setQuery],
  );
  const sortModel = useMemo(() => {
    if (!query.sorts) {
      return [];
    }
    return query.sorts.split(',').map(sort => {
      const [field, sortDirection] = sort.split(';');
      return { field, sort: sortDirection };
    });
  }, [query.sorts]);
  const handleGetRedirectUrl = useCallback(
    ({ id, columnType }) => {
      const issueLink = query.project ? `/projects/${query.project}/issues/${id}` : `/issues/${id}`;
      switch (columnType) {
        case 'user':
          return generateRedirectUrl({ host: window.location.origin, path: `/users/${id}` });
        case 'project':
          return `${window.location.origin}/projects/${id}`;
        case 'issue':
        case 'issue.subject':
        case 'id':
          return issueLink;
        default:
          return '';
      }
    },
    [query.project],
  );
  const handleParamsModalSave = useCallback(
    data => {
      setCurrentParams(data);
      setQuery(paramsToQuery(data));
      setOpenParamsModal(false);
      setIsLoading(true);
    },
    [setQuery],
  );
  const navigate = useNavigate();
  const handleError = useCallback(
    error => {
      if (error) {
        navigate(`/errors/error-${error.status}`);
      }
    },
    [navigate],
  );
  const handleSaveQueryModalSave = useCallback(
    async modalData => {
      let query = {
        filters: [],
        sorts: [],
        totals: [],
      };
      if (currentParams.filters && currentParams.filters.selectedItems) {
        const validFilters = currentParams.filters.selectedItems.filter(item => item.operation).length > 0;
        if (validFilters) {
          query.filters = currentParams.filters.selectedItems.map(filter => {
            let value = filter.values;
            Array.isArray(filter.values) && (value = filter.values.map(item => (item.valueId ? item.valueId : item)));
            return {
              instance: filter.instance,
              operation: filterDateValues.has(filter.operation)
                ? filterDateValues.get(filter.operation).operator
                : filter.operation,
              field: filter.field,
              values: value,
            };
          });
        }
      }
      if (currentParams.sorts && currentParams.sorts.selectedItems) {
        query.sorts = currentParams.sorts.selectedItems.map(item => {
          return {
            instance: item.instance,
            field: item.field,
            direction: item.direction,
          };
        });
      }
      if (currentParams.fields && currentParams.fields.selectedItems.selectedFields) {
        query.fields = currentParams.fields.selectedItems.selectedFields.map((item, index) => {
          return {
            instance: item.instance,
            field: item.field,
            position: index,
          };
        });
      }
      setOpenSaveQueryModal(false);
    },
    [currentParams],
  );
  useEffect(() => {
    let ignore = false;

    async function loadParams() {
      try {
        const { data: artFiltersData } = await getArtFilters();
        const data = {};
        artFiltersData.filters && (data.filters = { items: artFiltersData.filters, type: 'list', multiple: true });
        artFiltersData.sorts && (data.sorts = { items: artFiltersData.sorts, type: 'checkbox' });
        artFiltersData.fields && (data.fields = { items: artFiltersData.fields, type: 'columns' });

        const formattedData = formattedParamsData(data);
        setInitialParams(formattedData);
        if (Object.values(query).filter(item => item !== undefined).length > 0) {
          setCurrentParams(queryToParams(query, formattedData));
        } else {
          setCurrentParams(formattedData);
          setQuery(paramsToQuery(formattedData));
        }
      } catch (error) {
        handleError(error.response);
      } finally {
        if (!ignore) {
          setParamsLoading(false);
        }
      }
    }
    // loadParams();
    return () => {
      ignore = true;
    };
  }, [handleError, pageUseParams.queryId, setQuery, query]);
  useEffect(() => {
    let ignore = false;
    async function artFiltersData() {
      const response = await getArtFilters();
      if (!ignore) {
        return response;
      }
    }

    artFiltersData()
      .then(res => {
        const { data: filtersData } = res;
        const data = {};
        filtersData.filters && (data.filters = { items: filtersData.filters, type: 'list', multiple: true });
        filtersData.sorts && (data.sorts = { items: filtersData.sorts, type: 'checkbox' });
        filtersData.fields && (data.fields = { items: filtersData.fields, type: 'columns' });
        const formattedData = formattedParamsData(data);
        !initialParams && setInitialParams(formattedData);
        if (Object.values(query).filter(item => item !== undefined).length === 0) {
          setCurrentParams(formattedData);
          setQuery(paramsToQuery(formattedData));
        }
      })
      .catch(error => handleError(error.response));
    return () => {
      ignore = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleError, pageUseParams.queryId]);
  useEffect(() => {
    if (socket) {
      if (isLoading) {
        socket.emit('getArtTable', {
          token: accessToken,
          query: query,
        });
        setPagination(prev => ({
          ...prev,
          page: query.page ? query.page - 1 : 0,
        }));
      }
    }
  }, [accessToken, socket, isLoading, query]);
  const changePagination = useCallback(
    data => {
      setQuery(prev => ({ ...prev, page: data, offset: data * 500 }));
      setIsLoading(true);
    },
    [setQuery, setIsLoading],
  );
  const cellRenderer = useCallback(
    ({ row, column }) => {
      let fieldData = row[column.fieldId];
      if (fieldData && column.fieldType === 'date' && typeof fieldData === 'string') {
        fieldData = formatDate(fieldData);
      }
      if (
        column.fieldType === 'monthly_distribution' ||
        column.fieldType === 'loading_percent' ||
        column.fieldType === 'cost_fact' ||
        column.fieldType === 'labor_fact' ||
        column.fieldType === 'date'
      ) {
        return (
          <Box align="left">
            <Tooltip
              title={getTranslatedText(intl, 'filter', column.fieldId.split('-').join('_'), column.fieldName)}
              PopperProps={{ className: classes.commentTooltip }}
            >
              <Typography
                textOverflow="ellipsis"
                overflow="hidden"
                whiteSpace="nowrap"
                variant="h5"
                className={clsx(classes.filedValue, {
                  [artStyle.filedValueRed]: row.columnsEditable[column.fieldId].isManual,
                })}
              >
                {fieldData}
              </Typography>
            </Tooltip>
          </Box>
        );
      } else if (column.fieldType === 'issueId') {
        return (
          <Box align="left">
            <Tooltip
              title={getTranslatedText(intl, 'filter', column.fieldId.split('-').join('_'), column.fieldName)}
              PopperProps={{ className: classes.commentTooltip }}
            >
              <a
                href={handleGetRedirectUrl({
                  id: row.services.issueId,
                  columnType: 'issue',
                })}
                className={classes.tableCellLink}
                target="_blank"
              >
                <Typography textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" variant="h5">
                  {row[column.fieldId]}
                </Typography>
              </a>
            </Tooltip>
          </Box>
        );
      } else if (column.fieldType === 'issueId') {
        return (
          <Box align="left" overflow={'hidden'}>
            {row.services.isNew || row.kind === 'project' ? (
              ''
            ) : (
              <Tooltip
                title={getTranslatedText(intl, 'filter', column.fieldId.split('-').join('_'), column.fieldName)}
                PopperProps={{ className: classes.commentTooltip }}
              >
                <Typography textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" variant="h5">
                  {fieldData}
                </Typography>
              </Tooltip>
            )}
          </Box>
        );
      } else {
        return (
          <Box align="left" overflow={'hidden'}>
            <Tooltip
              title={getTranslatedText(intl, 'filter', column.fieldId.split('-').join('_'), column.fieldName)}
              PopperProps={{ className: classes.commentTooltip }}
            >
              <Typography textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" variant="h5">
                {fieldData}
              </Typography>
            </Tooltip>
          </Box>
        );
      }
    },
    [
      classes.commentTooltip,
      classes.filedValue,
      classes.tableCellLink,
      artStyle.filedValueRed,
      formatDate,
      intl,
      handleGetRedirectUrl,
    ],
  );

  const handleField = useCallback(
    (fieldValue, props, column) => {
      setDisableSaveButton(true);
      let newValue = fieldValue;
      newValue = fieldValue && fieldValue.$d ? dayjs(fieldValue.$d).format('YYYY-MM-DD') : null;
      socket.emit('updateArtCell', {
        rowId: props.id,
        fieldId: props.field,
        valueId: newValue,
        token: accessToken,
      });

      props.api.setEditCellValue({
        id: props.id,
        field: props.field,
        value: newValue,
      });
    },
    [socket, accessToken],
  );

  const formattedArtColumns = useCallback(
    columns => {
      const issueIdField = {
        instance: 'issue',
        field: 'id',
        fieldId: 'issue.gantt_id',
        fieldName: 'Id',
        fieldType: 'issueId',
        isChecked: true,
        isCustom: false,
        position: 0,
      };
      let newColumns = [issueIdField].concat(columns);
      newColumns = newColumns.map(column => {
        let type = '';
        let editable = true;
        const numberFields = ['float', 'int', 'percent'];
        return {
          field: column.fieldId,
          headerName: getTranslatedText(intl, 'filter', column.fieldId.split('-').join('_'), column.fieldName),
          renderHeader: props => (
            <Typography variant="h4" fontWeight={700}>
              {props.colDef.headerName}
            </Typography>
          ),
          renderCell: props => {
            const row = props.row;
            return cellRenderer({ row, column });
          },
          disableExport: false,
          type: type,
          position: column.position,
          sortable: true,
          editable: editable,
          renderEditCell: props => {
            if (column.fieldType === 'date') {
              return (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    className={clsx(dataGridTableStyle.datePicker, {
                      ['changed']: props.row.changed,
                    })}
                    format={'DD.MM.YYYY'}
                    value={props.value ? props.value : props.row[column.fieldId]}
                    renderInput={params => <TextField {...params} placeholder={intl.formatMessage({ id: 'enter' })} />}
                    autoFocus={true}
                    onChange={value => handleField(value, props, column)}
                    components={{
                      LeftArrowIcon: () => <ChevronIcon direction="left" viewBox="0 0 24 24" width={24} height={24} />,
                      RightArrowIcon: () => (
                        <ChevronIcon direction="right" viewBox="0 0 24 24" width={24} height={24} />
                      ),
                      RightArrowButton: componentProps => (
                        <IconButton disableRipple {...componentProps}>
                          {componentProps.children}
                        </IconButton>
                      ),
                      LeftArrowButton: componentProps => (
                        <IconButton disableRipple {...componentProps}>
                          {componentProps.children}
                        </IconButton>
                      ),
                    }}
                  />
                </LocalizationProvider>
              );
            }
          },
          valueFormatter: params => {
            if (column.fieldId === 'issue.start' || column.fieldId === 'issue.end') {
              if (dayjs(params.value, 'DD.MM.YYYY', true).isValid()) {
                return params.value;
              } else {
                return dayjs(params.value).format('DD.MM.YYYY');
              }
            } else {
              return params.value;
            }
          },
          cellClassName: 'tableCell',
        };
      });
      return newColumns.toSpliced(1, 0, {
        field: GRID_TREE_DATA_GROUPING_FIELD,
      });
    },
    [intl, cellRenderer, dataGridTableStyle.datePicker, handleField],
  );
  const formattedArtRow = useCallback(
    row => {
      if (row.rowType === 'normal') {
        const newCols = row.columns.reduce((columnAcc, _, i) => {
          return {
            ...columnAcc,
            [row.columns[i].fieldId]: row.columns[i].value,
          };
        }, {});
        const columnsEditable = row.columns.reduce((columnAcc, _, i) => {
          return {
            ...columnAcc,
            [row.columns[i].fieldId]: {
              isEditable: row.columns[i].isEditable,
              isManual: row.columns[i].isManual,
            },
          };
        }, {});
        return [
          {
            id: row.rowId,
            kind: row.type,
            project: Number(row.services.projectId),
            ['issue.gantt_id']: '#' + row.rowId,
            ...newCols,
            columnsEditable: columnsEditable,
            services: row.services,
            editable: row.isEditable,
            rowName: row.rowName,
            rowId: row.rowId,
            type: row.type,
            signatures: row.signatures,
          },
        ];
      }
      return [];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const formattedArtRows = useCallback(
    rows =>
      rows.reduce((acc, issue) => {
        if (issue.rowType === 'normal') {
          const newCols = issue.columns.reduce((columnAcc, _, i) => {
            return {
              ...columnAcc,
              [issue.columns[i].fieldId]: issue.columns[i].value,
            };
          }, {});
          const columnsEditable = issue.columns.reduce((columnAcc, _, i) => {
            return {
              ...columnAcc,
              [issue.columns[i].fieldId]: {
                isEditable: issue.columns[i].isEditable,
                isManual: issue.columns[i].isManual,
              },
            };
          }, {});
          return [
            ...acc,
            {
              id: issue.rowId,
              kind: issue.type,
              project: Number(issue.services.projectId),
              ['issue.gantt_id']: '#' + issue.rowId,
              ...newCols,
              columnsEditable: columnsEditable,
              services: issue.services,
              editable: issue.isEditable,
              rowName: issue.rowName,
              rowId: issue.rowId,
              type: issue.type,
              signatures: issue.signatures,
            },
          ];
        }
        return [];
      }, []),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  useEffect(() => {
    setQueryData(null);
    let ignore = false;
    async function getSavedQueries() {
      const { data: response } = await getSavedQuery({ id: pageUseParams.queryId });
      if (!ignore) {
        return response;
      }
    }
    if (pageUseParams.queryId) {
      getSavedQueries()
        .then(res => {
          res && setQueryData(res);
        })
        .catch(error => handleError(error.response));
    }
  }, [handleError, pageUseParams.queryId]);

  useEffect(() => {
    if (socket) {
      let tempData = {};
      let tempArtData = {};
      socket.on('getArtTable', data => {
        console.log('getArtTable', data);
      });
      socket.on('artStartGetTable', data => {
        console.log('artStartGetTable', data);
      });
      socket.on('artFieldsTable', data => {
        console.log('artFieldsTable', data);
        tempArtData.artColumns = data;
        setArtTableColumnsData(formattedArtColumns(tempArtData.artColumns));
      });
      socket.on('artRowsTable', data => {
        console.log('artRowsTable', data);
        tempArtData.artData = data;
        setArtTableData(data);
        setArtTableRowsData(formattedArtRows(tempArtData.artData.rows));
      });

      socket.on('artRowsNotSaved', data => {
        console.log('artRowsNotSaved', data);
      });
      socket.on('artEndGetTable', data => {
        console.log('artEndGetTable', data);
        console.log(tempArtData.artData);

        setIsLoading(false);
      });
      socket.on('artStartUpdateCell', data => {
        console.log('artStartUpdateCell', data);
      });
      socket.on('artEndUpdateCell', data => {
        console.log('artEndUpdateCell', data);
      });
      socket.on('artRefreshRowsTable', data => {
        console.log('artRefreshRowsTable', data);
        setDisableSaveButton(false);
        let updatedRowData = formattedArtRow(data);
        apiRef.current.updateRows(updatedRowData);
        setDisableSaveButton(false);
      });
      socket.on('rowNotFound', data => {
        console.log('rowNotFound', data);
      });
      socket.on('cannotEditRow', data => {
        console.log('cannotEditRow', data);
      });
      socket.on('noRowsForUpdate', data => {
        console.log('noRowsForUpdate', data);
      });
      socket.on('artStartConflictCheck', data => {
        console.log('artStartConflictCheck', data);
      });
      socket.on('artSomeConflictRows', data => {
        console.log('artSomeConflictRows', data);
        setArtRowsErrors(data);
      });
      socket.on('artNoConflictRows', data => {
        console.log('artNoConflictRows', data);
        socket.emit('saveArtRows', {
          token: accessToken,
        });
      });
      socket.on('artEndConflictCheck', data => {
        console.log('artEndConflictCheck', data);
      });
      socket.on('artStartRowsSave', data => {
        console.log('artStartRowsSave', data);
      });
      socket.on('artEndRowsSave', data => {
        console.log('artEndRowsSave', data);
      });
      socket.on('artRowsSaved', data => {
        console.log('artRowsSaved', data);
        setIsLoading(true);
      });
    }
    return () => {
      if (socket) {
        socket.off('connect');
        socket.off('artEndGetTable');
        socket.off('getArtTable');
        socket.off('artRowsTable');
        socket.off('artFieldsTable');
        socket.off('artRowsNotSaved');
        socket.off('artStartUpdateCell');
        socket.off('artEndUpdateCell');
        socket.off('artRefreshRowsTable');
        socket.off('rowNotFound');
        socket.off('cannotEditRow');
        socket.off('noRowsForUpdate');
        socket.off('artSomeConflictRows');
        socket.off('artNoConflictRows');
        socket.off('artEndConflictCheck');
        socket.off('artStartConflictCheck');
        socket.off('artStartRowsSave');
        socket.off('artEndRowsSave');
        socket.off('artRowsSaved');
      }
    };
  }, [
    formattedArtColumns,
    formattedArtRow,
    formattedArtRows,
    rowsToUpdate,
    tempCellValue,
    socket,
    apiRef,
    setArtRowsErrors,
    handleError,
    accessToken,
  ]);

  const handleParamsModalClose = useCallback(() => {
    setOpenParamsModal(false);
  }, []);

  useEffect(() => {
    if (artTableData && artTableData.rows?.length > 0) {
      artTableData.paging.total !== undefined &&
        setPagination(prev => ({ ...prev, count: Number(artTableData.paging.total) }));
    }
  }, [artTableData]);
  function Pagination({ page, onPageChange, className }) {
    return (
      <MuiPagination
        color="primary"
        className={className}
        count={pagination.count}
        siblingCount={1}
        boundaryCount={0}
        page={pagination.page + 1}
        renderItem={item => {
          if (item.type === 'next') {
            return (
              !item.disabled && (
                <Box>
                  {intl.formatMessage({ id: 'pagination_next' })}
                  <PaginationItem
                    {...item}
                    slots={{
                      next: PaginationNextButtonIcon,
                    }}
                  ></PaginationItem>
                </Box>
              )
            );
          } else if (item.type === 'previous') {
            return (
              !item.disabled && (
                <Box>
                  <PaginationItem
                    {...item}
                    slots={{
                      previous: PaginationPreviousButtonIcon,
                    }}
                  ></PaginationItem>
                  {intl.formatMessage({ id: 'pagination_previous' })}
                </Box>
              )
            );
          } else {
            return <PaginationItem {...item}></PaginationItem>;
          }
        }}
        onChange={(event, newPage) => {
          setPagination(prev => ({ ...prev, page: newPage - 1 }));
          changePagination(newPage);
        }}
      />
    );
  }
  const handleSaveArt = useCallback(() => {
    setGanttSavingLoader(true);
    setArtRowsErrors([]);
    if (socket) {
      socket.emit('checkArtSaveConflict', {
        token: accessToken,
      });
    }
  }, [accessToken, socket]);
  const handleSaveQueryModalClose = useCallback(() => {
    setOpenSaveQueryModal(false);
  }, []);
  const handleClearArt = useCallback(async value => {
    setIsLoading(true);
  }, []);
  const handleSelectFilters = useCallback(
    data => {
      if (!data.hasOwnProperty('operation')) {
        return;
      }
      const items = currentParams.filters.selectedItems.map(item => {
        if (item.fieldId === data.fieldId) {
          return data;
        } else {
          return item;
        }
      });
      const updatedParams = { ...currentParams, filters: { ...currentParams.filters, selectedItems: items } };
      const paramsToQueryFormated = paramsToQuery(updatedParams);
      setCurrentParams(updatedParams);
      setQuery(paramsToQueryFormated);
      if (paramsToQueryFormated.filters != query.filters) {
        setIsLoading(true);
      }
    },
    [currentParams, setQuery, query],
  );
  function CustomPagination(props) {
    return (
      <GridPagination {...props} labelDisplayedRows={() => ''} rowsPerPageOptions={[]} ActionsComponent={Pagination} />
    );
  }
  const downloadCsv = useCallback(e => {
    async function downloadGanttCsv() {
      try {
        setDownloadLoading(true);
        const params = { project: 696 };
        if (params) {
          const { data } = await getGantCsv(params);
          const url = window.URL.createObjectURL(new Blob(['\ufeff', data], { type: 'text/csv;charset=utf-8;' }));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `gantt-${dayjs().format('YYYY-MM-DD')}`);
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
        }
      } catch (e) {
        console.error(e);
      } finally {
        setDownloadLoading(false);
      }
    }
    downloadGanttCsv();
  }, []);
  useEffect(() => {
    if (Object.values(query).filter(item => item !== undefined).length > 0 && initialParams && !currentParams) {
      setCurrentParams(queryToParams(query, initialParams));
    }
  }, [currentParams, initialParams, query]);
  useEffect(() => {
    if (ganttStartDateErrors) {
      if (tempCellValue) {
        apiRef.current.updateRows([tempCellValue]);
      }
    }
  }, [tempCellValue, ganttStartDateErrors, apiRef]);
  const handleButtonEvent = useCallback(
    type => {
      switch (type) {
        case ButtonsTypes.SAVE:
          handleSaveArt();
          break;
        case ButtonsTypes.CANCEL:
          handleClearArt();
          break;
        case ButtonsTypes.PARAMS:
          setOpenParamsModal(true);
          break;
        case ButtonsTypes.QUERY:
          setOpenSaveQueryModal(true);
          break;
      }
    },
    [handleSaveArt, handleClearArt, setOpenParamsModal],
  );
  return (
    <>
      {currentParams && (
        <GridFiltersModalNew
          handleSave={data => handleParamsModalSave(data)}
          handleClose={handleParamsModalClose}
          open={openParamsModal}
          data={currentParams}
        />
      )}
      <CommonPage
        pageTitle={pageTitle}
        loading={isLoading}
        buttonEvent={handleButtonEvent}
        disableSave={disableSaveButton}
      >
        {currentParams && currentParams.filters?.selectedItems?.length > 0 && (
          <GridFiltersPanelNew
            data={currentParams.filters.selectedItems}
            handleCloseFilter={handleSelectFilters}
          ></GridFiltersPanelNew>
        )}
        {showSavingError.state && (
          <Grid container direction="column" marginBottom={2}>
            {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
            <Typography variant="h3" fontWeight={600} color="#E03737">
              Кто-то обновил задачу, пока вы ее редактировали.
            </Typography>
            {showSavingError.errors.length > 0 &&
              showSavingError.errors.map((issue, index) => {
                const values = Object.entries(issue.updatedValues);
                return values
                  .filter(item => item[1])
                  .map(value => {
                    return (
                      <Box>
                        <Typography variant="h4" color="#212346">
                          <Box component="span" fontWeight={600}>
                            {`${intl.formatMessage({ id: `gant_error_${value[0]}` })} `}
                          </Box>
                          <Box component="span">{`${intl.formatMessage({ id: 'error_task_text' })} `}</Box>
                          <Box component="span" fontWeight={600}>
                            {issue.issueName}
                          </Box>
                          <Box component="span">{` ${intl.formatMessage({
                            id: 'error_task_text_change_from',
                          })} `}</Box>
                          <Box component="span" fontWeight={600}>
                            {value[1].old}
                          </Box>
                          <Box component="span">{` ${intl.formatMessage({ id: 'error_task_text_change_to' })} `}</Box>
                          <Box component="span" fontWeight={600}>
                            {value[1].new}
                          </Box>
                        </Typography>
                      </Box>
                    );
                  });
              })}
            <Grid container marginLeft={4} wrap="nowrap" direction="column" spacing={0}>
              <RadioGroup
                value={gantErrorValue}
                onChange={e => setGanttErrorValue(e.target.value)}
                defaultValue={'save_all'}
              >
                <FormControlLabel
                  value="save_all"
                  control={
                    <Radio
                      disableRipple
                      icon={<UncheckedRadioIcon width="16" height="16" viewBox="0 0 16 16" />}
                      checkedIcon={<CheckedRadioIcon width="16" height="16" viewBox="0 0 16 16" />}
                    />
                  }
                  label={intl.formatMessage({ id: 'saving_gantt' })}
                />
                <FormControlLabel
                  value="cancel_changes"
                  control={
                    <Radio
                      disableRipple
                      icon={<UncheckedRadioIcon width="16" height="16" viewBox="0 0 16 16" />}
                      checkedIcon={<CheckedRadioIcon width="16" height="16" viewBox="0 0 16 16" />}
                    />
                  }
                  label={intl.formatMessage({ id: 'clearing_gantt' })}
                />
              </RadioGroup>
              <Grid>
                <Button variant="defaultBluePrimary" disableRipple onClick={handleSaveAfterError}>
                  <Typography variant="h4" fontWeight={600}>
                    <FormattedMessage id="resPlan_accept_saving" />
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
        {artRowsErrors.length > 0 && (
          <Box>
            <Typography variant="h3" color={'#E03737'}>
              {artRowsErrors.map((item, index) => (
                <>{item}</>
              ))}
              {intl.formatMessage({ id: 'finances_rows_error' })}
            </Typography>
          </Box>
        )}
        {isLoading ? (
          <Grid container alignItems="center" justifyContent="center" width="100%" height="100%">
            <CircularProgress color="secondary" />
          </Grid>
        ) : (
          <>
            <Grid
              container
              flexDirection={'column'}
              flex={1}
              height={'100%'}
              overflow={'hidden'}
              position={'relative'}
              borderRadius={'16px'}
            >
              <DataGridPro
                apiRef={apiRef}
                pagination
                sx={{
                  [`& .MuiDataGrid-columnHeader`]: {
                    maxHeight: 'none !important',
                    minHeight: '75px !important',
                    height: 'auto !important',
                    whiteSpace: 'inherit !important',
                    overflow: 'inherit !important',
                    lineHeight: '24px !important',
                  },
                  [`& .MuiDataGrid-columnHeaderTitle`]: {
                    whiteSpace: 'normal !important',
                    minHeight: '75px !important',
                  },
                  [`& .MuiDataGrid-columnHeaderTitleContainer`]: {
                    whiteSpace: 'normal !important',
                    minHeight: '75px !important',
                  },
                }}
                columnHeaderHeight={75}
                isCellEditable={isCellEditable}
                disableColumnFilter
                disableRowSelectionOnClick
                disableColumnMenu
                columns={artTableColumnsData}
                rows={artTableRowsData}
                className={clsx(dataGridTableStyle.DataGridTableRoot, dataGridTableStyle.FixedHeaderDataGridTableRoot)}
                getRowId={item => item.id}
                sortingMode="server"
                sortModel={sortModel}
                onSortModelChange={handleSort}
                slotProps={{
                  baseCheckbox: {
                    icon: <CheckboxIcon width="12" height="12" viewBox="0 0 12 12" />,
                    checkedIcon: <CheckboxIconChecked width="12" height="12" viewBox="0 0 12 12" />,
                  },
                }}
                slots={{
                  pagination: CustomPagination,
                  columnUnsortedIcon: () => <SortIcon width="20" height="20" viewBox="0 0 20 20" />,
                  columnSortedAscendingIcon: () => (
                    <SortIcon direction="up" width="20" height="20" viewBox="0 0 20 20" />
                  ),
                  columnSortedDescendingIcon: () => (
                    <SortIcon direction="down" width="20" height="20" viewBox="0 0 20 20" />
                  ),
                }}
                getRowClassName={params => params.row.groupName && 'groupRow'}
                isRowSelectable={params => !params.row.groupName}
                getRowHeight={() => 'auto'}
              />
            </Grid>
          </>
        )}
      </CommonPage>
    </>
  );
};

export default ArtContainer;
