import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Page from '../../../../packages/common/shared-ui/src/components/Page';
import { Button, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import ArtCommon from './components/ArtCommon';
import ArtTrackers from './components/ArtTrackers';
import ArtCustomers from './components/ArtCustomers';

const Art = ({ intl }) => {
  const navigate = useNavigate();
  const pageTitle = intl.formatMessage({ id: 'finance_page_title' });
  const childRef = useRef();
  const [formChanged, setFormChanged] = useState(false);
  const handleError = useCallback(
    error => {
      if (error) {
        navigate(`/errors/error-${error.status}`);
      }
    },
    [navigate],
  );

  return (
    <Page title={pageTitle} width="100%">
      <Grid container alignItems={'center'} justifyContent={'space-between'}>
        <Grid item>
          <Typography m={0} padding={'10px 0'} variant="h1">
            {pageTitle}
          </Typography>
        </Grid>
        <Grid item>
          <Grid item>
            <Button
              variant="defaultGreyPrimary"
              disableRipple
              onClick={event => childRef.current.save(event)}
              disabled={!formChanged}
            >
              <Typography variant="h4" fontWeight={700}>
                <FormattedMessage id="save_text" />
              </Typography>
            </Button>
            <Button
              variant="defaultGreyPrimary"
              disableRipple
              sx={{ marginLeft: '8px' }}
              disabled={!formChanged}
              onClick={event => childRef.current.cancel(event)}
            >
              <Typography variant="h4" fontWeight={700}>
                <FormattedMessage id="cancel" />
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <ArtCommon
        ref={childRef}
        intl={intl}
        formChanged={value => setFormChanged(value)}
        handleError={error => handleError(error)}
      ></ArtCommon>
      <ArtTrackers handleError={error => handleError(error)}></ArtTrackers>
      <ArtCustomers handleError={error => handleError(error)}></ArtCustomers>
    </Page>
  );
};

export default injectIntl(Art);
