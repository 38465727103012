import React, { memo, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import {
  Box,
  CircularProgress,
  Drawer,
  Grid,
  IconButton,
  Popover,
  SwipeableDrawer,
  Typography,
  useMediaQuery,
} from '@mui/material';
import MenuItem from '../MenuItem';

import useStyles from './LeftDrawer.styles';
import ArrowIcon from 'Common/shared-ui/src/icons/ArrowIcon';
import palette from 'Common/shared-ui/src/theme/palette';
import AppLayout from '../../app/AppLayout';
import { useDispatch, useSelector } from 'react-redux';
import ChevronIcon from '../../../../packages/common/shared-ui/src/icons/ChevronIcon';
import { useLocation, useNavigate } from 'react-router';
import ClearIcon from '../../../../packages/common/shared-ui/src/icons/ClearIcon';
import {
  getAgileSavedQueriesByProject,
  getConfirmationsSavedQueries,
  getIssuesSavedQueries,
  getMenuNavigation,
  getMenuNavigationId,
  getTimesheetSavedQueries,
} from '../../../../packages/common/api';
import { getTranslatedText } from '../../../../packages/common/utils/getTranslatedText';
import { generateRedirectUrl } from '../../../../packages/common/utils/getRedirectUrl';
import { store } from '../../app/redux';
import { toggleDrawer } from '../../app/redux/reducers/userSlice';
import { useParams } from 'react-router-dom';

const LeftDrawer = ({ children }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const isUserAdmin = useSelector(state => state.user.info.isAdmin);
  const userLastName = useSelector(state => state.user.info.lastName);
  const userFirstName = useSelector(state => state.user.info.firstName);
  const userFullNameArray = useSelector(state => state.user.info.fullName).split(' ');
  const userInitials = `${userFullNameArray[0][0]}${userFullNameArray[userFullNameArray.length - 1][0]}`;
  const avatar = null;
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.maxWidth('sm'));
  const [isSpinnerShown, setIsSpinnerShown] = useState(false);

  const drawerState = useSelector(state => state.user.isDrawerOpen);
  const [drawerProps, setDrawerProps] = useState({
    modalProps: {
      className: classes.drawerModal,
      keepMounted: true,
    },
  });

  const [menuItemsGlobal, setMenuItemsGlobal] = useState([]);
  const [menuItemsContext, setMenuItemsContext] = useState([]);
  const state = store.getState();
  const pageParams = useParams();

  const handleError = useCallback(
    error => {
      if (error) {
        navigate(`/errors/error-${error.status}`);
      }
    },
    [navigate],
  );

  const dispatch = useDispatch();
  const openDrawer = useCallback(() => dispatch(toggleDrawer(true)), [dispatch]);
  useEffect(() => {
    let ignore = false;
    async function getMenu() {
      let response;
      if (pageParams.projectId) {
        response = await getMenuNavigationId(pageParams.projectId);
      } else {
        response = await getMenuNavigation();
      }
      if (!ignore) {
        return response.data;
      }
    }

    getMenu()
      .then(res => {
        const subItems = [
          'projectsNavItem',
          'issuesNavItem',
          'timesheetsNavItem',
          'confirmationsNavItem',
          'projectIssuesNavItem',
          'projectConfirmationsNavItem',
          'projectReportsNavItem',
          'projectTimesheetsNavItem',
          'projectIssuesKanbanNavItem',
        ];
        let itemsGlobal = [];
        let itemsContext = [];
        if (res.global) {
          itemsGlobal = res.global.map(item => {
            let menuItem = item;
            !item.subitems && subItems.includes(item.itemId) && (menuItem.subitems = []);

            return menuItem;
          });
        }
        if (res.context) {
          itemsContext = res.context.map(item => {
            let menuItem = { ...item, context: true };
            !item.subitems && subItems.includes(item.itemId) && (menuItem.subitems = []);

            return menuItem;
          });
        }
        setMenuItemsGlobal(itemsGlobal);
        setMenuItemsContext(itemsContext);
      })
      .catch(error => handleError(error.response));
  }, [handleError, pageParams.projectId]);

  const redirectToExternalUrl = link => {
    window.open(link, '_blank');
  };
  const { current: menuItemStyles } = useRef({
    style: {
      width: 24,
      height: 24,
      color: palette.text.alt,
    },
  });
  const handleOpenDrawer = useCallback(() => {
    dispatch(toggleDrawer(false));
  }, [dispatch]);
  const handleProjectLink = useCallback(() => {
    navigate(`/projects/${state.user.project.redmineId}`);
    dispatch(toggleDrawer(false));
  }, [dispatch, navigate, state.user.project]);
  return (
    <Grid container wrap="nowrap">
      <Drawer
        open={drawerState}
        onClose={handleOpenDrawer}
        classes={{ paper: clsx(classes.drawerPaper, { [classes.drawerOpen]: drawerState }) }}
        ModalProps={drawerProps.modalProps}
      >
        {!isSmallScreen && !drawerState && (
          <IconButton onClick={openDrawer} className={clsx(classes.drawerArrowIcon)}>
            <ArrowIcon direction="left" viewBox="0 0 24 24" />
          </IconButton>
        )}
        {isSmallScreen ? (
          <Grid container direction="column" className={classes.mobileMenuUserInfo}>
            <Box className={classes.avatar} marginBottom={2.5}>
              {avatar ? (
                <img src={avatar} alt="user avatar" />
              ) : (
                <Typography variant="h4" color="primary" fontWeight={600}>
                  {userInitials}
                </Typography>
              )}
            </Box>
            <Grid container wrap="nowrap" alignItems="center" justifyContent="space-between" width={'auto'}>
              <Typography variant="h3" fontWeight={600} color="#E4E4EF" fontSize={'14px'}>
                {userFirstName} {userLastName}
              </Typography>
              <IconButton disableRipple>
                <ChevronIcon direction="down" width={24} height={24} viewBox="0 0 24 24" color="#E4E4EF" />
              </IconButton>
            </Grid>
          </Grid>
        ) : null}
        {drawerState && (
          <Grid container direction="column" className={clsx(classes.drawerMenu)}>
            {menuItemsGlobal.map((item, index) => (
              <MenuItem key={index} item={item}></MenuItem>
            ))}
            {state.user.project && (
              <Box
                onClick={handleProjectLink}
                backgroundColor={'#F0F1F8'}
                padding={'6px 10px'}
                marginTop={'20px'}
                marginBottom={'10px'}
                sx={{ cursor: 'pointer' }}
              >
                <Typography variant="h3" fontWeight={700} color={'#212346'}>
                  {state.user.project.name}
                </Typography>
              </Box>
            )}
            {menuItemsContext.map((item, index) => (
              <MenuItem key={index} item={item} context={true}></MenuItem>
            ))}
            {/*{menuItems.map((menuItem, itemProps) => (
              <MenuItem
                item={menuItem}
                itemName={menuItem.lang_key}
                itemIcon={menuItem.rowIcon && menuItem.rowIcon(menuItemStyles)}
                path={menuItem.path}
                key={menuItem.lang_key}
                closeDrawer={() => handleOpenDrawer(false)}
                redirectToExternalUrl={redirectToExternalUrl}
                redirectToAnotherUrl={redirectToAnotherUrl}
                {...itemProps}
                {...{ currentItem: window.location.pathname === menuItem.path }}
              />
            ))}*/}
          </Grid>
        )}
      </Drawer>
      {process.env.NODE_ENV !== 'development' && isSpinnerShown && (
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          width="100%"
          height="100vh"
          position="absolute"
          className={classes.spinner}
        >
          <CircularProgress color="inherit" />
        </Grid>
      )}
      <Grid container className={classes.content}>
        <AppLayout className={classes.AppLayoutRoot}>{children}</AppLayout>
      </Grid>
    </Grid>
  );
};

export default memo(LeftDrawer);
