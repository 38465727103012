import dayjs from 'dayjs';

export const autocompleteFields = [
  'list',
  'enumeration',
  'user',
  'project',
  'tracker',
  'issue_status',
  'issue_priority',
  'timesheet_issue',
  'issue_parent',
];
export const numberFields = ['float', 'acl_percent', 'int'];
export const handleField = (data, field, deleted = false) => {
  console.log(data);
  let changedField = {
    valueId: data,
    valueName: data,
    changed: true,
  };
  field.fieldDefinition.fieldFormat === 'date' &&
    (changedField = {
      ...changedField,
      valueId: data ? dayjs(data.$d).format('YYYY-MM-DD') : '',
      valueName: data ? dayjs(data.$d).format('YYYY-MM-DD') : '',
    });
  field.fieldDefinition.fieldFormat === 'attachment' &&
    (changedField = { ...changedField, valueId: data.token, valueName: data.token });

  deleted && (changedField = { ...changedField, valueId: '', valueName: '', deleted: true });
  return changedField;
};

export const formatFields = fields => {
  let standards = [];
  let customs = [];
  fields.standards && (standards = fields.standards);
  fields.customs && (customs = fields.customs);
  const formattedCustoms = customs.map(field => ({ ...field, custom: true }));
  const allFields = standards.concat(formattedCustoms);
  return allFields.map(item => {
    let field = item;
    if (autocompleteFields.includes(field.fieldDefinition.fieldFormat)) {
      if (field.fieldDefinition.isMultiple) {
        if (field.valueId) {
          field = {
            ...field,
            valueId: field.fieldDefinition.values.filter(item => item.isChecked),
            valueName: field.fieldDefinition.values.filter(item => item.isChecked),
          };
        } else {
          field = {
            ...field,
            valueId: [],
            valueName: [],
          };
        }
      } else {
        if (field.valueId) {
          field = {
            ...field,
            valueId: { valueId: field.valueId, valueName: field.valueName },
            valueName: { valueId: field.valueId, valueName: field.valueName },
          };
        }
      }
    }

    return field;
  });
};

export const saveFormat = fields => {
  return fields.standards.concat(fields.customs).reduce(
    (acc, field) => {
      let value = field.valueId;
      field.fieldDefinition.fieldFormat === 'date' && field.valueId === '' && (value = null);
      numberFields.includes(field.fieldDefinition.fieldFormat) && (value = Number(field.valueId.replace(',', '.')));
      field.fieldDefinition.fieldFormat === 'bool' && (value = field.valueId === 'true');
      if (autocompleteFields.includes(field.fieldDefinition.fieldFormat)) {
        if (field.fieldDefinition.isMultiple) {
          value = field.valueId.map(item => item.valueId);
        } else {
          if (field.valueId) {
            value = Number(field.valueId.valueId);
            field.custom && (value = value.toString());
          } else {
            value = field.valueId;
          }
        }
      }
      if (field.custom) {
        acc.customs.push({
          fieldId: field.fieldId,
          valueId: value ? value.toString() : '',
        });
      } else {
        acc[field.fieldId] = value;
      }
      return acc;
    },
    { customs: [] },
  );
};

export const sortOptions = (options, field) => {
  if (field.value.selectedItems) {
    const selectedItems = field.value.selectedItems.map(item => item.fieldId);
    const clearedOptions = options.filter(item => !selectedItems.includes(item.fieldId));
    return field.value.selectedItems.concat(clearedOptions);
  } else {
    return options;
  }
};
