import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Page from '../../../../packages/common/shared-ui/src/components/Page';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Popper,
  TextField,
  Typography,
} from '@mui/material';
import userPageStyles from '../userPage/UserPage.styles';
import { getConfirmationSettingsCommon, saveConfirmationSettingsCommon } from '../../../../packages/common/api';
import { useNavigate } from 'react-router';
import ChevronIcon from '../../../../packages/common/shared-ui/src/icons/ChevronIcon';
import CheckboxIcon from '../../../../packages/common/shared-ui/src/icons/CheckboxIcon';
import CheckboxIconChecked from '../../../../packages/common/shared-ui/src/icons/CheckboxIconChecked';
import issueFormFieldStyles from '../../issueCreateEdit/components/IssueFormField.styles';
import ConfirmationSettingsTable from './components/ConfirmationSettingsTable';
import DeleteIcon from '../../../../packages/common/shared-ui/src/icons/DeleteIcon';
import clsx from 'clsx';
import ConfirmationSettingsTrackers from './components/ConfirmationSettingsTrackers';

const ConfirmationSettings = ({ intl }) => {
  const commonClasses = userPageStyles();
  const fieldsClasses = issueFormFieldStyles();
  const pageTitle = intl.formatMessage({ id: 'confirmation_page_title' });
  const navigate = useNavigate();
  const [settingsFields, setSettingsFields] = useState([]);
  const [initialSettingsFields, setInitialSettingsFields] = useState([]);
  const [loading, setLoading] = useState(true);
  const [saveSettingsloading, setSaveSettingsloading] = useState(false);

  useEffect(() => {
    let ignore = false;
    async function getConfirmationCommon() {
      const { data: settings } = await getConfirmationSettingsCommon();
      if (!ignore) {
        return settings;
      }
    }
    getConfirmationCommon()
      .then(res => {
        setLoading(false);
        setSettingsFields(res);
        setInitialSettingsFields(res);
      })
      .catch(error => handleIssueError(error.response));
    return () => {
      ignore = true;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleIssueError = useCallback(
    error => {
      if (error) {
        navigate(`/errors/error-${error.status}`);
      }
    },
    [navigate],
  );
  const handleField = useCallback(
    (data, field, deleted = false) => {
      let updatedObject;
      const pos = settingsFields.map(e => e.fieldId).indexOf(field.fieldId);
      if (field.fieldDefinition.fieldFormat === 'list') {
        if (Array.isArray(data)) {
          updatedObject = {
            ...settingsFields[pos],
            valueId: data,
            valueName: data,
            changed: true,
          };
        } else {
          updatedObject = {
            ...settingsFields[pos],
            valueId: data.valueId,
            valueName: data.valueName,
            changed: true,
          };
        }
      }
      if (field.fieldDefinition.fieldFormat === 'bool') {
        updatedObject = {
          ...settingsFields[pos],
          valueId: data.target.checked,
          valueName: data.target.checked.toString(),
          changed: true,
        };
      }
      deleted && (updatedObject = { ...settingsFields[pos], valueId: '', valueName: '', changed: true, deleted: true });
      const newFields = [...settingsFields.slice(0, pos), updatedObject, ...settingsFields.slice(pos + 1)];
      setSettingsFields(newFields);
    },
    [settingsFields],
  );

  const saveConfirmationSettings = useCallback(async () => {
    setSaveSettingsloading(true);
    setInitialSettingsFields([]);
    const result = settingsFields
      .filter(field => field.changed)
      .reduce((acc, field) => {
        let value = Number(field.valueId);
        field.fieldId === 'initiatorRoles' && (value = field.valueId.map(item => item.valueId));
        field.fieldId === 'viewerRoles' && (value = field.valueId.map(item => item.valueId));
        field.fieldDefinition.fieldFormat === 'bool' && (value = field.valueId);
        acc[field.fieldId] = value;

        return acc;
      }, {});
    try {
      const { data: settings } = await saveConfirmationSettingsCommon(result);
      if (settings) {
        setSettingsFields(settings);
        setInitialSettingsFields(settings);
        setSaveSettingsloading(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleIssueError(error.response);
    }
  }, [handleIssueError, settingsFields]);

  const handleCancel = useCallback(() => {
    setSettingsFields(initialSettingsFields);
  }, [initialSettingsFields]);

  return (
    <>
      {loading ? (
        <Grid container alignItems="center" justifyContent="center" width="100%" height="70px">
          <CircularProgress color="secondary" />
        </Grid>
      ) : (
        <Page title={pageTitle} width="100%">
          <Grid container alignItems={'center'} justifyContent={'space-between'}>
            <Grid item>
              <Typography m={0} padding={'10px 0'} variant="h1">
                {pageTitle}
              </Typography>
            </Grid>
            <Grid item>
              <Grid item>
                <Button
                  variant="defaultGreyPrimary"
                  disableRipple
                  onClick={saveConfirmationSettings}
                  disabled={!settingsFields.find(field => field.changed)}
                >
                  <Typography variant="h4" fontWeight={700}>
                    <FormattedMessage id="save_text" />
                  </Typography>
                </Button>
                <Button onClick={handleCancel} variant="defaultGreyPrimary" disableRipple sx={{ marginLeft: '8px' }}>
                  <Typography variant="h4" fontWeight={700}>
                    <FormattedMessage id="cancel" />
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Box
            className={commonClasses.blockWrapper}
            padding={'0 28px 20px 28px'}
            marginTop={'16px'}
            position={'relative'}
          >
            {saveSettingsloading && (
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                width="100%"
                height="100%"
                zIndex={99}
                position={'absolute'}
              >
                <CircularProgress color="secondary" />
              </Grid>
            )}
            <Grid container columnSpacing={10} flexDirection={'column'} maxHeight={'286px'}>
              {settingsFields.map((field, index) => (
                <Grid key={index} item width={'50%'}>
                  {field.fieldDefinition.fieldFormat === 'list' && (
                    <FormControl
                      required={field.fieldDefinition.isRequired}
                      key={field.fieldId}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                      }}
                    >
                      <InputLabel
                        className={fieldsClasses.selectLabel}
                        sx={{ marginTop: '16px', width: '100%!important' }}
                      >
                        <Typography variant="h5" color={'#41424E'}>
                          {`${intl.formatMessage({
                            id: `confirm_settings_${field.fieldDefinition.fieldName
                              .replaceAll(' ', '_')
                              .toLowerCase()}`,
                          })}`}
                        </Typography>
                      </InputLabel>
                      {field.fieldDefinition.isMutiple ? (
                        <Autocomplete
                          multiple
                          onChange={(event, newValue) => handleField(newValue, field)}
                          className={clsx(fieldsClasses.filterSelect, fieldsClasses.filterSelectMultiple)}
                          noOptionsText={intl.formatMessage({ id: 'not_found' })}
                          options={field.fieldDefinition.values}
                          getOptionLabel={option => option.valueName || ''}
                          disableClearable
                          disablePortal
                          value={
                            !field.changed && field.fieldDefinition.values.filter(item => item.isChecked).length > 0
                              ? field.fieldDefinition.values.filter(item => item.isChecked)
                              : field.valueName
                              ? field.valueName
                              : []
                          }
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip
                                {...getTagProps({ index })}
                                key={index}
                                deleteIcon={<DeleteIcon width={10} height={10} viewBox="0 0 10 10" />}
                                className={fieldsClasses.chip}
                                variant="outlined"
                                label={option.valueName}
                              />
                            ))
                          }
                          renderOption={(props, option) => {
                            return (
                              <MenuItem
                                {...props}
                                key={option.valueId}
                                value={option.valueId}
                                selected={option.valueId === field.valueId}
                              >
                                {option.valueName}
                              </MenuItem>
                            );
                          }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              error={field.error}
                              placeholder={intl.formatMessage({ id: 'choose' })}
                            />
                          )}
                          PopperComponent={props => (
                            <Popper {...props} sx={{ maxWidth: '100%!important' }} placement="bottom-start"></Popper>
                          )}
                          popupIcon={<ChevronIcon direction="down"></ChevronIcon>}
                        />
                      ) : (
                        <Autocomplete
                          noOptionsText={intl.formatMessage({ id: 'not_found' })}
                          disabled={field.deleted}
                          required={field.fieldDefinition.isRequired}
                          onChange={(event, newValue) => handleField(newValue, field)}
                          className={fieldsClasses.filterSelect}
                          disablePortal
                          options={field.fieldDefinition.values}
                          //sx={{ width: 253 }}
                          disableClearable
                          renderOption={(props, option) => {
                            return (
                              <MenuItem
                                {...props}
                                key={option.valueId}
                                value={option.valueId}
                                selected={option.valueId === field.valueId}
                              >
                                {option.valueName}
                              </MenuItem>
                            );
                          }}
                          value={
                            !field.changed && field.fieldDefinition.values.find(item => item.isChecked)
                              ? field.fieldDefinition.values.find(item => item.isChecked)
                              : field.valueName
                              ? { valueId: field.valueId, valueName: field.valueName }
                              : ''
                          }
                          isOptionEqualToValue={(option, value) => option.value === value.value}
                          getOptionLabel={option => option.valueName || ''}
                          PopperComponent={props => (
                            <Popper {...props} sx={{ maxWidth: '100%!important' }} placement="bottom-start"></Popper>
                          )}
                          renderInput={params => (
                            <TextField
                              {...params}
                              error={field.error}
                              placeholder={intl.formatMessage({ id: 'choose' })}
                            />
                          )}
                          popupIcon={<ChevronIcon direction="down"></ChevronIcon>}
                        />
                      )}
                    </FormControl>
                  )}
                  {field.fieldDefinition.fieldFormat === 'bool' && (
                    <FormControlLabel
                      sx={{ marginTop: '9px' }}
                      control={
                        <Checkbox
                          //value={Boolean(field.valueName)}
                          onChange={event => handleField(event, field)}
                          icon={<CheckboxIcon width="12" height="12" viewBox="0 0 12 12" />}
                          checkedIcon={<CheckboxIconChecked width="12" height="12" viewBox="0 0 12 12" />}
                          checked={field.valueName === 'true'}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label={`${intl.formatMessage({
                        id: `confirm_settings_${field.fieldDefinition.fieldName.replaceAll(' ', '_').toLowerCase()}`,
                      })}`}
                    />
                  )}
                </Grid>
              ))}
            </Grid>
          </Box>
          <ConfirmationSettingsTrackers></ConfirmationSettingsTrackers>
          <ConfirmationSettingsTable></ConfirmationSettingsTable>
        </Page>
      )}
    </>
  );
};
export default injectIntl(ConfirmationSettings);
