import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Popper,
  TextField,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import DeleteIcon from '../../../../../packages/common/shared-ui/src/icons/DeleteIcon';
import ChevronIcon from '../../../../../packages/common/shared-ui/src/icons/ChevronIcon';
import issueFormFieldStyles from '../../../issueCreateEdit/components/IssueFormField.styles';
import { getArtSettingsCommon, saveArtSettingsCommon } from '../../../../../packages/common/api';
import CheckboxIconChecked from '../../../../../packages/common/shared-ui/src/icons/CheckboxIconChecked';
import CheckboxIcon from '../../../../../packages/common/shared-ui/src/icons/CheckboxIcon';
import formFieldsStyles from '../../../../../packages/common/shared-ui/src/styles/FormFields.styles';
import { useNavigate } from 'react-router';

const ArtCommon = forwardRef((props, ref) => {
  const navigate = useNavigate();
  const { formChanged, intl, ...otherProps } = props;
  const fieldsClasses = issueFormFieldStyles();
  const classes = formFieldsStyles();
  const [fields, setFields] = useState([]);
  const [initialFields, setInitialFields] = useState([]);
  const [loading, setLoading] = useState(true);
  const handleError = useCallback(
    error => {
      if (error) {
        navigate(`/errors/error-${error.status}`);
      }
    },
    [navigate],
  );
  useImperativeHandle(ref, () => {
    return {
      async save() {
        setInitialFields([]);
        const result = fields
          .filter(field => field.changed)
          .reduce((acc, field) => {
            acc[field.fieldId] = Array.isArray(field.value)
              ? field.value.map(item => Number(item.valueId))
              : Number(field.value.valueId);

            return acc;
          }, {});
        try {
          setLoading(true);
          const { data: res } = await saveArtSettingsCommon(result);
          if (res) {
            const formattedFields = res.map(item => {
              const value = item.fieldDefinition.isMutiple
                ? item.fieldDefinition.values.filter(item => item.isChecked)
                : item.fieldDefinition.values.find(item => item.isChecked);
              return {
                ...item,
                value: value,
              };
            });
            setFields(formattedFields);
            setInitialFields(formattedFields);
            formChanged(false);
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
          handleError(error.response);
        }
      },
      cancel() {
        setFields(initialFields);
        formChanged(false);
      },
    };
  });

  useEffect(() => {
    let ignore = false;
    async function getArtCommon() {
      const { data: settings } = await getArtSettingsCommon();
      if (!ignore) {
        return settings;
      }
    }
    getArtCommon()
      .then(res => {
        setLoading(false);
        const formattedFields = res.map(item => {
          const value = item.fieldDefinition.isMutiple
            ? item.fieldDefinition.values.filter(item => item.isChecked)
            : item.fieldDefinition.values.find(item => item.isChecked);
          return {
            ...item,
            value: value,
          };
        });
        setFields(formattedFields);
        setInitialFields(formattedFields);
      })
      .catch(error => {
        handleError(error.response);
      });
    return () => {
      ignore = true;
    };
  }, [handleError]);

  const handleField = useCallback(
    (data, field, deleted = false) => {
      let updatedObject;
      const pos = fields.map(e => e.fieldId).indexOf(field.fieldId);
      updatedObject = {
        ...fields[pos],
        valueId: Array.isArray(data) ? data.map(item => (item.valueId ? item.valueId : item)) : data.valueId,
        valueName: Array.isArray(data) ? data.map(item => (item.valueName ? item.valueName : item)) : data.valueName,
        value: data,
        changed: true,
      };
      const newFields = [...fields.slice(0, pos), updatedObject, ...fields.slice(pos + 1)];
      setFields(newFields);
      formChanged(true);
    },
    [fields, formChanged],
  );

  return (
    <Box
      marginTop={'16px'}
      padding={'24px 20px'}
      borderRadius={'16px'}
      backgroundColor={fields.length > 0 ? '#fff' : 'tranparent'}
      //minHeight={loading ? '184px' : 'auto'}
      position={'relative'}
    >
      {loading && (
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          width="100%"
          height="100%"
          zIndex={99}
          top={0}
          position={'absolute'}
        >
          <CircularProgress color="secondary" />
        </Grid>
      )}
      {fields.length > 0 && (
        <Grid container columnSpacing={10}>
          {fields.map((field, index) => (
            <Grid key={index} item width={'50%'} marginTop={index > 1 ? '16px' : 0}>
              <FormControl
                required={field.fieldDefinition.isRequired}
                key={field.fieldId}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                }}
              >
                <InputLabel className={classes.selectLabel}>
                  <Typography variant="h5" color={'#41424E'}>
                    {field.fieldName}
                  </Typography>
                </InputLabel>
                {field.fieldDefinition.isMutiple ? (
                  <Autocomplete
                    forcePopupIcon={false}
                    className={clsx(classes.fieldAutocomplete, classes.fieldAutocompleteMultiple)}
                    multiple
                    disableClearable
                    onChange={(event, newValue) => handleField(newValue, field)}
                    options={field.fieldDefinition.values}
                    disableCloseOnSelect
                    disablePortal
                    value={field.value ? field.value : []}
                    isOptionEqualToValue={(option, value) => option.valueId === value.valueId}
                    getOptionLabel={option => option.valueName || ''}
                    renderOption={(props, option, { selected }) => (
                      <li {...props} key={option.valueId}>
                        <Checkbox
                          icon=<CheckboxIcon width="12" height="12" viewBox="0 0 12 12" />
                          checkedIcon=<CheckboxIconChecked width="12" height="12" viewBox="0 0 12 12" />
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.valueName}
                      </li>
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => {
                        return (
                          <Chip
                            {...getTagProps({ index })}
                            key={index}
                            deleteIcon={<DeleteIcon width={10} height={10} viewBox="0 0 10 10" />}
                            className={classes.chip}
                            variant="outlined"
                            label={option.valueName}
                          />
                        );
                      })
                    }
                    renderInput={params => <TextField {...params} placeholder={intl.formatMessage({ id: 'enter' })} />}
                  />
                ) : (
                  <Autocomplete
                    noOptionsText={intl.formatMessage({ id: 'not_found' })}
                    onChange={(event, newValue) => handleField(newValue, field)}
                    className={fieldsClasses.filterSelect}
                    disablePortal
                    options={field.fieldDefinition.values}
                    renderOption={(props, option) => {
                      return (
                        <MenuItem
                          {...props}
                          key={option.valueId}
                          value={option.valueId}
                          selected={option.valueId === field.valueId}
                        >
                          {option.valueName}
                        </MenuItem>
                      );
                    }}
                    value={field.value ? field.value : null}
                    isOptionEqualToValue={(option, value) => option.valueId === value.valueId}
                    getOptionLabel={option => option.valueName || ''}
                    PopperComponent={props => (
                      <Popper {...props} sx={{ maxWidth: '100%!important' }} placement="bottom-start"></Popper>
                    )}
                    renderInput={params => (
                      <TextField {...params} error={field.error} placeholder={intl.formatMessage({ id: 'choose' })} />
                    )}
                    popupIcon={<ChevronIcon direction="down"></ChevronIcon>}
                  />
                )}
              </FormControl>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
});

export default ArtCommon;
