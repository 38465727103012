import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Form, withFormik } from 'formik';
import * as yup from 'yup';
import { checkLicense } from '../../../../packages/common/api';
import CheckboxIcon from 'Common/shared-ui/src/icons/CheckboxIcon';
import CheckboxIconChecked from 'Common/shared-ui/src/icons/CheckboxIconChecked';
import useStyles from './LoginForm.styles';

const LoginForm = ({ intl, handleChange, values, errors, handleSubmit, isSaveData, handleChangeDataSave }) => {
  const classes = useStyles();
  const loginInputText = intl.formatMessage({ id: 'auth_page_login_input_text' });
  const passwordInputText = intl.formatMessage({ id: 'auth_page_password_input_text' });
  const loginLabelText = intl.formatMessage({ id: 'auth_page_login_label_text' });
  const passwordLabelText = intl.formatMessage({ id: 'auth_page_password_label_text' });
  const rememberCheckboxText = intl.formatMessage({ id: 'auth_page_remember_checkbox_text' });
  const expiredLicense = intl.formatMessage({ id: 'auth_page_license_expired' });
  const soonLicense = intl.formatMessage({ id: 'auth_page_license_soon' });
  const [licenseStatus, setLicenseStatus] = useState();
  const [licenseStatusDate, setLicenseStatusDate] = useState();
  const checkLicenseStatus = useCallback(async () => {
    try {
      const { data } = await checkLicense();
      if (data.status == 'soon') {
        setLicenseStatus(soonLicense);
        setLicenseStatusDate(data.expirationDate);
      } else if (data.status == 'expired') {
        setLicenseStatus(expiredLicense);
      }
    } catch (error) {
      console.error('CANNOT GET LICENSE', error);
    }
  }, [expiredLicense, soonLicense]);

  useEffect(() => {
    checkLicenseStatus();
  }, [checkLicenseStatus]);
  return (
    <Box className={classes.LoginFormRoot}>
      <Form align="left" className={classes.form} onSubmit={handleSubmit}>
        <Box marginBottom={errors.requestAnswer ? 2.5 : 10}>
          <Typography variant="h1" align="center">
            <FormattedMessage id="auth_page_title" defaultMessage="Auth title" />
          </Typography>
        </Box>
        <Typography variant="h5" color="error" align="center" marginBottom={2.5}>
          {licenseStatus}
          {licenseStatusDate}
        </Typography>
        <Typography variant="h5" color="error" align="center" marginBottom={2.5}>
          {errors.requestAnswer}
        </Typography>
        <Box marginBottom={2.5}>
          <TextField
            variant="outlined"
            type="text"
            name="username"
            value={values.username}
            onChange={handleChange}
            error={!!errors?.username}
            helperText={loginInputText}
            placeholder={loginLabelText}
            required
            fullWidth
          />
        </Box>
        <Box marginBottom={2.5}>
          <TextField
            variant="outlined"
            type="password"
            name="password"
            value={values.password}
            onChange={handleChange}
            error={!!errors?.password}
            helperText={passwordInputText}
            placeholder={passwordLabelText}
            required
            fullWidth
          />
        </Box>
        <Grid container justifyContent="space-between" alignItems="center">
          <FormControlLabel
            control={
              <Checkbox
                icon={<CheckboxIcon width="12" height="12" viewBox="0 0 12 12" />}
                checkedIcon={<CheckboxIconChecked width="12" height="12" viewBox="0 0 12 12" />}
              />
            }
            label={rememberCheckboxText}
            value={isSaveData}
            onChange={handleChangeDataSave}
            className={classes.hidden}
          />
          <Link to="/recovery">
            <Typography variant="h5">
              <FormattedMessage id="auth_page_forget_pass_text" />
            </Typography>
          </Link>
        </Grid>
        <Grid container wrap="nowrap" alignItems="center" justifyContent="center" marginTop={5}>
          <Button disableRipple variant="defaultBlue" color="primary" type="submit">
            <FormattedMessage id="auth_page_button_text" />
          </Button>
        </Grid>
      </Form>
    </Box>
  );
};

export default withFormik({
  mapPropsToValues: () => ({
    username: '',
    password: '',
  }),
  validationSchema: yup.object().shape({
    username: yup.string().min(1, 'Too Short!').required('Required'),
    password: yup.string().min(2, 'Too Short!').required('Required'),
  }),
  handleSubmit(formValues, { props, resetForm, setSubmitting, setErrors }) {
    props.handleSignIn({
      ...formValues,
      resetForm,
      setSubmitting,
      setErrors,
    });
  },
})(injectIntl(LoginForm));
