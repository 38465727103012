import React, { useCallback, useState } from 'react';
import { injectIntl, useIntl } from 'react-intl';
import useStyles from './ProjectsListView.styles';
import { Box, Typography } from '@mui/material';
import clsx from 'clsx';
import { getTranslatedText } from 'Common/utils/getTranslatedText';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Link } from 'react-router-dom';
import SortIcon from '../../../../packages/common/shared-ui/src/icons/SortIcon';
import dataGridTableStyles from '../../../../packages/common/shared-ui/src/styles/DataGridTable.styles';
import ArrowDownIcon from '../../../../packages/common/shared-ui/src/icons/ArrowDownIcon';
const ProjectsListView = ({ projects, projectscColumns, changeSorting }) => {
  const classes = useStyles();
  const dataGridTableStyle = dataGridTableStyles();
  const intl = useIntl();
  const [sortModel, setSortModel] = useState([]);
  const handleGetRedirectUrl = useCallback(({ id, columnType }) => {
    switch (columnType) {
      case 'project':
        return `${window.location.origin}/projects/${id}`;
      default:
        return '';
    }
  }, []);
  const cellRenderer = useCallback(
    ({ props, column }) => {
      const linkFields = ['project'];
      if (linkFields.includes(column.fieldType)) {
        return (
          <Box
            sx={{
              wordBreak: 'break-word',
              display: 'flex',
              alignItems: 'center',
              padding: props.row.level > 1 ? `0 0 0 ${props.row.level * 8}px` : '0',
            }}
          >
            {props.row.isParent && <ArrowDownIcon></ArrowDownIcon>}
            <Link to={handleGetRedirectUrl({ id: props.id, columnType: column.fieldType })} target={'_blank'}>
              {props.value}{' '}
              <span
                className={clsx(classes.ProjectStatus, {
                  [classes.ProjectOpened]: props.row.status === 1,
                  [classes.ProjectClosed]: props.row.status != 1,
                })}
              >
                •
              </span>
            </Link>
          </Box>
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleGetRedirectUrl],
  );
  const formattedProjectsColumns = useCallback(() => {
    const projectsColumns = projectscColumns;
    const smallFields = ['date', 'hours', 'float', 'bool'];
    return projectsColumns.map(column => {
      return {
        field: column.fieldId,
        headerName: getTranslatedText(intl, 'filter', column.fieldId.split('-').join('_'), column.fieldName),
        renderHeader: props => (
          <Typography variant="h4" fontWeight={700}>
            {props.colDef.headerName}
          </Typography>
        ),
        disableExport: false,
        position: column.position,
        flex: smallFields.includes(column.fieldType) ? 0 : 1,
        width: smallFields.includes(column.fieldType) && 120,
        renderCell: props => cellRenderer({ props, column }),
        cellClassName: props =>
          props.colDef.field === 'project.name' && props.row.isParent ? 'tableCell parent' : 'tableCell',
      };
    });
  }, [cellRenderer, intl, projectscColumns]);
  const formattedProjectsRows = useCallback(
    () =>
      projects.reduce((acc, project) => {
        if (project.rowType === 'total') {
          const newCols = project.columns.reduce((columnAcc, _, i) => {
            return {
              ...columnAcc,
              [project.columns[i].fieldId]: project.columns[i].value,
            };
          }, {});
          return [
            ...acc,
            {
              id: project.rowId,
              ...newCols,
              status: project.services.status,
              level: project.services.level,
              isParent: project.services.isParent,
            },
          ];
        }
        return [];
      }, []),
    [projects],
  );
  const projectRows = formattedProjectsRows();
  const projectsColumns = formattedProjectsColumns();
  const handleSorting = useCallback(
    newSortModel => {
      const validSortModel = newSortModel.filter(
        item => item && typeof item.field === 'string' && ['asc', 'desc'].includes(item.sort),
      );

      setSortModel(validSortModel);
      changeSorting(validSortModel);
    },
    [changeSorting],
  );
  return (
    <>
      <DataGridPro
        hideFooter
        disableColumnFilter
        disableRowSelectionOnClick
        disableColumnMenu
        columns={projectsColumns}
        rows={projectRows}
        className={dataGridTableStyle.DataGridTableRoot}
        getRowId={item => item.id}
        sortModel={Array.isArray(sortModel) ? sortModel : []}
        onSortModelChange={handleSorting}
        sortingMode="server"
        slots={{
          columnUnsortedIcon: () => <SortIcon width="20" height="20" viewBox="0 0 20 20" />,
          columnSortedAscendingIcon: () => <SortIcon direction="up" width="20" height="20" viewBox="0 0 20 20" />,
          columnSortedDescendingIcon: () => <SortIcon direction="down" width="20" height="20" viewBox="0 0 20 20" />,
        }}
        getRowClassName={params => params.formattedValue && 'groupRow'}
        getRowHeight={() => 'auto'}
      />
    </>
  );
};
export default injectIntl(ProjectsListView);
