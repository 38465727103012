export const generateRedirectUrl = ({
  host,
  path = '',
  redmineToken,
  isWithAuth = false,
  skipRedirection = false,
  backUrl,
}) => {
  const newBackUrl = backUrl ? backUrl : '';
  switch (host) {
    case 'https://x-lance.lbx.for.uz': {
      return `https://x-admin.lbx.for.uz${path}${isWithAuth ? `/my/auth?api_auth_token=${redmineToken}` : ''}${
        isWithAuth ? '&' : '?'
      }incoming_url=${window.location.origin}${newBackUrl !== '' ? `&back_url=${newBackUrl}` : ''}${
        skipRedirection ? '&X-Lance-Skip-Redirection=true' : ''
      }`;
    }
    case 'https://xlstudy.dafr.loc': {
      return `https://rm.xlstudy.dafr.loc${path}${isWithAuth ? `/my/auth?api_auth_token=${redmineToken}` : ''}${
        isWithAuth ? '&' : '?'
      }incoming_url=${window.location.origin}${newBackUrl !== '' ? `&back_url=${newBackUrl}` : ''}${
        skipRedirection ? '&X-Lance-Skip-Redirection=true' : ''
      }`;
    }
    case 'https://test-x-lance.concept-software.ru': {
      return `https://test-x-admin.concept-software.ru${path}${
        isWithAuth ? `/my/auth?api_auth_token=${redmineToken}` : ''
      }${isWithAuth ? '&' : '?'}incoming_url=${window.location.origin}${
        newBackUrl !== '' ? `&back_url=${newBackUrl}` : ''
      }${skipRedirection ? '&X-Lance-Skip-Redirection=true' : ''}`;
    }
    case 'https://x-lance.concept-software.ru': {
      return `https://x-admin.concept-software.ru${path}${isWithAuth ? `/my/auth?api_auth_token=${redmineToken}` : ''}${
        isWithAuth ? '&' : '?'
      }incoming_url=${window.location.origin}${newBackUrl !== '' ? `&back_url=${newBackUrl}` : ''}${
        skipRedirection ? '&X-Lance-Skip-Redirection=true' : ''
      }`;
    }
    case 'https://xlance-demo.processtech.ru': {
      return `https://rm.xlance-demo.processtech.ru${path}${
        isWithAuth ? `/my/auth?api_auth_token=${redmineToken}` : ''
      }${isWithAuth ? '&' : '?'}incoming_url=${window.location.origin}${
        newBackUrl !== '' ? `&back_url=${newBackUrl}` : ''
      }${skipRedirection ? '&X-Lance-Skip-Redirection=true' : ''}`;
    }
    case 'https://demo2.x-lance.ru': {
      return `https://rm.demo2.x-lance.ru${path}${isWithAuth ? `/my/auth?api_auth_token=${redmineToken}` : ''}${
        isWithAuth ? '&' : '?'
      }incoming_url=${window.location.origin}${backUrl !== '' ? `&back_url=${backUrl}` : ''}${
        skipRedirection ? '&X-Lance-Skip-Redirection=true' : ''
      }`;
    }
    case 'https://dochubdemo.rbdata.group': {
      return `https://dochubrmdemo.rbdata.group${path}${isWithAuth ? `/my/auth?api_auth_token=${redmineToken}` : ''}${
        isWithAuth ? '&' : '?'
      }incoming_url=${window.location.origin}${newBackUrl !== '' ? `&back_url=${newBackUrl}` : ''}${
        skipRedirection ? '&X-Lance-Skip-Redirection=true' : ''
      }`;
    }
    case 'https://xl.davrbank.uz': {
      return `https://xladmin.davrbank.uz${path}${isWithAuth ? `/my/auth?api_auth_token=${redmineToken}` : ''}${
        isWithAuth ? '&' : '?'
      }incoming_url=${window.location.origin}${newBackUrl !== '' ? `&back_url=${newBackUrl}` : ''}${
        skipRedirection ? '&X-Lance-Skip-Redirection=true' : ''
      }`;
    }
    case 'https://rbdatagroup.x-lance.ru': {
      return `https://redmine.rbdata.group${path}${isWithAuth ? `/my/auth?api_auth_token=${redmineToken}` : ''}${
        isWithAuth ? '&' : '?'
      }incoming_url=${window.location.origin}${newBackUrl !== '' ? `&back_url=${newBackUrl}` : ''}${
        skipRedirection ? '&X-Lance-Skip-Redirection=true' : ''
      }`;
    }
    case 'https://opt2.cybernix.ru': {
      return `https://vg-test.cybernix.ru:3443${path}${isWithAuth ? `/my/auth?api_auth_token=${redmineToken}` : ''}${
        isWithAuth ? '&' : '?'
      }incoming_url=${window.location.origin}${newBackUrl !== '' ? `&back_url=${newBackUrl}` : ''}${
        skipRedirection ? '&X-Lance-Skip-Redirection=true' : ''
      }`;
    }
    case 'https://opt2.cybernix.ru:10062': {
      return `https://vg-test.cybernix.ru:10053${path}${isWithAuth ? `/my/auth?api_auth_token=${redmineToken}` : ''}${
        isWithAuth ? '&' : '?'
      }incoming_url=${window.location.origin}${newBackUrl !== '' ? `&back_url=${newBackUrl}` : ''}${
        skipRedirection ? '&X-Lance-Skip-Redirection=true' : ''
      }`;
    }
    case 'https://opt2.rbtechnologies.ru:10061': {
      return `https://opt2.rbtechnologies.ru:10051${path}${
        isWithAuth ? `/my/auth?api_auth_token=${redmineToken}` : ''
      }${isWithAuth ? '&' : '?'}incoming_url=${window.location.origin}${
        newBackUrl !== '' ? `&back_url=${newBackUrl}` : ''
      }${skipRedirection ? '&X-Lance-Skip-Redirection=true' : ''}`;
    }
    case 'https://opt2.rbtechnologies.ru:10062': {
      return `https://opt2.rbtechnologies.ru:10053${path}${
        isWithAuth ? `/my/auth?api_auth_token=${redmineToken}` : ''
      }${isWithAuth ? '&' : '?'}incoming_url=${window.location.origin}${
        newBackUrl !== '' ? `&back_url=${newBackUrl}` : ''
      }${skipRedirection ? '&X-Lance-Skip-Redirection=true' : ''}`;
    }
    case 'https://demo-new.x-lance.ru': {
      return `https://opt2.rbtechnologies.ru:10052${path}${
        isWithAuth ? `/my/auth?api_auth_token=${redmineToken}` : ''
      }${isWithAuth ? '&' : '?'}incoming_url=${window.location.origin}${
        newBackUrl !== '' ? `&back_url=${newBackUrl}` : ''
      }${skipRedirection ? '&X-Lance-Skip-Redirection=true' : ''}`;
    }
    case 'https://old-2935-redmine.rbtechnologies.ru': {
      return `https://redmine.rbtechnologies.ru${path}${isWithAuth ? `/my/auth?api_auth_token=${redmineToken}` : ''}${
        isWithAuth ? '&' : '?'
      }incoming_url=${window.location.origin}${newBackUrl !== '' ? `&back_url=${newBackUrl}` : ''}${
        skipRedirection ? '&X-Lance-Skip-Redirection=true' : ''
      }`;
    }
    case 'https://rbtechnologies.x-lance.ru': {
      return `https://redmine.rbtechnologies.ru${path}${isWithAuth ? `/my/auth?api_auth_token=${redmineToken}` : ''}${
        isWithAuth ? '&' : '?'
      }incoming_url=${window.location.origin}${newBackUrl !== '' ? `&back_url=${newBackUrl}` : ''}${
        skipRedirection ? '&X-Lance-Skip-Redirection=true' : ''
      }`;
    }
    case 'https://idft.x-lance.ru': {
      return `https://redmine.idft.ru${path}${isWithAuth ? `/my/auth?api_auth_token=${redmineToken}` : ''}${
        isWithAuth ? '&' : '?'
      }incoming_url=${window.location.origin}${newBackUrl !== '' ? `&back_url=${newBackUrl}` : ''}${
        skipRedirection ? '&X-Lance-Skip-Redirection=true' : ''
      }`;
    }
    case 'https://cybernix.x-lance.ru': {
      return `https://redmine.cybernix.ru${path}${isWithAuth ? `/my/auth?api_auth_token=${redmineToken}` : ''}${
        isWithAuth ? '&' : '?'
      }incoming_url=${window.location.origin}${newBackUrl !== '' ? `&back_url=${newBackUrl}` : ''}${
        skipRedirection ? '&X-Lance-Skip-Redirection=true' : ''
      }`;
    }
    case 'https://xlancerb.fst.sh': {
      return `https://redmine.fst.sh${path}${isWithAuth ? `/my/auth?api_auth_token=${redmineToken}` : ''}${
        isWithAuth ? '&' : '?'
      }incoming_url=${window.location.origin}${newBackUrl !== '' ? `&back_url=${newBackUrl}` : ''}${
        skipRedirection ? '&X-Lance-Skip-Redirection=true' : ''
      }`;
    }
    case 'http://192.168.96.30': {
      return `http://192.168.96.30:8080${path}${isWithAuth ? `/my/auth?api_auth_token=${redmineToken}` : ''}${
        isWithAuth ? '&' : '?'
      }incoming_url=${window.location.origin}${newBackUrl !== '' ? `&back_url=${newBackUrl}` : ''}${
        skipRedirection ? '&X-Lance-Skip-Redirection=true' : ''
      }`;
    }
    case 'https://opt2.rbtechnologies.ru:10063': {
      return `https://opt2.rbtechnologies.ru:10054${path}${
        isWithAuth ? `/my/auth?api_auth_token=${redmineToken}` : ''
      }${isWithAuth ? '&' : '?'}incoming_url=${window.location.origin}${
        newBackUrl !== '' ? `&back_url=${newBackUrl}` : ''
      }${skipRedirection ? '&X-Lance-Skip-Redirection=true' : ''}`;
    }
    default: {
      return host + newBackUrl;
    }
  }
};
