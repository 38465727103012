import React, { memo, useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  Box,
  Checkbox,
  ClickAwayListener,
  Grid,
  IconButton,
  PaginationItem,
  Popover,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  DataGridPro,
  gridPageCountSelector,
  GridPagination,
  useGridApiContext,
  useGridApiRef,
  useGridSelector,
} from '@mui/x-data-grid-pro';
import {
  deleteIssue,
  deleteIssueBulk,
  patchIssues,
  getIssuesActionMenu,
  deleteSavedQuery,
  confirmationInitial,
} from '../../../../packages/common/api';
import CheckboxIcon from '../../../../packages/common/shared-ui/src/icons/CheckboxIcon';
import CheckboxIconChecked from '../../../../packages/common/shared-ui/src/icons/CheckboxIconChecked';
import SortIcon from '../../../../packages/common/shared-ui/src/icons/SortIcon';
import ChevronIcon from '../../../../packages/common/shared-ui/src/icons/ChevronIcon';
import DownloadButton from '../../../../packages/common/shared-ui/src/icons/DownloadButton';
import { getTranslatedText } from '../../../../packages/common/utils/getTranslatedText';
import SpentTimeToolbar from '../IssuesToolbar';
import TableSecondStepPopover from '../TableSecondStepPopover';
import TreeArrowRight from 'Common/shared-ui/src/icons/TreeArrowRight';
import useStyles from './IssuesTable.styles';
import clsx from 'clsx';
import { generateRedirectUrl } from '../../../../packages/common/utils/getRedirectUrl';
import { Link, useParams } from 'react-router-dom';
import { store } from '../../app/redux';
import WarningModal from '../../../../packages/common/shared-ui/src/components/WarningModal';
import ParentIssueDropdown from '../../../../packages/common/shared-ui/src/icons/ParentIssueDropdown';
import dataGridTableStyles from '../../../../packages/common/shared-ui/src/styles/DataGridTable.styles';
import MuiPagination from '@mui/material/Pagination';
import PaginationNextButtonIcon from '../../../../packages/common/shared-ui/src/icons/PaginationNextButtonIcon';
import PaginationPreviousButtonIcon from '../../../../packages/common/shared-ui/src/icons/PaginationPreviousButtonIcon';

const IssuesTable = ({
  intl,
  issuesRowsData,
  tableErrorMessage,
  contextMenuData,
  setContextMenuData,
  currentType,
  issuesColumnsData,
  changePagination,
  changeSorting,
  paginationData,
}) => {
  //const issuesData = formattedTimesheetsData();
  const apiRef = useGridApiRef();
  const classes = useStyles();
  const dataGridTableStyle = dataGridTableStyles();
  const navigate = useNavigate();
  const state = store.getState();
  const [blockConfirm, setBlockConfirm] = useState(false);
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.maxWidth('lg'));
  const [tableSelectedValues, setTableSelectedValues] = useState([]);
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
  const [sortModel, setSortModel] = useState([]);
  const [, forceUpdate] = useReducer(x => x - 1, 0);
  const [showDeleteIssueWarning, setShowDeleteIssueWarning] = useState(false);
  const [issuesRows, setIssuesRows] = useState([]);
  const [issuesColumns, setIssuesColumns] = useState([]);
  const [pagination, setPagination] = useState({ count: 0, page: 0 });
  const pageParams = useParams();

  const cellRenderer = useCallback(
    ({ props, column }) => {
      const linkFields = ['project', 'user', 'issue', 'issueId'];
      if (!props.value && props.row.groupName) {
        if (props.colDef.position === -1) {
          return (
            <Box display={'flex'} alignItems={'center'}>
              <Typography variant="h4" fontWeight={700}>
                {props.row.groupName}
              </Typography>
              {/*              <Typography variant="h5">
                {props.row.columns &&
                  props.row.columns.map((item, index) => (
                    <>
                      <Box component={'span'} marginLeft={'10px'}>
                        {item.name}
                      </Box>
                      <Box component={'span'} marginLeft={'8px'} fontWeight={700}>
                        {item.value}
                      </Box>
                    </>
                  ))}
              </Typography>*/}
            </Box>
          );
        }
      } else {
        if (linkFields.includes(column.fieldType) || column.fieldId === 'issue.subject') {
          if (column.fieldType === 'issueId') {
            props.value = props.id + '$$' + props.id + '$$issue';
          }
          const splittedColumnName = props?.value?.split('$$');

          return (
            <Box
              sx={{
                wordBreak: 'break-word',
                display: 'flex',
                alignItems: 'center',
                padding:
                  column.fieldId === 'issue.subject' && props.row.level !== 0
                    ? `0 0 0 ${
                        props.row.hasSubtasks && props.row.level > 0
                          ? `${props.row.level * 16}px!important`
                          : !props.row.hasSubtasks && props.row.level > 0
                          ? `${props.row.level * 16 + 16}px!important`
                          : 0
                      }`
                    : 0,
              }}
            >
              {column.fieldId === 'issue.subject' && props.row.hasSubtasks && (
                <ParentIssueDropdown></ParentIssueDropdown>
              )}
              <Link to={handleGetRedirectUrl({ id: splittedColumnName[1], columnType: splittedColumnName[2] })}>
                {splittedColumnName[0]}
              </Link>
            </Box>
          );
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleGetRedirectUrl],
  );

  useEffect(() => {
    if (paginationData) {
      setPagination(() => ({ page: paginationData.offset / 100, count: Number(paginationData.total) }));
    }
  }, [paginationData]);

  useEffect(() => {
    if (issuesRowsData) {
      const data = issuesRowsData.reduce((acc, issue) => {
        if (issue.rowType === 'normal') {
          const newCols = issue.columns.reduce((columnAcc, _, i) => {
            if (issue.columns[i].fieldId === 'issue.subject') {
              issue.columns[i].fieldType = 'issue.subject';
            }
            switch (issue.columns[i].fieldId) {
              case 'issue.issue':
              case 'issue.user':
              case 'issue.parent_issue':
                return {
                  ...columnAcc,
                  [issue.columns[i]
                    .fieldId]: `${issue.columns[i].value}$$${issue.columns[i].valueId}$$${issue.columns[i].fieldType}`,
                };
              case 'issue.subject':
                return {
                  ...columnAcc,
                  [issue.columns[i]
                    .fieldId]: `${issue.columns[i].value}$$${issue.rowId}$$${issue.columns[i].fieldType}`,
                };
              case 'issue.tracker':
                return {
                  ...columnAcc,
                  [issue.columns[i].fieldId]: issue.columns[i].value,
                  [issue.columns[i].fieldId + 'Id']: issue.columns[i].valueId,
                };
              case 'issue.project':
                return {
                  ...columnAcc,
                  [issue.columns[i].fieldId]: issue.columns[i].value,
                  [issue.columns[i].fieldId + 'Id']: issue.columns[i].valueId,
                };
              default:
                return {
                  ...columnAcc,
                  [issue.columns[i].fieldId]: issue.columns[i].value,
                };
            }
          }, {});
          return [
            ...acc,
            { id: issue.rowId, ...newCols, level: issue.services.level, hasSubtasks: issue.services.hasSubtasks },
          ];
        }
        if (issue.rowType === 'group') {
          const newCols =
            issue.columns.length > 0
              ? issue.columns.reduce((columnAcc, item, i) => {
                  columnAcc.push({ name: item.fieldId, value: item.value });
                  return columnAcc;
                }, [])
              : {
                  groupName: issue.rowName,
                };
          return [
            ...acc,
            {
              id: issue.rowId,
              issueId: issue.rowId,
              groupName: issue.rowName,
              columns: newCols,
            },
          ];
        }
        return [];
      }, []);
      setIssuesRows(data);
    }
  }, [issuesRowsData]);

  useEffect(() => {
    if (issuesColumnsData) {
      const issueIdField = {
        instance: 'issue',
        field: 'id',
        fieldId: 'issue.gantt_id',
        fieldName: 'Id',
        fieldType: 'issueId',
        isChecked: true,
        isCustom: false,
        position: -1,
      };
      const smallFields = ['date', 'hours', 'float', 'bool', 'issueId'];
      let newColumns = [issueIdField].concat(issuesColumnsData);
      const data = newColumns.map(column => {
        const headerName = getTranslatedText(intl, 'filter', column.fieldId.split('-').join('_'), column.fieldName);
        //let headerNameWidth = (headerName.length / 2) * 10 + 60;
        let padding = 25;
        if (headerName.length <= 6) {
          padding = 40;
        }
        let headerNameWidth = headerName.length * 7 + padding;
        let columnWidth = 'auto';
        let columnFlex = 1;
        let columnType = '';
        switch (column.fieldType) {
          case 'issueId':
          case 'bool':
            columnType = 'number';
            //columnFlex = 0;
            break;
          case 'issue_status':
          case 'project':
          case 'issue':
          case 'list':
            columnType = 'string';
            break;
          case 'date':
          case 'hours':
          case 'float':
            columnType = 'Date';
            break;
          default:
            columnType = 'string';
            break;
        }
        return {
          field: column.fieldId,
          headerName: headerName,
          renderHeader: props => (
            <Typography variant="h4" fontWeight={700}>
              {props.colDef.headerName}
            </Typography>
          ),
          disableExport: false,
          position: column.position,
          //sortable: query.groups !== column.fieldId,
          //flex: columnFlex,
          //width: headerNameWidth,
          minWidth: headerNameWidth,
          //minWidth: headerNameWidth * 1.2,
          //width: headerNameWidth,
          maxWidth: 500,
          renderCell: props => cellRenderer({ props, column }),
          type: columnType,
          cellClassName: props => {
            let cellClasses = [];
            if (!props.row.groupName) {
              cellClasses.push('tableCell');
            }
            if (props.row.groupName) {
              cellClasses.push('tableCell tableGroupCell');
            }
            if (column.fieldId === 'issue.subject') {
              switch (props.row.level) {
                case 1:
                  cellClasses.push('tableCelllevelOne');
                  break;
                case 2:
                  cellClasses.push('tableCelllevelTwo');
                  break;
                case 3:
                  cellClasses.push('tableCelllevelThree');
                  break;
                default:
                  break;
              }
            }
            return cellClasses;
          },
        };
      });
      setIssuesColumns(data);
    }
  }, [cellRenderer, intl, issuesColumnsData]);

  const handleGetRedirectUrl = useCallback(
    ({ id, columnType }) => {
      const issueLink = pageParams.projectId ? `/projects/${pageParams.projectId}/issues/${id}` : `/issues/${id}`;
      switch (columnType) {
        case 'user':
          return generateRedirectUrl({ host: window.location.origin, path: `/users/${id}` });
        case 'project':
          return `${window.location.origin}/projects/${id}`;
        case 'issue':
        case 'issue.subject':
        case 'id':
          return issueLink;
        default:
          return '';
      }
    },
    [pageParams.projectId],
  );
  const contextPopperStylePosition = useRef({
    left: 0,
    top: 0,
    maxWidth: 180,
  });
  const handleCopyIssue = useCallback(() => {
    const tracker = contextMenuData.currentRow['issue.trackerId'];
    const project = contextMenuData.currentRow['issue.projectId'];
    let url = `/issues/${contextMenuData.currentRow.id}/copy?trackerId=${tracker}&projectId=${project}&list`;
    if (contextMenuData.currentRows?.length > 1) {
      const issuesIds = contextMenuData.currentRows.join('&ids%5B%5D=');
      url = url + 'bulk_edit?copy=1&ids%5B%5D=' + issuesIds;
    }
    if (pageParams.projectId) {
      url = `/projects/${pageParams.projectId}/issues/${contextMenuData.currentRow.id}/copy?trackerId=${tracker}&list`;
    }
    //url = generateRedirectUrl({ host: window.location.origin, path: url });
    window.open(url, '_blank');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextMenuData, pageParams.projectId]);
  const handleEditIssue = useCallback(() => {
    let url = '';
    url = `/issues/${contextMenuData.currentRow.id}/edit?list`;
    if (pageParams.projectId) {
      url = `/projects/${pageParams.projectId}/issues/${contextMenuData.currentRow.id}/edit?list`;
    }
    if (contextMenuData.currentRows?.length > 1) {
      const issuesIds = contextMenuData.currentRows.join(',');
      url = `/issues/bulk_edit?issues=${issuesIds}&list`;
      if (pageParams.projectId) {
        url = `/projects/${pageParams.projectId}/issues/bulk_edit?issues=${issuesIds}&list`;
      }
    }
    //url = generateRedirectUrl({ host: window.location.origin, path: url });
    window.open(url, '_blank');
  }, [contextMenuData, pageParams.projectId]);

  const handleDeleteIssue = useCallback(
    async (force = false) => {
      try {
        let response;
        let params = {};
        force && (params.force = true);
        if (contextMenuData.currentRows?.length > 1) {
          response = await deleteIssueBulk(
            contextMenuData.currentRows.map(item => Number(item)),
            params,
          );
        } else {
          response = await deleteIssue(contextMenuData.currentRow.id, params);
        }
        if (response) {
          //updateTable({
          //  totals: typeof query.totals != 'undefined' ? query.totals : 'null',
          //  fields: typeof query.fields != 'undefined' ? query.fields : 'null',
          //  [typeof query.filters != 'undefined' ? 'filters' : null]: query.filters,
          //  [query.groups !== 0 ? 'groups' : null]: query.groups,
          //  [query.sorts !== 0 ? 'sorts' : null]: query.sorts,
          //});
        }
      } catch (error) {
        error.response.status === 420 && setShowDeleteIssueWarning(true);
        console.error('ERROR WITH DELETE', error);
      }
    },
    [contextMenuData.currentRow.id, contextMenuData.currentRows],
  );
  const handleInitConfirmation = useCallback(async () => {
    try {
      setBlockConfirm(true);
      let rows = [];
      contextMenuData.currentRow && rows.push(Number(contextMenuData.currentRow.id));
      contextMenuData.currentRows.length > 0 && (rows = contextMenuData.currentRows.map(item => Number(item)));
      const { data: response } = await confirmationInitial(rows);
      if (response) {
        setBlockConfirm(false);
        navigate(`/confirmations/${Number(response)}`);
      }
    } catch (error) {
      console.error('ERROR WITH POST CONFIRMATION INITIAL', error);
      setBlockConfirm(false);
    }
  }, [contextMenuData.currentRow, contextMenuData.currentRows, navigate]);

  const handleReinitConfirmation = useCallback(async () => {
    let issuesIds = [];
    contextMenuData.currentRow && issuesIds.push(Number(contextMenuData.currentRow.id));
    contextMenuData.currentRows.length > 0 && (issuesIds = contextMenuData.currentRows.join(','));
    navigate(`/confirmation/reinit?issues=${issuesIds}`);
  }, [contextMenuData, navigate]);

  const handleChooseSubItemType = useCallback(
    (type, value, itemPosition) => {
      const itemsCount = tableSelectedValues.length === 1 ? 5 : 4;
      const valueLength = value.length;
      setContextMenuData(prevState => {
        return {
          ...prevState,
          isSecondStepVisible: !prevState.isSecondStepVisible,
          secondPopoverStepValue: value,
          secondStepType: type,
          positionInfo: {
            itemPosition: itemPosition,
            positionDelta: itemsCount - itemPosition - valueLength,
            totalCount: valueLength,
          },
        };
      });
    },
    [setContextMenuData, tableSelectedValues.length],
  );

  const handleChooseSubItem = useCallback(
    async (value, type) => {
      setIsContextMenuOpen(false);
      let updateIssuesQuery = {
        ids: tableSelectedValues.map(item => Number(item)),
        issue: {},
      };
      if (type === 'assigned') {
        setContextMenuData(prevState => {
          const newAssignedTo = prevState.data.assignedTo.map(item => {
            item.isChecked = false;
            if (item.userId === value) {
              item.isChecked = true;
            }
            return item;
          });
          prevState.data.assignedTo = newAssignedTo;
          return { ...prevState, isSecondStepVisible: false };
        });
        updateIssuesQuery.issue.assignedTo = value;
      } else if (type === 'status') {
        setContextMenuData(prevState => {
          const newStatuses = prevState.data.statuses.map(item => {
            item.isChecked = false;
            if (item.statusId === value) {
              item.isChecked = true;
            }
            return item;
          });
          prevState.data.statuses = newStatuses;
          return { ...prevState, isSecondStepVisible: false };
        });
        updateIssuesQuery.issue.statusId = value;
      }
      const { responce } = await patchIssues(updateIssuesQuery);
      //updateTable({
      //  totals: typeof query.totals != 'undefined' ? query.totals : 'null',
      //  fields: typeof query.fields != 'undefined' ? query.fields : 'null',
      //  [typeof query.filters != 'undefined' ? 'filters' : null]: query.filters,
      //  [query.groups !== 0 ? 'groups' : null]: query.groups,
      //  [query.sorts !== 0 ? 'sorts' : null]: query.sorts,
      //});
    },
    [setContextMenuData, tableSelectedValues],
  );

  const handleUnselect = useCallback(() => {
    setIsContextMenuOpen(false);
    setContextMenuData(prevState => ({
      ...prevState,
      isSecondStepVisible: false,
      currentRow: {},
    }));
    setTableSelectedValues([]);
  }, [setContextMenuData]);

  const { current: dataGridComponents } = useRef({
    ColumnUnsortedIcon: () => <SortIcon width="20" height="20" viewBox="0 0 20 20" />,
    ColumnSortedAscendingIcon: () => <SortIcon direction="up" width="20" height="20" viewBox="0 0 20 20" />,
    ColumnSortedDescendingIcon: () => <SortIcon direction="down" width="20" height="20" viewBox="0 0 20 20" />,
    ExportIcon: () => {
      return (
        <IconButton disableRipple disabled>
          <DownloadButton width="24" height="24" viewBox="0 0 24 24" />
        </IconButton>
      );
    },
    Toolbar: SpentTimeToolbar,
    BaseCheckbox: props => {
      return (
        <Checkbox
          {...props}
          disableRipple
          icon={<CheckboxIcon width="12" height="12" viewBox="0 0 12 12" />}
          checkedIcon={<CheckboxIconChecked width="12" height="12" viewBox="0 0 12 12" />}
          indeterminateIcon={<CheckboxIcon width="12" height="12" viewBox="0 0 12 12" />}
        />
      );
    },
  });

  const isDisabledMenuItems = {
    isProjectClosed: true,
    isVisibleApprove:
      !contextMenuData.currentRow.services?.canApprove || contextMenuData.currentRow.services?.isProjectClosed,
    isVisibleCanEditOrOvertimeItem:
      contextMenuData.currentRow.services?.isApproved ||
      !contextMenuData.currentRow.services?.canEdit ||
      contextMenuData.currentRow.services?.isProjectClosed,
  };

  const selectRow = useCallback(
    ({ rowId, clear = false }) => {
      setTableSelectedValues(prevState => {
        if (clear) {
          return [rowId];
        }
        if (prevState.includes(rowId)) {
          return prevState.filter(id => id !== rowId);
        }
        return [...prevState, rowId];
      });
      forceUpdate();
    },
    [setTableSelectedValues],
  );

  const handleRowContextMenu = useCallback(
    async e => {
      e.preventDefault();
      if (!e.currentTarget) {
        return;
      }
      const groupRow = e.currentTarget.className.includes('groupRow');
      const rowId = e.currentTarget.getAttribute('data-id');
      const selectedRows = tableSelectedValues.length > 0 ? tableSelectedValues.join('||') : rowId;
      const contexIssueMenuData = await getIssuesActionMenu(selectedRows);
      if (!isContextMenuOpen && !groupRow) {
        const currentRow = issuesRows.find(row => row.id === rowId);
        setContextMenuData(prevState => {
          prevState.currentRow = currentRow;
          prevState.currentRows = tableSelectedValues;
          prevState.isSecondStepVisible = false;
          prevState.secondStepType = '';
          prevState.data = contexIssueMenuData.data;
          return prevState;
        });
        contextPopperStylePosition.current.left = e.clientX;
        contextPopperStylePosition.current.top = e.clientY;
        if (!tableSelectedValues.includes(rowId)) {
          selectRow({ rowId: rowId });
        }
        setIsContextMenuOpen(true);
        forceUpdate();
      }
    },
    [tableSelectedValues, issuesRows, isContextMenuOpen, setContextMenuData, selectRow],
  );

  const handleModalAction = useCallback(
    async e => {
      switch (e.currentTarget.attributes.value.value) {
        case 'save':
          await handleDeleteIssue(true);
          break;
        case 'delete':
          setShowDeleteIssueWarning(false);
          break;
      }
    },
    [handleDeleteIssue],
  );

  function Pagination({ page, onPageChange, className }) {
    return (
      <MuiPagination
        color="primary"
        className={className}
        count={pagination.count}
        siblingCount={1}
        boundaryCount={0}
        page={pagination.page + 1}
        renderItem={item => {
          if (item.type === 'next') {
            return (
              !item.disabled && (
                <Box>
                  {intl.formatMessage({ id: 'pagination_next' })}
                  <PaginationItem
                    {...item}
                    slots={{
                      next: PaginationNextButtonIcon,
                    }}
                  ></PaginationItem>
                </Box>
              )
            );
          } else if (item.type === 'previous') {
            return (
              !item.disabled && (
                <Box>
                  <PaginationItem
                    {...item}
                    slots={{
                      previous: PaginationPreviousButtonIcon,
                    }}
                  ></PaginationItem>
                  {intl.formatMessage({ id: 'pagination_previous' })}
                </Box>
              )
            );
          } else {
            return <PaginationItem {...item}></PaginationItem>;
          }
        }}
        onChange={(event, newPage) => {
          changePagination(newPage - 1);
        }}
      />
    );
  }
  function CustomPagination(props) {
    return (
      <GridPagination {...props} labelDisplayedRows={() => ''} rowsPerPageOptions={[]} ActionsComponent={Pagination} />
    );
  }
  const handleSorting = useCallback(
    newSortModel => {
      const validSortModel = newSortModel.filter(
        item => item && typeof item.field === 'string' && ['asc', 'desc'].includes(item.sort),
      );

      setSortModel(validSortModel);
      changeSorting(validSortModel);
    },
    [changeSorting],
  );
  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      apiRef.current.autosizeColumns({ includeOutliers: true, includeHeaders: true, expand: true });
    }, 500);
    return () => {
      clearInterval(timeoutId);
    };
  }, [apiRef]);

  return (
    <>
      {isSmallScreen ? (
        <></>
      ) : (
        <>
          <WarningModal
            issue
            simple
            isWarningModalOpen={showDeleteIssueWarning}
            handleToggle={handleModalAction}
            closeWarningModal={() => setShowDeleteIssueWarning(false)}
            title={
              /* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */
              `Вы уверены, что хотите удалить выбранные задачи? Так же будут удалены подзадачи и таймшиты. Удалить?`
            }
          />
          {isContextMenuOpen && (
            <ClickAwayListener onClickAway={handleUnselect}>
              <Popover
                keepMounted
                open={isContextMenuOpen}
                style={contextPopperStylePosition.current}
                anchorPosition={contextPopperStylePosition.current}
                className={classes.contextPopover}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                anchorReference="none"
              >
                <TableSecondStepPopover
                  value={contextMenuData.secondPopoverStepValue}
                  type={contextMenuData.secondStepType}
                  data={contextMenuData}
                  handleChooseSubItem={handleChooseSubItem}
                  isSecondStepVisible={contextMenuData.isSecondStepVisible}
                  contextPopperStylePosition={contextPopperStylePosition}
                  positionInfo={contextMenuData.positionInfo}
                />
                <Grid container direction="column">
                  <Grid
                    item
                    xs={12}
                    padding="6px 20px"
                    className={clsx(classes.contextMenuItem, {
                      [classes.contextMenuItemDisabled]: !contextMenuData.data.canEdit,
                    })}
                    onClick={handleEditIssue}
                  >
                    {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                    <Typography variant="h5">Редактировать</Typography>
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-between"
                    padding="6px 0 6px 20px"
                    className={clsx(classes.contextMenuItem, {
                      [classes.contextMenuItemDisabled]: !contextMenuData.data.canEdit,
                    })}
                    wrap="nowrap"
                    alignItems="center"
                    onClick={() => handleChooseSubItemType('status', contextMenuData.data.statuses, 1)}
                  >
                    {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                    <Typography variant="h5">Статус</Typography>
                    <Box width={24} height={24}>
                      <ChevronIcon direction="right" color="rgba(228, 228, 239, 1)" viewBox="0 0 24 24" />
                    </Box>
                  </Grid>
                  {contextMenuData.data.canInitConfirmation && (
                    <Grid
                      item
                      xs={12}
                      disabled={blockConfirm}
                      padding="6px 20px"
                      className={classes.contextMenuItem}
                      onClick={handleInitConfirmation}
                    >
                      {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                      <Typography variant="h5">Согласовать документ</Typography>
                    </Grid>
                  )}
                  {contextMenuData.data.canReinitConfirmation && (
                    <Grid
                      item
                      xs={12}
                      padding="6px 20px"
                      className={classes.contextMenuItem}
                      onClick={handleReinitConfirmation}
                    >
                      {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                      <Typography variant="h5">Согласовать изменения</Typography>
                    </Grid>
                  )}
                  <Grid
                    container
                    justifyContent="space-between"
                    padding="6px 0 6px 20px"
                    className={clsx(classes.contextMenuItem, {
                      [classes.contextMenuItemDisabled]: !contextMenuData.data.canEdit,
                    })}
                    wrap="nowrap"
                    alignItems="center"
                    onClick={() => handleChooseSubItemType('assigned', contextMenuData.data.assignedTo, 2)}
                  >
                    {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                    <Typography variant="h5">Назначена</Typography>
                    <Box width={24} height={24}>
                      <ChevronIcon direction="right" color="rgba(228, 228, 239, 1)" viewBox="0 0 24 24" />
                    </Box>
                  </Grid>
                  {tableSelectedValues.length === 1 && (
                    <Grid
                      item
                      xs={12}
                      padding="6px 20px"
                      className={clsx(classes.contextMenuItem, {
                        [classes.contextMenuItemDisabled]: !contextMenuData.data.canCopy,
                      })}
                      onClick={() => handleCopyIssue()}
                    >
                      {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                      <Typography variant="h5">Копировать</Typography>
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    padding="6px 20px"
                    className={clsx(classes.contextMenuItem, {
                      [classes.contextMenuItemDisabled]: !contextMenuData.data.canDelete,
                    })}
                    onClick={() => handleDeleteIssue()}
                  >
                    {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                    <Typography variant="h5">Удалить</Typography>
                  </Grid>
                </Grid>
              </Popover>
            </ClickAwayListener>
          )}
          <Grid container flexDirection={'column'} flex={1} height={'100%'} overflow={'hidden'}>
            <Grid container justifyContent={tableErrorMessage ? 'space-between' : 'flex-end'}>
              {typeof tableErrorMessage === 'string' && (
                <Typography variant="h5" fontWeight={600} color="#E03737">
                  {tableErrorMessage}
                </Typography>
              )}
            </Grid>
            <DataGridPro
              apiRef={apiRef}
              pagination
              sx={{
                [`& .MuiDataGrid-columnHeader`]: {
                  maxHeight: 'none !important',
                  minHeight: '75px !important',
                  height: 'auto !important',
                  whiteSpace: 'inherit !important',
                  overflow: 'inherit !important',
                  lineHeight: '24px !important',
                },
                [`& .MuiDataGrid-columnHeaderTitle`]: {
                  whiteSpace: 'normal !important',
                  minHeight: '75px !important',
                },
                [`& .MuiDataGrid-columnHeaderTitleContainer`]: {
                  whiteSpace: 'normal !important',
                  minHeight: '75px !important',
                },
              }}
              columnHeaderHeight={70}
              {...{ ['checkboxSelection']: currentType !== 1 }}
              disableColumnFilter
              disableRowSelectionOnClick
              disableColumnMenu
              columns={issuesColumns}
              rows={issuesRows}
              className={dataGridTableStyle.DataGridTableRoot}
              rowSelectionModel={tableSelectedValues}
              onRowSelectionModelChange={newRowSelectionModel => {
                setTableSelectedValues(newRowSelectionModel);
              }}
              getRowId={item => item.id}
              sortModel={Array.isArray(sortModel) ? sortModel : []}
              onSortModelChange={handleSorting}
              sortingMode="server"
              slotProps={{
                row: {
                  onContextMenu: e => handleRowContextMenu(e),
                  style: { cursor: 'context-menu' },
                },
                baseCheckbox: {
                  icon: <CheckboxIcon width="12" height="12" viewBox="0 0 12 12" />,
                  checkedIcon: <CheckboxIconChecked width="12" height="12" viewBox="0 0 12 12" />,
                },
              }}
              slots={{
                pagination: CustomPagination,
                columnUnsortedIcon: () => <SortIcon width="20" height="20" viewBox="0 0 20 20" />,
                columnSortedAscendingIcon: () => <SortIcon direction="up" width="20" height="20" viewBox="0 0 20 20" />,
                columnSortedDescendingIcon: () => (
                  <SortIcon direction="down" width="20" height="20" viewBox="0 0 20 20" />
                ),
              }}
              getRowClassName={params => params.row.groupName && 'groupRow'}
              isRowSelectable={params => !params.row.groupName}
              getRowHeight={() => 'auto'}
            />
          </Grid>
        </>
      )}
    </>
  );
};

export default memo(IssuesTable);
