import { createSlice } from '@reduxjs/toolkit';
import { userSlice } from './userSlice';

const initialState = {
  canSaveAgileGlobalQueries: true,
};

export const permissonsSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    setGlobalQueriesPermission: (state, action) => {
      state.canSaveAgileGlobalQueries = action.payload;
    },
  },
});

export const { setGlobalQueriesPermission } = permissonsSlice.actions;

export default permissonsSlice.reducer;
