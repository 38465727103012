import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  menuItem: {
    transition: '.4s ease',
    minHeight: '32px',
    padding: '4px 0',
    paddingLeft: '63px',
    //cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& svg': {
      height: '24px',
      margin: '0 4px',
      cursor: 'pointer',
    },
    '&:hover': {
      backgroundColor: '#E1E4FA',
    },
  },
  menuSubItems: {
    '&.MuiPopover-root': {
      zIndex: 2000,
      width: 300,
    },
  },
  menuSubItemsPaper: {
    '&.MuiPaper-root': {
      maxWidth: 305,
      width: '100%',
      minHeight: 32,
      borderRadius: theme.spacing(0, 2, 2, 0),
      boxShadow: '0px 5px 10px -10px #212346',
    },
  },
  LeftDrawerRoot: {
    position: 'fixed',
    top: 60,
    bottom: 0,
    left: 0,
    height: '100vh',
    minWidth: 36,
    width: 36,
    backgroundColor: theme.palette.common.light,
    transition: 'all .3s ease',
    zIndex: 10,
    paddingBottom: theme.spacing(20),
    [theme.breakpoints.maxWidth('sm')]: {
      left: -999,
      top: 0,
      width: 0,
      minWidth: 0,
      backgroundColor: '#212346',
    },
  },
  content: {
    marginLeft: 36,
    marginTop: 60,
    overflowX: 'hidden',
    minHeight: '90vh',
    [theme.breakpoints.maxWidth('sm')]: {
      marginLeft: 0,
      marginTop: 80,
    },
  },
  hide: {
    opacity: 0,
    visibility: 'hidden',
    minWidth: 0,
  },
  drawerMenu: {
    marginTop: '8px',
    transition: 'all .1s ease',
    [theme.breakpoints.maxWidth('sm')]: {
      marginTop: theme.spacing(2.5),
    },
  },
  overlay: {
    position: 'fixed',
    width: '100%',
    height: '100vh',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    backgroundColor: 'rgba(31, 33, 72, 0.15)',
    zIndex: 1998,
  },
  AppLayoutRoot: {
    margin: '20px auto 0 auto',
    padding: '0px 44px',
    // maxWidth: 1280,
    [theme.breakpoints.minWidth('xxl')]: {
      // maxWidth: '95%',
    },
    [theme.breakpoints.maxWidth('xxl')]: {
      // maxWidth: 1280,
    },
    [theme.breakpoints.maxWidth(1330)]: {
      // maxWidth: 944,
    },
    [theme.breakpoints.maxWidth('lg')]: {
      // maxWidth: 717,
      padding: theme.spacing(0, 5),
    },
    [theme.breakpoints.maxWidth('sm')]: {
      maxWidth: 320,
      padding: theme.spacing(0, 2.5, 2.5),
    },
  },
  drawerPaper: {
    '& .MuiButtonBase-root.MuiIconButton-root': {
      margin: '32px auto 0 auto',
      height: '24px',
      width: '24px',
      [theme.breakpoints.maxWidth('sm')]: {
        margin: '0 8px 0 auto',
      },
    },
    minWidth: 36,
    boxShadow: 'unset !important',
    marginTop: theme.spacing(15),
    transform: 'unset !important',
    visibility: 'visible !important',
    pointerEvents: 'all !important',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    [theme.breakpoints.maxWidth('sm')]: {
      backgroundColor: 'transparent !important',
      minWidth: 250,
      height: 'auto!important',
    },
  },
  drawerOpen: {
    minWidth: 305,
    maxWidth: 305,
    overflowY: 'scroll',
    left: 0,
    zIndex: 1999,
    paddingTop: '38px',
    boxShadow:
      '0px 8px 10px -5px rgb(0 0 0 / 20%), 0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%)',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    [theme.breakpoints.maxWidth('sm')]: {
      backgroundColor: '#fff !important',
      //minWidth: 250,
      //maxWidth: 250,
    },
    '& .MuiButtonBase-root.MuiIconButton-root': {
      margin: '32px 8px 0 auto',
    },
  },
  drawerModal: {
    zIndex: '1999 !important',
    visibility: 'visible !important',
    maxWidth: 305,
    '&.MuiModal-hidden': {
      maxWidth: 36,
    },
    '& .MuiBackdrop-root': {
      opacity: '15% !important',
      backgroundColor: '#212346',
    },
    [theme.breakpoints.maxWidth('sm')]: {
      top: '80px !important',
      zIndex: '2000 !important',
      '&.MuiModal-hidden': {
        maxWidth: 0,
      },
    },
  },
  mobileMenuUserInfo: {
    padding: theme.spacing(5, 5, 2.5, 5),
    backgroundColor: 'rgba(0, 0, 0, 0.25)',
    [theme.breakpoints.maxWidth('sm')]: {
      flexDirection: 'row!important',
    },
  },
  avatar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 64,
    height: 64,
    borderRadius: '50%',
    overflow: 'hidden',
    backgroundColor: '#37C670',
    [theme.breakpoints.maxWidth('sm')]: {
      width: 30,
      height: 30,
      marginBottom: '0!important',
    },
  },
  spinner: {
    backgroundColor: 'rgba(31, 33, 72, 0.3)',
    zIndex: 3000,
    color: '#F1F2F8',
  },
}));

export default useStyles;
