import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Box, Button, CircularProgress, Grid, PaginationItem, Typography } from '@mui/material';
import {
  createNewSavedQuery,
  deleteSavedQuery,
  editQuery,
  getConfirmations,
  getConfirmationsFilters,
  getProjectsList,
  getSavedQuery,
  getSpentTimeFilters,
  saveQuery,
} from '../../../packages/common/api';
import { useLocation, useNavigate } from 'react-router';
import Page from '../../../packages/common/shared-ui/src/components/Page';
import {
  DataGridPro,
  gridPageCountSelector,
  GridPagination,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro';
import CheckboxIcon from '../../../packages/common/shared-ui/src/icons/CheckboxIcon';
import CheckboxIconChecked from '../../../packages/common/shared-ui/src/icons/CheckboxIconChecked';
import SortIcon from '../../../packages/common/shared-ui/src/icons/SortIcon';
import dataGridTableStyles from '../../../packages/common/shared-ui/src/styles/DataGridTable.styles';
import { ArrayParam, NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import GridFiltersModal from '../GridFiltersModal/GridFiltersModal';
import MuiPagination from '@mui/material/Pagination';
import PaginationNextButtonIcon from '../../../packages/common/shared-ui/src/icons/PaginationNextButtonIcon';
import PaginationPreviousButtonIcon from '../../../packages/common/shared-ui/src/icons/PaginationPreviousButtonIcon';
import { Link, useParams } from 'react-router-dom';
import { generateRedirectUrl } from '../../../packages/common/utils/getRedirectUrl';
import FilterIcon from '../../../packages/common/shared-ui/src/icons/FilterIcon';
import GridFiltersPanel from '../GridFiltersPanel/GridFiltersPanel';
import GridSaveQueryModal from '../GridSaveQueryModal/GridSaveQueryModal';
import dayjs from 'dayjs';
import { filterDateValues, filtersToQuery } from '../../../packages/common/utils/gridFiltersHelper';
import WarningModal from '../../../packages/common/shared-ui/src/components/WarningModal';
import { store } from '../app/redux';
import {
  formatQueryToSave,
  formattedParamsData,
  paramsToQuery,
  queryToParams,
} from '../../../packages/common/utils/gridFiltersHelperNew';
import GridFiltersModalNew from '../GridFiltersModalNew/GridFiltersModalNew';
import CommonPage from '../CommonPage/CommonPage';
import { ButtonsTypes } from '../../../packages/common/shared-ui/src/enums/commonEnums';
import GridFiltersPanelNew from '../GridFiltersPanelNew/GridFiltersPanelNew';
import SpentTimeTable from '../spentTime/SpentTimeTable/SpentTimeTable';
import ConfirmationsTable from './components/ConfirmationsTable';

const ConfirmationsPage = ({ intl }) => {
  const pageTitle = intl.formatMessage({ id: 'page_title_confirmations' });
  const dataGridTableStyle = dataGridTableStyles();
  const navigate = useNavigate();
  const pageUseParams = useParams();
  const [queryData, setQueryData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [paramsLoading, setParamsLoading] = useState(false);
  const [openParamsModal, setOpenParamsModal] = useState(false);
  const [openSaveQueryModal, setOpenSaveQueryModal] = useState(false);
  const [openDeleteQueryModal, setOpenDeleteQueryModal] = useState(false);
  const [confirmationsFiltersData, setConfirmationsFiltersData] = useState(null);
  const [confirmationsGridRows, setConfirmationsGridRows] = useState([]);
  const [confirmationsGridColumns, setConfirmationsGridColumns] = useState([]);
  const [pageParams, setPageParams] = useState({ checked: false, params: {} });
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [activeFields, setActiveFields] = useState({ availableFields: [], selectedFields: [] });
  const [currentParams, setCurrentParams] = useState(null);
  const [initialParams, setInitialParams] = useState(null);
  const [gridRows, setGridRows] = useState([]);
  const [gridColumns, setGridColumns] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);

  const location = useLocation();
  const state = store.getState();
  const [query, setQuery] = useQueryParams({
    filters: StringParam,
    sorts: StringParam,
    fields: StringParam,
    offset: NumberParam,
  });
  const handleError = useCallback(
    error => {
      if (error) {
        navigate(`/errors/error-${error.status}`);
      }
    },
    [navigate],
  );

  //Получение фильтров
  useEffect(() => {
    let ignore = false;
    let params = {};
    pageUseParams.queryId && (params.query = Number(pageUseParams.queryId));
    pageUseParams.projectId && (params.project = pageUseParams.projectId);
    async function getFiltersData() {
      const response = await getConfirmationsFilters(params);
      if (!ignore) {
        return response;
      }
    }

    getFiltersData()
      .then(res => {
        const { data: filtersData } = res;
        const data = {};
        filtersData.filters && (data.filters = { items: filtersData.filters, type: 'list', multiple: true });
        filtersData.fields && (data.fields = { items: filtersData.fields, type: 'columns' });
        const formattedData = formattedParamsData(data);
        !initialParams && setInitialParams(formattedData);
        if (Object.values(query).filter(item => item !== undefined).length === 0) {
          setCurrentParams(formattedData);
          setQuery(paramsToQuery(formattedData));
        }
      })
      .catch(error => handleError(error.response));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageUseParams.queryId]);

  //Получение query по id
  useEffect(() => {
    setQueryData(null);
    let ignore = false;
    async function getSavedQueries() {
      const { data: response } = await getSavedQuery({ id: pageUseParams.queryId });
      if (!ignore) {
        return response;
      }
    }
    if (pageUseParams.queryId) {
      getSavedQueries()
        .then(res => {
          res && setQueryData(res);
        })
        .catch(error => handleError(error.response));
    }
  }, [handleError, pageUseParams.queryId]);

  //Получение списка согласований
  useEffect(() => {
    let ignore = false;
    let params = {};
    setParamsLoading(true);
    let queryIsSet = false;
    queryIsSet = Object.values(query).filter(item => item !== undefined).length > 0;
    pageUseParams.projectId && (params.project = Number(pageUseParams.projectId));
    queryIsSet && (params = { ...params, ...query });
    async function getConfirmationsData() {
      const response = await getConfirmations(params);
      if (!ignore) {
        return response;
      }
    }
    if (queryIsSet) {
      getConfirmationsData()
        .then(res => {
          const { data: confirmationsData } = res;
          confirmationsData.fields && setGridColumns(confirmationsData.fields);
          confirmationsData.rows && setGridRows(confirmationsData.rows);
          confirmationsData.paging && setPagination(confirmationsData.paging);
          setLoading(false);
          setParamsLoading(false);
        })
        .catch(error => handleError(error.response));
    }
    return () => {
      ignore = true;
    };
  }, [handleError, pageUseParams.projectId, query]);

  useEffect(() => {
    if (Object.values(query).filter(item => item !== undefined).length > 0 && initialParams && !currentParams) {
      setCurrentParams(queryToParams(query, initialParams));
    }
  }, [currentParams, initialParams, query]);

  const handleParamsModalClose = useCallback(() => {
    setOpenParamsModal(false);
  }, []);
  const handleSaveQueryModalClose = useCallback(() => {
    setOpenSaveQueryModal(false);
  }, []);

  const handleSelectFilters = useCallback(
    data => {
      if (!data.hasOwnProperty('operation')) {
        return;
      }
      const items = currentParams.filters.selectedItems.map(item => {
        if (item.fieldId === data.fieldId) {
          return data;
        } else {
          return item;
        }
      });
      const updatedParams = { ...currentParams, filters: { ...currentParams.filters, selectedItems: items } };
      setCurrentParams(updatedParams);
      setQuery(paramsToQuery(updatedParams));
    },
    [currentParams, setQuery],
  );

  const handleParamsModalSave = useCallback(
    data => {
      setCurrentParams(data);
      setQuery(paramsToQuery(data));
      setOpenParamsModal(false);
    },
    [setQuery],
  );

  const handleSaveQueryModalSave = useCallback(
    async modalData => {
      const value = formatQueryToSave(modalData, currentParams, 'confirmation');
      try {
        setSaveLoader(true);
        let response;
        if (pageUseParams.queryId) {
          response = await editQuery(pageUseParams.queryId, value);
        } else {
          response = await saveQuery(value);
        }
        if (response) {
          setSaveLoader(false);
          const { data: res } = response;
          if (pageUseParams.projectId) {
            window.open(`/projects/${pageUseParams.projectId}/confirmations/query/${res.id}`, '_self');
          } else {
            window.open(`/confirmations/query/${res.id}`, '_self');
          }
        }
      } catch (error) {
        handleError(error.response);
      }
      setOpenSaveQueryModal(false);
    },
    [currentParams, handleError, pageUseParams.queryId, pageUseParams.projectId],
  );
  const handleChangeSorting = useCallback(
    newSortModel => {
      if (!newSortModel || newSortModel.length === 0) {
        setQuery(prev => ({ ...prev, sorts: undefined }));
        return;
      }

      const sortString = newSortModel.map(item => `${item.field};${item.sort}`).join('$$');

      setQuery(prev => ({ ...prev, sorts: sortString }));
    },
    [setQuery],
  );
  const handleButtonEvent = useCallback(type => {
    switch (type) {
      case ButtonsTypes.PARAMS:
        setOpenParamsModal(true);
        break;
      case ButtonsTypes.QUERY:
        setOpenSaveQueryModal(true);
        break;
      case ButtonsTypes.EDIT_QUERY:
        setOpenSaveQueryModal(true);
        break;
      case ButtonsTypes.DELETE_QUERY:
        setOpenWarningModal(true);
        break;
    }
  }, []);
  const handleChangePagination = useCallback(
    data => {
      setQuery(prev => ({ ...prev, offset: data * 100 }));
    },
    [setQuery],
  );
  const handleWarningModalClose = useCallback(() => {
    setOpenWarningModal(false);
  }, []);
  const handleToggleDelete = useCallback(
    async e => {
      switch (e.currentTarget.attributes.value.value) {
        case 'save':
          await deleteSavedQuery({ id: pageUseParams.queryId });
          navigate('/confirmations');
          setOpenWarningModal(false);
          break;
        case 'delete':
          setOpenWarningModal(false);
          break;
      }
    },
    [pageUseParams.queryId, navigate],
  );
  return (
    <>
      <WarningModal
        simple
        isWarningModalOpen={openWarningModal}
        handleToggle={handleToggleDelete}
        closeWarningModal={handleWarningModalClose}
        title={`${intl.formatMessage({ id: 'spent_time_delete' })}?`}
      />
      {currentParams && (
        <GridFiltersModalNew
          handleSave={data => handleParamsModalSave(data)}
          handleClose={handleParamsModalClose}
          open={openParamsModal}
          data={currentParams}
        />
      )}
      <GridSaveQueryModal
        saveLoader={saveLoader}
        permission={'canSaveConfirmationGlobalQueries'}
        queryData={queryData}
        open={openSaveQueryModal}
        handleSave={data => handleSaveQueryModalSave(data)}
        handleClose={handleSaveQueryModalClose}
      />
      <CommonPage
        pageTitle={pageTitle}
        loading={loading}
        //canCreate={isAddButtonDisplayed}
        buttonEvent={handleButtonEvent}
        queryName={queryData?.name}
      >
        {currentParams && currentParams.filters?.selectedItems?.length > 0 && (
          <GridFiltersPanelNew
            data={currentParams.filters.selectedItems}
            handleCloseFilter={handleSelectFilters}
          ></GridFiltersPanelNew>
        )}
        {gridColumns && gridRows && (
          <Grid overflow={'hidden'} container flexDirection={'column'} wrap="nowrap" position={'relative'}>
            {paramsLoading && (
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                width="100%"
                height="100%"
                zIndex={99}
                position={'absolute'}
              >
                <CircularProgress color="secondary" />
              </Grid>
            )}
            <ConfirmationsTable
              intl={intl}
              columnsData={gridColumns}
              rowsData={gridRows}
              changePagination={handleChangePagination}
              changeSorting={handleChangeSorting}
              paginationData={pagination}
            />
          </Grid>
        )}
      </CommonPage>
    </>
  );
  /*  return (
    <>
      {loading ? (
        <Grid container alignItems="center" justifyContent="center" width="100%" height="70px">
          <CircularProgress color="secondary" />
        </Grid>
      ) : (
        <>
          {queryData && (
            <WarningModal
              simple
              isWarningModalOpen={openDeleteQueryModal}
              handleToggle={handleDeleteQueryModal}
              closeWarningModal={handleDeleteQueryModalClose}
              title={`${intl.formatMessage({ id: 'spent_time_delete' })}?`}
            />
          )}
          {confirmationsFiltersData && (
            <GridFiltersModal
              selectedFilters={selectedFilters}
              activeFields={activeFields}
              handleSave={data => handleParamsModalSave(data)}
              handleClear={data => handleParamsModalClear(data)}
              handleClose={handleParamsModalClose}
              open={openParamsModal}
              data={confirmationsFiltersData}
            />
          )}
          <GridSaveQueryModal
            queryData={queryData}
            open={openSaveQueryModal}
            handleSave={data => handleSaveQueryModalSave(data)}
            handleClose={handleSaveQueryModalClose}
          />
          <Page
            title={pageTitle}
            width="100%"
            sx={{ height: 'calc(100vh - 80px)', display: 'flex', flexDirection: 'column', paddingBottom: '20px' }}
          >
            <Grid container justifyContent={'space-between'} alignItems={'center'} marginBottom={'20px'}>
              <Grid item>
                <Grid container alignItems={'center'}>
                  <Grid item>
                    <Typography variant="h1">{pageTitle}</Typography>
                  </Grid>
                  {queryData && queryData.name && (
                    <Grid item>
                      <Typography variant="h2" color={'#082253'} marginLeft={'12px'} fontWeight={'400'}>
                        {queryData.name}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item>
                <Grid container>
                  {/!*                  <Button variant="defaultGreyPrimary" disableRipple onClick={handleSaveQueryModalOpen}>
                    <Typography variant="h4" fontWeight={600}>
                      <FormattedMessage id="spent_time_save" />
                    </Typography>
                  </Button>*!/}
                  {/!*                  {queryData && (
                    <>
                      <Button
                        variant="defaultGreyPrimary"
                        disableRipple
                        onClick={handleSaveQueryModalOpen}
                        sx={{ marginLeft: '10px' }}
                      >
                        <Typography variant="h4" fontWeight={600}>
                          <FormattedMessage id="spent_time_edit" />
                        </Typography>
                      </Button>
                      <Button
                        variant="defaultGreyPrimary"
                        disableRipple
                        onClick={handleDeleteQueryModalOpen}
                        sx={{ marginLeft: '10px' }}
                      >
                        <Typography variant="h4" fontWeight={600}>
                          <FormattedMessage id="spent_time_delete" />
                        </Typography>
                      </Button>
                    </>
                  )}*!/}
                  {confirmationsFiltersData && (
                    <Button
                      variant="defaultGreyPrimaryWithIcon"
                      disableRipple
                      onClick={handleParamsModalOpen}
                      sx={{ marginLeft: '10px' }}
                    >
                      <Box display="flex" alignItems="center" marginRight={'4px'}>
                        <FilterIcon width="20" height="20" viewBox="0 0 20 20" />
                      </Box>
                      <Typography variant="h4" fontWeight={600}>
                        <FormattedMessage id="parameters_text" />
                      </Typography>
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
            {selectedFilters.length > 0 && (
              <GridFiltersPanel data={selectedFilters} handleCloseFilter={handleSelectFilters}></GridFiltersPanel>
            )}
            {confirmationsGridRows && confirmationsGridColumns && (
              <Grid
                container
                flexDirection={'column'}
                flex={1}
                height={'100%'}
                overflow={'hidden'}
                position={'relative'}
              >
                {paramsLoading && (
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    width="100%"
                    height="100%"
                    zIndex={99}
                    position={'absolute'}
                  >
                    <CircularProgress color="secondary" />
                  </Grid>
                )}
                <DataGridPro
                  pagination
                  autoPageSize
                  disableColumnFilter
                  disableRowSelectionOnClick
                  disableColumnMenu
                  columns={confirmationsColumnsValue}
                  rows={confirmationsGridRows}
                  className={dataGridTableStyle.DataGridTableRoot}
                  getRowId={item => item.id}
                  //sortModel={sortModel ? sortModel : []}
                  //onSortModelChange={model => setSortModel(model?.length > 0 ? model : sortModel)}
                  //sortingMode="server"
                  //sortingOrder={['asc', 'desc', null]}
                  slotProps={{
                    baseCheckbox: {
                      icon: <CheckboxIcon width="12" height="12" viewBox="0 0 12 12" />,
                      checkedIcon: <CheckboxIconChecked width="12" height="12" viewBox="0 0 12 12" />,
                    },
                  }}
                  slots={{
                    pagination: CustomPagination,
                    columnUnsortedIcon: () => <SortIcon width="20" height="20" viewBox="0 0 20 20" />,
                    columnSortedAscendingIcon: () => (
                      <SortIcon direction="up" width="20" height="20" viewBox="0 0 20 20" />
                    ),
                    columnSortedDescendingIcon: () => (
                      <SortIcon direction="down" width="20" height="20" viewBox="0 0 20 20" />
                    ),
                  }}
                  getRowClassName={params => params.row.groupName && 'groupRow'}
                  isRowSelectable={params => !params.row.groupName}
                  getRowHeight={() => 'auto'}
                />
              </Grid>
            )}
          </Page>
        </>
      )}
    </>
  );*/
};
export default injectIntl(ConfirmationsPage);
