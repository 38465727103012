import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, Button, Dialog, Grid, IconButton, Modal, Typography } from '@mui/material';
import ClearIcon from 'Common/shared-ui/src/icons/ClearIcon';
import useStyles from './WarningModal.styles';
import clsx from 'clsx';
import { ButtonsTypes } from '../../enums/commonEnums';

const WarningModal = ({ simple, isWarningModalOpen, closeWarningModal, handleToggle, title, issue }) => {
  const classes = useStyles();
  return (
    <Dialog open={isWarningModalOpen} disableRestoreFocus className={classes.modalRoot}>
      <Grid container direction="column">
        <Grid container justifyContent="flex-end">
          <IconButton disableRipple onClick={closeWarningModal} value="close" sx={{ padding: issue ? 0 : '8px' }}>
            <Box width={24} height={24}>
              <ClearIcon width={24} height={24} viewBox="0 0 24 24" />
            </Box>
          </IconButton>
        </Grid>
        <Grid container direction="column" overflow={'hidden'} flexWrap={'nowrap'} marginTop={'40px'}>
          <Typography
            variant={simple ? 'h1' : 'h3'}
            textAlign="center"
            fontWeight={simple ? '700' : '600'}
            color={'#082253'}
          >
            {title}
          </Typography>
        </Grid>
        <Grid container justifyContent="center" marginTop={'40px'}>
          <Button
            disableRipple
            className={clsx('defaultButton', 'primary')}
            value="save"
            onClick={handleToggle}
            sx={{ minWidth: '100px' }}
          >
            <Typography variant="h4" fontWeight={700}>
              <FormattedMessage id="yes" />
            </Typography>
          </Button>
          <Button
            disableRipple
            className={clsx('defaultButton', 'secondary')}
            value="delete"
            onClick={handleToggle}
            sx={{ marginLeft: '4px', minWidth: '100px' }}
          >
            <Typography variant="h4" fontWeight={700}>
              <FormattedMessage id="no" />
            </Typography>
          </Button>
          {!simple && (
            <Button
              disableRipple
              variant="defaultBluePrimary"
              value="cancel"
              onClick={handleToggle}
              sx={{ minWidth: '100px' }}
            >
              <Typography variant="h4" fontWeight={700}>
                <FormattedMessage id="cancel" />
              </Typography>
            </Button>
          )}
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default WarningModal;
