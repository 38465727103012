import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  modalRoot: {
    '& .MuiDialog-container': {
      padding: '84px 0 20px 0',
    },
    '& .MuiPaper-root.MuiDialog-paper': {
      overflow: 'visible',
      padding: '20px',
      width: '362px',
      backgroundColor: '#fff',
      borderRadius: '16px',
      margin: 0,
      maxHeight: '100%',
    },
  },
  /*  calendarWarningRoot: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&.MuiModal-root': {
      zIndex: 2000,
    },
  },
  calendarWarningPaper: {
    backgroundColor: '#ff',
    padding: '20px',
    borderRadius: '16px',
    maxWidth: 362,
  },
  issueWarningPaper: {
    backgroundColor: '#FFFFFF',
    padding: '20px',
    borderRadius: '16px',
    maxWidth: 362,
  },*/
}));

export default useStyles;
