import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Popper,
  TextField,
  Typography,
} from '@mui/material';
import FormField from '../../../../packages/common/shared-ui/src/components/FormField/FormField';
import React, { useCallback, useEffect, useState } from 'react';
import userPageStyles from '../../administration/userPage/UserPage.styles';
import ChevronIcon from '../../../../packages/common/shared-ui/src/icons/ChevronIcon';
import { injectIntl } from 'react-intl';
import formFieldStyles from '../../../../packages/common/shared-ui/src/components/FormField/FormField.styles';
import clsx from 'clsx';
import {
  addProjectMember,
  deleteConfirmationSettingsRow,
  deleteProjectMember,
  editProject,
  getProjectMembers,
  getProjectMembersRoles,
  saveIssueWatchers,
  updateProjectMember,
} from '../../../../packages/common/api';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import CheckboxIconChecked from '../../../../packages/common/shared-ui/src/icons/CheckboxIconChecked';
import DeleteIcon from '../../../../packages/common/shared-ui/src/icons/DeleteIcon';
import CheckboxIcon from '../../../../packages/common/shared-ui/src/icons/CheckboxIcon';
import SubmitIcon from '../../../../packages/common/shared-ui/src/icons/SubmitIcon';
import DeleteIconBig from '../../../../packages/common/shared-ui/src/icons/DeleteIconBig';
import UserNewEditIcon from '../../../../packages/common/shared-ui/src/icons/UserNewEditIcon';

const ProjectMembers = ({ intl }) => {
  const commonClasses = userPageStyles();
  const classes = formFieldStyles();
  const navigate = useNavigate();
  const pageParams = useParams();

  const [loading, setLoading] = useState(true);
  const [members, setMembers] = useState([]);
  const [membersOptions, setMembersOptions] = useState([]);
  const [roles, setRoles] = useState([]);
  const [actionLoading, setActionLoading] = useState(false);

  const handleError = useCallback(
    error => {
      if (error) {
        navigate(`/errors/error-${error.status}`);
      }
    },
    [navigate],
  );

  useEffect(() => {
    let ignore = false;
    async function getMembers() {
      const response = await getProjectMembers(pageParams.projectId);
      if (!ignore) {
        return response;
      }
    }
    getMembers()
      .then(res => {
        const { data: membersData } = res;
        setMembers(membersData.filter(item => item.isChecked));
        setMembersOptions(membersData.filter(item => !item.isChecked));
        setLoading(false);
      })
      .catch(error => handleError(error.response));
    return () => {
      ignore = true;
    };
  }, [handleError, pageParams.projectId]);

  useEffect(() => {
    let ignore = false;
    async function getRoles() {
      const response = await getProjectMembersRoles(pageParams.projectId);
      if (!ignore) {
        return response;
      }
    }
    getRoles()
      .then(res => {
        const { data: rolesData } = res;
        setRoles(rolesData);
      })
      .catch(error => handleError(error.response));
    return () => {
      ignore = true;
    };
  }, [handleError, pageParams.projectId]);

  const handleAddMember = useCallback(value => {
    setMembers(members => [...members, { ...value, new: true, edit: true }]);
  }, []);
  const handleDeleteMember = useCallback(
    async member => {
      setActionLoading(true);
      if (member.new) {
        const newArray = members.filter(item => item.userId !== member.userId);
        setMembers(newArray);
        setActionLoading(false);
        return;
      }
      try {
        const { data: response } = await deleteProjectMember(pageParams.projectId, member.memberId);
        if (response) {
          setActionLoading(false);
          setMembers(response.filter(item => item.isChecked));
        }
      } catch (error) {
        console.error('ERROR WITH DELETE', error);
      }
    },
    [members, pageParams.projectId],
  );
  const handleSaveMember = useCallback(
    async member => {
      setActionLoading(true);
      let data = {
        userId: member.userId,
        roles: member.roles.map(item => item.roleId),
      };
      try {
        let response;
        if (member.new) {
          response = await addProjectMember(pageParams.projectId, data);
        } else {
          delete data.userId;
          response = await updateProjectMember(pageParams.projectId, member.memberId, data);
        }
        if (response) {
          const { data: members } = response;
          setActionLoading(false);
          setMembers(members.filter(item => item.isChecked));
          setMembersOptions(members.filter(item => !item.isChecked));
        }
      } catch (error) {
        console.error('ERROR WITH SAVE', error);
      }
    },
    [pageParams.projectId],
  );
  const handleEditMember = useCallback(
    member => {
      const index = members.findIndex(item => item.memberId === member.memberId);
      const updatedObject = { ...members[index], edit: true };
      setMembers([...members.slice(0, index), updatedObject, ...members.slice(index + 1)]);
    },
    [members],
  );

  const handleRoleSelect = useCallback(
    (value, member) => {
      const pos = members.map(e => e.userId).indexOf(member.userId);
      const updatedObject = {
        ...member,
        roles: value,
      };
      const newMembers = [...members.slice(0, pos), updatedObject, ...members.slice(pos + 1)];
      setMembers(newMembers);
    },
    [members],
  );
  return (
    <>
      {loading ? (
        <Grid container alignItems="center" justifyContent="center" width="100%">
          <CircularProgress color="secondary" />
        </Grid>
      ) : (
        <Grid
          container
          direction={'column'}
          className={commonClasses.blockWrapper}
          padding={'24px 16px 16px 32px'}
          position={'relative'}
        >
          {actionLoading && (
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              width="100%"
              height="100%"
              zIndex={99}
              left={0}
              top={0}
              position={'absolute'}
            >
              <CircularProgress color="secondary" />
            </Grid>
          )}
          {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
          <Typography variant="h3" color={'#082253'} fontWeight={600} marginBottom={'24px'}>
            Участники
          </Typography>
          <Grid container>
            {members.length > 0 &&
              members.map((item, index) => {
                return (
                  <Grid key={index} container flexWrap={'nowrap'}>
                    <Grid item minWidth={'215px'} paddingTop={item.new ? '8px' : 0}>
                      <Typography variant="h5" color={'#212346'}>
                        {item.userName}
                      </Typography>
                    </Grid>
                    <Grid item flex={1}>
                      {item.edit ? (
                        <Autocomplete
                          forcePopupIcon={false}
                          className={clsx(
                            classes.fieldAutocomplete,
                            classes.fieldAutocompleteMultiple,
                            classes.fieldAutocompleteBorderless,
                          )}
                          multiple
                          disableClearable
                          //open={true}
                          onChange={(event, newValue) => handleRoleSelect(newValue, item)}
                          options={roles}
                          sx={{ width: '100%' }}
                          disableCloseOnSelect
                          disablePortal
                          value={item.roles}
                          //isOptionEqualToValue={(option, value) => option.fieldId === value.fieldId}
                          getOptionLabel={option => option.roleName || ''}
                          renderOption={(props, option, { selected }) => (
                            <li {...props} key={option.roleId}>
                              <Checkbox
                                icon=<CheckboxIcon width="12" height="12" viewBox="0 0 12 12" />
                                checkedIcon=<CheckboxIconChecked width="12" height="12" viewBox="0 0 12 12" />
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.roleName}
                            </li>
                          )}
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip
                                {...getTagProps({ index })}
                                key={index}
                                deleteIcon={<DeleteIcon width={10} height={10} viewBox="0 0 10 10" />}
                                className={classes.chip}
                                variant="outlined"
                                label={option.roleName}
                              />
                            ))
                          }
                          renderInput={params => (
                            <TextField {...params} placeholder={intl.formatMessage({ id: 'add_user_role' })} />
                          )}
                        />
                      ) : (
                        <Grid container flexDirection={'column'}>
                          {item.roles.map((item, index) => {
                            return (
                              <Box key={index} marginTop={index > 0 ? '16px' : 0}>
                                <Typography variant="h5" color={'#212346'}>
                                  {item.roleName}
                                </Typography>
                              </Box>
                            );
                          })}
                        </Grid>
                      )}
                    </Grid>
                    <Grid item justifyContent={'flex-end'}>
                      <Grid container marginLeft={'16px'}>
                        <Grid item>
                          {item.edit ? (
                            <IconButton onClick={() => handleSaveMember(item)} sx={{ padding: '8px', height: '100%' }}>
                              <SubmitIcon width={'16px'} />
                            </IconButton>
                          ) : (
                            <IconButton onClick={() => handleEditMember(item)} sx={{ padding: '8px', height: '100%' }}>
                              <UserNewEditIcon width="16" height="16" viewBox="0 0 16 16" />
                            </IconButton>
                          )}
                        </Grid>
                        {!item.isUnremovable && (
                          <Grid item>
                            <IconButton
                              onClick={() => handleDeleteMember(item)}
                              sx={{ padding: '8px', height: '100%' }}
                            >
                              <DeleteIconBig />
                            </IconButton>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
          </Grid>
          <Box>
            <Autocomplete
              noOptionsText={intl.formatMessage({ id: 'not_found' })}
              disabled={members.filter(item => item.new).length > 0}
              onChange={(event, newValue) => handleAddMember(newValue)}
              className={clsx(classes.fieldAutocomplete, classes.fieldAutocompleteBorderless)}
              disablePortal
              options={membersOptions}
              //sx={{ width: 253 }}
              disableClearable
              renderOption={(props, option) => {
                return (
                  <MenuItem
                    {...props}
                    key={option.userId}
                    value={option.userId}
                    //selected={field.valueId && option.valueId.toString() === field.valueId.toString()}
                  >
                    {option.userName}
                  </MenuItem>
                );
              }}
              value={null}
              //isOptionEqualToValue={(option, value) => option.userId === value.userId}
              getOptionLabel={option => option.userName || ''}
              PopperComponent={props => <Popper {...props} placement="bottom-start"></Popper>}
              renderInput={params => <TextField {...params} placeholder={intl.formatMessage({ id: 'add_user' })} />}
              forcePopupIcon={false}
            />
          </Box>
        </Grid>
      )}
    </>
  );
};

export default injectIntl(ProjectMembers);
